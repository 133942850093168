@font-face {
  font-family: "uicons-bold-rounded";
  src: url("../fonts/uicons-bold-rounded.eot#iefix") format("embedded-opentype"),
    url("../fonts/uicons-bold-rounded.woff2") format("woff2"),
    url("../fonts/uicons-bold-rounded.woff") format("woff");
}

i[class^="fi-br-"]:before,
i[class*=" fi-br-"]:before,
span[class^="fi-br-"]:before,
span[class*="fi-br-"]:before {
  font-family: uicons-bold-rounded !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi-br-a:before {
  content: "\f101";
}
.fi-br-ad:before {
  content: "\f102";
}
.fi-br-add-document:before {
  content: "\f103";
}
.fi-br-add-folder:before {
  content: "\f104";
}
.fi-br-add:before {
  content: "\f105";
}
.fi-br-address-book:before {
  content: "\f106";
}
.fi-br-air-conditioner:before {
  content: "\f107";
}
.fi-br-air-freshener:before {
  content: "\f108";
}
.fi-br-alarm-clock:before {
  content: "\f109";
}
.fi-br-alarm-exclamation:before {
  content: "\f10a";
}
.fi-br-alarm-plus:before {
  content: "\f10b";
}
.fi-br-alarm-snooze:before {
  content: "\f10c";
}
.fi-br-align-justify:before {
  content: "\f10d";
}
.fi-br-align-left:before {
  content: "\f10e";
}
.fi-br-ambulance:before {
  content: "\f10f";
}
.fi-br-angle-circle-down:before {
  content: "\f110";
}
.fi-br-angle-circle-left:before {
  content: "\f111";
}
.fi-br-angle-circle-right:before {
  content: "\f112";
}
.fi-br-angle-circle-up:before {
  content: "\f113";
}
.fi-br-angle-double-left:before {
  content: "\f114";
}
.fi-br-angle-double-right:before {
  content: "\f115";
}
.fi-br-angle-double-small-down:before {
  content: "\f116";
}
.fi-br-angle-double-small-left:before {
  content: "\f117";
}
.fi-br-angle-double-small-right:before {
  content: "\f118";
}
.fi-br-angle-double-small-up:before {
  content: "\f119";
}
.fi-br-angle-down:before {
  content: "\f11a";
}
.fi-br-angle-left:before {
  content: "\f11b";
}
.fi-br-angle-right:before {
  content: "\f11c";
}
.fi-br-angle-small-down:before {
  content: "\f11d";
}
.fi-br-angle-small-left:before {
  content: "\f11e";
}
.fi-br-angle-small-right:before {
  content: "\f11f";
}
.fi-br-angle-small-up:before {
  content: "\f120";
}
.fi-br-angle-square-down:before {
  content: "\f121";
}
.fi-br-angle-square-left:before {
  content: "\f122";
}
.fi-br-angle-square-right:before {
  content: "\f123";
}
.fi-br-angle-square-up:before {
  content: "\f124";
}
.fi-br-angle-up:before {
  content: "\f125";
}
.fi-br-angry:before {
  content: "\f126";
}
.fi-br-apple-whole:before {
  content: "\f127";
}
.fi-br-apps-add:before {
  content: "\f128";
}
.fi-br-apps-delete:before {
  content: "\f129";
}
.fi-br-apps-sort:before {
  content: "\f12a";
}
.fi-br-apps:before {
  content: "\f12b";
}
.fi-br-archive:before {
  content: "\f12c";
}
.fi-br-arrow-alt-circle-down:before {
  content: "\f12d";
}
.fi-br-arrow-alt-circle-left:before {
  content: "\f12e";
}
.fi-br-arrow-alt-circle-right:before {
  content: "\f12f";
}
.fi-br-arrow-alt-circle-up:before {
  content: "\f130";
}
.fi-br-arrow-alt-down:before {
  content: "\f131";
}
.fi-br-arrow-alt-from-bottom:before {
  content: "\f132";
}
.fi-br-arrow-alt-from-left:before {
  content: "\f133";
}
.fi-br-arrow-alt-from-right:before {
  content: "\f134";
}
.fi-br-arrow-alt-from-top:before {
  content: "\f135";
}
.fi-br-arrow-alt-left:before {
  content: "\f136";
}
.fi-br-arrow-alt-right:before {
  content: "\f137";
}
.fi-br-arrow-alt-square-down:before {
  content: "\f138";
}
.fi-br-arrow-alt-square-left:before {
  content: "\f139";
}
.fi-br-arrow-alt-square-right:before {
  content: "\f13a";
}
.fi-br-arrow-alt-square-up:before {
  content: "\f13b";
}
.fi-br-arrow-alt-to-bottom:before {
  content: "\f13c";
}
.fi-br-arrow-alt-to-left:before {
  content: "\f13d";
}
.fi-br-arrow-alt-to-right:before {
  content: "\f13e";
}
.fi-br-arrow-alt-to-top:before {
  content: "\f13f";
}
.fi-br-arrow-alt-up:before {
  content: "\f140";
}
.fi-br-arrow-circle-down:before {
  content: "\f141";
}
.fi-br-arrow-circle-left:before {
  content: "\f142";
}
.fi-br-arrow-circle-right:before {
  content: "\f143";
}
.fi-br-arrow-circle-up:before {
  content: "\f144";
}
.fi-br-arrow-down:before {
  content: "\f145";
}
.fi-br-arrow-from-bottom:before {
  content: "\f146";
}
.fi-br-arrow-from-left:before {
  content: "\f147";
}
.fi-br-arrow-from-right:before {
  content: "\f148";
}
.fi-br-arrow-from-top:before {
  content: "\f149";
}
.fi-br-arrow-left:before {
  content: "\f14a";
}
.fi-br-arrow-right:before {
  content: "\f14b";
}
.fi-br-arrow-small-down:before {
  content: "\f14c";
}
.fi-br-arrow-small-left:before {
  content: "\f14d";
}
.fi-br-arrow-small-right:before {
  content: "\f14e";
}
.fi-br-arrow-small-up:before {
  content: "\f14f";
}
.fi-br-arrow-square-down:before {
  content: "\f150";
}
.fi-br-arrow-square-left:before {
  content: "\f151";
}
.fi-br-arrow-square-right:before {
  content: "\f152";
}
.fi-br-arrow-square-up:before {
  content: "\f153";
}
.fi-br-arrow-to-bottom:before {
  content: "\f154";
}
.fi-br-arrow-to-left:before {
  content: "\f155";
}
.fi-br-arrow-to-right:before {
  content: "\f156";
}
.fi-br-arrow-to-top:before {
  content: "\f157";
}
.fi-br-arrow-up:before {
  content: "\f158";
}
.fi-br-arrows-alt-h:before {
  content: "\f159";
}
.fi-br-arrows-alt-v:before {
  content: "\f15a";
}
.fi-br-arrows-alt:before {
  content: "\f15b";
}
.fi-br-arrows-h-copy:before {
  content: "\f15c";
}
.fi-br-arrows-h:before {
  content: "\f15d";
}
.fi-br-arrows:before {
  content: "\f15e";
}
.fi-br-assept-document:before {
  content: "\f15f";
}
.fi-br-assistive-listening-systems:before {
  content: "\f160";
}
.fi-br-asterik:before {
  content: "\f161";
}
.fi-br-astonished-face:before {
  content: "\f162";
}
.fi-br-at:before {
  content: "\f163";
}
.fi-br-aubergine:before {
  content: "\f164";
}
.fi-br-avocado:before {
  content: "\f165";
}
.fi-br-b:before {
  content: "\f166";
}
.fi-br-baby-carriage:before {
  content: "\f167";
}
.fi-br-backpack:before {
  content: "\f168";
}
.fi-br-bacon:before {
  content: "\f169";
}
.fi-br-badge-sheriff:before {
  content: "\f16a";
}
.fi-br-badge-check:before {
  content: "\f16b";
}
.fi-br-badge-dollar:before {
  content: "\f16c";
}
.fi-br-badge-percent:before {
  content: "\f16d";
}
.fi-br-badge:before {
  content: "\f16e";
}
.fi-br-bags-shopping:before {
  content: "\f16f";
}
.fi-br-bahai:before {
  content: "\f170";
}
.fi-br-balance-scale-left:before {
  content: "\f171";
}
.fi-br-balance-scale-right:before {
  content: "\f172";
}
.fi-br-balloons:before {
  content: "\f173";
}
.fi-br-ballot:before {
  content: "\f174";
}
.fi-br-ban:before {
  content: "\f175";
}
.fi-br-band-aid:before {
  content: "\f176";
}
.fi-br-bank:before {
  content: "\f177";
}
.fi-br-barber-shop:before {
  content: "\f178";
}
.fi-br-bars-progress:before {
  content: "\f179";
}
.fi-br-baseball-alt:before {
  content: "\f17a";
}
.fi-br-baseball:before {
  content: "\f17b";
}
.fi-br-basketball-hoop:before {
  content: "\f17c";
}
.fi-br-basketball:before {
  content: "\f17d";
}
.fi-br-bed-alt:before {
  content: "\f17e";
}
.fi-br-bed:before {
  content: "\f17f";
}
.fi-br-beer:before {
  content: "\f180";
}
.fi-br-bell-ring:before {
  content: "\f181";
}
.fi-br-bell-school:before {
  content: "\f182";
}
.fi-br-bell:before {
  content: "\f183";
}
.fi-br-bike:before {
  content: "\f184";
}
.fi-br-biking-mountain:before {
  content: "\f185";
}
.fi-br-biking:before {
  content: "\f186";
}
.fi-br-billiard:before {
  content: "\f187";
}
.fi-br-blinds-open:before {
  content: "\f188";
}
.fi-br-blinds-raised:before {
  content: "\f189";
}
.fi-br-blinds:before {
  content: "\f18a";
}
.fi-br-bold:before {
  content: "\f18b";
}
.fi-br-bolt:before {
  content: "\f18c";
}
.fi-br-book-alt:before {
  content: "\f18d";
}
.fi-br-book-arrow-right:before {
  content: "\f18e";
}
.fi-br-book-arrow-up:before {
  content: "\f18f";
}
.fi-br-book-bookmark:before {
  content: "\f190";
}
.fi-br-book-open-cover:before {
  content: "\f191";
}
.fi-br-book-open-reader:before {
  content: "\f192";
}
.fi-br-book:before {
  content: "\f193";
}
.fi-br-bookmark:before {
  content: "\f194";
}
.fi-br-books:before {
  content: "\f195";
}
.fi-br-bottle:before {
  content: "\f196";
}
.fi-br-bow-arrow:before {
  content: "\f197";
}
.fi-br-bowling-ball:before {
  content: "\f198";
}
.fi-br-bowling-pins:before {
  content: "\f199";
}
.fi-br-bowling:before {
  content: "\f19a";
}
.fi-br-box-check:before {
  content: "\f19b";
}
.fi-br-box-tissue:before {
  content: "\f19c";
}
.fi-br-box-alt:before {
  content: "\f19d";
}
.fi-br-box-ballot:before {
  content: "\f19e";
}
.fi-br-box:before {
  content: "\f19f";
}
.fi-br-boxes:before {
  content: "\f1a0";
}
.fi-br-boxing-glove:before {
  content: "\f1a1";
}
.fi-br-braille:before {
  content: "\f1a2";
}
.fi-br-bread-slice:before {
  content: "\f1a3";
}
.fi-br-bread:before {
  content: "\f1a4";
}
.fi-br-briefcase:before {
  content: "\f1a5";
}
.fi-br-broccoli:before {
  content: "\f1a6";
}
.fi-br-broom:before {
  content: "\f1a7";
}
.fi-br-browser:before {
  content: "\f1a8";
}
.fi-br-brush:before {
  content: "\f1a9";
}
.fi-br-bug:before {
  content: "\f1aa";
}
.fi-br-building:before {
  content: "\f1ab";
}
.fi-br-bulb:before {
  content: "\f1ac";
}
.fi-br-bullseye-pointer:before {
  content: "\f1ad";
}
.fi-br-bullseye:before {
  content: "\f1ae";
}
.fi-br-burrito:before {
  content: "\f1af";
}
.fi-br-bus-alt:before {
  content: "\f1b0";
}
.fi-br-bus:before {
  content: "\f1b1";
}
.fi-br-business-time:before {
  content: "\f1b2";
}
.fi-br-butterfly:before {
  content: "\f1b3";
}
.fi-br-cake-birthday:before {
  content: "\f1b4";
}
.fi-br-cake-wedding:before {
  content: "\f1b5";
}
.fi-br-calculator:before {
  content: "\f1b6";
}
.fi-br-calendar-check:before {
  content: "\f1b7";
}
.fi-br-calendar-clock:before {
  content: "\f1b8";
}
.fi-br-calendar-exclamation:before {
  content: "\f1b9";
}
.fi-br-calendar-lines-pen:before {
  content: "\f1ba";
}
.fi-br-calendar-lines:before {
  content: "\f1bb";
}
.fi-br-calendar-minus:before {
  content: "\f1bc";
}
.fi-br-calendar-pen:before {
  content: "\f1bd";
}
.fi-br-calendar-plus:before {
  content: "\f1be";
}
.fi-br-calendar:before {
  content: "\f1bf";
}
.fi-br-calendars:before {
  content: "\f1c0";
}
.fi-br-call-history:before {
  content: "\f1c1";
}
.fi-br-call-incoming:before {
  content: "\f1c2";
}
.fi-br-call-missed:before {
  content: "\f1c3";
}
.fi-br-call-outgoing:before {
  content: "\f1c4";
}
.fi-br-camcorder:before {
  content: "\f1c5";
}
.fi-br-camera:before {
  content: "\f1c6";
}
.fi-br-camping:before {
  content: "\f1c7";
}
.fi-br-candy-alt:before {
  content: "\f1c8";
}
.fi-br-candy:before {
  content: "\f1c9";
}
.fi-br-canned-food:before {
  content: "\f1ca";
}
.fi-br-car-bolt:before {
  content: "\f1cb";
}
.fi-br-car-circle-bolt:before {
  content: "\f1cc";
}
.fi-br-car-side-bolt:before {
  content: "\f1cd";
}
.fi-br-car-alt:before {
  content: "\f1ce";
}
.fi-br-car-battery:before {
  content: "\f1cf";
}
.fi-br-car-building:before {
  content: "\f1d0";
}
.fi-br-car-bump:before {
  content: "\f1d1";
}
.fi-br-car-bus:before {
  content: "\f1d2";
}
.fi-br-car-crash:before {
  content: "\f1d3";
}
.fi-br-car-garage:before {
  content: "\f1d4";
}
.fi-br-car-mechanic:before {
  content: "\f1d5";
}
.fi-br-car-side:before {
  content: "\f1d6";
}
.fi-br-car-tilt:before {
  content: "\f1d7";
}
.fi-br-car-wash:before {
  content: "\f1d8";
}
.fi-br-car:before {
  content: "\f1d9";
}
.fi-br-caravan-alt:before {
  content: "\f1da";
}
.fi-br-caravan:before {
  content: "\f1db";
}
.fi-br-care:before {
  content: "\f1dc";
}
.fi-br-caret-circle-down:before {
  content: "\f1dd";
}
.fi-br-caret-circle-right:before {
  content: "\f1de";
}
.fi-br-caret-circle-up:before {
  content: "\f1df";
}
.fi-br-caret-down:before {
  content: "\f1e0";
}
.fi-br-caret-left:before {
  content: "\f1e1";
}
.fi-br-caret-quare-up:before {
  content: "\f1e2";
}
.fi-br-caret-right:before {
  content: "\f1e3";
}
.fi-br-caret-square-down:before {
  content: "\f1e4";
}
.fi-br-caret-square-left_1:before {
  content: "\f1e5";
}
.fi-br-caret-square-left:before {
  content: "\f1e6";
}
.fi-br-caret-square-right:before {
  content: "\f1e7";
}
.fi-br-caret-up:before {
  content: "\f1e8";
}
.fi-br-carrot:before {
  content: "\f1e9";
}
.fi-br-cars:before {
  content: "\f1ea";
}
.fi-br-cash-register:before {
  content: "\f1eb";
}
.fi-br-chair-office:before {
  content: "\f1ec";
}
.fi-br-chair:before {
  content: "\f1ed";
}
.fi-br-charging-station:before {
  content: "\f1ee";
}
.fi-br-chart-area:before {
  content: "\f1ef";
}
.fi-br-chart-connected:before {
  content: "\f1f0";
}
.fi-br-chart-histogram:before {
  content: "\f1f1";
}
.fi-br-chart-line-up:before {
  content: "\f1f2";
}
.fi-br-chart-network:before {
  content: "\f1f3";
}
.fi-br-chart-pie-alt:before {
  content: "\f1f4";
}
.fi-br-chart-pie:before {
  content: "\f1f5";
}
.fi-br-chart-pyramid:before {
  content: "\f1f6";
}
.fi-br-chart-scatter:before {
  content: "\f1f7";
}
.fi-br-chart-set-theory:before {
  content: "\f1f8";
}
.fi-br-chart-tree:before {
  content: "\f1f9";
}
.fi-br-chat-arrow-down:before {
  content: "\f1fa";
}
.fi-br-chat-arrow-grow:before {
  content: "\f1fb";
}
.fi-br-check:before {
  content: "\f1fc";
}
.fi-br-checkbox:before {
  content: "\f1fd";
}
.fi-br-cheese:before {
  content: "\f1fe";
}
.fi-br-cherry:before {
  content: "\f1ff";
}
.fi-br-chess-board:before {
  content: "\f200";
}
.fi-br-chess-clock:before {
  content: "\f201";
}
.fi-br-chess-pawn:before {
  content: "\f202";
}
.fi-br-chess-piece:before {
  content: "\f203";
}
.fi-br-chess-bishop:before {
  content: "\f204";
}
.fi-br-chess-clock-alt:before {
  content: "\f205";
}
.fi-br-chess-king-alt:before {
  content: "\f206";
}
.fi-br-chess-king:before {
  content: "\f207";
}
.fi-br-chess-knight-alt:before {
  content: "\f208";
}
.fi-br-chess-knight:before {
  content: "\f209";
}
.fi-br-chess-pawn-alt:before {
  content: "\f20a";
}
.fi-br-chess-queen-alt:before {
  content: "\f20b";
}
.fi-br-chess-queen:before {
  content: "\f20c";
}
.fi-br-chess-rook-alt:before {
  content: "\f20d";
}
.fi-br-chess-rook:before {
  content: "\f20e";
}
.fi-br-chess:before {
  content: "\f20f";
}
.fi-br-chevron-double-down:before {
  content: "\f210";
}
.fi-br-chevron-double-up:before {
  content: "\f211";
}
.fi-br-child-head:before {
  content: "\f212";
}
.fi-br-chocolate:before {
  content: "\f213";
}
.fi-br-circle-phone:before {
  content: "\f214";
}
.fi-br-circle-envelope:before {
  content: "\f215";
}
.fi-br-circle-phone-flip:before {
  content: "\f216";
}
.fi-br-circle-phone-hangup:before {
  content: "\f217";
}
.fi-br-circle-small:before {
  content: "\f218";
}
.fi-br-circle:before {
  content: "\f219";
}
.fi-br-clip:before {
  content: "\f21a";
}
.fi-br-clipboard-list:before {
  content: "\f21b";
}
.fi-br-clipboard-list-check:before {
  content: "\f21c";
}
.fi-br-clock-eight-thirty:before {
  content: "\f21d";
}
.fi-br-clock-eleven-thirty:before {
  content: "\f21e";
}
.fi-br-clock-eleven:before {
  content: "\f21f";
}
.fi-br-clock-five-thirty:before {
  content: "\f220";
}
.fi-br-clock-five:before {
  content: "\f221";
}
.fi-br-clock-four-thirty:before {
  content: "\f222";
}
.fi-br-clock-nine-thirty:before {
  content: "\f223";
}
.fi-br-clock-nine:before {
  content: "\f224";
}
.fi-br-clock-one-thirty:before {
  content: "\f225";
}
.fi-br-clock-one:before {
  content: "\f226";
}
.fi-br-clock-seven-thirty:before {
  content: "\f227";
}
.fi-br-clock-seven:before {
  content: "\f228";
}
.fi-br-clock-six-thirty:before {
  content: "\f229";
}
.fi-br-clock-six:before {
  content: "\f22a";
}
.fi-br-clock-ten-thirty:before {
  content: "\f22b";
}
.fi-br-clock-ten:before {
  content: "\f22c";
}
.fi-br-clock-three-thirty:before {
  content: "\f22d";
}
.fi-br-clock-three:before {
  content: "\f22e";
}
.fi-br-clock-twelve-thirty:before {
  content: "\f22f";
}
.fi-br-clock-twelve:before {
  content: "\f230";
}
.fi-br-clock-two-thirty:before {
  content: "\f231";
}
.fi-br-clock-two:before {
  content: "\f232";
}
.fi-br-clock:before {
  content: "\f233";
}
.fi-br-cloud-upload:before {
  content: "\f234";
}
.fi-br-cloud-check:before {
  content: "\f235";
}
.fi-br-cloud-disabled:before {
  content: "\f236";
}
.fi-br-cloud-download-alt:before {
  content: "\f237";
}
.fi-br-cloud-download:before {
  content: "\f238";
}
.fi-br-cloud-drizzle:before {
  content: "\f239";
}
.fi-br-cloud-hail-mixed:before {
  content: "\f23a";
}
.fi-br-cloud-hail:before {
  content: "\f23b";
}
.fi-br-cloud-moon-rain:before {
  content: "\f23c";
}
.fi-br-cloud-moon:before {
  content: "\f23d";
}
.fi-br-cloud-rain:before {
  content: "\f23e";
}
.fi-br-cloud-rainbow:before {
  content: "\f23f";
}
.fi-br-cloud-share:before {
  content: "\f240";
}
.fi-br-cloud-showers-heavy:before {
  content: "\f241";
}
.fi-br-cloud-showers:before {
  content: "\f242";
}
.fi-br-cloud-sleet:before {
  content: "\f243";
}
.fi-br-cloud-snow:before {
  content: "\f244";
}
.fi-br-cloud-sun-rain:before {
  content: "\f245";
}
.fi-br-cloud-sun:before {
  content: "\f246";
}
.fi-br-cloud-upload-alt:before {
  content: "\f247";
}
.fi-br-cloud:before {
  content: "\f248";
}
.fi-br-clouds-moon:before {
  content: "\f249";
}
.fi-br-clouds-sun:before {
  content: "\f24a";
}
.fi-br-clouds:before {
  content: "\f24b";
}
.fi-br-club:before {
  content: "\f24c";
}
.fi-br-cocktail-alt:before {
  content: "\f24d";
}
.fi-br-cocktail:before {
  content: "\f24e";
}
.fi-br-coffee-pot:before {
  content: "\f24f";
}
.fi-br-coffee:before {
  content: "\f250";
}
.fi-br-coin:before {
  content: "\f251";
}
.fi-br-coins:before {
  content: "\f252";
}
.fi-br-comet:before {
  content: "\f253";
}
.fi-br-comment-alt-middle-top:before {
  content: "\f254";
}
.fi-br-comment-alt-middle:before {
  content: "\f255";
}
.fi-br-comment-alt:before {
  content: "\f256";
}
.fi-br-comment-arrow-down:before {
  content: "\f257";
}
.fi-br-comment-arrow-up-right:before {
  content: "\f258";
}
.fi-br-comment-arrow-up:before {
  content: "\f259";
}
.fi-br-comment-check:before {
  content: "\f25a";
}
.fi-br-comment-code:before {
  content: "\f25b";
}
.fi-br-comment-dollar:before {
  content: "\f25c";
}
.fi-br-comment-exclamation:before {
  content: "\f25d";
}
.fi-br-comment-heart:before {
  content: "\f25e";
}
.fi-br-comment-image:before {
  content: "\f25f";
}
.fi-br-comment-info:before {
  content: "\f260";
}
.fi-br-comment-pen:before {
  content: "\f261";
}
.fi-br-comment-question:before {
  content: "\f262";
}
.fi-br-comment-quote:before {
  content: "\f263";
}
.fi-br-comment-slash:before {
  content: "\f264";
}
.fi-br-comment-sms:before {
  content: "\f265";
}
.fi-br-comment-text:before {
  content: "\f266";
}
.fi-br-comment-user:before {
  content: "\f267";
}
.fi-br-comment-xmark:before {
  content: "\f268";
}
.fi-br-comment:before {
  content: "\f269";
}
.fi-br-comments-dollar:before {
  content: "\f26a";
}
.fi-br-comments-question-check:before {
  content: "\f26b";
}
.fi-br-comments-question:before {
  content: "\f26c";
}
.fi-br-comments:before {
  content: "\f26d";
}
.fi-br-compress-alt:before {
  content: "\f26e";
}
.fi-br-compress:before {
  content: "\f26f";
}
.fi-br-computer:before {
  content: "\f270";
}
.fi-br-confetti:before {
  content: "\f271";
}
.fi-br-cookie:before {
  content: "\f272";
}
.fi-br-copy-alt:before {
  content: "\f273";
}
.fi-br-copy:before {
  content: "\f274";
}
.fi-br-copyright:before {
  content: "\f275";
}
.fi-br-corn:before {
  content: "\f276";
}
.fi-br-couple:before {
  content: "\f277";
}
.fi-br-cow:before {
  content: "\f278";
}
.fi-br-cream:before {
  content: "\f279";
}
.fi-br-credit-card:before {
  content: "\f27a";
}
.fi-br-cricket:before {
  content: "\f27b";
}
.fi-br-croissant:before {
  content: "\f27c";
}
.fi-br-cross-circle:before {
  content: "\f27d";
}
.fi-br-cross-small:before {
  content: "\f27e";
}
.fi-br-cross:before {
  content: "\f27f";
}
.fi-br-crown:before {
  content: "\f280";
}
.fi-br-crystal-ball:before {
  content: "\f281";
}
.fi-br-cube:before {
  content: "\f282";
}
.fi-br-cupcake:before {
  content: "\f283";
}
.fi-br-curling:before {
  content: "\f284";
}
.fi-br-cursor-finger:before {
  content: "\f285";
}
.fi-br-cursor-plus:before {
  content: "\f286";
}
.fi-br-cursor-text-alt:before {
  content: "\f287";
}
.fi-br-cursor-text:before {
  content: "\f288";
}
.fi-br-cursor:before {
  content: "\f289";
}
.fi-br-d:before {
  content: "\f28a";
}
.fi-br-dart:before {
  content: "\f28b";
}
.fi-br-dashboard:before {
  content: "\f28c";
}
.fi-br-data-transfer:before {
  content: "\f28d";
}
.fi-br-database:before {
  content: "\f28e";
}
.fi-br-delete-document:before {
  content: "\f28f";
}
.fi-br-delete-user:before {
  content: "\f290";
}
.fi-br-delete:before {
  content: "\f291";
}
.fi-br-democrat:before {
  content: "\f292";
}
.fi-br-dewpoint:before {
  content: "\f293";
}
.fi-br-diamond:before {
  content: "\f294";
}
.fi-br-dice-alt:before {
  content: "\f295";
}
.fi-br-dice-d10:before {
  content: "\f296";
}
.fi-br-dice-d12:before {
  content: "\f297";
}
.fi-br-dice-d20:before {
  content: "\f298";
}
.fi-br-dice-d4:before {
  content: "\f299";
}
.fi-br-dice-d6:before {
  content: "\f29a";
}
.fi-br-dice-d8:before {
  content: "\f29b";
}
.fi-br-dice-four:before {
  content: "\f29c";
}
.fi-br-dice-one:before {
  content: "\f29d";
}
.fi-br-dice-six:before {
  content: "\f29e";
}
.fi-br-dice-three:before {
  content: "\f29f";
}
.fi-br-dice-two:before {
  content: "\f2a0";
}
.fi-br-dice:before {
  content: "\f2a1";
}
.fi-br-diploma:before {
  content: "\f2a2";
}
.fi-br-disco-ball:before {
  content: "\f2a3";
}
.fi-br-disk:before {
  content: "\f2a4";
}
.fi-br-dizzy:before {
  content: "\f2a5";
}
.fi-br-doctor:before {
  content: "\f2a6";
}
.fi-br-document-signed:before {
  content: "\f2a7";
}
.fi-br-document:before {
  content: "\f2a8";
}
.fi-br-dollar:before {
  content: "\f2a9";
}
.fi-br-donate:before {
  content: "\f2aa";
}
.fi-br-down-left-and-up-right-to-center:before {
  content: "\f2ab";
}
.fi-br-download:before {
  content: "\f2ac";
}
.fi-br-drafting-compass:before {
  content: "\f2ad";
}
.fi-br-dreidel:before {
  content: "\f2ae";
}
.fi-br-drink-alt:before {
  content: "\f2af";
}
.fi-br-drumstick:before {
  content: "\f2b0";
}
.fi-br-duplicate:before {
  content: "\f2b1";
}
.fi-br-e-learning:before {
  content: "\f2b2";
}
.fi-br-e:before {
  content: "\f2b3";
}
.fi-br-earnings:before {
  content: "\f2b4";
}
.fi-br-eclipse-alt:before {
  content: "\f2b5";
}
.fi-br-eclipse:before {
  content: "\f2b6";
}
.fi-br-edit-alt:before {
  content: "\f2b7";
}
.fi-br-edit:before {
  content: "\f2b8";
}
.fi-br-egg-fried:before {
  content: "\f2b9";
}
.fi-br-egg:before {
  content: "\f2ba";
}
.fi-br-engine-warning:before {
  content: "\f2bb";
}
.fi-br-enter:before {
  content: "\f2bc";
}
.fi-br-envelope-ban:before {
  content: "\f2bd";
}
.fi-br-envelope-bulk:before {
  content: "\f2be";
}
.fi-br-envelope-download:before {
  content: "\f2bf";
}
.fi-br-envelope-marker:before {
  content: "\f2c0";
}
.fi-br-envelope-open-dollar:before {
  content: "\f2c1";
}
.fi-br-envelope-open-text:before {
  content: "\f2c2";
}
.fi-br-envelope-open:before {
  content: "\f2c3";
}
.fi-br-envelope-plus:before {
  content: "\f2c4";
}
.fi-br-envelope:before {
  content: "\f2c5";
}
.fi-br-equality:before {
  content: "\f2c6";
}
.fi-br-euro:before {
  content: "\f2c7";
}
.fi-br-exchange-alt:before {
  content: "\f2c8";
}
.fi-br-exchange:before {
  content: "\f2c9";
}
.fi-br-exclamation:before {
  content: "\f2ca";
}
.fi-br-exit:before {
  content: "\f2cb";
}
.fi-br-expand-arrows-alt:before {
  content: "\f2cc";
}
.fi-br-expand-arrows:before {
  content: "\f2cd";
}
.fi-br-expand:before {
  content: "\f2ce";
}
.fi-br-eye-crossed:before {
  content: "\f2cf";
}
.fi-br-eye-dropper:before {
  content: "\f2d0";
}
.fi-br-eye:before {
  content: "\f2d1";
}
.fi-br-f:before {
  content: "\f2d2";
}
.fi-br-feather:before {
  content: "\f2d3";
}
.fi-br-female-couple:before {
  content: "\f2d4";
}
.fi-br-female:before {
  content: "\f2d5";
}
.fi-br-ferris-wheel:before {
  content: "\f2d6";
}
.fi-br-field-hockey:before {
  content: "\f2d7";
}
.fi-br-fighter-jet:before {
  content: "\f2d8";
}
.fi-br-file-invoice-dollar:before {
  content: "\f2d9";
}
.fi-br-file-invoice:before {
  content: "\f2da";
}
.fi-br-file-ai:before {
  content: "\f2db";
}
.fi-br-file-chart-line:before {
  content: "\f2dc";
}
.fi-br-file-chart-pie:before {
  content: "\f2dd";
}
.fi-br-file-eps:before {
  content: "\f2de";
}
.fi-br-file-psd:before {
  content: "\f2df";
}
.fi-br-file-spreadsheet:before {
  content: "\f2e0";
}
.fi-br-file-video:before {
  content: "\f2e1";
}
.fi-br-file:before {
  content: "\f2e2";
}
.fi-br-fill:before {
  content: "\f2e3";
}
.fi-br-film-slash:before {
  content: "\f2e4";
}
.fi-br-film:before {
  content: "\f2e5";
}
.fi-br-filter-slash:before {
  content: "\f2e6";
}
.fi-br-filter:before {
  content: "\f2e7";
}
.fi-br-filters:before {
  content: "\f2e8";
}
.fi-br-fingerprint:before {
  content: "\f2e9";
}
.fi-br-fish:before {
  content: "\f2ea";
}
.fi-br-flag-alt:before {
  content: "\f2eb";
}
.fi-br-flag-usa:before {
  content: "\f2ec";
}
.fi-br-flag:before {
  content: "\f2ed";
}
.fi-br-flame:before {
  content: "\f2ee";
}
.fi-br-flip-horizontal:before {
  content: "\f2ef";
}
.fi-br-flower-bouquet:before {
  content: "\f2f0";
}
.fi-br-flower-tulip:before {
  content: "\f2f1";
}
.fi-br-flower:before {
  content: "\f2f2";
}
.fi-br-flushed-face:before {
  content: "\f2f3";
}
.fi-br-flushed:before {
  content: "\f2f4";
}
.fi-br-fog:before {
  content: "\f2f5";
}
.fi-br-folder-download:before {
  content: "\f2f6";
}
.fi-br-folder-minus:before {
  content: "\f2f7";
}
.fi-br-folder-times:before {
  content: "\f2f8";
}
.fi-br-folder-tree:before {
  content: "\f2f9";
}
.fi-br-folder-upload:before {
  content: "\f2fa";
}
.fi-br-folder:before {
  content: "\f2fb";
}
.fi-br-folders:before {
  content: "\f2fc";
}
.fi-br-following:before {
  content: "\f2fd";
}
.fi-br-football:before {
  content: "\f2fe";
}
.fi-br-fork:before {
  content: "\f2ff";
}
.fi-br-form:before {
  content: "\f300";
}
.fi-br-forward:before {
  content: "\f301";
}
.fi-br-fox:before {
  content: "\f302";
}
.fi-br-french-fries:before {
  content: "\f303";
}
.fi-br-ftp:before {
  content: "\f304";
}
.fi-br-funnel-dollar:before {
  content: "\f305";
}
.fi-br-g:before {
  content: "\f306";
}
.fi-br-gallery:before {
  content: "\f307";
}
.fi-br-game-board-alt:before {
  content: "\f308";
}
.fi-br-gamepad:before {
  content: "\f309";
}
.fi-br-garage-car:before {
  content: "\f30a";
}
.fi-br-garage-open:before {
  content: "\f30b";
}
.fi-br-garage:before {
  content: "\f30c";
}
.fi-br-garlic:before {
  content: "\f30d";
}
.fi-br-gas-pump-alt:before {
  content: "\f30e";
}
.fi-br-gas-pump-slash:before {
  content: "\f30f";
}
.fi-br-gas-pump:before {
  content: "\f310";
}
.fi-br-gem:before {
  content: "\f311";
}
.fi-br-gif:before {
  content: "\f312";
}
.fi-br-gift-card:before {
  content: "\f313";
}
.fi-br-gift:before {
  content: "\f314";
}
.fi-br-gifts:before {
  content: "\f315";
}
.fi-br-gingerbread-man:before {
  content: "\f316";
}
.fi-br-glass-cheers:before {
  content: "\f317";
}
.fi-br-glass:before {
  content: "\f318";
}
.fi-br-glasses:before {
  content: "\f319";
}
.fi-br-globe-alt:before {
  content: "\f31a";
}
.fi-br-globe:before {
  content: "\f31b";
}
.fi-br-golf-ball:before {
  content: "\f31c";
}
.fi-br-golf-club:before {
  content: "\f31d";
}
.fi-br-golf:before {
  content: "\f31e";
}
.fi-br-graduation-cap:before {
  content: "\f31f";
}
.fi-br-grape:before {
  content: "\f320";
}
.fi-br-graphic-tablet:before {
  content: "\f321";
}
.fi-br-grid-alt:before {
  content: "\f322";
}
.fi-br-grid:before {
  content: "\f323";
}
.fi-br-grill:before {
  content: "\f324";
}
.fi-br-grimace:before {
  content: "\f325";
}
.fi-br-grin-alt:before {
  content: "\f326";
}
.fi-br-grin-beam-sweat:before {
  content: "\f327";
}
.fi-br-grin-beam:before {
  content: "\f328";
}
.fi-br-grin-hearts:before {
  content: "\f329";
}
.fi-br-grin-squint-tears:before {
  content: "\f32a";
}
.fi-br-grin-squint:before {
  content: "\f32b";
}
.fi-br-grin-stars:before {
  content: "\f32c";
}
.fi-br-grin-tears:before {
  content: "\f32d";
}
.fi-br-grin-tongue-squint:before {
  content: "\f32e";
}
.fi-br-grin-tongue-wink:before {
  content: "\f32f";
}
.fi-br-grin-tongue:before {
  content: "\f330";
}
.fi-br-grin-wink:before {
  content: "\f331";
}
.fi-br-grin:before {
  content: "\f332";
}
.fi-br-guitar:before {
  content: "\f333";
}
.fi-br-gym:before {
  content: "\f334";
}
.fi-br-h:before {
  content: "\f335";
}
.fi-br-hamburger-soda:before {
  content: "\f336";
}
.fi-br-hamburger:before {
  content: "\f337";
}
.fi-br-hand-holding-box:before {
  content: "\f338";
}
.fi-br-hand-holding-seeding:before {
  content: "\f339";
}
.fi-br-hand:before {
  content: "\f33a";
}
.fi-br-handshake:before {
  content: "\f33b";
}
.fi-br-happy:before {
  content: "\f33c";
}
.fi-br-hastag:before {
  content: "\f33d";
}
.fi-br-hat-birthday:before {
  content: "\f33e";
}
.fi-br-hat-chef:before {
  content: "\f33f";
}
.fi-br-head-side-thinking:before {
  content: "\f340";
}
.fi-br-headphones:before {
  content: "\f341";
}
.fi-br-headset:before {
  content: "\f342";
}
.fi-br-heart-arrow:before {
  content: "\f343";
}
.fi-br-heart:before {
  content: "\f344";
}
.fi-br-heat:before {
  content: "\f345";
}
.fi-br-helicopter-side:before {
  content: "\f346";
}
.fi-br-highlighter:before {
  content: "\f347";
}
.fi-br-hiking:before {
  content: "\f348";
}
.fi-br-hockey-mask:before {
  content: "\f349";
}
.fi-br-hockey-puck:before {
  content: "\f34a";
}
.fi-br-hockey-sticks:before {
  content: "\f34b";
}
.fi-br-home-location-alt:before {
  content: "\f34c";
}
.fi-br-home-location:before {
  content: "\f34d";
}
.fi-br-home:before {
  content: "\f34e";
}
.fi-br-hotdog:before {
  content: "\f34f";
}
.fi-br-hourglass-end:before {
  content: "\f350";
}
.fi-br-hourglass:before {
  content: "\f351";
}
.fi-br-house-flood:before {
  content: "\f352";
}
.fi-br-hryvnia:before {
  content: "\f353";
}
.fi-br-humidity:before {
  content: "\f354";
}
.fi-br-hurricane:before {
  content: "\f355";
}
.fi-br-i:before {
  content: "\f356";
}
.fi-br-ice-cream:before {
  content: "\f357";
}
.fi-br-ice-skate:before {
  content: "\f358";
}
.fi-br-id-badge:before {
  content: "\f359";
}
.fi-br-inbox-in:before {
  content: "\f35a";
}
.fi-br-inbox-out:before {
  content: "\f35b";
}
.fi-br-inbox:before {
  content: "\f35c";
}
.fi-br-incognito:before {
  content: "\f35d";
}
.fi-br-indent:before {
  content: "\f35e";
}
.fi-br-infinity:before {
  content: "\f35f";
}
.fi-br-info:before {
  content: "\f360";
}
.fi-br-interactive:before {
  content: "\f361";
}
.fi-br-interlining:before {
  content: "\f362";
}
.fi-br-interrogation:before {
  content: "\f363";
}
.fi-br-italic:before {
  content: "\f364";
}
.fi-br-j:before {
  content: "\f365";
}
.fi-br-jam:before {
  content: "\f366";
}
.fi-br-jpg:before {
  content: "\f367";
}
.fi-br-k:before {
  content: "\f368";
}
.fi-br-kerning:before {
  content: "\f369";
}
.fi-br-key:before {
  content: "\f36a";
}
.fi-br-keyboard:before {
  content: "\f36b";
}
.fi-br-keynote:before {
  content: "\f36c";
}
.fi-br-kiss-beam:before {
  content: "\f36d";
}
.fi-br-kiss-wink-heart:before {
  content: "\f36e";
}
.fi-br-kiss:before {
  content: "\f36f";
}
.fi-br-kite:before {
  content: "\f370";
}
.fi-br-knife:before {
  content: "\f371";
}
.fi-br-l:before {
  content: "\f372";
}
.fi-br-label:before {
  content: "\f373";
}
.fi-br-laptop:before {
  content: "\f374";
}
.fi-br-lasso:before {
  content: "\f375";
}
.fi-br-laugh-beam:before {
  content: "\f376";
}
.fi-br-laugh-squint:before {
  content: "\f377";
}
.fi-br-laugh-wink:before {
  content: "\f378";
}
.fi-br-laugh:before {
  content: "\f379";
}
.fi-br-layer-minus:before {
  content: "\f37a";
}
.fi-br-layer-plus:before {
  content: "\f37b";
}
.fi-br-layers:before {
  content: "\f37c";
}
.fi-br-layout-fluid:before {
  content: "\f37d";
}
.fi-br-leaf:before {
  content: "\f37e";
}
.fi-br-lemon:before {
  content: "\f37f";
}
.fi-br-letter-case:before {
  content: "\f380";
}
.fi-br-lettuce:before {
  content: "\f381";
}
.fi-br-level-down-alt:before {
  content: "\f382";
}
.fi-br-level-down:before {
  content: "\f383";
}
.fi-br-level-up-alt:before {
  content: "\f384";
}
.fi-br-level-up:before {
  content: "\f385";
}
.fi-br-life-ring:before {
  content: "\f386";
}
.fi-br-lightbulb-dollar:before {
  content: "\f387";
}
.fi-br-line-width:before {
  content: "\f388";
}
.fi-br-link-alt:before {
  content: "\f389";
}
.fi-br-link-slash-alt:before {
  content: "\f38a";
}
.fi-br-link-slash:before {
  content: "\f38b";
}
.fi-br-link:before {
  content: "\f38c";
}
.fi-br-lipstick:before {
  content: "\f38d";
}
.fi-br-lira-sign:before {
  content: "\f38e";
}
.fi-br-list-check:before {
  content: "\f38f";
}
.fi-br-list:before {
  content: "\f390";
}
.fi-br-loading:before {
  content: "\f391";
}
.fi-br-location-alt:before {
  content: "\f392";
}
.fi-br-lock-alt:before {
  content: "\f393";
}
.fi-br-lock:before {
  content: "\f394";
}
.fi-br-luchador:before {
  content: "\f395";
}
.fi-br-luggage-rolling:before {
  content: "\f396";
}
.fi-br-m:before {
  content: "\f397";
}
.fi-br-magic-wand:before {
  content: "\f398";
}
.fi-br-mailbox:before {
  content: "\f399";
}
.fi-br-makeup-brush:before {
  content: "\f39a";
}
.fi-br-male-couple:before {
  content: "\f39b";
}
.fi-br-male:before {
  content: "\f39c";
}
.fi-br-man-head:before {
  content: "\f39d";
}
.fi-br-map-marker-cross:before {
  content: "\f39e";
}
.fi-br-map-marker-home:before {
  content: "\f39f";
}
.fi-br-map-marker-minus:before {
  content: "\f3a0";
}
.fi-br-map-marker-plus:before {
  content: "\f3a1";
}
.fi-br-map-marker:before {
  content: "\f3a2";
}
.fi-br-map:before {
  content: "\f3a3";
}
.fi-br-marker-time:before {
  content: "\f3a4";
}
.fi-br-marker:before {
  content: "\f3a5";
}
.fi-br-mask-carnival:before {
  content: "\f3a6";
}
.fi-br-medicine:before {
  content: "\f3a7";
}
.fi-br-megaphone:before {
  content: "\f3a8";
}
.fi-br-meh-blank:before {
  content: "\f3a9";
}
.fi-br-meh-rolling-eyes:before {
  content: "\f3aa";
}
.fi-br-melon:before {
  content: "\f3ab";
}
.fi-br-menu-burger:before {
  content: "\f3ac";
}
.fi-br-menu-dots-vertical:before {
  content: "\f3ad";
}
.fi-br-menu-dots:before {
  content: "\f3ae";
}
.fi-br-meteor:before {
  content: "\f3af";
}
.fi-br-microphone-alt:before {
  content: "\f3b0";
}
.fi-br-microphone:before {
  content: "\f3b1";
}
.fi-br-mind-share:before {
  content: "\f3b2";
}
.fi-br-minus-small:before {
  content: "\f3b3";
}
.fi-br-minus:before {
  content: "\f3b4";
}
.fi-br-mobile-button:before {
  content: "\f3b5";
}
.fi-br-mobile-notch:before {
  content: "\f3b6";
}
.fi-br-mobile:before {
  content: "\f3b7";
}
.fi-br-mode-landscape:before {
  content: "\f3b8";
}
.fi-br-mode-portrait:before {
  content: "\f3b9";
}
.fi-br-money-bill-wave-alt:before {
  content: "\f3ba";
}
.fi-br-money-bill-wave:before {
  content: "\f3bb";
}
.fi-br-money-check:before {
  content: "\f3bc";
}
.fi-br-money-check-edit-alt:before {
  content: "\f3bd";
}
.fi-br-money-check-edit:before {
  content: "\f3be";
}
.fi-br-money:before {
  content: "\f3bf";
}
.fi-br-moon-stars:before {
  content: "\f3c0";
}
.fi-br-moon:before {
  content: "\f3c1";
}
.fi-br-motorcycle:before {
  content: "\f3c2";
}
.fi-br-mountains:before {
  content: "\f3c3";
}
.fi-br-mouse:before {
  content: "\f3c4";
}
.fi-br-mug-alt:before {
  content: "\f3c5";
}
.fi-br-mug-hot-alt:before {
  content: "\f3c6";
}
.fi-br-mug-hot:before {
  content: "\f3c7";
}
.fi-br-mug-tea:before {
  content: "\f3c8";
}
.fi-br-mug:before {
  content: "\f3c9";
}
.fi-br-mushroom:before {
  content: "\f3ca";
}
.fi-br-music-file:before {
  content: "\f3cb";
}
.fi-br-music-alt:before {
  content: "\f3cc";
}
.fi-br-music:before {
  content: "\f3cd";
}
.fi-br-n:before {
  content: "\f3ce";
}
.fi-br-navigation:before {
  content: "\f3cf";
}
.fi-br-network-cloud:before {
  content: "\f3d0";
}
.fi-br-network:before {
  content: "\f3d1";
}
.fi-br-noodles:before {
  content: "\f3d2";
}
.fi-br-notebook:before {
  content: "\f3d3";
}
.fi-br-o:before {
  content: "\f3d4";
}
.fi-br-oil-can:before {
  content: "\f3d5";
}
.fi-br-oil-temp:before {
  content: "\f3d6";
}
.fi-br-olive-oil:before {
  content: "\f3d7";
}
.fi-br-olives:before {
  content: "\f3d8";
}
.fi-br-onion:before {
  content: "\f3d9";
}
.fi-br-opacity:before {
  content: "\f3da";
}
.fi-br-overline:before {
  content: "\f3db";
}
.fi-br-p:before {
  content: "\f3dc";
}
.fi-br-package:before {
  content: "\f3dd";
}
.fi-br-page-break:before {
  content: "\f3de";
}
.fi-br-paint-roller:before {
  content: "\f3df";
}
.fi-br-paint-brush:before {
  content: "\f3e0";
}
.fi-br-palette:before {
  content: "\f3e1";
}
.fi-br-pan:before {
  content: "\f3e2";
}
.fi-br-paper-plane:before {
  content: "\f3e3";
}
.fi-br-password:before {
  content: "\f3e4";
}
.fi-br-pause:before {
  content: "\f3e5";
}
.fi-br-paw:before {
  content: "\f3e6";
}
.fi-br-peach:before {
  content: "\f3e7";
}
.fi-br-pencil-ruler:before {
  content: "\f3e8";
}
.fi-br-pencil:before {
  content: "\f3e9";
}
.fi-br-pennant:before {
  content: "\f3ea";
}
.fi-br-people-poll:before {
  content: "\f3eb";
}
.fi-br-pepper-hot:before {
  content: "\f3ec";
}
.fi-br-pepper:before {
  content: "\f3ed";
}
.fi-br-percentage:before {
  content: "\f3ee";
}
.fi-br-pharmacy:before {
  content: "\f3ef";
}
.fi-br-phone-office:before {
  content: "\f3f0";
}
.fi-br-phone-call:before {
  content: "\f3f1";
}
.fi-br-phone-cross:before {
  content: "\f3f2";
}
.fi-br-phone-pause:before {
  content: "\f3f3";
}
.fi-br-phone-slash:before {
  content: "\f3f4";
}
.fi-br-photo-film-music:before {
  content: "\f3f5";
}
.fi-br-photo-video:before {
  content: "\f3f6";
}
.fi-br-physics:before {
  content: "\f3f7";
}
.fi-br-picnic:before {
  content: "\f3f8";
}
.fi-br-picture:before {
  content: "\f3f9";
}
.fi-br-pie:before {
  content: "\f3fa";
}
.fi-br-piggy-bank:before {
  content: "\f3fb";
}
.fi-br-pineapple:before {
  content: "\f3fc";
}
.fi-br-ping-pong:before {
  content: "\f3fd";
}
.fi-br-pizza-slice:before {
  content: "\f3fe";
}
.fi-br-plane-alt:before {
  content: "\f3ff";
}
.fi-br-plane:before {
  content: "\f400";
}
.fi-br-plate:before {
  content: "\f401";
}
.fi-br-play-alt:before {
  content: "\f402";
}
.fi-br-play-pause:before {
  content: "\f403";
}
.fi-br-play:before {
  content: "\f404";
}
.fi-br-playing-cards:before {
  content: "\f405";
}
.fi-br-plus-small:before {
  content: "\f406";
}
.fi-br-plus:before {
  content: "\f407";
}
.fi-br-podium-star:before {
  content: "\f408";
}
.fi-br-podium:before {
  content: "\f409";
}
.fi-br-poker-chip:before {
  content: "\f40a";
}
.fi-br-poo:before {
  content: "\f40b";
}
.fi-br-popcorn:before {
  content: "\f40c";
}
.fi-br-portrait:before {
  content: "\f40d";
}
.fi-br-pot:before {
  content: "\f40e";
}
.fi-br-pound:before {
  content: "\f40f";
}
.fi-br-power:before {
  content: "\f410";
}
.fi-br-presentation:before {
  content: "\f411";
}
.fi-br-print:before {
  content: "\f412";
}
.fi-br-protractor:before {
  content: "\f413";
}
.fi-br-pulse:before {
  content: "\f414";
}
.fi-br-pumpkin:before {
  content: "\f415";
}
.fi-br-puzzle-piece:before {
  content: "\f416";
}
.fi-br-pyramid:before {
  content: "\f417";
}
.fi-br-q:before {
  content: "\f418";
}
.fi-br-question-square:before {
  content: "\f419";
}
.fi-br-question:before {
  content: "\f41a";
}
.fi-br-quote-right:before {
  content: "\f41b";
}
.fi-br-r:before {
  content: "\f41c";
}
.fi-br-racquet:before {
  content: "\f41d";
}
.fi-br-radish:before {
  content: "\f41e";
}
.fi-br-rainbow:before {
  content: "\f41f";
}
.fi-br-raindrops:before {
  content: "\f420";
}
.fi-br-rec:before {
  content: "\f421";
}
.fi-br-receipt:before {
  content: "\f422";
}
.fi-br-record-vinyl:before {
  content: "\f423";
}
.fi-br-rectabgle-vertical:before {
  content: "\f424";
}
.fi-br-rectangle-horizontal:before {
  content: "\f425";
}
.fi-br-rectangle-panoramic:before {
  content: "\f426";
}
.fi-br-recycle:before {
  content: "\f427";
}
.fi-br-redo-alt:before {
  content: "\f428";
}
.fi-br-redo:before {
  content: "\f429";
}
.fi-br-reflect:before {
  content: "\f42a";
}
.fi-br-refresh:before {
  content: "\f42b";
}
.fi-br-registered:before {
  content: "\f42c";
}
.fi-br-remove-user:before {
  content: "\f42d";
}
.fi-br-reply-all:before {
  content: "\f42e";
}
.fi-br-republican:before {
  content: "\f42f";
}
.fi-br-resize:before {
  content: "\f430";
}
.fi-br-resources:before {
  content: "\f431";
}
.fi-br-restaurant:before {
  content: "\f432";
}
.fi-br-rewind:before {
  content: "\f433";
}
.fi-br-rhombus:before {
  content: "\f434";
}
.fi-br-rings-wedding:before {
  content: "\f435";
}
.fi-br-road:before {
  content: "\f436";
}
.fi-br-rocket-lunch:before {
  content: "\f437";
}
.fi-br-rocket:before {
  content: "\f438";
}
.fi-br-room-service:before {
  content: "\f439";
}
.fi-br-rotate-left:before {
  content: "\f43a";
}
.fi-br-rotate-right:before {
  content: "\f43b";
}
.fi-br-ruble-sign:before {
  content: "\f43c";
}
.fi-br-rugby-helmet:before {
  content: "\f43d";
}
.fi-br-rugby:before {
  content: "\f43e";
}
.fi-br-ruler-combined:before {
  content: "\f43f";
}
.fi-br-ruler-horizontal:before {
  content: "\f440";
}
.fi-br-ruler-triangle:before {
  content: "\f441";
}
.fi-br-ruler-vertical:before {
  content: "\f442";
}
.fi-br-running:before {
  content: "\f443";
}
.fi-br-rupee-sign:before {
  content: "\f444";
}
.fi-br-rv:before {
  content: "\f445";
}
.fi-br-s:before {
  content: "\f446";
}
.fi-br-sack-dollar:before {
  content: "\f447";
}
.fi-br-sack:before {
  content: "\f448";
}
.fi-br-sad-cry:before {
  content: "\f449";
}
.fi-br-sad-tear:before {
  content: "\f44a";
}
.fi-br-sad:before {
  content: "\f44b";
}
.fi-br-salad:before {
  content: "\f44c";
}
.fi-br-salt-pepper:before {
  content: "\f44d";
}
.fi-br-sandwich:before {
  content: "\f44e";
}
.fi-br-sauce:before {
  content: "\f44f";
}
.fi-br-sausage:before {
  content: "\f450";
}
.fi-br-scale:before {
  content: "\f451";
}
.fi-br-school-bus:before {
  content: "\f452";
}
.fi-br-school:before {
  content: "\f453";
}
.fi-br-scissors:before {
  content: "\f454";
}
.fi-br-screen:before {
  content: "\f455";
}
.fi-br-search-dollar:before {
  content: "\f456";
}
.fi-br-search-location:before {
  content: "\f457";
}
.fi-br-search-alt:before {
  content: "\f458";
}
.fi-br-search-heart:before {
  content: "\f459";
}
.fi-br-search:before {
  content: "\f45a";
}
.fi-br-sensor-alert:before {
  content: "\f45b";
}
.fi-br-sensor-fire:before {
  content: "\f45c";
}
.fi-br-sensor-on:before {
  content: "\f45d";
}
.fi-br-sensor-smoke:before {
  content: "\f45e";
}
.fi-br-sensor:before {
  content: "\f45f";
}
.fi-br-settings-sliders:before {
  content: "\f460";
}
.fi-br-settings:before {
  content: "\f461";
}
.fi-br-share:before {
  content: "\f462";
}
.fi-br-shekel-sign:before {
  content: "\f463";
}
.fi-br-shield-check:before {
  content: "\f464";
}
.fi-br-shield-exclamation:before {
  content: "\f465";
}
.fi-br-shield-interrogation:before {
  content: "\f466";
}
.fi-br-shield-plus:before {
  content: "\f467";
}
.fi-br-shield:before {
  content: "\f468";
}
.fi-br-ship-side:before {
  content: "\f469";
}
.fi-br-ship:before {
  content: "\f46a";
}
.fi-br-shop:before {
  content: "\f46b";
}
.fi-br-shopping-basket:before {
  content: "\f46c";
}
.fi-br-shopping-bag-add:before {
  content: "\f46d";
}
.fi-br-shopping-bag:before {
  content: "\f46e";
}
.fi-br-shopping-cart-add:before {
  content: "\f46f";
}
.fi-br-shopping-cart-check:before {
  content: "\f470";
}
.fi-br-shopping-cart:before {
  content: "\f471";
}
.fi-br-shrimp:before {
  content: "\f472";
}
.fi-br-shuffle:before {
  content: "\f473";
}
.fi-br-shuttle-van:before {
  content: "\f474";
}
.fi-br-shuttlecock:before {
  content: "\f475";
}
.fi-br-sign-in-alt:before {
  content: "\f476";
}
.fi-br-sign-out-alt:before {
  content: "\f477";
}
.fi-br-signal-alt-1:before {
  content: "\f478";
}
.fi-br-signal-alt-2:before {
  content: "\f479";
}
.fi-br-signal-alt:before {
  content: "\f47a";
}
.fi-br-skateboard:before {
  content: "\f47b";
}
.fi-br-skating:before {
  content: "\f47c";
}
.fi-br-skewer:before {
  content: "\f47d";
}
.fi-br-ski-jump:before {
  content: "\f47e";
}
.fi-br-ski-lift:before {
  content: "\f47f";
}
.fi-br-skiing-nordic:before {
  content: "\f480";
}
.fi-br-skiing:before {
  content: "\f481";
}
.fi-br-sledding:before {
  content: "\f482";
}
.fi-br-sleigh:before {
  content: "\f483";
}
.fi-br-smartphone:before {
  content: "\f484";
}
.fi-br-smile-beam:before {
  content: "\f485";
}
.fi-br-smog:before {
  content: "\f486";
}
.fi-br-smoke:before {
  content: "\f487";
}
.fi-br-snow-blowing:before {
  content: "\f488";
}
.fi-br-snowboarding:before {
  content: "\f489";
}
.fi-br-snowflake:before {
  content: "\f48a";
}
.fi-br-snowflakes:before {
  content: "\f48b";
}
.fi-br-snowmobile:before {
  content: "\f48c";
}
.fi-br-snowplow:before {
  content: "\f48d";
}
.fi-br-soap:before {
  content: "\f48e";
}
.fi-br-social-network:before {
  content: "\f48f";
}
.fi-br-sort-alpha-down-alt:before {
  content: "\f490";
}
.fi-br-sort-alpha-down:before {
  content: "\f491";
}
.fi-br-sort-alpha-up-alt:before {
  content: "\f492";
}
.fi-br-sort-alpha-up:before {
  content: "\f493";
}
.fi-br-sort-alt:before {
  content: "\f494";
}
.fi-br-sort-amount-down-alt:before {
  content: "\f495";
}
.fi-br-sort-amount-down:before {
  content: "\f496";
}
.fi-br-sort-amount-up-alt:before {
  content: "\f497";
}
.fi-br-sort-amount-up:before {
  content: "\f498";
}
.fi-br-sort-down:before {
  content: "\f499";
}
.fi-br-sort-numeric-down-alt:before {
  content: "\f49a";
}
.fi-br-sort-numeric-down:before {
  content: "\f49b";
}
.fi-br-sort:before {
  content: "\f49c";
}
.fi-br-soup:before {
  content: "\f49d";
}
.fi-br-spa:before {
  content: "\f49e";
}
.fi-br-space-shuttle:before {
  content: "\f49f";
}
.fi-br-spade:before {
  content: "\f4a0";
}
.fi-br-sparkles:before {
  content: "\f4a1";
}
.fi-br-speaker:before {
  content: "\f4a2";
}
.fi-br-sphere:before {
  content: "\f4a3";
}
.fi-br-spinner:before {
  content: "\f4a4";
}
.fi-br-spoon:before {
  content: "\f4a5";
}
.fi-br-square-root:before {
  content: "\f4a6";
}
.fi-br-square:before {
  content: "\f4a7";
}
.fi-br-stamp:before {
  content: "\f4a8";
}
.fi-br-star-octogram:before {
  content: "\f4a9";
}
.fi-br-star:before {
  content: "\f4aa";
}
.fi-br-starfighter:before {
  content: "\f4ab";
}
.fi-br-stars:before {
  content: "\f4ac";
}
.fi-br-stats:before {
  content: "\f4ad";
}
.fi-br-steak:before {
  content: "\f4ae";
}
.fi-br-steering-wheel:before {
  content: "\f4af";
}
.fi-br-stethoscope:before {
  content: "\f4b0";
}
.fi-br-sticker:before {
  content: "\f4b1";
}
.fi-br-stop:before {
  content: "\f4b2";
}
.fi-br-stopwatch:before {
  content: "\f4b3";
}
.fi-br-store-alt:before {
  content: "\f4b4";
}
.fi-br-strawberry:before {
  content: "\f4b5";
}
.fi-br-subtitles:before {
  content: "\f4b6";
}
.fi-br-subway:before {
  content: "\f4b7";
}
.fi-br-suitcase-alt:before {
  content: "\f4b8";
}
.fi-br-summer:before {
  content: "\f4b9";
}
.fi-br-sun:before {
  content: "\f4ba";
}
.fi-br-sunrise-alt:before {
  content: "\f4bb";
}
.fi-br-sunrise:before {
  content: "\f4bc";
}
.fi-br-sunset:before {
  content: "\f4bd";
}
.fi-br-surfing:before {
  content: "\f4be";
}
.fi-br-surprise:before {
  content: "\f4bf";
}
.fi-br-sushi:before {
  content: "\f4c0";
}
.fi-br-swimmer:before {
  content: "\f4c1";
}
.fi-br-sword:before {
  content: "\f4c2";
}
.fi-br-symbol:before {
  content: "\f4c3";
}
.fi-br-syringe:before {
  content: "\f4c4";
}
.fi-br-t:before {
  content: "\f4c5";
}
.fi-br-tablet:before {
  content: "\f4c6";
}
.fi-br-tachometer-alt-average:before {
  content: "\f4c7";
}
.fi-br-tachometer-alt-fastest:before {
  content: "\f4c8";
}
.fi-br-tachometer-alt-slow:before {
  content: "\f4c9";
}
.fi-br-tachometer-alt-slowest:before {
  content: "\f4ca";
}
.fi-br-tachometer-average:before {
  content: "\f4cb";
}
.fi-br-tachometer-fast:before {
  content: "\f4cc";
}
.fi-br-tachometer-fastest:before {
  content: "\f4cd";
}
.fi-br-tachometer-slow:before {
  content: "\f4ce";
}
.fi-br-tachometer-slowest:before {
  content: "\f4cf";
}
.fi-br-tachometer:before {
  content: "\f4d0";
}
.fi-br-taco:before {
  content: "\f4d1";
}
.fi-br-tags:before {
  content: "\f4d2";
}
.fi-br-tally:before {
  content: "\f4d3";
}
.fi-br-target:before {
  content: "\f4d4";
}
.fi-br-taxi:before {
  content: "\f4d5";
}
.fi-br-temperature-down:before {
  content: "\f4d6";
}
.fi-br-temperature-frigid:before {
  content: "\f4d7";
}
.fi-br-temperature-high:before {
  content: "\f4d8";
}
.fi-br-temperature-low:before {
  content: "\f4d9";
}
.fi-br-temperature-up:before {
  content: "\f4da";
}
.fi-br-tenge:before {
  content: "\f4db";
}
.fi-br-tennis:before {
  content: "\f4dc";
}
.fi-br-terrace:before {
  content: "\f4dd";
}
.fi-br-test-tube:before {
  content: "\f4de";
}
.fi-br-test:before {
  content: "\f4df";
}
.fi-br-text-check:before {
  content: "\f4e0";
}
.fi-br-text-slash:before {
  content: "\f4e1";
}
.fi-br-text:before {
  content: "\f4e2";
}
.fi-br-thermometer-half:before {
  content: "\f4e3";
}
.fi-br-thumbtack:before {
  content: "\f4e4";
}
.fi-br-thunderstorm-moon:before {
  content: "\f4e5";
}
.fi-br-thunderstorm-sun:before {
  content: "\f4e6";
}
.fi-br-thunderstorm:before {
  content: "\f4e7";
}
.fi-br-ticket:before {
  content: "\f4e8";
}
.fi-br-time-quarter-past:before {
  content: "\f4e9";
}
.fi-br-time-add:before {
  content: "\f4ea";
}
.fi-br-time-check:before {
  content: "\f4eb";
}
.fi-br-time-delete:before {
  content: "\f4ec";
}
.fi-br-time-fast:before {
  content: "\f4ed";
}
.fi-br-time-forward-sixty:before {
  content: "\f4ee";
}
.fi-br-time-forward-ten:before {
  content: "\f4ef";
}
.fi-br-time-forward:before {
  content: "\f4f0";
}
.fi-br-time-half-past:before {
  content: "\f4f1";
}
.fi-br-time-oclock:before {
  content: "\f4f2";
}
.fi-br-time-past:before {
  content: "\f4f3";
}
.fi-br-time-quarter-to:before {
  content: "\f4f4";
}
.fi-br-time-twenty-four:before {
  content: "\f4f5";
}
.fi-br-tire-flat:before {
  content: "\f4f6";
}
.fi-br-tire-pressure-warning:before {
  content: "\f4f7";
}
.fi-br-tire-rugged:before {
  content: "\f4f8";
}
.fi-br-tire:before {
  content: "\f4f9";
}
.fi-br-tired:before {
  content: "\f4fa";
}
.fi-br-tomato:before {
  content: "\f4fb";
}
.fi-br-tool-box:before {
  content: "\f4fc";
}
.fi-br-tool-crop:before {
  content: "\f4fd";
}
.fi-br-tool-marquee:before {
  content: "\f4fe";
}
.fi-br-tooth:before {
  content: "\f4ff";
}
.fi-br-tornado:before {
  content: "\f500";
}
.fi-br-tractor:before {
  content: "\f501";
}
.fi-br-trailer:before {
  content: "\f502";
}
.fi-br-train-side:before {
  content: "\f503";
}
.fi-br-train:before {
  content: "\f504";
}
.fi-br-tram:before {
  content: "\f505";
}
.fi-br-transform:before {
  content: "\f506";
}
.fi-br-trash:before {
  content: "\f507";
}
.fi-br-treasure-chest:before {
  content: "\f508";
}
.fi-br-treatment:before {
  content: "\f509";
}
.fi-br-tree-christmas:before {
  content: "\f50a";
}
.fi-br-tree:before {
  content: "\f50b";
}
.fi-br-triangle:before {
  content: "\f50c";
}
.fi-br-trophy:before {
  content: "\f50d";
}
.fi-br-truck-container:before {
  content: "\f50e";
}
.fi-br-truck-couch:before {
  content: "\f50f";
}
.fi-br-truck-loading:before {
  content: "\f510";
}
.fi-br-truck-monster:before {
  content: "\f511";
}
.fi-br-truck-moving:before {
  content: "\f512";
}
.fi-br-truck-pickup:before {
  content: "\f513";
}
.fi-br-truck-plow:before {
  content: "\f514";
}
.fi-br-truck-ramp:before {
  content: "\f515";
}
.fi-br-truck-side:before {
  content: "\f516";
}
.fi-br-tty:before {
  content: "\f517";
}
.fi-br-turkey:before {
  content: "\f518";
}
.fi-br-tv-music:before {
  content: "\f519";
}
.fi-br-typewriter:before {
  content: "\f51a";
}
.fi-br-u:before {
  content: "\f51b";
}
.fi-br-umbrella:before {
  content: "\f51c";
}
.fi-br-underline:before {
  content: "\f51d";
}
.fi-br-undo-alt:before {
  content: "\f51e";
}
.fi-br-undo:before {
  content: "\f51f";
}
.fi-br-unlock:before {
  content: "\f520";
}
.fi-br-upload:before {
  content: "\f521";
}
.fi-br-usb-pendrive:before {
  content: "\f522";
}
.fi-br-usd-circle:before {
  content: "\f523";
}
.fi-br-usd-square:before {
  content: "\f524";
}
.fi-br-user-add:before {
  content: "\f525";
}
.fi-br-user-time:before {
  content: "\f526";
}
.fi-br-user:before {
  content: "\f527";
}
.fi-br-users-alt:before {
  content: "\f528";
}
.fi-br-users:before {
  content: "\f529";
}
.fi-br-utensils:before {
  content: "\f52a";
}
.fi-br-v:before {
  content: "\f52b";
}
.fi-br-vector-alt:before {
  content: "\f52c";
}
.fi-br-vector:before {
  content: "\f52d";
}
.fi-br-video-arrow-down-left:before {
  content: "\f52e";
}
.fi-br-video-arrow-up-right:before {
  content: "\f52f";
}
.fi-br-video-camera-alt:before {
  content: "\f530";
}
.fi-br-video-camera:before {
  content: "\f531";
}
.fi-br-video-plus:before {
  content: "\f532";
}
.fi-br-video-slash:before {
  content: "\f533";
}
.fi-br-volcano:before {
  content: "\f534";
}
.fi-br-volleyball:before {
  content: "\f535";
}
.fi-br-volume:before {
  content: "\f536";
}
.fi-br-w:before {
  content: "\f537";
}
.fi-br-wagon-covered:before {
  content: "\f538";
}
.fi-br-wallet:before {
  content: "\f539";
}
.fi-br-water-bottle:before {
  content: "\f53a";
}
.fi-br-water-lower:before {
  content: "\f53b";
}
.fi-br-water-rise:before {
  content: "\f53c";
}
.fi-br-water:before {
  content: "\f53d";
}
.fi-br-watermelon:before {
  content: "\f53e";
}
.fi-br-wheat:before {
  content: "\f53f";
}
.fi-br-wheelchair:before {
  content: "\f540";
}
.fi-br-whistle:before {
  content: "\f541";
}
.fi-br-wifi-alt:before {
  content: "\f542";
}
.fi-br-wind-warning:before {
  content: "\f543";
}
.fi-br-wind:before {
  content: "\f544";
}
.fi-br-windsock:before {
  content: "\f545";
}
.fi-br-wink:before {
  content: "\f546";
}
.fi-br-woman-head:before {
  content: "\f547";
}
.fi-br-world:before {
  content: "\f548";
}
.fi-br-x:before {
  content: "\f549";
}
.fi-br-y:before {
  content: "\f54a";
}
.fi-br-yen:before {
  content: "\f54b";
}
.fi-br-z:before {
  content: "\f54c";
}
.fi-br-zoom-in:before {
  content: "\f54d";
}
.fi-br-zoom-out:before {
  content: "\f54e";
}

@font-face {
  font-family: "uicons-bold-straight";
  src: url("../fonts/uicons-bold-straight.eot#iefix")
      format("embedded-opentype"),
    url("../fonts/uicons-bold-straight.woff2") format("woff2"),
    url("../fonts/uicons-bold-straight.woff") format("woff");
}

i[class^="fi-bs-"]:before,
i[class*=" fi-bs-"]:before,
span[class^="fi-bs-"]:before,
span[class*="fi-bs-"]:before {
  font-family: uicons-bold-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi-bs-a:before {
  content: "\f101";
}
.fi-bs-ad:before {
  content: "\f102";
}
.fi-bs-add-document:before {
  content: "\f103";
}
.fi-bs-add-folder:before {
  content: "\f104";
}
.fi-bs-add:before {
  content: "\f105";
}
.fi-bs-address-book:before {
  content: "\f106";
}
.fi-bs-air-conditioner:before {
  content: "\f107";
}
.fi-bs-air-freshener:before {
  content: "\f108";
}
.fi-bs-alarm-clock:before {
  content: "\f109";
}
.fi-bs-alarm-exclamation:before {
  content: "\f10a";
}
.fi-bs-alarm-plus:before {
  content: "\f10b";
}
.fi-bs-alarm-snooze:before {
  content: "\f10c";
}
.fi-bs-align-justify:before {
  content: "\f10d";
}
.fi-bs-align-left:before {
  content: "\f10e";
}
.fi-bs-ambulance:before {
  content: "\f10f";
}
.fi-bs-angle-circle-down:before {
  content: "\f110";
}
.fi-bs-angle-circle-left:before {
  content: "\f111";
}
.fi-bs-angle-circle-right:before {
  content: "\f112";
}
.fi-bs-angle-circle-up:before {
  content: "\f113";
}
.fi-bs-angle-double-left:before {
  content: "\f114";
}
.fi-bs-angle-double-right:before {
  content: "\f115";
}
.fi-bs-angle-double-small-down:before {
  content: "\f116";
}
.fi-bs-angle-double-small-left:before {
  content: "\f117";
}
.fi-bs-angle-double-small-right:before {
  content: "\f118";
}
.fi-bs-angle-double-small-up:before {
  content: "\f119";
}
.fi-bs-angle-down:before {
  content: "\f11a";
}
.fi-bs-angle-left:before {
  content: "\f11b";
}
.fi-bs-angle-right:before {
  content: "\f11c";
}
.fi-bs-angle-small-down:before {
  content: "\f11d";
}
.fi-bs-angle-small-left:before {
  content: "\f11e";
}
.fi-bs-angle-small-right:before {
  content: "\f11f";
}
.fi-bs-angle-small-up:before {
  content: "\f120";
}
.fi-bs-angle-square-down:before {
  content: "\f121";
}
.fi-bs-angle-square-left:before {
  content: "\f122";
}
.fi-bs-angle-square-right:before {
  content: "\f123";
}
.fi-bs-angle-square-up:before {
  content: "\f124";
}
.fi-bs-angle-up:before {
  content: "\f125";
}
.fi-bs-angry:before {
  content: "\f126";
}
.fi-bs-apple-whole:before {
  content: "\f127";
}
.fi-bs-apps-add:before {
  content: "\f128";
}
.fi-bs-apps-delete:before {
  content: "\f129";
}
.fi-bs-apps-sort:before {
  content: "\f12a";
}
.fi-bs-apps:before {
  content: "\f12b";
}
.fi-bs-archive:before {
  content: "\f12c";
}
.fi-bs-arrow-alt-circle-down:before {
  content: "\f12d";
}
.fi-bs-arrow-alt-circle-left:before {
  content: "\f12e";
}
.fi-bs-arrow-alt-circle-right:before {
  content: "\f12f";
}
.fi-bs-arrow-alt-circle-up:before {
  content: "\f130";
}
.fi-bs-arrow-alt-down:before {
  content: "\f131";
}
.fi-bs-arrow-alt-from-bottom:before {
  content: "\f132";
}
.fi-bs-arrow-alt-from-left:before {
  content: "\f133";
}
.fi-bs-arrow-alt-from-right:before {
  content: "\f134";
}
.fi-bs-arrow-alt-from-top:before {
  content: "\f135";
}
.fi-bs-arrow-alt-left:before {
  content: "\f136";
}
.fi-bs-arrow-alt-right:before {
  content: "\f137";
}
.fi-bs-arrow-alt-square-down:before {
  content: "\f138";
}
.fi-bs-arrow-alt-square-left:before {
  content: "\f139";
}
.fi-bs-arrow-alt-square-right:before {
  content: "\f13a";
}
.fi-bs-arrow-alt-square-up:before {
  content: "\f13b";
}
.fi-bs-arrow-alt-to-bottom:before {
  content: "\f13c";
}
.fi-bs-arrow-alt-to-left:before {
  content: "\f13d";
}
.fi-bs-arrow-alt-to-right:before {
  content: "\f13e";
}
.fi-bs-arrow-alt-to-top:before {
  content: "\f13f";
}
.fi-bs-arrow-alt-up:before {
  content: "\f140";
}
.fi-bs-arrow-circle-down:before {
  content: "\f141";
}
.fi-bs-arrow-circle-left:before {
  content: "\f142";
}
.fi-bs-arrow-circle-right:before {
  content: "\f143";
}
.fi-bs-arrow-circle-up:before {
  content: "\f144";
}
.fi-bs-arrow-down:before {
  content: "\f145";
}
.fi-bs-arrow-from-bottom:before {
  content: "\f146";
}
.fi-bs-arrow-from-left:before {
  content: "\f147";
}
.fi-bs-arrow-from-right:before {
  content: "\f148";
}
.fi-bs-arrow-from-top:before {
  content: "\f149";
}
.fi-bs-arrow-left:before {
  content: "\f14a";
}
.fi-bs-arrow-right:before {
  content: "\f14b";
}
.fi-bs-arrow-small-down:before {
  content: "\f14c";
}
.fi-bs-arrow-small-left:before {
  content: "\f14d";
}
.fi-bs-arrow-small-right:before {
  content: "\f14e";
}
.fi-bs-arrow-small-up:before {
  content: "\f14f";
}
.fi-bs-arrow-square-down:before {
  content: "\f150";
}
.fi-bs-arrow-square-left:before {
  content: "\f151";
}
.fi-bs-arrow-square-right:before {
  content: "\f152";
}
.fi-bs-arrow-square-up:before {
  content: "\f153";
}
.fi-bs-arrow-to-bottom:before {
  content: "\f154";
}
.fi-bs-arrow-to-left:before {
  content: "\f155";
}
.fi-bs-arrow-to-right:before {
  content: "\f156";
}
.fi-bs-arrow-to-top:before {
  content: "\f157";
}
.fi-bs-arrow-up:before {
  content: "\f158";
}
.fi-bs-arrows-alt-h:before {
  content: "\f159";
}
.fi-bs-arrows-alt-v:before {
  content: "\f15a";
}
.fi-bs-arrows-alt:before {
  content: "\f15b";
}
.fi-bs-arrows-h-copy:before {
  content: "\f15c";
}
.fi-bs-arrows-h:before {
  content: "\f15d";
}
.fi-bs-arrows:before {
  content: "\f15e";
}
.fi-bs-assept-document:before {
  content: "\f15f";
}
.fi-bs-assistive-listening-systems:before {
  content: "\f160";
}
.fi-bs-asterik:before {
  content: "\f161";
}
.fi-bs-at:before {
  content: "\f162";
}
.fi-bs-aubergine:before {
  content: "\f163";
}
.fi-bs-avocado:before {
  content: "\f164";
}
.fi-bs-b:before {
  content: "\f165";
}
.fi-bs-baby-carriage:before {
  content: "\f166";
}
.fi-bs-backpack:before {
  content: "\f167";
}
.fi-bs-bacon:before {
  content: "\f168";
}
.fi-bs-badge-sheriff:before {
  content: "\f169";
}
.fi-bs-badge-check:before {
  content: "\f16a";
}
.fi-bs-badge-dollar:before {
  content: "\f16b";
}
.fi-bs-badge-percent:before {
  content: "\f16c";
}
.fi-bs-badge:before {
  content: "\f16d";
}
.fi-bs-bags-shopping:before {
  content: "\f16e";
}
.fi-bs-bahai:before {
  content: "\f16f";
}
.fi-bs-balance-scale-left:before {
  content: "\f170";
}
.fi-bs-balance-scale-right:before {
  content: "\f171";
}
.fi-bs-balloons:before {
  content: "\f172";
}
.fi-bs-ballot:before {
  content: "\f173";
}
.fi-bs-ban:before {
  content: "\f174";
}
.fi-bs-band-aid:before {
  content: "\f175";
}
.fi-bs-bank:before {
  content: "\f176";
}
.fi-bs-barber-shop:before {
  content: "\f177";
}
.fi-bs-bars-progress:before {
  content: "\f178";
}
.fi-bs-baseball-alt:before {
  content: "\f179";
}
.fi-bs-baseball:before {
  content: "\f17a";
}
.fi-bs-basketball-hoop:before {
  content: "\f17b";
}
.fi-bs-basketball:before {
  content: "\f17c";
}
.fi-bs-bed-alt:before {
  content: "\f17d";
}
.fi-bs-bed:before {
  content: "\f17e";
}
.fi-bs-beer:before {
  content: "\f17f";
}
.fi-bs-bell-ring:before {
  content: "\f180";
}
.fi-bs-bell-school:before {
  content: "\f181";
}
.fi-bs-bell:before {
  content: "\f182";
}
.fi-bs-bike:before {
  content: "\f183";
}
.fi-bs-biking-mountain:before {
  content: "\f184";
}
.fi-bs-biking:before {
  content: "\f185";
}
.fi-bs-billiard:before {
  content: "\f186";
}
.fi-bs-blinds-open:before {
  content: "\f187";
}
.fi-bs-blinds-raised:before {
  content: "\f188";
}
.fi-bs-blinds:before {
  content: "\f189";
}
.fi-bs-bold:before {
  content: "\f18a";
}
.fi-bs-bolt:before {
  content: "\f18b";
}
.fi-bs-book-alt:before {
  content: "\f18c";
}
.fi-bs-book-arrow-right:before {
  content: "\f18d";
}
.fi-bs-book-arrow-up:before {
  content: "\f18e";
}
.fi-bs-book-bookmark:before {
  content: "\f18f";
}
.fi-bs-book-open-cover:before {
  content: "\f190";
}
.fi-bs-book-open-reader:before {
  content: "\f191";
}
.fi-bs-book:before {
  content: "\f192";
}
.fi-bs-bookmark:before {
  content: "\f193";
}
.fi-bs-books:before {
  content: "\f194";
}
.fi-bs-bottle:before {
  content: "\f195";
}
.fi-bs-bow-arrow:before {
  content: "\f196";
}
.fi-bs-bowling-ball:before {
  content: "\f197";
}
.fi-bs-bowling-pins:before {
  content: "\f198";
}
.fi-bs-bowling:before {
  content: "\f199";
}
.fi-bs-box-check:before {
  content: "\f19a";
}
.fi-bs-box-tissue:before {
  content: "\f19b";
}
.fi-bs-box-alt:before {
  content: "\f19c";
}
.fi-bs-box-ballot:before {
  content: "\f19d";
}
.fi-bs-box:before {
  content: "\f19e";
}
.fi-bs-boxes:before {
  content: "\f19f";
}
.fi-bs-boxing-glove:before {
  content: "\f1a0";
}
.fi-bs-braille:before {
  content: "\f1a1";
}
.fi-bs-bread-slice:before {
  content: "\f1a2";
}
.fi-bs-bread:before {
  content: "\f1a3";
}
.fi-bs-briefcase:before {
  content: "\f1a4";
}
.fi-bs-broccoli:before {
  content: "\f1a5";
}
.fi-bs-broom:before {
  content: "\f1a6";
}
.fi-bs-browser:before {
  content: "\f1a7";
}
.fi-bs-brush:before {
  content: "\f1a8";
}
.fi-bs-bug:before {
  content: "\f1a9";
}
.fi-bs-building:before {
  content: "\f1aa";
}
.fi-bs-bulb:before {
  content: "\f1ab";
}
.fi-bs-bullseye-pointer:before {
  content: "\f1ac";
}
.fi-bs-bullseye:before {
  content: "\f1ad";
}
.fi-bs-burrito:before {
  content: "\f1ae";
}
.fi-bs-bus-alt:before {
  content: "\f1af";
}
.fi-bs-bus:before {
  content: "\f1b0";
}
.fi-bs-business-time:before {
  content: "\f1b1";
}
.fi-bs-butterfly:before {
  content: "\f1b2";
}
.fi-bs-cake-birthday:before {
  content: "\f1b3";
}
.fi-bs-cake-wedding:before {
  content: "\f1b4";
}
.fi-bs-calculator:before {
  content: "\f1b5";
}
.fi-bs-calendar-check:before {
  content: "\f1b6";
}
.fi-bs-calendar-clock:before {
  content: "\f1b7";
}
.fi-bs-calendar-exclamation:before {
  content: "\f1b8";
}
.fi-bs-calendar-lines-pen:before {
  content: "\f1b9";
}
.fi-bs-calendar-lines:before {
  content: "\f1ba";
}
.fi-bs-calendar-minus:before {
  content: "\f1bb";
}
.fi-bs-calendar-pen:before {
  content: "\f1bc";
}
.fi-bs-calendar-plus:before {
  content: "\f1bd";
}
.fi-bs-calendar:before {
  content: "\f1be";
}
.fi-bs-calendars:before {
  content: "\f1bf";
}
.fi-bs-call-history:before {
  content: "\f1c0";
}
.fi-bs-call-incoming:before {
  content: "\f1c1";
}
.fi-bs-call-missed:before {
  content: "\f1c2";
}
.fi-bs-call-outgoing:before {
  content: "\f1c3";
}
.fi-bs-camcorder:before {
  content: "\f1c4";
}
.fi-bs-camera:before {
  content: "\f1c5";
}
.fi-bs-camping:before {
  content: "\f1c6";
}
.fi-bs-candy-alt:before {
  content: "\f1c7";
}
.fi-bs-candy:before {
  content: "\f1c8";
}
.fi-bs-canned-food:before {
  content: "\f1c9";
}
.fi-bs-car-bolt:before {
  content: "\f1ca";
}
.fi-bs-car-circle-bolt:before {
  content: "\f1cb";
}
.fi-bs-car-side-bolt:before {
  content: "\f1cc";
}
.fi-bs-car-alt:before {
  content: "\f1cd";
}
.fi-bs-car-battery:before {
  content: "\f1ce";
}
.fi-bs-car-building:before {
  content: "\f1cf";
}
.fi-bs-car-bump:before {
  content: "\f1d0";
}
.fi-bs-car-bus:before {
  content: "\f1d1";
}
.fi-bs-car-crash:before {
  content: "\f1d2";
}
.fi-bs-car-garage:before {
  content: "\f1d3";
}
.fi-bs-car-mechanic:before {
  content: "\f1d4";
}
.fi-bs-car-side:before {
  content: "\f1d5";
}
.fi-bs-car-tilt:before {
  content: "\f1d6";
}
.fi-bs-car-wash:before {
  content: "\f1d7";
}
.fi-bs-car:before {
  content: "\f1d8";
}
.fi-bs-caravan-alt:before {
  content: "\f1d9";
}
.fi-bs-caravan:before {
  content: "\f1da";
}
.fi-bs-caret-circle-down:before {
  content: "\f1db";
}
.fi-bs-caret-circle-right:before {
  content: "\f1dc";
}
.fi-bs-caret-circle-up:before {
  content: "\f1dd";
}
.fi-bs-caret-down:before {
  content: "\f1de";
}
.fi-bs-caret-left:before {
  content: "\f1df";
}
.fi-bs-caret-quare-up:before {
  content: "\f1e0";
}
.fi-bs-caret-right:before {
  content: "\f1e1";
}
.fi-bs-caret-square-down:before {
  content: "\f1e2";
}
.fi-bs-caret-square-left_1:before {
  content: "\f1e3";
}
.fi-bs-caret-square-left:before {
  content: "\f1e4";
}
.fi-bs-caret-square-right:before {
  content: "\f1e5";
}
.fi-bs-caret-up:before {
  content: "\f1e6";
}
.fi-bs-carrot:before {
  content: "\f1e7";
}
.fi-bs-cars:before {
  content: "\f1e8";
}
.fi-bs-cash-register:before {
  content: "\f1e9";
}
.fi-bs-chair-office:before {
  content: "\f1ea";
}
.fi-bs-chair:before {
  content: "\f1eb";
}
.fi-bs-charging-station:before {
  content: "\f1ec";
}
.fi-bs-chart-area:before {
  content: "\f1ed";
}
.fi-bs-chart-connected:before {
  content: "\f1ee";
}
.fi-bs-chart-histogram:before {
  content: "\f1ef";
}
.fi-bs-chart-line-up:before {
  content: "\f1f0";
}
.fi-bs-chart-network:before {
  content: "\f1f1";
}
.fi-bs-chart-pie-alt:before {
  content: "\f1f2";
}
.fi-bs-chart-pie:before {
  content: "\f1f3";
}
.fi-bs-chart-pyramid:before {
  content: "\f1f4";
}
.fi-bs-chart-scatter:before {
  content: "\f1f5";
}
.fi-bs-chart-set-theory:before {
  content: "\f1f6";
}
.fi-bs-chart-tree:before {
  content: "\f1f7";
}
.fi-bs-chat-arrow-down:before {
  content: "\f1f8";
}
.fi-bs-chat-arrow-grow:before {
  content: "\f1f9";
}
.fi-bs-check:before {
  content: "\f1fa";
}
.fi-bs-checkbox:before {
  content: "\f1fb";
}
.fi-bs-cheese:before {
  content: "\f1fc";
}
.fi-bs-cherry:before {
  content: "\f1fd";
}
.fi-bs-chess-board:before {
  content: "\f1fe";
}
.fi-bs-chess-clock:before {
  content: "\f1ff";
}
.fi-bs-chess-pawn:before {
  content: "\f200";
}
.fi-bs-chess-piece:before {
  content: "\f201";
}
.fi-bs-chess-bishop:before {
  content: "\f202";
}
.fi-bs-chess-clock-alt:before {
  content: "\f203";
}
.fi-bs-chess-king-alt:before {
  content: "\f204";
}
.fi-bs-chess-king:before {
  content: "\f205";
}
.fi-bs-chess-knight-alt:before {
  content: "\f206";
}
.fi-bs-chess-knight:before {
  content: "\f207";
}
.fi-bs-chess-pawn-alt:before {
  content: "\f208";
}
.fi-bs-chess-queen-alt:before {
  content: "\f209";
}
.fi-bs-chess-queen:before {
  content: "\f20a";
}
.fi-bs-chess-rook-alt:before {
  content: "\f20b";
}
.fi-bs-chess-rook:before {
  content: "\f20c";
}
.fi-bs-chess:before {
  content: "\f20d";
}
.fi-bs-chevron-double-down:before {
  content: "\f20e";
}
.fi-bs-chevron-double-up:before {
  content: "\f20f";
}
.fi-bs-child-head:before {
  content: "\f210";
}
.fi-bs-chocolate:before {
  content: "\f211";
}
.fi-bs-circle-phone:before {
  content: "\f212";
}
.fi-bs-circle-envelope:before {
  content: "\f213";
}
.fi-bs-circle-phone-flip:before {
  content: "\f214";
}
.fi-bs-circle-phone-hangup:before {
  content: "\f215";
}
.fi-bs-circle-small:before {
  content: "\f216";
}
.fi-bs-circle:before {
  content: "\f217";
}
.fi-bs-clip:before {
  content: "\f218";
}
.fi-bs-clipboard-list:before {
  content: "\f219";
}
.fi-bs-clipboard-list-check:before {
  content: "\f21a";
}
.fi-bs-clock-eight-thirty:before {
  content: "\f21b";
}
.fi-bs-clock-eleven-thirty:before {
  content: "\f21c";
}
.fi-bs-clock-eleven:before {
  content: "\f21d";
}
.fi-bs-clock-five-thirty:before {
  content: "\f21e";
}
.fi-bs-clock-five:before {
  content: "\f21f";
}
.fi-bs-clock-four-thirty:before {
  content: "\f220";
}
.fi-bs-clock-nine-thirty:before {
  content: "\f221";
}
.fi-bs-clock-nine:before {
  content: "\f222";
}
.fi-bs-clock-one-thirty:before {
  content: "\f223";
}
.fi-bs-clock-one:before {
  content: "\f224";
}
.fi-bs-clock-seven-thirty:before {
  content: "\f225";
}
.fi-bs-clock-seven:before {
  content: "\f226";
}
.fi-bs-clock-six-thirty:before {
  content: "\f227";
}
.fi-bs-clock-six:before {
  content: "\f228";
}
.fi-bs-clock-ten-thirty:before {
  content: "\f229";
}
.fi-bs-clock-ten:before {
  content: "\f22a";
}
.fi-bs-clock-three-thirty:before {
  content: "\f22b";
}
.fi-bs-clock-three:before {
  content: "\f22c";
}
.fi-bs-clock-twelve-thirty:before {
  content: "\f22d";
}
.fi-bs-clock-twelve:before {
  content: "\f22e";
}
.fi-bs-clock-two-thirty:before {
  content: "\f22f";
}
.fi-bs-clock-two:before {
  content: "\f230";
}
.fi-bs-clock:before {
  content: "\f231";
}
.fi-bs-cloud-upload:before {
  content: "\f232";
}
.fi-bs-cloud-check:before {
  content: "\f233";
}
.fi-bs-cloud-disabled:before {
  content: "\f234";
}
.fi-bs-cloud-download-alt:before {
  content: "\f235";
}
.fi-bs-cloud-download:before {
  content: "\f236";
}
.fi-bs-cloud-drizzle:before {
  content: "\f237";
}
.fi-bs-cloud-hail-mixed:before {
  content: "\f238";
}
.fi-bs-cloud-hail:before {
  content: "\f239";
}
.fi-bs-cloud-moon-rain:before {
  content: "\f23a";
}
.fi-bs-cloud-moon:before {
  content: "\f23b";
}
.fi-bs-cloud-rain:before {
  content: "\f23c";
}
.fi-bs-cloud-rainbow:before {
  content: "\f23d";
}
.fi-bs-cloud-share:before {
  content: "\f23e";
}
.fi-bs-cloud-showers-heavy:before {
  content: "\f23f";
}
.fi-bs-cloud-showers:before {
  content: "\f240";
}
.fi-bs-cloud-sleet:before {
  content: "\f241";
}
.fi-bs-cloud-snow:before {
  content: "\f242";
}
.fi-bs-cloud-sun-rain:before {
  content: "\f243";
}
.fi-bs-cloud-sun:before {
  content: "\f244";
}
.fi-bs-cloud-upload-alt:before {
  content: "\f245";
}
.fi-bs-cloud:before {
  content: "\f246";
}
.fi-bs-clouds-moon:before {
  content: "\f247";
}
.fi-bs-clouds-sun:before {
  content: "\f248";
}
.fi-bs-clouds:before {
  content: "\f249";
}
.fi-bs-club:before {
  content: "\f24a";
}
.fi-bs-cocktail-alt:before {
  content: "\f24b";
}
.fi-bs-cocktail:before {
  content: "\f24c";
}
.fi-bs-coffee-pot:before {
  content: "\f24d";
}
.fi-bs-coffee:before {
  content: "\f24e";
}
.fi-bs-coin:before {
  content: "\f24f";
}
.fi-bs-coins:before {
  content: "\f250";
}
.fi-bs-comet:before {
  content: "\f251";
}
.fi-bs-comment-alt-middle-top:before {
  content: "\f252";
}
.fi-bs-comment-alt-middle:before {
  content: "\f253";
}
.fi-bs-comment-alt:before {
  content: "\f254";
}
.fi-bs-comment-arrow-down:before {
  content: "\f255";
}
.fi-bs-comment-arrow-up-right:before {
  content: "\f256";
}
.fi-bs-comment-arrow-up:before {
  content: "\f257";
}
.fi-bs-comment-check:before {
  content: "\f258";
}
.fi-bs-comment-code:before {
  content: "\f259";
}
.fi-bs-comment-dollar:before {
  content: "\f25a";
}
.fi-bs-comment-exclamation:before {
  content: "\f25b";
}
.fi-bs-comment-heart:before {
  content: "\f25c";
}
.fi-bs-comment-image:before {
  content: "\f25d";
}
.fi-bs-comment-info:before {
  content: "\f25e";
}
.fi-bs-comment-pen:before {
  content: "\f25f";
}
.fi-bs-comment-question:before {
  content: "\f260";
}
.fi-bs-comment-quote:before {
  content: "\f261";
}
.fi-bs-comment-slash:before {
  content: "\f262";
}
.fi-bs-comment-sms:before {
  content: "\f263";
}
.fi-bs-comment-text:before {
  content: "\f264";
}
.fi-bs-comment-user:before {
  content: "\f265";
}
.fi-bs-comment-xmark:before {
  content: "\f266";
}
.fi-bs-comment:before {
  content: "\f267";
}
.fi-bs-comments-dollar:before {
  content: "\f268";
}
.fi-bs-comments-question-check:before {
  content: "\f269";
}
.fi-bs-comments-question:before {
  content: "\f26a";
}
.fi-bs-comments:before {
  content: "\f26b";
}
.fi-bs-compress-alt:before {
  content: "\f26c";
}
.fi-bs-compress:before {
  content: "\f26d";
}
.fi-bs-computer:before {
  content: "\f26e";
}
.fi-bs-confetti:before {
  content: "\f26f";
}
.fi-bs-cookie:before {
  content: "\f270";
}
.fi-bs-copy-alt:before {
  content: "\f271";
}
.fi-bs-copy:before {
  content: "\f272";
}
.fi-bs-copyright:before {
  content: "\f273";
}
.fi-bs-corn:before {
  content: "\f274";
}
.fi-bs-cow:before {
  content: "\f275";
}
.fi-bs-cream:before {
  content: "\f276";
}
.fi-bs-credit-card:before {
  content: "\f277";
}
.fi-bs-cricket:before {
  content: "\f278";
}
.fi-bs-croissant:before {
  content: "\f279";
}
.fi-bs-cross-circle:before {
  content: "\f27a";
}
.fi-bs-cross-small:before {
  content: "\f27b";
}
.fi-bs-cross:before {
  content: "\f27c";
}
.fi-bs-crown:before {
  content: "\f27d";
}
.fi-bs-crystal-ball:before {
  content: "\f27e";
}
.fi-bs-cube:before {
  content: "\f27f";
}
.fi-bs-cupcake:before {
  content: "\f280";
}
.fi-bs-curling:before {
  content: "\f281";
}
.fi-bs-cursor-finger:before {
  content: "\f282";
}
.fi-bs-cursor-plus:before {
  content: "\f283";
}
.fi-bs-cursor-text-alt:before {
  content: "\f284";
}
.fi-bs-cursor-text:before {
  content: "\f285";
}
.fi-bs-cursor:before {
  content: "\f286";
}
.fi-bs-d:before {
  content: "\f287";
}
.fi-bs-dart:before {
  content: "\f288";
}
.fi-bs-dashboard:before {
  content: "\f289";
}
.fi-bs-data-transfer:before {
  content: "\f28a";
}
.fi-bs-database:before {
  content: "\f28b";
}
.fi-bs-delete-document:before {
  content: "\f28c";
}
.fi-bs-delete-user:before {
  content: "\f28d";
}
.fi-bs-delete:before {
  content: "\f28e";
}
.fi-bs-democrat:before {
  content: "\f28f";
}
.fi-bs-dewpoint:before {
  content: "\f290";
}
.fi-bs-diamond:before {
  content: "\f291";
}
.fi-bs-dice-alt:before {
  content: "\f292";
}
.fi-bs-dice-d10:before {
  content: "\f293";
}
.fi-bs-dice-d12:before {
  content: "\f294";
}
.fi-bs-dice-d20:before {
  content: "\f295";
}
.fi-bs-dice-d4:before {
  content: "\f296";
}
.fi-bs-dice-d6:before {
  content: "\f297";
}
.fi-bs-dice-d8:before {
  content: "\f298";
}
.fi-bs-dice-four:before {
  content: "\f299";
}
.fi-bs-dice-one:before {
  content: "\f29a";
}
.fi-bs-dice-six:before {
  content: "\f29b";
}
.fi-bs-dice-three:before {
  content: "\f29c";
}
.fi-bs-dice-two:before {
  content: "\f29d";
}
.fi-bs-dice:before {
  content: "\f29e";
}
.fi-bs-diploma:before {
  content: "\f29f";
}
.fi-bs-disco-ball:before {
  content: "\f2a0";
}
.fi-bs-disk:before {
  content: "\f2a1";
}
.fi-bs-dizzy:before {
  content: "\f2a2";
}
.fi-bs-doctor:before {
  content: "\f2a3";
}
.fi-bs-document-signed:before {
  content: "\f2a4";
}
.fi-bs-document:before {
  content: "\f2a5";
}
.fi-bs-dollar:before {
  content: "\f2a6";
}
.fi-bs-donate:before {
  content: "\f2a7";
}
.fi-bs-down-left-and-up-right-to-center:before {
  content: "\f2a8";
}
.fi-bs-download:before {
  content: "\f2a9";
}
.fi-bs-drafting-compass:before {
  content: "\f2aa";
}
.fi-bs-dreidel:before {
  content: "\f2ab";
}
.fi-bs-drink-alt:before {
  content: "\f2ac";
}
.fi-bs-drumstick:before {
  content: "\f2ad";
}
.fi-bs-duplicate:before {
  content: "\f2ae";
}
.fi-bs-e-learning:before {
  content: "\f2af";
}
.fi-bs-e:before {
  content: "\f2b0";
}
.fi-bs-earnings:before {
  content: "\f2b1";
}
.fi-bs-eclipse-alt:before {
  content: "\f2b2";
}
.fi-bs-eclipse:before {
  content: "\f2b3";
}
.fi-bs-edit-alt:before {
  content: "\f2b4";
}
.fi-bs-edit:before {
  content: "\f2b5";
}
.fi-bs-egg-fried:before {
  content: "\f2b6";
}
.fi-bs-egg:before {
  content: "\f2b7";
}
.fi-bs-engine-warning:before {
  content: "\f2b8";
}
.fi-bs-enter:before {
  content: "\f2b9";
}
.fi-bs-envelope-ban:before {
  content: "\f2ba";
}
.fi-bs-envelope-bulk:before {
  content: "\f2bb";
}
.fi-bs-envelope-download:before {
  content: "\f2bc";
}
.fi-bs-envelope-marker:before {
  content: "\f2bd";
}
.fi-bs-envelope-open-dollar:before {
  content: "\f2be";
}
.fi-bs-envelope-open-text:before {
  content: "\f2bf";
}
.fi-bs-envelope-open:before {
  content: "\f2c0";
}
.fi-bs-envelope-plus:before {
  content: "\f2c1";
}
.fi-bs-envelope:before {
  content: "\f2c2";
}
.fi-bs-equality:before {
  content: "\f2c3";
}
.fi-bs-euro:before {
  content: "\f2c4";
}
.fi-bs-exchange-alt:before {
  content: "\f2c5";
}
.fi-bs-exchange:before {
  content: "\f2c6";
}
.fi-bs-exclamation:before {
  content: "\f2c7";
}
.fi-bs-exit:before {
  content: "\f2c8";
}
.fi-bs-expand-arrows-alt:before {
  content: "\f2c9";
}
.fi-bs-expand-arrows:before {
  content: "\f2ca";
}
.fi-bs-expand:before {
  content: "\f2cb";
}
.fi-bs-eye-crossed:before {
  content: "\f2cc";
}
.fi-bs-eye-dropper:before {
  content: "\f2cd";
}
.fi-bs-eye:before {
  content: "\f2ce";
}
.fi-bs-f:before {
  content: "\f2cf";
}
.fi-bs-feather:before {
  content: "\f2d0";
}
.fi-bs-ferris-wheel:before {
  content: "\f2d1";
}
.fi-bs-field-hockey:before {
  content: "\f2d2";
}
.fi-bs-fighter-jet:before {
  content: "\f2d3";
}
.fi-bs-file-invoice-dollar:before {
  content: "\f2d4";
}
.fi-bs-file-invoice:before {
  content: "\f2d5";
}
.fi-bs-file-ai:before {
  content: "\f2d6";
}
.fi-bs-file-chart-line:before {
  content: "\f2d7";
}
.fi-bs-file-chart-pie:before {
  content: "\f2d8";
}
.fi-bs-file-eps:before {
  content: "\f2d9";
}
.fi-bs-file-psd:before {
  content: "\f2da";
}
.fi-bs-file-spreadsheet:before {
  content: "\f2db";
}
.fi-bs-file-video:before {
  content: "\f2dc";
}
.fi-bs-file:before {
  content: "\f2dd";
}
.fi-bs-fill:before {
  content: "\f2de";
}
.fi-bs-film-slash:before {
  content: "\f2df";
}
.fi-bs-film:before {
  content: "\f2e0";
}
.fi-bs-filter-slash:before {
  content: "\f2e1";
}
.fi-bs-filter:before {
  content: "\f2e2";
}
.fi-bs-filters:before {
  content: "\f2e3";
}
.fi-bs-fingerprint:before {
  content: "\f2e4";
}
.fi-bs-fish:before {
  content: "\f2e5";
}
.fi-bs-flag-alt:before {
  content: "\f2e6";
}
.fi-bs-flag-usa:before {
  content: "\f2e7";
}
.fi-bs-flag:before {
  content: "\f2e8";
}
.fi-bs-flame:before {
  content: "\f2e9";
}
.fi-bs-flip-horizontal:before {
  content: "\f2ea";
}
.fi-bs-flower-bouquet:before {
  content: "\f2eb";
}
.fi-bs-flower-tulip:before {
  content: "\f2ec";
}
.fi-bs-flower:before {
  content: "\f2ed";
}
.fi-bs-flushed:before {
  content: "\f2ee";
}
.fi-bs-fog:before {
  content: "\f2ef";
}
.fi-bs-folder-download:before {
  content: "\f2f0";
}
.fi-bs-folder-minus:before {
  content: "\f2f1";
}
.fi-bs-folder-times:before {
  content: "\f2f2";
}
.fi-bs-folder-tree:before {
  content: "\f2f3";
}
.fi-bs-folder-upload:before {
  content: "\f2f4";
}
.fi-bs-folder:before {
  content: "\f2f5";
}
.fi-bs-folders:before {
  content: "\f2f6";
}
.fi-bs-following:before {
  content: "\f2f7";
}
.fi-bs-football:before {
  content: "\f2f8";
}
.fi-bs-fork:before {
  content: "\f2f9";
}
.fi-bs-form:before {
  content: "\f2fa";
}
.fi-bs-forward:before {
  content: "\f2fb";
}
.fi-bs-fox:before {
  content: "\f2fc";
}
.fi-bs-french-fries:before {
  content: "\f2fd";
}
.fi-bs-frown:before {
  content: "\f2fe";
}
.fi-bs-ftp:before {
  content: "\f2ff";
}
.fi-bs-funnel-dollar:before {
  content: "\f300";
}
.fi-bs-g:before {
  content: "\f301";
}
.fi-bs-gallery:before {
  content: "\f302";
}
.fi-bs-game-board-alt:before {
  content: "\f303";
}
.fi-bs-gamepad:before {
  content: "\f304";
}
.fi-bs-garage-car:before {
  content: "\f305";
}
.fi-bs-garage-open:before {
  content: "\f306";
}
.fi-bs-garage:before {
  content: "\f307";
}
.fi-bs-garlic:before {
  content: "\f308";
}
.fi-bs-gas-pump-alt:before {
  content: "\f309";
}
.fi-bs-gas-pump-slash:before {
  content: "\f30a";
}
.fi-bs-gas-pump:before {
  content: "\f30b";
}
.fi-bs-gem:before {
  content: "\f30c";
}
.fi-bs-gif:before {
  content: "\f30d";
}
.fi-bs-gift-card:before {
  content: "\f30e";
}
.fi-bs-gift:before {
  content: "\f30f";
}
.fi-bs-gifts:before {
  content: "\f310";
}
.fi-bs-gingerbread-man:before {
  content: "\f311";
}
.fi-bs-glass-cheers:before {
  content: "\f312";
}
.fi-bs-glass:before {
  content: "\f313";
}
.fi-bs-glasses:before {
  content: "\f314";
}
.fi-bs-globe-alt:before {
  content: "\f315";
}
.fi-bs-globe:before {
  content: "\f316";
}
.fi-bs-golf-ball:before {
  content: "\f317";
}
.fi-bs-golf-club:before {
  content: "\f318";
}
.fi-bs-golf:before {
  content: "\f319";
}
.fi-bs-graduation-cap:before {
  content: "\f31a";
}
.fi-bs-grape:before {
  content: "\f31b";
}
.fi-bs-graphic-tablet:before {
  content: "\f31c";
}
.fi-bs-grid-alt:before {
  content: "\f31d";
}
.fi-bs-grid:before {
  content: "\f31e";
}
.fi-bs-grill:before {
  content: "\f31f";
}
.fi-bs-grimace:before {
  content: "\f320";
}
.fi-bs-grin-alt:before {
  content: "\f321";
}
.fi-bs-grin-beam-sweat:before {
  content: "\f322";
}
.fi-bs-grin-beam:before {
  content: "\f323";
}
.fi-bs-grin-hearts:before {
  content: "\f324";
}
.fi-bs-grin-squint-tears:before {
  content: "\f325";
}
.fi-bs-grin-squint:before {
  content: "\f326";
}
.fi-bs-grin-stars:before {
  content: "\f327";
}
.fi-bs-grin-tears:before {
  content: "\f328";
}
.fi-bs-grin-tongue-squint:before {
  content: "\f329";
}
.fi-bs-grin-tongue-wink:before {
  content: "\f32a";
}
.fi-bs-grin-tongue:before {
  content: "\f32b";
}
.fi-bs-grin-wink:before {
  content: "\f32c";
}
.fi-bs-grin:before {
  content: "\f32d";
}
.fi-bs-guitar:before {
  content: "\f32e";
}
.fi-bs-gym:before {
  content: "\f32f";
}
.fi-bs-h:before {
  content: "\f330";
}
.fi-bs-hamburger-soda:before {
  content: "\f331";
}
.fi-bs-hamburger:before {
  content: "\f332";
}
.fi-bs-hand-holding-box:before {
  content: "\f333";
}
.fi-bs-hand-holding-heart:before {
  content: "\f334";
}
.fi-bs-hand-holding-seedling:before {
  content: "\f335";
}
.fi-bs-hand:before {
  content: "\f336";
}
.fi-bs-handshake:before {
  content: "\f337";
}
.fi-bs-happy:before {
  content: "\f338";
}
.fi-bs-hastag:before {
  content: "\f339";
}
.fi-bs-hat-birthday:before {
  content: "\f33a";
}
.fi-bs-hat-chef:before {
  content: "\f33b";
}
.fi-bs-head-side-thinking:before {
  content: "\f33c";
}
.fi-bs-headphones:before {
  content: "\f33d";
}
.fi-bs-headset:before {
  content: "\f33e";
}
.fi-bs-heart-arrow:before {
  content: "\f33f";
}
.fi-bs-heart:before {
  content: "\f340";
}
.fi-bs-heat:before {
  content: "\f341";
}
.fi-bs-helicopter-side:before {
  content: "\f342";
}
.fi-bs-highlighter:before {
  content: "\f343";
}
.fi-bs-hiking:before {
  content: "\f344";
}
.fi-bs-hockey-mask:before {
  content: "\f345";
}
.fi-bs-hockey-puck:before {
  content: "\f346";
}
.fi-bs-hockey-sticks:before {
  content: "\f347";
}
.fi-bs-home-location-alt:before {
  content: "\f348";
}
.fi-bs-home-location:before {
  content: "\f349";
}
.fi-bs-home:before {
  content: "\f34a";
}
.fi-bs-hotdog:before {
  content: "\f34b";
}
.fi-bs-hourglass-end:before {
  content: "\f34c";
}
.fi-bs-hourglass:before {
  content: "\f34d";
}
.fi-bs-house-flood:before {
  content: "\f34e";
}
.fi-bs-hryvnia:before {
  content: "\f34f";
}
.fi-bs-humidity:before {
  content: "\f350";
}
.fi-bs-hurricane:before {
  content: "\f351";
}
.fi-bs-i:before {
  content: "\f352";
}
.fi-bs-ice-cream:before {
  content: "\f353";
}
.fi-bs-ice-skate:before {
  content: "\f354";
}
.fi-bs-id-badge:before {
  content: "\f355";
}
.fi-bs-inbox-in:before {
  content: "\f356";
}
.fi-bs-inbox-out:before {
  content: "\f357";
}
.fi-bs-inbox:before {
  content: "\f358";
}
.fi-bs-incognito:before {
  content: "\f359";
}
.fi-bs-indent:before {
  content: "\f35a";
}
.fi-bs-infinity:before {
  content: "\f35b";
}
.fi-bs-info:before {
  content: "\f35c";
}
.fi-bs-interactive:before {
  content: "\f35d";
}
.fi-bs-interlining:before {
  content: "\f35e";
}
.fi-bs-interrogation:before {
  content: "\f35f";
}
.fi-bs-italic:before {
  content: "\f360";
}
.fi-bs-j:before {
  content: "\f361";
}
.fi-bs-jam:before {
  content: "\f362";
}
.fi-bs-jpg:before {
  content: "\f363";
}
.fi-bs-k:before {
  content: "\f364";
}
.fi-bs-kerning:before {
  content: "\f365";
}
.fi-bs-key:before {
  content: "\f366";
}
.fi-bs-keyboard:before {
  content: "\f367";
}
.fi-bs-keynote:before {
  content: "\f368";
}
.fi-bs-kiss-beam:before {
  content: "\f369";
}
.fi-bs-kiss-wink-heart:before {
  content: "\f36a";
}
.fi-bs-kiss:before {
  content: "\f36b";
}
.fi-bs-kite:before {
  content: "\f36c";
}
.fi-bs-knife:before {
  content: "\f36d";
}
.fi-bs-l:before {
  content: "\f36e";
}
.fi-bs-label:before {
  content: "\f36f";
}
.fi-bs-laptop:before {
  content: "\f370";
}
.fi-bs-lasso:before {
  content: "\f371";
}
.fi-bs-laugh-beam:before {
  content: "\f372";
}
.fi-bs-laugh-squint:before {
  content: "\f373";
}
.fi-bs-laugh-wink:before {
  content: "\f374";
}
.fi-bs-laugh:before {
  content: "\f375";
}
.fi-bs-layer-minus:before {
  content: "\f376";
}
.fi-bs-layer-plus:before {
  content: "\f377";
}
.fi-bs-layers:before {
  content: "\f378";
}
.fi-bs-layout-fluid:before {
  content: "\f379";
}
.fi-bs-leaf:before {
  content: "\f37a";
}
.fi-bs-lemon:before {
  content: "\f37b";
}
.fi-bs-letter-case:before {
  content: "\f37c";
}
.fi-bs-lettuce:before {
  content: "\f37d";
}
.fi-bs-level-down-alt:before {
  content: "\f37e";
}
.fi-bs-level-down:before {
  content: "\f37f";
}
.fi-bs-level-up-alt:before {
  content: "\f380";
}
.fi-bs-level-up:before {
  content: "\f381";
}
.fi-bs-life-ring:before {
  content: "\f382";
}
.fi-bs-lightbulb-dollar:before {
  content: "\f383";
}
.fi-bs-line-width:before {
  content: "\f384";
}
.fi-bs-link-alt:before {
  content: "\f385";
}
.fi-bs-link-slash-alt:before {
  content: "\f386";
}
.fi-bs-link-slash:before {
  content: "\f387";
}
.fi-bs-link:before {
  content: "\f388";
}
.fi-bs-lipstick:before {
  content: "\f389";
}
.fi-bs-lira-sign:before {
  content: "\f38a";
}
.fi-bs-list-check:before {
  content: "\f38b";
}
.fi-bs-list:before {
  content: "\f38c";
}
.fi-bs-loading:before {
  content: "\f38d";
}
.fi-bs-location-alt:before {
  content: "\f38e";
}
.fi-bs-lock-alt:before {
  content: "\f38f";
}
.fi-bs-lock:before {
  content: "\f390";
}
.fi-bs-luchador:before {
  content: "\f391";
}
.fi-bs-luggage-rolling:before {
  content: "\f392";
}
.fi-bs-m:before {
  content: "\f393";
}
.fi-bs-magic-wand:before {
  content: "\f394";
}
.fi-bs-mailbox:before {
  content: "\f395";
}
.fi-bs-makeup-brush:before {
  content: "\f396";
}
.fi-bs-man-head:before {
  content: "\f397";
}
.fi-bs-map-marker-cross:before {
  content: "\f398";
}
.fi-bs-map-marker-home:before {
  content: "\f399";
}
.fi-bs-map-marker-minus:before {
  content: "\f39a";
}
.fi-bs-map-marker-plus:before {
  content: "\f39b";
}
.fi-bs-map-marker:before {
  content: "\f39c";
}
.fi-bs-map:before {
  content: "\f39d";
}
.fi-bs-marker-time:before {
  content: "\f39e";
}
.fi-bs-marker:before {
  content: "\f39f";
}
.fi-bs-mars-double:before {
  content: "\f3a0";
}
.fi-bs-mars:before {
  content: "\f3a1";
}
.fi-bs-mask-carnival:before {
  content: "\f3a2";
}
.fi-bs-medicine:before {
  content: "\f3a3";
}
.fi-bs-megaphone:before {
  content: "\f3a4";
}
.fi-bs-meh-blank:before {
  content: "\f3a5";
}
.fi-bs-meh-rolling-eyes:before {
  content: "\f3a6";
}
.fi-bs-meh:before {
  content: "\f3a7";
}
.fi-bs-melon:before {
  content: "\f3a8";
}
.fi-bs-menu-burger:before {
  content: "\f3a9";
}
.fi-bs-menu-dots-vertical:before {
  content: "\f3aa";
}
.fi-bs-menu-dots:before {
  content: "\f3ab";
}
.fi-bs-meteor:before {
  content: "\f3ac";
}
.fi-bs-microphone-alt:before {
  content: "\f3ad";
}
.fi-bs-microphone:before {
  content: "\f3ae";
}
.fi-bs-mind-share:before {
  content: "\f3af";
}
.fi-bs-minus-small:before {
  content: "\f3b0";
}
.fi-bs-minus:before {
  content: "\f3b1";
}
.fi-bs-mobile-button:before {
  content: "\f3b2";
}
.fi-bs-mobile-notch:before {
  content: "\f3b3";
}
.fi-bs-mobile:before {
  content: "\f3b4";
}
.fi-bs-mode-landscape:before {
  content: "\f3b5";
}
.fi-bs-mode-portrait:before {
  content: "\f3b6";
}
.fi-bs-money-bill-wave-alt:before {
  content: "\f3b7";
}
.fi-bs-money-bill-wave:before {
  content: "\f3b8";
}
.fi-bs-money-check:before {
  content: "\f3b9";
}
.fi-bs-money-check-edit-alt:before {
  content: "\f3ba";
}
.fi-bs-money-check-edit:before {
  content: "\f3bb";
}
.fi-bs-money:before {
  content: "\f3bc";
}
.fi-bs-moon-stars:before {
  content: "\f3bd";
}
.fi-bs-moon:before {
  content: "\f3be";
}
.fi-bs-motorcycle:before {
  content: "\f3bf";
}
.fi-bs-mountains:before {
  content: "\f3c0";
}
.fi-bs-mouse:before {
  content: "\f3c1";
}
.fi-bs-mug-alt:before {
  content: "\f3c2";
}
.fi-bs-mug-hot-alt:before {
  content: "\f3c3";
}
.fi-bs-mug-hot:before {
  content: "\f3c4";
}
.fi-bs-mug-tea:before {
  content: "\f3c5";
}
.fi-bs-mug:before {
  content: "\f3c6";
}
.fi-bs-mushroom:before {
  content: "\f3c7";
}
.fi-bs-music-file:before {
  content: "\f3c8";
}
.fi-bs-music-alt:before {
  content: "\f3c9";
}
.fi-bs-music:before {
  content: "\f3ca";
}
.fi-bs-n:before {
  content: "\f3cb";
}
.fi-bs-navigation:before {
  content: "\f3cc";
}
.fi-bs-network-cloud:before {
  content: "\f3cd";
}
.fi-bs-network:before {
  content: "\f3ce";
}
.fi-bs-noodles:before {
  content: "\f3cf";
}
.fi-bs-notebook:before {
  content: "\f3d0";
}
.fi-bs-o:before {
  content: "\f3d1";
}
.fi-bs-oil-can:before {
  content: "\f3d2";
}
.fi-bs-oil-temp:before {
  content: "\f3d3";
}
.fi-bs-olive-oil:before {
  content: "\f3d4";
}
.fi-bs-olives:before {
  content: "\f3d5";
}
.fi-bs-onion:before {
  content: "\f3d6";
}
.fi-bs-opacity:before {
  content: "\f3d7";
}
.fi-bs-overline:before {
  content: "\f3d8";
}
.fi-bs-p:before {
  content: "\f3d9";
}
.fi-bs-package:before {
  content: "\f3da";
}
.fi-bs-page-break:before {
  content: "\f3db";
}
.fi-bs-paint-roller:before {
  content: "\f3dc";
}
.fi-bs-paint-brush:before {
  content: "\f3dd";
}
.fi-bs-palette:before {
  content: "\f3de";
}
.fi-bs-pan:before {
  content: "\f3df";
}
.fi-bs-paper-plane:before {
  content: "\f3e0";
}
.fi-bs-password:before {
  content: "\f3e1";
}
.fi-bs-pause:before {
  content: "\f3e2";
}
.fi-bs-paw:before {
  content: "\f3e3";
}
.fi-bs-peach:before {
  content: "\f3e4";
}
.fi-bs-pencil-ruler:before {
  content: "\f3e5";
}
.fi-bs-pencil:before {
  content: "\f3e6";
}
.fi-bs-pennant:before {
  content: "\f3e7";
}
.fi-bs-people-poll:before {
  content: "\f3e8";
}
.fi-bs-pepper-hot:before {
  content: "\f3e9";
}
.fi-bs-pepper:before {
  content: "\f3ea";
}
.fi-bs-percentage:before {
  content: "\f3eb";
}
.fi-bs-pharmacy:before {
  content: "\f3ec";
}
.fi-bs-phone-office:before {
  content: "\f3ed";
}
.fi-bs-phone-call:before {
  content: "\f3ee";
}
.fi-bs-phone-cross:before {
  content: "\f3ef";
}
.fi-bs-phone-pause:before {
  content: "\f3f0";
}
.fi-bs-phone-slash:before {
  content: "\f3f1";
}
.fi-bs-photo-film-music:before {
  content: "\f3f2";
}
.fi-bs-photo-video:before {
  content: "\f3f3";
}
.fi-bs-physics:before {
  content: "\f3f4";
}
.fi-bs-picnic:before {
  content: "\f3f5";
}
.fi-bs-picture:before {
  content: "\f3f6";
}
.fi-bs-pie:before {
  content: "\f3f7";
}
.fi-bs-piggy-bank:before {
  content: "\f3f8";
}
.fi-bs-pineapple:before {
  content: "\f3f9";
}
.fi-bs-ping-pong:before {
  content: "\f3fa";
}
.fi-bs-pizza-slice:before {
  content: "\f3fb";
}
.fi-bs-plane-alt:before {
  content: "\f3fc";
}
.fi-bs-plane:before {
  content: "\f3fd";
}
.fi-bs-plate:before {
  content: "\f3fe";
}
.fi-bs-play-alt:before {
  content: "\f3ff";
}
.fi-bs-play-pause:before {
  content: "\f400";
}
.fi-bs-play:before {
  content: "\f401";
}
.fi-bs-playing-cards:before {
  content: "\f402";
}
.fi-bs-plus-small:before {
  content: "\f403";
}
.fi-bs-plus:before {
  content: "\f404";
}
.fi-bs-podium-star:before {
  content: "\f405";
}
.fi-bs-podium:before {
  content: "\f406";
}
.fi-bs-poker-chip:before {
  content: "\f407";
}
.fi-bs-poo:before {
  content: "\f408";
}
.fi-bs-popcorn:before {
  content: "\f409";
}
.fi-bs-portrait:before {
  content: "\f40a";
}
.fi-bs-pot:before {
  content: "\f40b";
}
.fi-bs-pound:before {
  content: "\f40c";
}
.fi-bs-power:before {
  content: "\f40d";
}
.fi-bs-presentation:before {
  content: "\f40e";
}
.fi-bs-print:before {
  content: "\f40f";
}
.fi-bs-protractor:before {
  content: "\f410";
}
.fi-bs-pulse:before {
  content: "\f411";
}
.fi-bs-pumpkin:before {
  content: "\f412";
}
.fi-bs-puzzle-piece:before {
  content: "\f413";
}
.fi-bs-pyramid:before {
  content: "\f414";
}
.fi-bs-q:before {
  content: "\f415";
}
.fi-bs-question-square:before {
  content: "\f416";
}
.fi-bs-question:before {
  content: "\f417";
}
.fi-bs-quote-right:before {
  content: "\f418";
}
.fi-bs-r:before {
  content: "\f419";
}
.fi-bs-racquet:before {
  content: "\f41a";
}
.fi-bs-radish:before {
  content: "\f41b";
}
.fi-bs-rainbow:before {
  content: "\f41c";
}
.fi-bs-raindrops:before {
  content: "\f41d";
}
.fi-bs-rec:before {
  content: "\f41e";
}
.fi-bs-receipt:before {
  content: "\f41f";
}
.fi-bs-record-vinyl:before {
  content: "\f420";
}
.fi-bs-rectabgle-vertical:before {
  content: "\f421";
}
.fi-bs-rectangle-horizontal:before {
  content: "\f422";
}
.fi-bs-rectangle-panoramic:before {
  content: "\f423";
}
.fi-bs-recycle:before {
  content: "\f424";
}
.fi-bs-redo-alt:before {
  content: "\f425";
}
.fi-bs-redo:before {
  content: "\f426";
}
.fi-bs-reflect:before {
  content: "\f427";
}
.fi-bs-refresh:before {
  content: "\f428";
}
.fi-bs-registered:before {
  content: "\f429";
}
.fi-bs-remove-user:before {
  content: "\f42a";
}
.fi-bs-reply-all:before {
  content: "\f42b";
}
.fi-bs-republican:before {
  content: "\f42c";
}
.fi-bs-resize:before {
  content: "\f42d";
}
.fi-bs-resources:before {
  content: "\f42e";
}
.fi-bs-restaurant:before {
  content: "\f42f";
}
.fi-bs-rewind:before {
  content: "\f430";
}
.fi-bs-rhombus:before {
  content: "\f431";
}
.fi-bs-rings-wedding:before {
  content: "\f432";
}
.fi-bs-road:before {
  content: "\f433";
}
.fi-bs-rocket-lunch:before {
  content: "\f434";
}
.fi-bs-rocket:before {
  content: "\f435";
}
.fi-bs-room-service:before {
  content: "\f436";
}
.fi-bs-rotate-left:before {
  content: "\f437";
}
.fi-bs-rotate-right:before {
  content: "\f438";
}
.fi-bs-ruble-sign:before {
  content: "\f439";
}
.fi-bs-rugby-helmet:before {
  content: "\f43a";
}
.fi-bs-rugby:before {
  content: "\f43b";
}
.fi-bs-ruler-combined:before {
  content: "\f43c";
}
.fi-bs-ruler-horizontal:before {
  content: "\f43d";
}
.fi-bs-ruler-triangle:before {
  content: "\f43e";
}
.fi-bs-ruler-vertical:before {
  content: "\f43f";
}
.fi-bs-running:before {
  content: "\f440";
}
.fi-bs-rupee-sign:before {
  content: "\f441";
}
.fi-bs-rv:before {
  content: "\f442";
}
.fi-bs-s:before {
  content: "\f443";
}
.fi-bs-sack-dollar:before {
  content: "\f444";
}
.fi-bs-sack:before {
  content: "\f445";
}
.fi-bs-sad-cry:before {
  content: "\f446";
}
.fi-bs-sad-tear:before {
  content: "\f447";
}
.fi-bs-sad:before {
  content: "\f448";
}
.fi-bs-salad:before {
  content: "\f449";
}
.fi-bs-salt-pepper:before {
  content: "\f44a";
}
.fi-bs-sandwich:before {
  content: "\f44b";
}
.fi-bs-sauce:before {
  content: "\f44c";
}
.fi-bs-sausage:before {
  content: "\f44d";
}
.fi-bs-scale:before {
  content: "\f44e";
}
.fi-bs-school-bus:before {
  content: "\f44f";
}
.fi-bs-school:before {
  content: "\f450";
}
.fi-bs-scissors:before {
  content: "\f451";
}
.fi-bs-screen:before {
  content: "\f452";
}
.fi-bs-search-dollar:before {
  content: "\f453";
}
.fi-bs-search-location:before {
  content: "\f454";
}
.fi-bs-search-alt:before {
  content: "\f455";
}
.fi-bs-search-heart:before {
  content: "\f456";
}
.fi-bs-search:before {
  content: "\f457";
}
.fi-bs-sensor-alert:before {
  content: "\f458";
}
.fi-bs-sensor-fire:before {
  content: "\f459";
}
.fi-bs-sensor-on:before {
  content: "\f45a";
}
.fi-bs-sensor-smoke:before {
  content: "\f45b";
}
.fi-bs-sensor:before {
  content: "\f45c";
}
.fi-bs-settings-sliders:before {
  content: "\f45d";
}
.fi-bs-settings:before {
  content: "\f45e";
}
.fi-bs-share:before {
  content: "\f45f";
}
.fi-bs-shekel-sign:before {
  content: "\f460";
}
.fi-bs-shield-check:before {
  content: "\f461";
}
.fi-bs-shield-exclamation:before {
  content: "\f462";
}
.fi-bs-shield-interrogation:before {
  content: "\f463";
}
.fi-bs-shield-plus:before {
  content: "\f464";
}
.fi-bs-shield:before {
  content: "\f465";
}
.fi-bs-ship-side:before {
  content: "\f466";
}
.fi-bs-ship:before {
  content: "\f467";
}
.fi-bs-shop:before {
  content: "\f468";
}
.fi-bs-shopping-basket:before {
  content: "\f469";
}
.fi-bs-shopping-bag-add:before {
  content: "\f46a";
}
.fi-bs-shopping-bag:before {
  content: "\f46b";
}
.fi-bs-shopping-cart-add:before {
  content: "\f46c";
}
.fi-bs-shopping-cart-check:before {
  content: "\f46d";
}
.fi-bs-shopping-cart:before {
  content: "\f46e";
}
.fi-bs-shrimp:before {
  content: "\f46f";
}
.fi-bs-shuffle:before {
  content: "\f470";
}
.fi-bs-shuttle-van:before {
  content: "\f471";
}
.fi-bs-shuttlecock:before {
  content: "\f472";
}
.fi-bs-sign-in-alt:before {
  content: "\f473";
}
.fi-bs-sign-out-alt:before {
  content: "\f474";
}
.fi-bs-signal-alt-1:before {
  content: "\f475";
}
.fi-bs-signal-alt-2:before {
  content: "\f476";
}
.fi-bs-signal-alt:before {
  content: "\f477";
}
.fi-bs-skateboard:before {
  content: "\f478";
}
.fi-bs-skating:before {
  content: "\f479";
}
.fi-bs-skewer:before {
  content: "\f47a";
}
.fi-bs-ski-jump:before {
  content: "\f47b";
}
.fi-bs-ski-lift:before {
  content: "\f47c";
}
.fi-bs-skiing-nordic:before {
  content: "\f47d";
}
.fi-bs-skiing:before {
  content: "\f47e";
}
.fi-bs-sledding:before {
  content: "\f47f";
}
.fi-bs-sleigh:before {
  content: "\f480";
}
.fi-bs-smartphone:before {
  content: "\f481";
}
.fi-bs-smile-beam:before {
  content: "\f482";
}
.fi-bs-smile-wink:before {
  content: "\f483";
}
.fi-bs-smog:before {
  content: "\f484";
}
.fi-bs-smoke:before {
  content: "\f485";
}
.fi-bs-snow-blowing:before {
  content: "\f486";
}
.fi-bs-snowboarding:before {
  content: "\f487";
}
.fi-bs-snowflake:before {
  content: "\f488";
}
.fi-bs-snowflakes:before {
  content: "\f489";
}
.fi-bs-snowmobile:before {
  content: "\f48a";
}
.fi-bs-snowplow:before {
  content: "\f48b";
}
.fi-bs-soap:before {
  content: "\f48c";
}
.fi-bs-social-network:before {
  content: "\f48d";
}
.fi-bs-sort-alpha-down-alt:before {
  content: "\f48e";
}
.fi-bs-sort-alpha-down:before {
  content: "\f48f";
}
.fi-bs-sort-alpha-up-alt:before {
  content: "\f490";
}
.fi-bs-sort-alpha-up:before {
  content: "\f491";
}
.fi-bs-sort-alt:before {
  content: "\f492";
}
.fi-bs-sort-amount-down-alt:before {
  content: "\f493";
}
.fi-bs-sort-amount-down:before {
  content: "\f494";
}
.fi-bs-sort-amount-up-alt:before {
  content: "\f495";
}
.fi-bs-sort-amount-up:before {
  content: "\f496";
}
.fi-bs-sort-down:before {
  content: "\f497";
}
.fi-bs-sort-numeric-down-alt:before {
  content: "\f498";
}
.fi-bs-sort-numeric-down:before {
  content: "\f499";
}
.fi-bs-sort:before {
  content: "\f49a";
}
.fi-bs-soup:before {
  content: "\f49b";
}
.fi-bs-spa:before {
  content: "\f49c";
}
.fi-bs-space-shuttle:before {
  content: "\f49d";
}
.fi-bs-spade:before {
  content: "\f49e";
}
.fi-bs-sparkles:before {
  content: "\f49f";
}
.fi-bs-speaker:before {
  content: "\f4a0";
}
.fi-bs-sphere:before {
  content: "\f4a1";
}
.fi-bs-spinner:before {
  content: "\f4a2";
}
.fi-bs-spoon:before {
  content: "\f4a3";
}
.fi-bs-square-root:before {
  content: "\f4a4";
}
.fi-bs-square:before {
  content: "\f4a5";
}
.fi-bs-stamp:before {
  content: "\f4a6";
}
.fi-bs-star-octogram:before {
  content: "\f4a7";
}
.fi-bs-star:before {
  content: "\f4a8";
}
.fi-bs-starfighter:before {
  content: "\f4a9";
}
.fi-bs-stars:before {
  content: "\f4aa";
}
.fi-bs-stats:before {
  content: "\f4ab";
}
.fi-bs-steak:before {
  content: "\f4ac";
}
.fi-bs-steering-wheel:before {
  content: "\f4ad";
}
.fi-bs-stethoscope:before {
  content: "\f4ae";
}
.fi-bs-sticker:before {
  content: "\f4af";
}
.fi-bs-stop:before {
  content: "\f4b0";
}
.fi-bs-stopwatch:before {
  content: "\f4b1";
}
.fi-bs-store-alt:before {
  content: "\f4b2";
}
.fi-bs-strawberry:before {
  content: "\f4b3";
}
.fi-bs-subtitles:before {
  content: "\f4b4";
}
.fi-bs-subway:before {
  content: "\f4b5";
}
.fi-bs-suitcase-alt:before {
  content: "\f4b6";
}
.fi-bs-summer:before {
  content: "\f4b7";
}
.fi-bs-sun:before {
  content: "\f4b8";
}
.fi-bs-sunrise-alt:before {
  content: "\f4b9";
}
.fi-bs-sunrise:before {
  content: "\f4ba";
}
.fi-bs-sunset:before {
  content: "\f4bb";
}
.fi-bs-surfing:before {
  content: "\f4bc";
}
.fi-bs-surprise:before {
  content: "\f4bd";
}
.fi-bs-sushi:before {
  content: "\f4be";
}
.fi-bs-swimmer:before {
  content: "\f4bf";
}
.fi-bs-sword:before {
  content: "\f4c0";
}
.fi-bs-symbol:before {
  content: "\f4c1";
}
.fi-bs-syringe:before {
  content: "\f4c2";
}
.fi-bs-t:before {
  content: "\f4c3";
}
.fi-bs-tablet:before {
  content: "\f4c4";
}
.fi-bs-tachometer-alt-average:before {
  content: "\f4c5";
}
.fi-bs-tachometer-alt-fastest:before {
  content: "\f4c6";
}
.fi-bs-tachometer-alt-slow:before {
  content: "\f4c7";
}
.fi-bs-tachometer-alt-slowest:before {
  content: "\f4c8";
}
.fi-bs-tachometer-average:before {
  content: "\f4c9";
}
.fi-bs-tachometer-fast:before {
  content: "\f4ca";
}
.fi-bs-tachometer-fastest:before {
  content: "\f4cb";
}
.fi-bs-tachometer-slow:before {
  content: "\f4cc";
}
.fi-bs-tachometer-slowest:before {
  content: "\f4cd";
}
.fi-bs-tachometer:before {
  content: "\f4ce";
}
.fi-bs-taco:before {
  content: "\f4cf";
}
.fi-bs-tags:before {
  content: "\f4d0";
}
.fi-bs-tally:before {
  content: "\f4d1";
}
.fi-bs-target:before {
  content: "\f4d2";
}
.fi-bs-taxi:before {
  content: "\f4d3";
}
.fi-bs-temperature-down:before {
  content: "\f4d4";
}
.fi-bs-temperature-frigid:before {
  content: "\f4d5";
}
.fi-bs-temperature-high:before {
  content: "\f4d6";
}
.fi-bs-temperature-low:before {
  content: "\f4d7";
}
.fi-bs-temperature-up:before {
  content: "\f4d8";
}
.fi-bs-tenge:before {
  content: "\f4d9";
}
.fi-bs-tennis:before {
  content: "\f4da";
}
.fi-bs-terrace:before {
  content: "\f4db";
}
.fi-bs-test-tube:before {
  content: "\f4dc";
}
.fi-bs-test:before {
  content: "\f4dd";
}
.fi-bs-text-check:before {
  content: "\f4de";
}
.fi-bs-text-slash:before {
  content: "\f4df";
}
.fi-bs-text:before {
  content: "\f4e0";
}
.fi-bs-thermometer-half:before {
  content: "\f4e1";
}
.fi-bs-thumbtack:before {
  content: "\f4e2";
}
.fi-bs-thunderstorm-moon:before {
  content: "\f4e3";
}
.fi-bs-thunderstorm-sun:before {
  content: "\f4e4";
}
.fi-bs-thunderstorm:before {
  content: "\f4e5";
}
.fi-bs-ticket:before {
  content: "\f4e6";
}
.fi-bs-time-quarter-past:before {
  content: "\f4e7";
}
.fi-bs-time-add:before {
  content: "\f4e8";
}
.fi-bs-time-check:before {
  content: "\f4e9";
}
.fi-bs-time-delete:before {
  content: "\f4ea";
}
.fi-bs-time-fast:before {
  content: "\f4eb";
}
.fi-bs-time-forward-sixty:before {
  content: "\f4ec";
}
.fi-bs-time-forward-ten:before {
  content: "\f4ed";
}
.fi-bs-time-forward:before {
  content: "\f4ee";
}
.fi-bs-time-half-past:before {
  content: "\f4ef";
}
.fi-bs-time-oclock:before {
  content: "\f4f0";
}
.fi-bs-time-past:before {
  content: "\f4f1";
}
.fi-bs-time-quarter-to:before {
  content: "\f4f2";
}
.fi-bs-time-twenty-four:before {
  content: "\f4f3";
}
.fi-bs-tire-flat:before {
  content: "\f4f4";
}
.fi-bs-tire-pressure-warning:before {
  content: "\f4f5";
}
.fi-bs-tire-rugged:before {
  content: "\f4f6";
}
.fi-bs-tire:before {
  content: "\f4f7";
}
.fi-bs-tired:before {
  content: "\f4f8";
}
.fi-bs-tomato:before {
  content: "\f4f9";
}
.fi-bs-tool-box:before {
  content: "\f4fa";
}
.fi-bs-tool-crop:before {
  content: "\f4fb";
}
.fi-bs-tool-marquee:before {
  content: "\f4fc";
}
.fi-bs-tooth:before {
  content: "\f4fd";
}
.fi-bs-tornado:before {
  content: "\f4fe";
}
.fi-bs-tractor:before {
  content: "\f4ff";
}
.fi-bs-trailer:before {
  content: "\f500";
}
.fi-bs-train-side:before {
  content: "\f501";
}
.fi-bs-train:before {
  content: "\f502";
}
.fi-bs-tram:before {
  content: "\f503";
}
.fi-bs-transform:before {
  content: "\f504";
}
.fi-bs-trash:before {
  content: "\f505";
}
.fi-bs-treasure-chest:before {
  content: "\f506";
}
.fi-bs-treatment:before {
  content: "\f507";
}
.fi-bs-tree-christmas:before {
  content: "\f508";
}
.fi-bs-tree:before {
  content: "\f509";
}
.fi-bs-triangle:before {
  content: "\f50a";
}
.fi-bs-trophy:before {
  content: "\f50b";
}
.fi-bs-truck-container:before {
  content: "\f50c";
}
.fi-bs-truck-couch:before {
  content: "\f50d";
}
.fi-bs-truck-loading:before {
  content: "\f50e";
}
.fi-bs-truck-monster:before {
  content: "\f50f";
}
.fi-bs-truck-moving:before {
  content: "\f510";
}
.fi-bs-truck-pickup:before {
  content: "\f511";
}
.fi-bs-truck-plow:before {
  content: "\f512";
}
.fi-bs-truck-ramp:before {
  content: "\f513";
}
.fi-bs-truck-side:before {
  content: "\f514";
}
.fi-bs-tty:before {
  content: "\f515";
}
.fi-bs-turkey:before {
  content: "\f516";
}
.fi-bs-tv-music:before {
  content: "\f517";
}
.fi-bs-typewriter:before {
  content: "\f518";
}
.fi-bs-u:before {
  content: "\f519";
}
.fi-bs-umbrella:before {
  content: "\f51a";
}
.fi-bs-underline:before {
  content: "\f51b";
}
.fi-bs-undo-alt:before {
  content: "\f51c";
}
.fi-bs-undo:before {
  content: "\f51d";
}
.fi-bs-unlock:before {
  content: "\f51e";
}
.fi-bs-upload:before {
  content: "\f51f";
}
.fi-bs-usb-pendrive:before {
  content: "\f520";
}
.fi-bs-usd-circle:before {
  content: "\f521";
}
.fi-bs-usd-square:before {
  content: "\f522";
}
.fi-bs-user-add:before {
  content: "\f523";
}
.fi-bs-user-time:before {
  content: "\f524";
}
.fi-bs-user:before {
  content: "\f525";
}
.fi-bs-users-alt:before {
  content: "\f526";
}
.fi-bs-users:before {
  content: "\f527";
}
.fi-bs-utensils:before {
  content: "\f528";
}
.fi-bs-v:before {
  content: "\f529";
}
.fi-bs-vector-alt:before {
  content: "\f52a";
}
.fi-bs-vector:before {
  content: "\f52b";
}
.fi-bs-venus-double:before {
  content: "\f52c";
}
.fi-bs-venus-mars:before {
  content: "\f52d";
}
.fi-bs-venus:before {
  content: "\f52e";
}
.fi-bs-video-arrow-down-left:before {
  content: "\f52f";
}
.fi-bs-video-arrow-up-right:before {
  content: "\f530";
}
.fi-bs-video-camera-alt:before {
  content: "\f531";
}
.fi-bs-video-camera:before {
  content: "\f532";
}
.fi-bs-video-plus:before {
  content: "\f533";
}
.fi-bs-video-slash:before {
  content: "\f534";
}
.fi-bs-volcano:before {
  content: "\f535";
}
.fi-bs-volleyball:before {
  content: "\f536";
}
.fi-bs-volume:before {
  content: "\f537";
}
.fi-bs-w:before {
  content: "\f538";
}
.fi-bs-wagon-covered:before {
  content: "\f539";
}
.fi-bs-wallet:before {
  content: "\f53a";
}
.fi-bs-water-bottle:before {
  content: "\f53b";
}
.fi-bs-water-lower:before {
  content: "\f53c";
}
.fi-bs-water-rise:before {
  content: "\f53d";
}
.fi-bs-water:before {
  content: "\f53e";
}
.fi-bs-watermelon:before {
  content: "\f53f";
}
.fi-bs-wheat:before {
  content: "\f540";
}
.fi-bs-wheelchair:before {
  content: "\f541";
}
.fi-bs-whistle:before {
  content: "\f542";
}
.fi-bs-wifi-alt:before {
  content: "\f543";
}
.fi-bs-wind-warning:before {
  content: "\f544";
}
.fi-bs-wind:before {
  content: "\f545";
}
.fi-bs-windsock:before {
  content: "\f546";
}
.fi-bs-woman-head:before {
  content: "\f547";
}
.fi-bs-world:before {
  content: "\f548";
}
.fi-bs-x:before {
  content: "\f549";
}
.fi-bs-y:before {
  content: "\f54a";
}
.fi-bs-yen:before {
  content: "\f54b";
}
.fi-bs-z:before {
  content: "\f54c";
}
.fi-bs-zoom-in:before {
  content: "\f54d";
}
.fi-bs-zoom-out:before {
  content: "\f54e";
}

@font-face {
  font-family: "uicons-regular-rounded";
  src: url("../fonts/uicons-regular-rounded.eot#iefix")
      format("embedded-opentype"),
    url("../fonts/uicons-regular-rounded.woff2") format("woff2"),
    url("../fonts/uicons-regular-rounded.woff") format("woff");
}

i[class^="fi-rr-"]:before,
i[class*=" fi-rr-"]:before,
span[class^="fi-rr-"]:before,
span[class*="fi-rr-"]:before {
  font-family: uicons-regular-rounded !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi-rr-a:before {
  content: "\f101";
}
.fi-rr-ad:before {
  content: "\f102";
}
.fi-rr-add-document:before {
  content: "\f103";
}
.fi-rr-add-folder:before {
  content: "\f104";
}
.fi-rr-add:before {
  content: "\f105";
}
.fi-rr-address-book:before {
  content: "\f106";
}
.fi-rr-air-conditioner:before {
  content: "\f107";
}
.fi-rr-air-freshener:before {
  content: "\f108";
}
.fi-rr-alarm-clock:before {
  content: "\f109";
}
.fi-rr-alarm-exclamation:before {
  content: "\f10a";
}
.fi-rr-alarm-plus:before {
  content: "\f10b";
}
.fi-rr-alarm-snooze:before {
  content: "\f10c";
}
.fi-rr-align-justify:before {
  content: "\f10d";
}
.fi-rr-align-left:before {
  content: "\f10e";
}
.fi-rr-ambulance:before {
  content: "\f10f";
}
.fi-rr-angle-circle-down:before {
  content: "\f110";
}
.fi-rr-angle-circle-left:before {
  content: "\f111";
}
.fi-rr-angle-circle-right:before {
  content: "\f112";
}
.fi-rr-angle-circle-up:before {
  content: "\f113";
}
.fi-rr-angle-double-left:before {
  content: "\f114";
}
.fi-rr-angle-double-right:before {
  content: "\f115";
}
.fi-rr-angle-double-small-down:before {
  content: "\f116";
}
.fi-rr-angle-double-small-left:before {
  content: "\f117";
}
.fi-rr-angle-double-small-right:before {
  content: "\f118";
}
.fi-rr-angle-double-small-up:before {
  content: "\f119";
}
.fi-rr-angle-down:before {
  content: "\f11a";
}
.fi-rr-angle-left:before {
  content: "\f11b";
}
.fi-rr-angle-right:before {
  content: "\f11c";
}
.fi-rr-angle-small-down:before {
  content: "\f11d";
}
.fi-rr-angle-small-left:before {
  content: "\f11e";
}
.fi-rr-angle-small-right:before {
  content: "\f11f";
}
.fi-rr-angle-small-up:before {
  content: "\f120";
}
.fi-rr-angle-square-down:before {
  content: "\f121";
}
.fi-rr-angle-square-left:before {
  content: "\f122";
}
.fi-rr-angle-square-right:before {
  content: "\f123";
}
.fi-rr-angle-square-up:before {
  content: "\f124";
}
.fi-rr-angle-up:before {
  content: "\f125";
}
.fi-rr-angry:before {
  content: "\f126";
}
.fi-rr-apple-whole:before {
  content: "\f127";
}
.fi-rr-apps-add:before {
  content: "\f128";
}
.fi-rr-apps-delete:before {
  content: "\f129";
}
.fi-rr-apps-sort:before {
  content: "\f12a";
}
.fi-rr-apps:before {
  content: "\f12b";
}
.fi-rr-archive:before {
  content: "\f12c";
}
.fi-rr-arrow-alt-circle-down:before {
  content: "\f12d";
}
.fi-rr-arrow-alt-circle-left:before {
  content: "\f12e";
}
.fi-rr-arrow-alt-circle-right:before {
  content: "\f12f";
}
.fi-rr-arrow-alt-circle-up:before {
  content: "\f130";
}
.fi-rr-arrow-alt-down:before {
  content: "\f131";
}
.fi-rr-arrow-alt-from-bottom:before {
  content: "\f132";
}
.fi-rr-arrow-alt-from-left:before {
  content: "\f133";
}
.fi-rr-arrow-alt-from-right:before {
  content: "\f134";
}
.fi-rr-arrow-alt-from-top:before {
  content: "\f135";
}
.fi-rr-arrow-alt-left:before {
  content: "\f136";
}
.fi-rr-arrow-alt-right:before {
  content: "\f137";
}
.fi-rr-arrow-alt-square-down:before {
  content: "\f138";
}
.fi-rr-arrow-alt-square-left:before {
  content: "\f139";
}
.fi-rr-arrow-alt-square-right:before {
  content: "\f13a";
}
.fi-rr-arrow-alt-square-up:before {
  content: "\f13b";
}
.fi-rr-arrow-alt-to-bottom:before {
  content: "\f13c";
}
.fi-rr-arrow-alt-to-left:before {
  content: "\f13d";
}
.fi-rr-arrow-alt-to-right:before {
  content: "\f13e";
}
.fi-rr-arrow-alt-to-top:before {
  content: "\f13f";
}
.fi-rr-arrow-alt-up:before {
  content: "\f140";
}
.fi-rr-arrow-circle-down:before {
  content: "\f141";
}
.fi-rr-arrow-circle-left:before {
  content: "\f142";
}
.fi-rr-arrow-circle-right:before {
  content: "\f143";
}
.fi-rr-arrow-circle-up:before {
  content: "\f144";
}
.fi-rr-arrow-down:before {
  content: "\f145";
}
.fi-rr-arrow-from-bottom:before {
  content: "\f146";
}
.fi-rr-arrow-from-left:before {
  content: "\f147";
}
.fi-rr-arrow-from-right:before {
  content: "\f148";
}
.fi-rr-arrow-from-top:before {
  content: "\f149";
}
.fi-rr-arrow-left:before {
  content: "\f14a";
}
.fi-rr-arrow-right:before {
  content: "\f14b";
}
.fi-rr-arrow-small-down:before {
  content: "\f14c";
}
.fi-rr-arrow-small-left:before {
  content: "\f14d";
}
.fi-rr-arrow-small-right:before {
  content: "\f14e";
}
.fi-rr-arrow-small-up:before {
  content: "\f14f";
}
.fi-rr-arrow-square-down:before {
  content: "\f150";
}
.fi-rr-arrow-square-left:before {
  content: "\f151";
}
.fi-rr-arrow-square-right:before {
  content: "\f152";
}
.fi-rr-arrow-square-up:before {
  content: "\f153";
}
.fi-rr-arrow-to-bottom:before {
  content: "\f154";
}
.fi-rr-arrow-to-left:before {
  content: "\f155";
}
.fi-rr-arrow-to-right:before {
  content: "\f156";
}
.fi-rr-arrow-to-top:before {
  content: "\f157";
}
.fi-rr-arrow-up:before {
  content: "\f158";
}
.fi-rr-arrows-alt-h:before {
  content: "\f159";
}
.fi-rr-arrows-alt-v:before {
  content: "\f15a";
}
.fi-rr-arrows-alt:before {
  content: "\f15b";
}
.fi-rr-arrows-h-copy:before {
  content: "\f15c";
}
.fi-rr-arrows-h:before {
  content: "\f15d";
}
.fi-rr-arrows:before {
  content: "\f15e";
}
.fi-rr-assept-document:before {
  content: "\f15f";
}
.fi-rr-assistive-listening-systems:before {
  content: "\f160";
}
.fi-rr-asterik:before {
  content: "\f161";
}
.fi-rr-at:before {
  content: "\f162";
}
.fi-rr-aubergine:before {
  content: "\f163";
}
.fi-rr-avocado:before {
  content: "\f164";
}
.fi-rr-b:before {
  content: "\f165";
}
.fi-rr-baby-carriage:before {
  content: "\f166";
}
.fi-rr-backpack:before {
  content: "\f167";
}
.fi-rr-bacon:before {
  content: "\f168";
}
.fi-rr-badge-sheriff:before {
  content: "\f169";
}
.fi-rr-badge-check:before {
  content: "\f16a";
}
.fi-rr-badge-dollar:before {
  content: "\f16b";
}
.fi-rr-badge-percent:before {
  content: "\f16c";
}
.fi-rr-badge:before {
  content: "\f16d";
}
.fi-rr-bags-shopping:before {
  content: "\f16e";
}
.fi-rr-bahai:before {
  content: "\f16f";
}
.fi-rr-balance-scale-left:before {
  content: "\f170";
}
.fi-rr-balance-scale-right:before {
  content: "\f171";
}
.fi-rr-ball:before {
  content: "\f172";
}
.fi-rr-balloons:before {
  content: "\f173";
}
.fi-rr-ballot:before {
  content: "\f174";
}
.fi-rr-ban:before {
  content: "\f175";
}
.fi-rr-band-aid:before {
  content: "\f176";
}
.fi-rr-bank:before {
  content: "\f177";
}
.fi-rr-barber-shop:before {
  content: "\f178";
}
.fi-rr-bars-progress:before {
  content: "\f179";
}
.fi-rr-baseball-alt:before {
  content: "\f17a";
}
.fi-rr-baseball:before {
  content: "\f17b";
}
.fi-rr-basket:before {
  content: "\f17c";
}
.fi-rr-basketball:before {
  content: "\f17d";
}
.fi-rr-bed-alt:before {
  content: "\f17e";
}
.fi-rr-bed:before {
  content: "\f17f";
}
.fi-rr-beer:before {
  content: "\f180";
}
.fi-rr-bell-ring:before {
  content: "\f181";
}
.fi-rr-bell-school:before {
  content: "\f182";
}
.fi-rr-bell:before {
  content: "\f183";
}
.fi-rr-bike:before {
  content: "\f184";
}
.fi-rr-biking-mountain:before {
  content: "\f185";
}
.fi-rr-biking:before {
  content: "\f186";
}
.fi-rr-billiard:before {
  content: "\f187";
}
.fi-rr-blinds-open:before {
  content: "\f188";
}
.fi-rr-blinds-raised:before {
  content: "\f189";
}
.fi-rr-blinds:before {
  content: "\f18a";
}
.fi-rr-bold:before {
  content: "\f18b";
}
.fi-rr-bolt:before {
  content: "\f18c";
}
.fi-rr-book-alt:before {
  content: "\f18d";
}
.fi-rr-book-arrow-right:before {
  content: "\f18e";
}
.fi-rr-book-arrow-up:before {
  content: "\f18f";
}
.fi-rr-book-bookmark:before {
  content: "\f190";
}
.fi-rr-book-open-cover:before {
  content: "\f191";
}
.fi-rr-book-open-reader:before {
  content: "\f192";
}
.fi-rr-book:before {
  content: "\f193";
}
.fi-rr-bookmark:before {
  content: "\f194";
}
.fi-rr-books:before {
  content: "\f195";
}
.fi-rr-bottle:before {
  content: "\f196";
}
.fi-rr-bow-arrow:before {
  content: "\f197";
}
.fi-rr-bowling-ball:before {
  content: "\f198";
}
.fi-rr-bowling-pins:before {
  content: "\f199";
}
.fi-rr-bowling:before {
  content: "\f19a";
}
.fi-rr-box-check:before {
  content: "\f19b";
}
.fi-rr-box-tissue:before {
  content: "\f19c";
}
.fi-rr-box-alt:before {
  content: "\f19d";
}
.fi-rr-box-ballot:before {
  content: "\f19e";
}
.fi-rr-box:before {
  content: "\f19f";
}
.fi-rr-boxes:before {
  content: "\f1a0";
}
.fi-rr-boxing-glove:before {
  content: "\f1a1";
}
.fi-rr-braille:before {
  content: "\f1a2";
}
.fi-rr-bread-slice:before {
  content: "\f1a3";
}
.fi-rr-bread:before {
  content: "\f1a4";
}
.fi-rr-briefcase:before {
  content: "\f1a5";
}
.fi-rr-broccoli:before {
  content: "\f1a6";
}
.fi-rr-broom:before {
  content: "\f1a7";
}
.fi-rr-browser:before {
  content: "\f1a8";
}
.fi-rr-brush:before {
  content: "\f1a9";
}
.fi-rr-bug:before {
  content: "\f1aa";
}
.fi-rr-building:before {
  content: "\f1ab";
}
.fi-rr-bulb:before {
  content: "\f1ac";
}
.fi-rr-bullseye-pointer:before {
  content: "\f1ad";
}
.fi-rr-bullseye:before {
  content: "\f1ae";
}
.fi-rr-burrito:before {
  content: "\f1af";
}
.fi-rr-bus-alt:before {
  content: "\f1b0";
}
.fi-rr-bus:before {
  content: "\f1b1";
}
.fi-rr-business-time:before {
  content: "\f1b2";
}
.fi-rr-butterfly:before {
  content: "\f1b3";
}
.fi-rr-cake-birthday:before {
  content: "\f1b4";
}
.fi-rr-cake-wedding:before {
  content: "\f1b5";
}
.fi-rr-calculator:before {
  content: "\f1b6";
}
.fi-rr-calendar-check:before {
  content: "\f1b7";
}
.fi-rr-calendar-clock:before {
  content: "\f1b8";
}
.fi-rr-calendar-exclamation:before {
  content: "\f1b9";
}
.fi-rr-calendar-lines-pen:before {
  content: "\f1ba";
}
.fi-rr-calendar-lines:before {
  content: "\f1bb";
}
.fi-rr-calendar-minus:before {
  content: "\f1bc";
}
.fi-rr-calendar-pen:before {
  content: "\f1bd";
}
.fi-rr-calendar-plus:before {
  content: "\f1be";
}
.fi-rr-calendar:before {
  content: "\f1bf";
}
.fi-rr-calendars:before {
  content: "\f1c0";
}
.fi-rr-call-history:before {
  content: "\f1c1";
}
.fi-rr-call-incoming:before {
  content: "\f1c2";
}
.fi-rr-call-missed:before {
  content: "\f1c3";
}
.fi-rr-call-outgoing:before {
  content: "\f1c4";
}
.fi-rr-camcorder:before {
  content: "\f1c5";
}
.fi-rr-camera:before {
  content: "\f1c6";
}
.fi-rr-camping:before {
  content: "\f1c7";
}
.fi-rr-candy-alt:before {
  content: "\f1c8";
}
.fi-rr-candy:before {
  content: "\f1c9";
}
.fi-rr-canned-food:before {
  content: "\f1ca";
}
.fi-rr-car-bolt:before {
  content: "\f1cb";
}
.fi-rr-car-circle-bolt:before {
  content: "\f1cc";
}
.fi-rr-car-side-bolt:before {
  content: "\f1cd";
}
.fi-rr-car-alt:before {
  content: "\f1ce";
}
.fi-rr-car-battery:before {
  content: "\f1cf";
}
.fi-rr-car-building:before {
  content: "\f1d0";
}
.fi-rr-car-bump:before {
  content: "\f1d1";
}
.fi-rr-car-bus:before {
  content: "\f1d2";
}
.fi-rr-car-crash:before {
  content: "\f1d3";
}
.fi-rr-car-garage:before {
  content: "\f1d4";
}
.fi-rr-car-mechanic:before {
  content: "\f1d5";
}
.fi-rr-car-side:before {
  content: "\f1d6";
}
.fi-rr-car-tilt:before {
  content: "\f1d7";
}
.fi-rr-car-wash:before {
  content: "\f1d8";
}
.fi-rr-car:before {
  content: "\f1d9";
}
.fi-rr-caravan-alt:before {
  content: "\f1da";
}
.fi-rr-caravan:before {
  content: "\f1db";
}
.fi-rr-caret-circle-down:before {
  content: "\f1dc";
}
.fi-rr-caret-circle-right:before {
  content: "\f1dd";
}
.fi-rr-caret-circle-up:before {
  content: "\f1de";
}
.fi-rr-caret-down:before {
  content: "\f1df";
}
.fi-rr-caret-left:before {
  content: "\f1e0";
}
.fi-rr-caret-quare-up:before {
  content: "\f1e1";
}
.fi-rr-caret-right:before {
  content: "\f1e2";
}
.fi-rr-caret-square-down:before {
  content: "\f1e3";
}
.fi-rr-caret-square-left_1:before {
  content: "\f1e4";
}
.fi-rr-caret-square-left:before {
  content: "\f1e5";
}
.fi-rr-caret-square-right:before {
  content: "\f1e6";
}
.fi-rr-caret-up:before {
  content: "\f1e7";
}
.fi-rr-carrot:before {
  content: "\f1e8";
}
.fi-rr-cars:before {
  content: "\f1e9";
}
.fi-rr-cash-register:before {
  content: "\f1ea";
}
.fi-rr-chair-office:before {
  content: "\f1eb";
}
.fi-rr-chair:before {
  content: "\f1ec";
}
.fi-rr-charging-station:before {
  content: "\f1ed";
}
.fi-rr-chart-area:before {
  content: "\f1ee";
}
.fi-rr-chart-connected:before {
  content: "\f1ef";
}
.fi-rr-chart-histogram:before {
  content: "\f1f0";
}
.fi-rr-chart-line-up:before {
  content: "\f1f1";
}
.fi-rr-chart-network:before {
  content: "\f1f2";
}
.fi-rr-chart-pie-alt:before {
  content: "\f1f3";
}
.fi-rr-chart-pie:before {
  content: "\f1f4";
}
.fi-rr-chart-pyramid:before {
  content: "\f1f5";
}
.fi-rr-chart-scatter:before {
  content: "\f1f6";
}
.fi-rr-chart-set-theory:before {
  content: "\f1f7";
}
.fi-rr-chart-tree:before {
  content: "\f1f8";
}
.fi-rr-chat-arrow-down:before {
  content: "\f1f9";
}
.fi-rr-chat-arrow-grow:before {
  content: "\f1fa";
}
.fi-rr-check:before {
  content: "\f1fb";
}
.fi-rr-checkbox:before {
  content: "\f1fc";
}
.fi-rr-cheese:before {
  content: "\f1fd";
}
.fi-rr-cherry:before {
  content: "\f1fe";
}
.fi-rr-chess-piece:before {
  content: "\f1ff";
}
.fi-rr-chess-bishop:before {
  content: "\f200";
}
.fi-rr-chess-board:before {
  content: "\f201";
}
.fi-rr-chess-clock-alt:before {
  content: "\f202";
}
.fi-rr-chess-clock:before {
  content: "\f203";
}
.fi-rr-chess-king-alt:before {
  content: "\f204";
}
.fi-rr-chess-king:before {
  content: "\f205";
}
.fi-rr-chess-knight-alt:before {
  content: "\f206";
}
.fi-rr-chess-knight:before {
  content: "\f207";
}
.fi-rr-chess-pawn-alt:before {
  content: "\f208";
}
.fi-rr-chess-queen-alt:before {
  content: "\f209";
}
.fi-rr-chess-queen:before {
  content: "\f20a";
}
.fi-rr-chess-rook-alt:before {
  content: "\f20b";
}
.fi-rr-chess-rook:before {
  content: "\f20c";
}
.fi-rr-chess:before {
  content: "\f20d";
}
.fi-rr-chevron-double-down:before {
  content: "\f20e";
}
.fi-rr-chevron-double-up:before {
  content: "\f20f";
}
.fi-rr-child-head:before {
  content: "\f210";
}
.fi-rr-chocolate:before {
  content: "\f211";
}
.fi-rr-circle-phone:before {
  content: "\f212";
}
.fi-rr-circle-envelope:before {
  content: "\f213";
}
.fi-rr-circle-phone-flip:before {
  content: "\f214";
}
.fi-rr-circle-phone-hangup:before {
  content: "\f215";
}
.fi-rr-circle-small:before {
  content: "\f216";
}
.fi-rr-circle:before {
  content: "\f217";
}
.fi-rr-clip:before {
  content: "\f218";
}
.fi-rr-clipboard-list:before {
  content: "\f219";
}
.fi-rr-clipboard-list-check:before {
  content: "\f21a";
}
.fi-rr-clock-eight-thirty:before {
  content: "\f21b";
}
.fi-rr-clock-eleven-thirty:before {
  content: "\f21c";
}
.fi-rr-clock-eleven:before {
  content: "\f21d";
}
.fi-rr-clock-five-thirty:before {
  content: "\f21e";
}
.fi-rr-clock-five:before {
  content: "\f21f";
}
.fi-rr-clock-four-thirty:before {
  content: "\f220";
}
.fi-rr-clock-nine-thirty:before {
  content: "\f221";
}
.fi-rr-clock-nine:before {
  content: "\f222";
}
.fi-rr-clock-one-thirty:before {
  content: "\f223";
}
.fi-rr-clock-one:before {
  content: "\f224";
}
.fi-rr-clock-seven-thirty:before {
  content: "\f225";
}
.fi-rr-clock-seven:before {
  content: "\f226";
}
.fi-rr-clock-six-thirty:before {
  content: "\f227";
}
.fi-rr-clock-six:before {
  content: "\f228";
}
.fi-rr-clock-ten-thirty:before {
  content: "\f229";
}
.fi-rr-clock-ten:before {
  content: "\f22a";
}
.fi-rr-clock-three-thirty:before {
  content: "\f22b";
}
.fi-rr-clock-three:before {
  content: "\f22c";
}
.fi-rr-clock-twelve-thirty:before {
  content: "\f22d";
}
.fi-rr-clock-twelve:before {
  content: "\f22e";
}
.fi-rr-clock-two-thirty:before {
  content: "\f22f";
}
.fi-rr-clock-two:before {
  content: "\f230";
}
.fi-rr-clock:before {
  content: "\f231";
}
.fi-rr-cloud-upload:before {
  content: "\f232";
}
.fi-rr-cloud-check:before {
  content: "\f233";
}
.fi-rr-cloud-disabled:before {
  content: "\f234";
}
.fi-rr-cloud-download-alt:before {
  content: "\f235";
}
.fi-rr-cloud-download:before {
  content: "\f236";
}
.fi-rr-cloud-drizzle:before {
  content: "\f237";
}
.fi-rr-cloud-hail-mixed:before {
  content: "\f238";
}
.fi-rr-cloud-hail:before {
  content: "\f239";
}
.fi-rr-cloud-moon-rain:before {
  content: "\f23a";
}
.fi-rr-cloud-moon:before {
  content: "\f23b";
}
.fi-rr-cloud-rain:before {
  content: "\f23c";
}
.fi-rr-cloud-rainbow:before {
  content: "\f23d";
}
.fi-rr-cloud-share:before {
  content: "\f23e";
}
.fi-rr-cloud-showers-heavy:before {
  content: "\f23f";
}
.fi-rr-cloud-showers:before {
  content: "\f240";
}
.fi-rr-cloud-sleet:before {
  content: "\f241";
}
.fi-rr-cloud-snow:before {
  content: "\f242";
}
.fi-rr-cloud-sun-rain:before {
  content: "\f243";
}
.fi-rr-cloud-sun:before {
  content: "\f244";
}
.fi-rr-cloud-upload-alt:before {
  content: "\f245";
}
.fi-rr-cloud:before {
  content: "\f246";
}
.fi-rr-clouds-moon:before {
  content: "\f247";
}
.fi-rr-clouds-sun:before {
  content: "\f248";
}
.fi-rr-clouds:before {
  content: "\f249";
}
.fi-rr-club:before {
  content: "\f24a";
}
.fi-rr-cocktail-alt:before {
  content: "\f24b";
}
.fi-rr-cocktail:before {
  content: "\f24c";
}
.fi-rr-coffee-pot:before {
  content: "\f24d";
}
.fi-rr-coffee:before {
  content: "\f24e";
}
.fi-rr-coin:before {
  content: "\f24f";
}
.fi-rr-coins:before {
  content: "\f250";
}
.fi-rr-comet:before {
  content: "\f251";
}
.fi-rr-comment-alt-middle-top:before {
  content: "\f252";
}
.fi-rr-comment-alt-middle:before {
  content: "\f253";
}
.fi-rr-comment-alt:before {
  content: "\f254";
}
.fi-rr-comment-arrow-down:before {
  content: "\f255";
}
.fi-rr-comment-arrow-up-right:before {
  content: "\f256";
}
.fi-rr-comment-arrow-up:before {
  content: "\f257";
}
.fi-rr-comment-check:before {
  content: "\f258";
}
.fi-rr-comment-code:before {
  content: "\f259";
}
.fi-rr-comment-dollar:before {
  content: "\f25a";
}
.fi-rr-comment-exclamation:before {
  content: "\f25b";
}
.fi-rr-comment-heart:before {
  content: "\f25c";
}
.fi-rr-comment-image:before {
  content: "\f25d";
}
.fi-rr-comment-info:before {
  content: "\f25e";
}
.fi-rr-comment-pen:before {
  content: "\f25f";
}
.fi-rr-comment-question:before {
  content: "\f260";
}
.fi-rr-comment-quote:before {
  content: "\f261";
}
.fi-rr-comment-slash:before {
  content: "\f262";
}
.fi-rr-comment-sms:before {
  content: "\f263";
}
.fi-rr-comment-text:before {
  content: "\f264";
}
.fi-rr-comment-user:before {
  content: "\f265";
}
.fi-rr-comment-xmark:before {
  content: "\f266";
}
.fi-rr-comment:before {
  content: "\f267";
}
.fi-rr-comments-dollar:before {
  content: "\f268";
}
.fi-rr-comments-question-check:before {
  content: "\f269";
}
.fi-rr-comments-question:before {
  content: "\f26a";
}
.fi-rr-comments:before {
  content: "\f26b";
}
.fi-rr-compress-alt:before {
  content: "\f26c";
}
.fi-rr-compress:before {
  content: "\f26d";
}
.fi-rr-computer:before {
  content: "\f26e";
}
.fi-rr-confetti:before {
  content: "\f26f";
}
.fi-rr-cookie:before {
  content: "\f270";
}
.fi-rr-copy-alt:before {
  content: "\f271";
}
.fi-rr-copy:before {
  content: "\f272";
}
.fi-rr-copyright:before {
  content: "\f273";
}
.fi-rr-corn:before {
  content: "\f274";
}
.fi-rr-cow:before {
  content: "\f275";
}
.fi-rr-cream:before {
  content: "\f276";
}
.fi-rr-credit-card:before {
  content: "\f277";
}
.fi-rr-cricket:before {
  content: "\f278";
}
.fi-rr-croissant:before {
  content: "\f279";
}
.fi-rr-cross-circle:before {
  content: "\f27a";
}
.fi-rr-cross-small:before {
  content: "\f27b";
}
.fi-rr-cross:before {
  content: "\f27c";
}
.fi-rr-crown:before {
  content: "\f27d";
}
.fi-rr-crystal-ball:before {
  content: "\f27e";
}
.fi-rr-cube:before {
  content: "\f27f";
}
.fi-rr-cupcake:before {
  content: "\f280";
}
.fi-rr-curling:before {
  content: "\f281";
}
.fi-rr-cursor-finger:before {
  content: "\f282";
}
.fi-rr-cursor-plus:before {
  content: "\f283";
}
.fi-rr-cursor-text-alt:before {
  content: "\f284";
}
.fi-rr-cursor-text:before {
  content: "\f285";
}
.fi-rr-cursor:before {
  content: "\f286";
}
.fi-rr-d:before {
  content: "\f287";
}
.fi-rr-dart:before {
  content: "\f288";
}
.fi-rr-dashboard:before {
  content: "\f289";
}
.fi-rr-data-transfer:before {
  content: "\f28a";
}
.fi-rr-database:before {
  content: "\f28b";
}
.fi-rr-delete-document:before {
  content: "\f28c";
}
.fi-rr-delete-user:before {
  content: "\f28d";
}
.fi-rr-delete:before {
  content: "\f28e";
}
.fi-rr-democrat:before {
  content: "\f28f";
}
.fi-rr-dewpoint:before {
  content: "\f290";
}
.fi-rr-diamond:before {
  content: "\f291";
}
.fi-rr-dice-alt:before {
  content: "\f292";
}
.fi-rr-dice-d10:before {
  content: "\f293";
}
.fi-rr-dice-d12:before {
  content: "\f294";
}
.fi-rr-dice-d20:before {
  content: "\f295";
}
.fi-rr-dice-d4:before {
  content: "\f296";
}
.fi-rr-dice-d6:before {
  content: "\f297";
}
.fi-rr-dice-d8:before {
  content: "\f298";
}
.fi-rr-dice-four:before {
  content: "\f299";
}
.fi-rr-dice-one:before {
  content: "\f29a";
}
.fi-rr-dice-six:before {
  content: "\f29b";
}
.fi-rr-dice-three:before {
  content: "\f29c";
}
.fi-rr-dice-two:before {
  content: "\f29d";
}
.fi-rr-dice:before {
  content: "\f29e";
}
.fi-rr-diploma:before {
  content: "\f29f";
}
.fi-rr-disco-ball:before {
  content: "\f2a0";
}
.fi-rr-disk:before {
  content: "\f2a1";
}
.fi-rr-dizzy:before {
  content: "\f2a2";
}
.fi-rr-doctor:before {
  content: "\f2a3";
}
.fi-rr-document-signed:before {
  content: "\f2a4";
}
.fi-rr-document:before {
  content: "\f2a5";
}
.fi-rr-dollar:before {
  content: "\f2a6";
}
.fi-rr-donate:before {
  content: "\f2a7";
}
.fi-rr-down-left-and-up-right-to-center:before {
  content: "\f2a8";
}
.fi-rr-download:before {
  content: "\f2a9";
}
.fi-rr-drafting-compass:before {
  content: "\f2aa";
}
.fi-rr-dreidel:before {
  content: "\f2ab";
}
.fi-rr-drink-alt:before {
  content: "\f2ac";
}
.fi-rr-drumstick:before {
  content: "\f2ad";
}
.fi-rr-duplicate:before {
  content: "\f2ae";
}
.fi-rr-e-learning:before {
  content: "\f2af";
}
.fi-rr-e:before {
  content: "\f2b0";
}
.fi-rr-earnings:before {
  content: "\f2b1";
}
.fi-rr-eclipse-alt:before {
  content: "\f2b2";
}
.fi-rr-eclipse:before {
  content: "\f2b3";
}
.fi-rr-edit-alt:before {
  content: "\f2b4";
}
.fi-rr-edit:before {
  content: "\f2b5";
}
.fi-rr-egg-fried:before {
  content: "\f2b6";
}
.fi-rr-egg:before {
  content: "\f2b7";
}
.fi-rr-engine-warning:before {
  content: "\f2b8";
}
.fi-rr-enter:before {
  content: "\f2b9";
}
.fi-rr-envelope-ban:before {
  content: "\f2ba";
}
.fi-rr-envelope-bulk:before {
  content: "\f2bb";
}
.fi-rr-envelope-download:before {
  content: "\f2bc";
}
.fi-rr-envelope-marker:before {
  content: "\f2bd";
}
.fi-rr-envelope-open-dollar:before {
  content: "\f2be";
}
.fi-rr-envelope-open-text:before {
  content: "\f2bf";
}
.fi-rr-envelope-open:before {
  content: "\f2c0";
}
.fi-rr-envelope-plus:before {
  content: "\f2c1";
}
.fi-rr-envelope:before {
  content: "\f2c2";
}
.fi-rr-equality:before {
  content: "\f2c3";
}
.fi-rr-euro:before {
  content: "\f2c4";
}
.fi-rr-exchange-alt:before {
  content: "\f2c5";
}
.fi-rr-exchange:before {
  content: "\f2c6";
}
.fi-rr-exclamation:before {
  content: "\f2c7";
}
.fi-rr-exit:before {
  content: "\f2c8";
}
.fi-rr-expand-arrows-alt:before {
  content: "\f2c9";
}
.fi-rr-expand-arrows:before {
  content: "\f2ca";
}
.fi-rr-expand:before {
  content: "\f2cb";
}
.fi-rr-eye-crossed:before {
  content: "\f2cc";
}
.fi-rr-eye-dropper:before {
  content: "\f2cd";
}
.fi-rr-eye:before {
  content: "\f2ce";
}
.fi-rr-f:before {
  content: "\f2cf";
}
.fi-rr-feather:before {
  content: "\f2d0";
}
.fi-rr-ferris-wheel:before {
  content: "\f2d1";
}
.fi-rr-fighter-jet:before {
  content: "\f2d2";
}
.fi-rr-file-invoice-dollar:before {
  content: "\f2d3";
}
.fi-rr-file-invoice:before {
  content: "\f2d4";
}
.fi-rr-file-ai:before {
  content: "\f2d5";
}
.fi-rr-file-chart-line:before {
  content: "\f2d6";
}
.fi-rr-file-chart-pie:before {
  content: "\f2d7";
}
.fi-rr-file-eps:before {
  content: "\f2d8";
}
.fi-rr-file-psd:before {
  content: "\f2d9";
}
.fi-rr-file-spreadsheet:before {
  content: "\f2da";
}
.fi-rr-file-video:before {
  content: "\f2db";
}
.fi-rr-file:before {
  content: "\f2dc";
}
.fi-rr-fill:before {
  content: "\f2dd";
}
.fi-rr-film-slash:before {
  content: "\f2de";
}
.fi-rr-film:before {
  content: "\f2df";
}
.fi-rr-filter-slash:before {
  content: "\f2e0";
}
.fi-rr-filter:before {
  content: "\f2e1";
}
.fi-rr-filters:before {
  content: "\f2e2";
}
.fi-rr-fingerprint:before {
  content: "\f2e3";
}
.fi-rr-fish:before {
  content: "\f2e4";
}
.fi-rr-flag-alt:before {
  content: "\f2e5";
}
.fi-rr-flag-usa:before {
  content: "\f2e6";
}
.fi-rr-flag:before {
  content: "\f2e7";
}
.fi-rr-flame:before {
  content: "\f2e8";
}
.fi-rr-flip-horizontal:before {
  content: "\f2e9";
}
.fi-rr-flower-bouquet:before {
  content: "\f2ea";
}
.fi-rr-flower-tulip:before {
  content: "\f2eb";
}
.fi-rr-flower:before {
  content: "\f2ec";
}
.fi-rr-flushed:before {
  content: "\f2ed";
}
.fi-rr-fog:before {
  content: "\f2ee";
}
.fi-rr-folder-download:before {
  content: "\f2ef";
}
.fi-rr-folder-minus:before {
  content: "\f2f0";
}
.fi-rr-folder-times:before {
  content: "\f2f1";
}
.fi-rr-folder-tree:before {
  content: "\f2f2";
}
.fi-rr-folder-upload:before {
  content: "\f2f3";
}
.fi-rr-folder:before {
  content: "\f2f4";
}
.fi-rr-folders:before {
  content: "\f2f5";
}
.fi-rr-following:before {
  content: "\f2f6";
}
.fi-rr-football:before {
  content: "\f2f7";
}
.fi-rr-fork:before {
  content: "\f2f8";
}
.fi-rr-form:before {
  content: "\f2f9";
}
.fi-rr-forward:before {
  content: "\f2fa";
}
.fi-rr-fox:before {
  content: "\f2fb";
}
.fi-rr-french-fries:before {
  content: "\f2fc";
}
.fi-rr-frown:before {
  content: "\f2fd";
}
.fi-rr-ftp:before {
  content: "\f2fe";
}
.fi-rr-funnel-dollar:before {
  content: "\f2ff";
}
.fi-rr-g:before {
  content: "\f300";
}
.fi-rr-gallery:before {
  content: "\f301";
}
.fi-rr-game-board-alt:before {
  content: "\f302";
}
.fi-rr-gamepad:before {
  content: "\f303";
}
.fi-rr-garage-car:before {
  content: "\f304";
}
.fi-rr-garage-open:before {
  content: "\f305";
}
.fi-rr-garage:before {
  content: "\f306";
}
.fi-rr-garlic:before {
  content: "\f307";
}
.fi-rr-gas-pump-alt:before {
  content: "\f308";
}
.fi-rr-gas-pump-slash:before {
  content: "\f309";
}
.fi-rr-gas-pump:before {
  content: "\f30a";
}
.fi-rr-gem:before {
  content: "\f30b";
}
.fi-rr-gif:before {
  content: "\f30c";
}
.fi-rr-gift-card:before {
  content: "\f30d";
}
.fi-rr-gift:before {
  content: "\f30e";
}
.fi-rr-gifts:before {
  content: "\f30f";
}
.fi-rr-gingerbread-man:before {
  content: "\f310";
}
.fi-rr-glass-cheers:before {
  content: "\f311";
}
.fi-rr-glass:before {
  content: "\f312";
}
.fi-rr-glasses:before {
  content: "\f313";
}
.fi-rr-globe-alt:before {
  content: "\f314";
}
.fi-rr-globe:before {
  content: "\f315";
}
.fi-rr-golf-ball:before {
  content: "\f316";
}
.fi-rr-golf:before {
  content: "\f317";
}
.fi-rr-graduation-cap:before {
  content: "\f318";
}
.fi-rr-grape:before {
  content: "\f319";
}
.fi-rr-graphic-tablet:before {
  content: "\f31a";
}
.fi-rr-grid-alt:before {
  content: "\f31b";
}
.fi-rr-grid:before {
  content: "\f31c";
}
.fi-rr-grill:before {
  content: "\f31d";
}
.fi-rr-grimace:before {
  content: "\f31e";
}
.fi-rr-grin-alt:before {
  content: "\f31f";
}
.fi-rr-grin-beam-sweat:before {
  content: "\f320";
}
.fi-rr-grin-beam:before {
  content: "\f321";
}
.fi-rr-grin-hearts:before {
  content: "\f322";
}
.fi-rr-grin-squint-tears:before {
  content: "\f323";
}
.fi-rr-grin-squint:before {
  content: "\f324";
}
.fi-rr-grin-stars:before {
  content: "\f325";
}
.fi-rr-grin-tears:before {
  content: "\f326";
}
.fi-rr-grin-tongue-squint:before {
  content: "\f327";
}
.fi-rr-grin-tongue-wink:before {
  content: "\f328";
}
.fi-rr-grin-tongue:before {
  content: "\f329";
}
.fi-rr-grin-wink:before {
  content: "\f32a";
}
.fi-rr-grin:before {
  content: "\f32b";
}
.fi-rr-guitar:before {
  content: "\f32c";
}
.fi-rr-gym:before {
  content: "\f32d";
}
.fi-rr-h:before {
  content: "\f32e";
}
.fi-rr-hamburger-soda:before {
  content: "\f32f";
}
.fi-rr-hamburger:before {
  content: "\f330";
}
.fi-rr-hand-holding-box:before {
  content: "\f331";
}
.fi-rr-hand-holding-heart:before {
  content: "\f332";
}
.fi-rr-hand-holding-seeding:before {
  content: "\f333";
}
.fi-rr-hand:before {
  content: "\f334";
}
.fi-rr-handshake:before {
  content: "\f335";
}
.fi-rr-hastag:before {
  content: "\f336";
}
.fi-rr-hat-birthday:before {
  content: "\f337";
}
.fi-rr-hat-chef:before {
  content: "\f338";
}
.fi-rr-head-side-thinking:before {
  content: "\f339";
}
.fi-rr-headphones:before {
  content: "\f33a";
}
.fi-rr-headset:before {
  content: "\f33b";
}
.fi-rr-heart-arrow:before {
  content: "\f33c";
}
.fi-rr-heart:before {
  content: "\f33d";
}
.fi-rr-heat:before {
  content: "\f33e";
}
.fi-rr-helicopter-side:before {
  content: "\f33f";
}
.fi-rr-highlighter:before {
  content: "\f340";
}
.fi-rr-hiking:before {
  content: "\f341";
}
.fi-rr-hockey-puck:before {
  content: "\f342";
}
.fi-rr-hockey-sticks:before {
  content: "\f343";
}
.fi-rr-home-location-alt:before {
  content: "\f344";
}
.fi-rr-home-location:before {
  content: "\f345";
}
.fi-rr-home:before {
  content: "\f346";
}
.fi-rr-hotdog:before {
  content: "\f347";
}
.fi-rr-hourglass-end:before {
  content: "\f348";
}
.fi-rr-hourglass:before {
  content: "\f349";
}
.fi-rr-house-flood:before {
  content: "\f34a";
}
.fi-rr-hryvnia:before {
  content: "\f34b";
}
.fi-rr-humidity:before {
  content: "\f34c";
}
.fi-rr-hurricane:before {
  content: "\f34d";
}
.fi-rr-i:before {
  content: "\f34e";
}
.fi-rr-ice-cream:before {
  content: "\f34f";
}
.fi-rr-ice-skate:before {
  content: "\f350";
}
.fi-rr-id-badge:before {
  content: "\f351";
}
.fi-rr-inbox-in:before {
  content: "\f352";
}
.fi-rr-inbox-out:before {
  content: "\f353";
}
.fi-rr-inbox:before {
  content: "\f354";
}
.fi-rr-incognito:before {
  content: "\f355";
}
.fi-rr-indent:before {
  content: "\f356";
}
.fi-rr-infinity:before {
  content: "\f357";
}
.fi-rr-info:before {
  content: "\f358";
}
.fi-rr-interactive:before {
  content: "\f359";
}
.fi-rr-interlining:before {
  content: "\f35a";
}
.fi-rr-interrogation:before {
  content: "\f35b";
}
.fi-rr-italic:before {
  content: "\f35c";
}
.fi-rr-j:before {
  content: "\f35d";
}
.fi-rr-jam:before {
  content: "\f35e";
}
.fi-rr-jpg:before {
  content: "\f35f";
}
.fi-rr-k:before {
  content: "\f360";
}
.fi-rr-kerning:before {
  content: "\f361";
}
.fi-rr-key:before {
  content: "\f362";
}
.fi-rr-keyboard:before {
  content: "\f363";
}
.fi-rr-keynote:before {
  content: "\f364";
}
.fi-rr-kiss-beam:before {
  content: "\f365";
}
.fi-rr-kiss-wink-heart:before {
  content: "\f366";
}
.fi-rr-kiss:before {
  content: "\f367";
}
.fi-rr-kite:before {
  content: "\f368";
}
.fi-rr-knife:before {
  content: "\f369";
}
.fi-rr-l:before {
  content: "\f36a";
}
.fi-rr-label:before {
  content: "\f36b";
}
.fi-rr-laptop:before {
  content: "\f36c";
}
.fi-rr-lasso:before {
  content: "\f36d";
}
.fi-rr-laugh-beam:before {
  content: "\f36e";
}
.fi-rr-laugh-squint:before {
  content: "\f36f";
}
.fi-rr-laugh-wink:before {
  content: "\f370";
}
.fi-rr-laugh:before {
  content: "\f371";
}
.fi-rr-layer-minus:before {
  content: "\f372";
}
.fi-rr-layer-plus:before {
  content: "\f373";
}
.fi-rr-layers:before {
  content: "\f374";
}
.fi-rr-layout-fluid:before {
  content: "\f375";
}
.fi-rr-leaf:before {
  content: "\f376";
}
.fi-rr-lemon:before {
  content: "\f377";
}
.fi-rr-letter-case:before {
  content: "\f378";
}
.fi-rr-lettuce:before {
  content: "\f379";
}
.fi-rr-level-down-alt:before {
  content: "\f37a";
}
.fi-rr-level-down:before {
  content: "\f37b";
}
.fi-rr-level-up-alt:before {
  content: "\f37c";
}
.fi-rr-level-up:before {
  content: "\f37d";
}
.fi-rr-life-ring:before {
  content: "\f37e";
}
.fi-rr-lightbulb-dollar:before {
  content: "\f37f";
}
.fi-rr-line-width:before {
  content: "\f380";
}
.fi-rr-link-alt:before {
  content: "\f381";
}
.fi-rr-link-slash-alt:before {
  content: "\f382";
}
.fi-rr-link-slash:before {
  content: "\f383";
}
.fi-rr-link:before {
  content: "\f384";
}
.fi-rr-lipstick:before {
  content: "\f385";
}
.fi-rr-lira-sign:before {
  content: "\f386";
}
.fi-rr-list-check:before {
  content: "\f387";
}
.fi-rr-list:before {
  content: "\f388";
}
.fi-rr-loading:before {
  content: "\f389";
}
.fi-rr-location-alt:before {
  content: "\f38a";
}
.fi-rr-lock-alt:before {
  content: "\f38b";
}
.fi-rr-lock:before {
  content: "\f38c";
}
.fi-rr-luchador:before {
  content: "\f38d";
}
.fi-rr-luggage-rolling:before {
  content: "\f38e";
}
.fi-rr-m:before {
  content: "\f38f";
}
.fi-rr-magic-wand:before {
  content: "\f390";
}
.fi-rr-mailbox:before {
  content: "\f391";
}
.fi-rr-makeup-brush:before {
  content: "\f392";
}
.fi-rr-man-head:before {
  content: "\f393";
}
.fi-rr-map-marker-cross:before {
  content: "\f394";
}
.fi-rr-map-marker-home:before {
  content: "\f395";
}
.fi-rr-map-marker-minus:before {
  content: "\f396";
}
.fi-rr-map-marker-plus:before {
  content: "\f397";
}
.fi-rr-map-marker:before {
  content: "\f398";
}
.fi-rr-map:before {
  content: "\f399";
}
.fi-rr-marker-time:before {
  content: "\f39a";
}
.fi-rr-marker:before {
  content: "\f39b";
}
.fi-rr-mars-double:before {
  content: "\f39c";
}
.fi-rr-mars:before {
  content: "\f39d";
}
.fi-rr-mask-carnival:before {
  content: "\f39e";
}
.fi-rr-mask:before {
  content: "\f39f";
}
.fi-rr-medicine:before {
  content: "\f3a0";
}
.fi-rr-megaphone:before {
  content: "\f3a1";
}
.fi-rr-meh-blank:before {
  content: "\f3a2";
}
.fi-rr-meh-rolling-eyes:before {
  content: "\f3a3";
}
.fi-rr-meh:before {
  content: "\f3a4";
}
.fi-rr-melon:before {
  content: "\f3a5";
}
.fi-rr-menu-burger:before {
  content: "\f3a6";
}
.fi-rr-menu-dots-vertical:before {
  content: "\f3a7";
}
.fi-rr-menu-dots:before {
  content: "\f3a8";
}
.fi-rr-meteor:before {
  content: "\f3a9";
}
.fi-rr-microphone-alt:before {
  content: "\f3aa";
}
.fi-rr-microphone:before {
  content: "\f3ab";
}
.fi-rr-mind-share:before {
  content: "\f3ac";
}
.fi-rr-minus-small:before {
  content: "\f3ad";
}
.fi-rr-minus:before {
  content: "\f3ae";
}
.fi-rr-mobile-button:before {
  content: "\f3af";
}
.fi-rr-mobile-notch:before {
  content: "\f3b0";
}
.fi-rr-mobile:before {
  content: "\f3b1";
}
.fi-rr-mode-landscape:before {
  content: "\f3b2";
}
.fi-rr-mode-portrait:before {
  content: "\f3b3";
}
.fi-rr-money-bill-wave-alt:before {
  content: "\f3b4";
}
.fi-rr-money-bill-wave:before {
  content: "\f3b5";
}
.fi-rr-money-check:before {
  content: "\f3b6";
}
.fi-rr-money-check-edit-alt:before {
  content: "\f3b7";
}
.fi-rr-money-check-edit:before {
  content: "\f3b8";
}
.fi-rr-money:before {
  content: "\f3b9";
}
.fi-rr-moon-stars:before {
  content: "\f3ba";
}
.fi-rr-moon:before {
  content: "\f3bb";
}
.fi-rr-motorcycle:before {
  content: "\f3bc";
}
.fi-rr-mountains:before {
  content: "\f3bd";
}
.fi-rr-mouse:before {
  content: "\f3be";
}
.fi-rr-mug-alt:before {
  content: "\f3bf";
}
.fi-rr-mug-hot-alt:before {
  content: "\f3c0";
}
.fi-rr-mug-hot:before {
  content: "\f3c1";
}
.fi-rr-mug-tea:before {
  content: "\f3c2";
}
.fi-rr-mug:before {
  content: "\f3c3";
}
.fi-rr-mushroom:before {
  content: "\f3c4";
}
.fi-rr-music-file:before {
  content: "\f3c5";
}
.fi-rr-music-alt:before {
  content: "\f3c6";
}
.fi-rr-music:before {
  content: "\f3c7";
}
.fi-rr-n:before {
  content: "\f3c8";
}
.fi-rr-navigation:before {
  content: "\f3c9";
}
.fi-rr-network-cloud:before {
  content: "\f3ca";
}
.fi-rr-network:before {
  content: "\f3cb";
}
.fi-rr-noodles:before {
  content: "\f3cc";
}
.fi-rr-notebook:before {
  content: "\f3cd";
}
.fi-rr-o:before {
  content: "\f3ce";
}
.fi-rr-oil-can:before {
  content: "\f3cf";
}
.fi-rr-oil-temp:before {
  content: "\f3d0";
}
.fi-rr-olive-oil:before {
  content: "\f3d1";
}
.fi-rr-olives:before {
  content: "\f3d2";
}
.fi-rr-onion:before {
  content: "\f3d3";
}
.fi-rr-opacity:before {
  content: "\f3d4";
}
.fi-rr-overline:before {
  content: "\f3d5";
}
.fi-rr-p:before {
  content: "\f3d6";
}
.fi-rr-package:before {
  content: "\f3d7";
}
.fi-rr-page-break:before {
  content: "\f3d8";
}
.fi-rr-paint-roller:before {
  content: "\f3d9";
}
.fi-rr-paint-brush:before {
  content: "\f3da";
}
.fi-rr-palette:before {
  content: "\f3db";
}
.fi-rr-pan:before {
  content: "\f3dc";
}
.fi-rr-paper-plane:before {
  content: "\f3dd";
}
.fi-rr-password:before {
  content: "\f3de";
}
.fi-rr-pause:before {
  content: "\f3df";
}
.fi-rr-paw:before {
  content: "\f3e0";
}
.fi-rr-peach:before {
  content: "\f3e1";
}
.fi-rr-pencil-ruler:before {
  content: "\f3e2";
}
.fi-rr-pencil:before {
  content: "\f3e3";
}
.fi-rr-pennant:before {
  content: "\f3e4";
}
.fi-rr-people-poll:before {
  content: "\f3e5";
}
.fi-rr-pepper-hot:before {
  content: "\f3e6";
}
.fi-rr-pepper:before {
  content: "\f3e7";
}
.fi-rr-percentage:before {
  content: "\f3e8";
}
.fi-rr-pharmacy:before {
  content: "\f3e9";
}
.fi-rr-phone-office:before {
  content: "\f3ea";
}
.fi-rr-phone-call:before {
  content: "\f3eb";
}
.fi-rr-phone-cross:before {
  content: "\f3ec";
}
.fi-rr-phone-pause:before {
  content: "\f3ed";
}
.fi-rr-phone-slash:before {
  content: "\f3ee";
}
.fi-rr-photo-film-music:before {
  content: "\f3ef";
}
.fi-rr-photo-video:before {
  content: "\f3f0";
}
.fi-rr-physics:before {
  content: "\f3f1";
}
.fi-rr-picnic:before {
  content: "\f3f2";
}
.fi-rr-picture:before {
  content: "\f3f3";
}
.fi-rr-pie:before {
  content: "\f3f4";
}
.fi-rr-piece:before {
  content: "\f3f5";
}
.fi-rr-piggy-bank:before {
  content: "\f3f6";
}
.fi-rr-pineapple:before {
  content: "\f3f7";
}
.fi-rr-ping-pong:before {
  content: "\f3f8";
}
.fi-rr-pizza-slice:before {
  content: "\f3f9";
}
.fi-rr-plane-alt:before {
  content: "\f3fa";
}
.fi-rr-plane:before {
  content: "\f3fb";
}
.fi-rr-plate:before {
  content: "\f3fc";
}
.fi-rr-play-alt:before {
  content: "\f3fd";
}
.fi-rr-play-pause:before {
  content: "\f3fe";
}
.fi-rr-play:before {
  content: "\f3ff";
}
.fi-rr-playing-cards:before {
  content: "\f400";
}
.fi-rr-plus-small:before {
  content: "\f401";
}
.fi-rr-plus:before {
  content: "\f402";
}
.fi-rr-podium-star:before {
  content: "\f403";
}
.fi-rr-podium:before {
  content: "\f404";
}
.fi-rr-poker-chip:before {
  content: "\f405";
}
.fi-rr-poo:before {
  content: "\f406";
}
.fi-rr-popcorn:before {
  content: "\f407";
}
.fi-rr-portrait:before {
  content: "\f408";
}
.fi-rr-pot:before {
  content: "\f409";
}
.fi-rr-pound:before {
  content: "\f40a";
}
.fi-rr-power:before {
  content: "\f40b";
}
.fi-rr-presentation:before {
  content: "\f40c";
}
.fi-rr-print:before {
  content: "\f40d";
}
.fi-rr-protractor:before {
  content: "\f40e";
}
.fi-rr-pulse:before {
  content: "\f40f";
}
.fi-rr-pumpkin:before {
  content: "\f410";
}
.fi-rr-puzzle:before {
  content: "\f411";
}
.fi-rr-pyramid:before {
  content: "\f412";
}
.fi-rr-q:before {
  content: "\f413";
}
.fi-rr-question-square:before {
  content: "\f414";
}
.fi-rr-question:before {
  content: "\f415";
}
.fi-rr-quote-right:before {
  content: "\f416";
}
.fi-rr-r:before {
  content: "\f417";
}
.fi-rr-racquet:before {
  content: "\f418";
}
.fi-rr-radish:before {
  content: "\f419";
}
.fi-rr-rainbow:before {
  content: "\f41a";
}
.fi-rr-raindrops:before {
  content: "\f41b";
}
.fi-rr-rec:before {
  content: "\f41c";
}
.fi-rr-receipt:before {
  content: "\f41d";
}
.fi-rr-record-vinyl:before {
  content: "\f41e";
}
.fi-rr-rectabgle-vertical:before {
  content: "\f41f";
}
.fi-rr-rectangle-horizontal:before {
  content: "\f420";
}
.fi-rr-rectangle-panoramic:before {
  content: "\f421";
}
.fi-rr-recycle:before {
  content: "\f422";
}
.fi-rr-redo-alt:before {
  content: "\f423";
}
.fi-rr-redo:before {
  content: "\f424";
}
.fi-rr-reflect:before {
  content: "\f425";
}
.fi-rr-refresh:before {
  content: "\f426";
}
.fi-rr-registered:before {
  content: "\f427";
}
.fi-rr-remove-user:before {
  content: "\f428";
}
.fi-rr-reply-all:before {
  content: "\f429";
}
.fi-rr-republican:before {
  content: "\f42a";
}
.fi-rr-resize:before {
  content: "\f42b";
}
.fi-rr-resources:before {
  content: "\f42c";
}
.fi-rr-restaurant:before {
  content: "\f42d";
}
.fi-rr-rewind:before {
  content: "\f42e";
}
.fi-rr-rhombus:before {
  content: "\f42f";
}
.fi-rr-rings-wedding:before {
  content: "\f430";
}
.fi-rr-road:before {
  content: "\f431";
}
.fi-rr-rocket-lunch:before {
  content: "\f432";
}
.fi-rr-rocket:before {
  content: "\f433";
}
.fi-rr-room-service:before {
  content: "\f434";
}
.fi-rr-rotate-left:before {
  content: "\f435";
}
.fi-rr-rotate-right:before {
  content: "\f436";
}
.fi-rr-ruble-sign:before {
  content: "\f437";
}
.fi-rr-rugby:before {
  content: "\f438";
}
.fi-rr-ruler-combined:before {
  content: "\f439";
}
.fi-rr-ruler-horizontal:before {
  content: "\f43a";
}
.fi-rr-ruler-triangle:before {
  content: "\f43b";
}
.fi-rr-ruler-vertical:before {
  content: "\f43c";
}
.fi-rr-running:before {
  content: "\f43d";
}
.fi-rr-rupee-sign:before {
  content: "\f43e";
}
.fi-rr-rv:before {
  content: "\f43f";
}
.fi-rr-s:before {
  content: "\f440";
}
.fi-rr-sack-dollar:before {
  content: "\f441";
}
.fi-rr-sack:before {
  content: "\f442";
}
.fi-rr-sad-cry:before {
  content: "\f443";
}
.fi-rr-sad-tear:before {
  content: "\f444";
}
.fi-rr-sad:before {
  content: "\f445";
}
.fi-rr-salad:before {
  content: "\f446";
}
.fi-rr-salt-pepper:before {
  content: "\f447";
}
.fi-rr-sandwich:before {
  content: "\f448";
}
.fi-rr-sauce:before {
  content: "\f449";
}
.fi-rr-sausage:before {
  content: "\f44a";
}
.fi-rr-scale:before {
  content: "\f44b";
}
.fi-rr-school-bus:before {
  content: "\f44c";
}
.fi-rr-school:before {
  content: "\f44d";
}
.fi-rr-scissors:before {
  content: "\f44e";
}
.fi-rr-screen:before {
  content: "\f44f";
}
.fi-rr-search-dollar:before {
  content: "\f450";
}
.fi-rr-search-location:before {
  content: "\f451";
}
.fi-rr-search-alt:before {
  content: "\f452";
}
.fi-rr-search-heart:before {
  content: "\f453";
}
.fi-rr-search:before {
  content: "\f454";
}
.fi-rr-security:before {
  content: "\f455";
}
.fi-rr-sensor-alert:before {
  content: "\f456";
}
.fi-rr-sensor-fire:before {
  content: "\f457";
}
.fi-rr-sensor-on:before {
  content: "\f458";
}
.fi-rr-sensor-smoke:before {
  content: "\f459";
}
.fi-rr-sensor:before {
  content: "\f45a";
}
.fi-rr-settings-sliders:before {
  content: "\f45b";
}
.fi-rr-settings:before {
  content: "\f45c";
}
.fi-rr-share:before {
  content: "\f45d";
}
.fi-rr-shekel-sign:before {
  content: "\f45e";
}
.fi-rr-shield-check:before {
  content: "\f45f";
}
.fi-rr-shield-exclamation:before {
  content: "\f460";
}
.fi-rr-shield-interrogation:before {
  content: "\f461";
}
.fi-rr-shield-plus:before {
  content: "\f462";
}
.fi-rr-shield:before {
  content: "\f463";
}
.fi-rr-ship-side:before {
  content: "\f464";
}
.fi-rr-ship:before {
  content: "\f465";
}
.fi-rr-shop:before {
  content: "\f466";
}
.fi-rr-shopping-basket:before {
  content: "\f467";
}
.fi-rr-shopping-bag-add:before {
  content: "\f468";
}
.fi-rr-shopping-bag:before {
  content: "\f469";
}
.fi-rr-shopping-cart-add:before {
  content: "\f46a";
}
.fi-rr-shopping-cart-check:before {
  content: "\f46b";
}
.fi-rr-shopping-cart:before {
  content: "\f46c";
}
.fi-rr-shrimp:before {
  content: "\f46d";
}
.fi-rr-shuffle:before {
  content: "\f46e";
}
.fi-rr-shuttle-van:before {
  content: "\f46f";
}
.fi-rr-shuttlecock:before {
  content: "\f470";
}
.fi-rr-sign-in-alt:before {
  content: "\f471";
}
.fi-rr-sign-out-alt:before {
  content: "\f472";
}
.fi-rr-signal-alt-1:before {
  content: "\f473";
}
.fi-rr-signal-alt-2:before {
  content: "\f474";
}
.fi-rr-signal-alt:before {
  content: "\f475";
}
.fi-rr-skateboard:before {
  content: "\f476";
}
.fi-rr-skating:before {
  content: "\f477";
}
.fi-rr-skewer:before {
  content: "\f478";
}
.fi-rr-ski-jump:before {
  content: "\f479";
}
.fi-rr-ski-lift:before {
  content: "\f47a";
}
.fi-rr-skiing-nordic:before {
  content: "\f47b";
}
.fi-rr-skiing:before {
  content: "\f47c";
}
.fi-rr-sledding:before {
  content: "\f47d";
}
.fi-rr-sleigh:before {
  content: "\f47e";
}
.fi-rr-smartphone:before {
  content: "\f47f";
}
.fi-rr-smile-beam:before {
  content: "\f480";
}
.fi-rr-smile-wink:before {
  content: "\f481";
}
.fi-rr-smile:before {
  content: "\f482";
}
.fi-rr-smog:before {
  content: "\f483";
}
.fi-rr-smoke:before {
  content: "\f484";
}
.fi-rr-snow-blowing:before {
  content: "\f485";
}
.fi-rr-snowboarding:before {
  content: "\f486";
}
.fi-rr-snowflake:before {
  content: "\f487";
}
.fi-rr-snowflakes:before {
  content: "\f488";
}
.fi-rr-snowmobile:before {
  content: "\f489";
}
.fi-rr-snowplow:before {
  content: "\f48a";
}
.fi-rr-soap:before {
  content: "\f48b";
}
.fi-rr-social-network:before {
  content: "\f48c";
}
.fi-rr-sort-alpha-down-alt:before {
  content: "\f48d";
}
.fi-rr-sort-alpha-down:before {
  content: "\f48e";
}
.fi-rr-sort-alpha-up-alt:before {
  content: "\f48f";
}
.fi-rr-sort-alpha-up:before {
  content: "\f490";
}
.fi-rr-sort-alt:before {
  content: "\f491";
}
.fi-rr-sort-amount-down-alt:before {
  content: "\f492";
}
.fi-rr-sort-amount-down:before {
  content: "\f493";
}
.fi-rr-sort-amount-up-alt:before {
  content: "\f494";
}
.fi-rr-sort-amount-up:before {
  content: "\f495";
}
.fi-rr-sort-down:before {
  content: "\f496";
}
.fi-rr-sort-numeric-down-alt:before {
  content: "\f497";
}
.fi-rr-sort-numeric-down:before {
  content: "\f498";
}
.fi-rr-sort:before {
  content: "\f499";
}
.fi-rr-soup:before {
  content: "\f49a";
}
.fi-rr-spa:before {
  content: "\f49b";
}
.fi-rr-space-shuttle:before {
  content: "\f49c";
}
.fi-rr-spade:before {
  content: "\f49d";
}
.fi-rr-sparkles:before {
  content: "\f49e";
}
.fi-rr-speaker:before {
  content: "\f49f";
}
.fi-rr-sphere:before {
  content: "\f4a0";
}
.fi-rr-spinner:before {
  content: "\f4a1";
}
.fi-rr-spoon:before {
  content: "\f4a2";
}
.fi-rr-sport:before {
  content: "\f4a3";
}
.fi-rr-square-root:before {
  content: "\f4a4";
}
.fi-rr-square:before {
  content: "\f4a5";
}
.fi-rr-stamp:before {
  content: "\f4a6";
}
.fi-rr-star-octogram:before {
  content: "\f4a7";
}
.fi-rr-star:before {
  content: "\f4a8";
}
.fi-rr-starfighter:before {
  content: "\f4a9";
}
.fi-rr-stars:before {
  content: "\f4aa";
}
.fi-rr-stats:before {
  content: "\f4ab";
}
.fi-rr-steak:before {
  content: "\f4ac";
}
.fi-rr-steering-wheel:before {
  content: "\f4ad";
}
.fi-rr-stethoscope:before {
  content: "\f4ae";
}
.fi-rr-sticker:before {
  content: "\f4af";
}
.fi-rr-stop:before {
  content: "\f4b0";
}
.fi-rr-stopwatch:before {
  content: "\f4b1";
}
.fi-rr-store-alt:before {
  content: "\f4b2";
}
.fi-rr-strawberry:before {
  content: "\f4b3";
}
.fi-rr-subtitles:before {
  content: "\f4b4";
}
.fi-rr-subway:before {
  content: "\f4b5";
}
.fi-rr-suitcase-alt:before {
  content: "\f4b6";
}
.fi-rr-summer:before {
  content: "\f4b7";
}
.fi-rr-sun:before {
  content: "\f4b8";
}
.fi-rr-sunrise-alt:before {
  content: "\f4b9";
}
.fi-rr-sunrise:before {
  content: "\f4ba";
}
.fi-rr-sunset:before {
  content: "\f4bb";
}
.fi-rr-surfing:before {
  content: "\f4bc";
}
.fi-rr-surprise:before {
  content: "\f4bd";
}
.fi-rr-sushi:before {
  content: "\f4be";
}
.fi-rr-swimmer:before {
  content: "\f4bf";
}
.fi-rr-sword:before {
  content: "\f4c0";
}
.fi-rr-symbol:before {
  content: "\f4c1";
}
.fi-rr-syringe:before {
  content: "\f4c2";
}
.fi-rr-t:before {
  content: "\f4c3";
}
.fi-rr-tablet:before {
  content: "\f4c4";
}
.fi-rr-tachometer-alt-average:before {
  content: "\f4c5";
}
.fi-rr-tachometer-alt-fastest:before {
  content: "\f4c6";
}
.fi-rr-tachometer-alt-slow:before {
  content: "\f4c7";
}
.fi-rr-tachometer-alt-slowest:before {
  content: "\f4c8";
}
.fi-rr-tachometer-average:before {
  content: "\f4c9";
}
.fi-rr-tachometer-fast:before {
  content: "\f4ca";
}
.fi-rr-tachometer-fastest:before {
  content: "\f4cb";
}
.fi-rr-tachometer-slow:before {
  content: "\f4cc";
}
.fi-rr-tachometer-slowest:before {
  content: "\f4cd";
}
.fi-rr-tachometer:before {
  content: "\f4ce";
}
.fi-rr-taco:before {
  content: "\f4cf";
}
.fi-rr-tags:before {
  content: "\f4d0";
}
.fi-rr-tally:before {
  content: "\f4d1";
}
.fi-rr-target:before {
  content: "\f4d2";
}
.fi-rr-taxi:before {
  content: "\f4d3";
}
.fi-rr-temperature-down:before {
  content: "\f4d4";
}
.fi-rr-temperature-frigid:before {
  content: "\f4d5";
}
.fi-rr-temperature-high:before {
  content: "\f4d6";
}
.fi-rr-temperature-low:before {
  content: "\f4d7";
}
.fi-rr-temperature-up:before {
  content: "\f4d8";
}
.fi-rr-tenge:before {
  content: "\f4d9";
}
.fi-rr-tennis:before {
  content: "\f4da";
}
.fi-rr-terrace:before {
  content: "\f4db";
}
.fi-rr-test-tube:before {
  content: "\f4dc";
}
.fi-rr-test:before {
  content: "\f4dd";
}
.fi-rr-text-check:before {
  content: "\f4de";
}
.fi-rr-text-slash:before {
  content: "\f4df";
}
.fi-rr-text:before {
  content: "\f4e0";
}
.fi-rr-thermometer-half:before {
  content: "\f4e1";
}
.fi-rr-thumbtack:before {
  content: "\f4e2";
}
.fi-rr-thunderstorm-moon:before {
  content: "\f4e3";
}
.fi-rr-thunderstorm-sun:before {
  content: "\f4e4";
}
.fi-rr-thunderstorm:before {
  content: "\f4e5";
}
.fi-rr-ticket:before {
  content: "\f4e6";
}
.fi-rr-time-quarter-past:before {
  content: "\f4e7";
}
.fi-rr-time-add:before {
  content: "\f4e8";
}
.fi-rr-time-check:before {
  content: "\f4e9";
}
.fi-rr-time-delete:before {
  content: "\f4ea";
}
.fi-rr-time-fast:before {
  content: "\f4eb";
}
.fi-rr-time-forward-sixty:before {
  content: "\f4ec";
}
.fi-rr-time-forward-ten:before {
  content: "\f4ed";
}
.fi-rr-time-forward:before {
  content: "\f4ee";
}
.fi-rr-time-half-past:before {
  content: "\f4ef";
}
.fi-rr-time-oclock:before {
  content: "\f4f0";
}
.fi-rr-time-past:before {
  content: "\f4f1";
}
.fi-rr-time-quarter-to:before {
  content: "\f4f2";
}
.fi-rr-time-twenty-four:before {
  content: "\f4f3";
}
.fi-rr-tire-flat:before {
  content: "\f4f4";
}
.fi-rr-tire-pressure-warning:before {
  content: "\f4f5";
}
.fi-rr-tire-rugged:before {
  content: "\f4f6";
}
.fi-rr-tire:before {
  content: "\f4f7";
}
.fi-rr-tired:before {
  content: "\f4f8";
}
.fi-rr-tomato:before {
  content: "\f4f9";
}
.fi-rr-tool-box:before {
  content: "\f4fa";
}
.fi-rr-tool-crop:before {
  content: "\f4fb";
}
.fi-rr-tool-marquee:before {
  content: "\f4fc";
}
.fi-rr-tooth:before {
  content: "\f4fd";
}
.fi-rr-tornado:before {
  content: "\f4fe";
}
.fi-rr-tractor:before {
  content: "\f4ff";
}
.fi-rr-trailer:before {
  content: "\f500";
}
.fi-rr-train-side:before {
  content: "\f501";
}
.fi-rr-train:before {
  content: "\f502";
}
.fi-rr-tram:before {
  content: "\f503";
}
.fi-rr-transform:before {
  content: "\f504";
}
.fi-rr-trash:before {
  content: "\f505";
}
.fi-rr-treasure-chest:before {
  content: "\f506";
}
.fi-rr-treatment:before {
  content: "\f507";
}
.fi-rr-tree-christmas:before {
  content: "\f508";
}
.fi-rr-tree:before {
  content: "\f509";
}
.fi-rr-triangle:before {
  content: "\f50a";
}
.fi-rr-trophy:before {
  content: "\f50b";
}
.fi-rr-truck-container:before {
  content: "\f50c";
}
.fi-rr-truck-couch:before {
  content: "\f50d";
}
.fi-rr-truck-loading:before {
  content: "\f50e";
}
.fi-rr-truck-monster:before {
  content: "\f50f";
}
.fi-rr-truck-moving:before {
  content: "\f510";
}
.fi-rr-truck-pickup:before {
  content: "\f511";
}
.fi-rr-truck-plow:before {
  content: "\f512";
}
.fi-rr-truck-ramp:before {
  content: "\f513";
}
.fi-rr-truck-side:before {
  content: "\f514";
}
.fi-rr-tty:before {
  content: "\f515";
}
.fi-rr-turkey:before {
  content: "\f516";
}
.fi-rr-tv-music:before {
  content: "\f517";
}
.fi-rr-typewriter:before {
  content: "\f518";
}
.fi-rr-u:before {
  content: "\f519";
}
.fi-rr-umbrella:before {
  content: "\f51a";
}
.fi-rr-underline:before {
  content: "\f51b";
}
.fi-rr-undo-alt:before {
  content: "\f51c";
}
.fi-rr-undo:before {
  content: "\f51d";
}
.fi-rr-unlock:before {
  content: "\f51e";
}
.fi-rr-upload:before {
  content: "\f51f";
}
.fi-rr-usb-pendrive:before {
  content: "\f520";
}
.fi-rr-usd-circle:before {
  content: "\f521";
}
.fi-rr-usd-square:before {
  content: "\f522";
}
.fi-rr-user-add:before {
  content: "\f523";
}
.fi-rr-user-time:before {
  content: "\f524";
}
.fi-rr-user:before {
  content: "\f525";
}
.fi-rr-users-alt:before {
  content: "\f526";
}
.fi-rr-users:before {
  content: "\f527";
}
.fi-rr-utensils:before {
  content: "\f528";
}
.fi-rr-v:before {
  content: "\f529";
}
.fi-rr-vector-alt:before {
  content: "\f52a";
}
.fi-rr-vector:before {
  content: "\f52b";
}
.fi-rr-venus-double:before {
  content: "\f52c";
}
.fi-rr-venus-mars:before {
  content: "\f52d";
}
.fi-rr-venus:before {
  content: "\f52e";
}
.fi-rr-video-arrow-down-left:before {
  content: "\f52f";
}
.fi-rr-video-arrow-up-right:before {
  content: "\f530";
}
.fi-rr-video-camera-alt:before {
  content: "\f531";
}
.fi-rr-video-camera:before {
  content: "\f532";
}
.fi-rr-video-plus:before {
  content: "\f533";
}
.fi-rr-video-slash:before {
  content: "\f534";
}
.fi-rr-volcano:before {
  content: "\f535";
}
.fi-rr-volleyball:before {
  content: "\f536";
}
.fi-rr-volume:before {
  content: "\f537";
}
.fi-rr-w:before {
  content: "\f538";
}
.fi-rr-wagon-covered:before {
  content: "\f539";
}
.fi-rr-wallet:before {
  content: "\f53a";
}
.fi-rr-water-bottle:before {
  content: "\f53b";
}
.fi-rr-water-lower:before {
  content: "\f53c";
}
.fi-rr-water-rise:before {
  content: "\f53d";
}
.fi-rr-water:before {
  content: "\f53e";
}
.fi-rr-watermelon:before {
  content: "\f53f";
}
.fi-rr-wheat:before {
  content: "\f540";
}
.fi-rr-wheelchair:before {
  content: "\f541";
}
.fi-rr-whistle:before {
  content: "\f542";
}
.fi-rr-wifi-alt:before {
  content: "\f543";
}
.fi-rr-wind-warning:before {
  content: "\f544";
}
.fi-rr-wind:before {
  content: "\f545";
}
.fi-rr-windsock:before {
  content: "\f546";
}
.fi-rr-woman-head:before {
  content: "\f547";
}
.fi-rr-world:before {
  content: "\f548";
}
.fi-rr-x:before {
  content: "\f549";
}
.fi-rr-y:before {
  content: "\f54a";
}
.fi-rr-yen:before {
  content: "\f54b";
}
.fi-rr-z:before {
  content: "\f54c";
}
.fi-rr-zoom-in:before {
  content: "\f54d";
}
.fi-rr-zoom-out:before {
  content: "\f54e";
}

@font-face {
  font-family: "uicons-regular-straight";
  src: url("../fonts/uicons-regular-straight.eot#iefix")
      format("embedded-opentype"),
    url("../fonts/uicons-regular-straight.woff2") format("woff2"),
    url("../fonts/uicons-regular-straight.woff") format("woff");
}

i[class^="fi-rs-"]:before,
i[class*=" fi-rs-"]:before,
span[class^="fi-rs-"]:before,
span[class*="fi-rs-"]:before {
  font-family: uicons-regular-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi-rs-a:before {
  content: "\f101";
}
.fi-rs-ad:before {
  content: "\f102";
}
.fi-rs-add-document:before {
  content: "\f103";
}
.fi-rs-add-folder:before {
  content: "\f104";
}
.fi-rs-add:before {
  content: "\f105";
}
.fi-rs-address-book:before {
  content: "\f106";
}
.fi-rs-air-conditioner:before {
  content: "\f107";
}
.fi-rs-air-freshener:before {
  content: "\f108";
}
.fi-rs-alarm-clock:before {
  content: "\f109";
}
.fi-rs-alarm-exclamation:before {
  content: "\f10a";
}
.fi-rs-alarm-plus:before {
  content: "\f10b";
}
.fi-rs-alarm-snooze:before {
  content: "\f10c";
}
.fi-rs-align-justify:before {
  content: "\f10d";
}
.fi-rs-align-left:before {
  content: "\f10e";
}
.fi-rs-ambulance:before {
  content: "\f10f";
}
.fi-rs-angle-circle-down:before {
  content: "\f110";
}
.fi-rs-angle-circle-left:before {
  content: "\f111";
}
.fi-rs-angle-circle-right:before {
  content: "\f112";
}
.fi-rs-angle-circle-up:before {
  content: "\f113";
}
.fi-rs-angle-double-left:before {
  content: "\f114";
}
.fi-rs-angle-double-right:before {
  content: "\f115";
}
.fi-rs-angle-double-small-down:before {
  content: "\f116";
}
.fi-rs-angle-double-small-left:before {
  content: "\f117";
}
.fi-rs-angle-double-small-right:before {
  content: "\f118";
}
.fi-rs-angle-double-small-up:before {
  content: "\f119";
}
.fi-rs-angle-down:before {
  content: "\f11a";
}
.fi-rs-angle-left:before {
  content: "\f11b";
}
.fi-rs-angle-right:before {
  content: "\f11c";
}
.fi-rs-angle-small-down:before {
  content: "\f11d";
}
.fi-rs-angle-small-left:before {
  content: "\f11e";
}
.fi-rs-angle-small-right:before {
  content: "\f11f";
}
.fi-rs-angle-small-up:before {
  content: "\f120";
}
.fi-rs-angle-square-down:before {
  content: "\f121";
}
.fi-rs-angle-square-left:before {
  content: "\f122";
}
.fi-rs-angle-square-right:before {
  content: "\f123";
}
.fi-rs-angle-square-up:before {
  content: "\f124";
}
.fi-rs-angle-up:before {
  content: "\f125";
}
.fi-rs-angry:before {
  content: "\f126";
}
.fi-rs-apple-whole:before {
  content: "\f127";
}
.fi-rs-apps-add:before {
  content: "\f128";
}
.fi-rs-apps-delete:before {
  content: "\f129";
}
.fi-rs-apps-sort:before {
  content: "\f12a";
}
.fi-rs-apps:before {
  content: "\f12b";
}
.fi-rs-archive:before {
  content: "\f12c";
}
.fi-rs-arrow-alt-circle-down:before {
  content: "\f12d";
}
.fi-rs-arrow-alt-circle-left:before {
  content: "\f12e";
}
.fi-rs-arrow-alt-circle-right:before {
  content: "\f12f";
}
.fi-rs-arrow-alt-circle-up:before {
  content: "\f130";
}
.fi-rs-arrow-alt-down:before {
  content: "\f131";
}
.fi-rs-arrow-alt-from-bottom:before {
  content: "\f132";
}
.fi-rs-arrow-alt-from-left:before {
  content: "\f133";
}
.fi-rs-arrow-alt-from-right:before {
  content: "\f134";
}
.fi-rs-arrow-alt-from-top:before {
  content: "\f135";
}
.fi-rs-arrow-alt-left:before {
  content: "\f136";
}
.fi-rs-arrow-alt-right:before {
  content: "\f137";
}
.fi-rs-arrow-alt-square-down:before {
  content: "\f138";
}
.fi-rs-arrow-alt-square-left:before {
  content: "\f139";
}
.fi-rs-arrow-alt-square-right:before {
  content: "\f13a";
}
.fi-rs-arrow-alt-square-up:before {
  content: "\f13b";
}
.fi-rs-arrow-alt-to-bottom:before {
  content: "\f13c";
}
.fi-rs-arrow-alt-to-left:before {
  content: "\f13d";
}
.fi-rs-arrow-alt-to-right:before {
  content: "\f13e";
}
.fi-rs-arrow-alt-to-top:before {
  content: "\f13f";
}
.fi-rs-arrow-alt-up:before {
  content: "\f140";
}
.fi-rs-arrow-circle-down:before {
  content: "\f141";
}
.fi-rs-arrow-circle-left:before {
  content: "\f142";
}
.fi-rs-arrow-circle-right:before {
  content: "\f143";
}
.fi-rs-arrow-circle-up:before {
  content: "\f144";
}
.fi-rs-arrow-down:before {
  content: "\f145";
}
.fi-rs-arrow-from-bottom:before {
  content: "\f146";
}
.fi-rs-arrow-from-left:before {
  content: "\f147";
}
.fi-rs-arrow-from-right:before {
  content: "\f148";
}
.fi-rs-arrow-from-top:before {
  content: "\f149";
}
.fi-rs-arrow-left:before {
  content: "\f14a";
}
.fi-rs-arrow-right:before {
  content: "\f14b";
}
.fi-rs-arrow-small-down:before {
  content: "\f14c";
}
.fi-rs-arrow-small-left:before {
  content: "\f14d";
}
.fi-rs-arrow-small-right:before {
  content: "\f14e";
}
.fi-rs-arrow-small-up:before {
  content: "\f14f";
}
.fi-rs-arrow-square-down:before {
  content: "\f150";
}
.fi-rs-arrow-square-left:before {
  content: "\f151";
}
.fi-rs-arrow-square-right:before {
  content: "\f152";
}
.fi-rs-arrow-square-up:before {
  content: "\f153";
}
.fi-rs-arrow-to-bottom:before {
  content: "\f154";
}
.fi-rs-arrow-to-left:before {
  content: "\f155";
}
.fi-rs-arrow-to-right:before {
  content: "\f156";
}
.fi-rs-arrow-to-top:before {
  content: "\f157";
}
.fi-rs-arrow-up:before {
  content: "\f158";
}
.fi-rs-arrows-alt-h:before {
  content: "\f159";
}
.fi-rs-arrows-alt-v:before {
  content: "\f15a";
}
.fi-rs-arrows-alt:before {
  content: "\f15b";
}
.fi-rs-arrows-h-copy:before {
  content: "\f15c";
}
.fi-rs-arrows-h:before {
  content: "\f15d";
}
.fi-rs-arrows:before {
  content: "\f15e";
}
.fi-rs-assept-document:before {
  content: "\f15f";
}
.fi-rs-assistive-listening-systems:before {
  content: "\f160";
}
.fi-rs-asterik:before {
  content: "\f161";
}
.fi-rs-at:before {
  content: "\f162";
}
.fi-rs-aubergine:before {
  content: "\f163";
}
.fi-rs-avocado:before {
  content: "\f164";
}
.fi-rs-b:before {
  content: "\f165";
}
.fi-rs-baby-carriage:before {
  content: "\f166";
}
.fi-rs-backpack:before {
  content: "\f167";
}
.fi-rs-bacon:before {
  content: "\f168";
}
.fi-rs-badge-sheriff:before {
  content: "\f169";
}
.fi-rs-badge-check:before {
  content: "\f16a";
}
.fi-rs-badge-dollar:before {
  content: "\f16b";
}
.fi-rs-badge-percent:before {
  content: "\f16c";
}
.fi-rs-badge:before {
  content: "\f16d";
}
.fi-rs-bags-shopping:before {
  content: "\f16e";
}
.fi-rs-bahai:before {
  content: "\f16f";
}
.fi-rs-balance-scale-left:before {
  content: "\f170";
}
.fi-rs-balance-scale-right:before {
  content: "\f171";
}
.fi-rs-balloons:before {
  content: "\f172";
}
.fi-rs-ballot:before {
  content: "\f173";
}
.fi-rs-ban:before {
  content: "\f174";
}
.fi-rs-band-aid:before {
  content: "\f175";
}
.fi-rs-bank:before {
  content: "\f176";
}
.fi-rs-barber-shop:before {
  content: "\f177";
}
.fi-rs-bars-progress:before {
  content: "\f178";
}
.fi-rs-baseball-alt:before {
  content: "\f179";
}
.fi-rs-baseball:before {
  content: "\f17a";
}
.fi-rs-basketball-hoop:before {
  content: "\f17b";
}
.fi-rs-basketball:before {
  content: "\f17c";
}
.fi-rs-bed-alt:before {
  content: "\f17d";
}
.fi-rs-bed:before {
  content: "\f17e";
}
.fi-rs-beer:before {
  content: "\f17f";
}
.fi-rs-bell-ring:before {
  content: "\f180";
}
.fi-rs-bell-school:before {
  content: "\f181";
}
.fi-rs-bell:before {
  content: "\f182";
}
.fi-rs-bike:before {
  content: "\f183";
}
.fi-rs-biking-mountain:before {
  content: "\f184";
}
.fi-rs-biking:before {
  content: "\f185";
}
.fi-rs-billiard:before {
  content: "\f186";
}
.fi-rs-blinds-open:before {
  content: "\f187";
}
.fi-rs-blinds-raised:before {
  content: "\f188";
}
.fi-rs-blinds:before {
  content: "\f189";
}
.fi-rs-bold:before {
  content: "\f18a";
}
.fi-rs-bolt:before {
  content: "\f18b";
}
.fi-rs-book-alt:before {
  content: "\f18c";
}
.fi-rs-book-arrow-right:before {
  content: "\f18d";
}
.fi-rs-book-arrow-up:before {
  content: "\f18e";
}
.fi-rs-book-bookmark:before {
  content: "\f18f";
}
.fi-rs-book-open-cover:before {
  content: "\f190";
}
.fi-rs-book-open-reader:before {
  content: "\f191";
}
.fi-rs-book:before {
  content: "\f192";
}
.fi-rs-bookmark:before {
  content: "\f193";
}
.fi-rs-books:before {
  content: "\f194";
}
.fi-rs-bottle:before {
  content: "\f195";
}
.fi-rs-bow-arrow:before {
  content: "\f196";
}
.fi-rs-bowling-ball:before {
  content: "\f197";
}
.fi-rs-bowling-pins:before {
  content: "\f198";
}
.fi-rs-bowling:before {
  content: "\f199";
}
.fi-rs-box-check:before {
  content: "\f19a";
}
.fi-rs-box-tissue:before {
  content: "\f19b";
}
.fi-rs-box-alt:before {
  content: "\f19c";
}
.fi-rs-box-ballot:before {
  content: "\f19d";
}
.fi-rs-box:before {
  content: "\f19e";
}
.fi-rs-boxes:before {
  content: "\f19f";
}
.fi-rs-boxing-glove:before {
  content: "\f1a0";
}
.fi-rs-braille:before {
  content: "\f1a1";
}
.fi-rs-bread-slice:before {
  content: "\f1a2";
}
.fi-rs-bread:before {
  content: "\f1a3";
}
.fi-rs-briefcase:before {
  content: "\f1a4";
}
.fi-rs-broccoli:before {
  content: "\f1a5";
}
.fi-rs-browser:before {
  content: "\f1a6";
}
.fi-rs-brush:before {
  content: "\f1a7";
}
.fi-rs-bug:before {
  content: "\f1a8";
}
.fi-rs-building:before {
  content: "\f1a9";
}
.fi-rs-bulb:before {
  content: "\f1aa";
}
.fi-rs-bullseye-pointer:before {
  content: "\f1ab";
}
.fi-rs-bullseye:before {
  content: "\f1ac";
}
.fi-rs-burger-menu:before {
  content: "\f1ad";
}
.fi-rs-burrito:before {
  content: "\f1ae";
}
.fi-rs-bus-alt:before {
  content: "\f1af";
}
.fi-rs-bus:before {
  content: "\f1b0";
}
.fi-rs-business-time:before {
  content: "\f1b1";
}
.fi-rs-butterfly:before {
  content: "\f1b2";
}
.fi-rs-cake-birthday:before {
  content: "\f1b3";
}
.fi-rs-cake-wedding:before {
  content: "\f1b4";
}
.fi-rs-calculator:before {
  content: "\f1b5";
}
.fi-rs-calendar-check:before {
  content: "\f1b6";
}
.fi-rs-calendar-clock:before {
  content: "\f1b7";
}
.fi-rs-calendar-exclamation:before {
  content: "\f1b8";
}
.fi-rs-calendar-lines-pen:before {
  content: "\f1b9";
}
.fi-rs-calendar-lines:before {
  content: "\f1ba";
}
.fi-rs-calendar-minus:before {
  content: "\f1bb";
}
.fi-rs-calendar-pen:before {
  content: "\f1bc";
}
.fi-rs-calendar-plus:before {
  content: "\f1bd";
}
.fi-rs-calendar:before {
  content: "\f1be";
}
.fi-rs-calendars:before {
  content: "\f1bf";
}
.fi-rs-call-history:before {
  content: "\f1c0";
}
.fi-rs-call-incoming:before {
  content: "\f1c1";
}
.fi-rs-call-missed:before {
  content: "\f1c2";
}
.fi-rs-call-outgoing:before {
  content: "\f1c3";
}
.fi-rs-camcorder:before {
  content: "\f1c4";
}
.fi-rs-camera:before {
  content: "\f1c5";
}
.fi-rs-camping:before {
  content: "\f1c6";
}
.fi-rs-candy-alt:before {
  content: "\f1c7";
}
.fi-rs-candy:before {
  content: "\f1c8";
}
.fi-rs-canned-food:before {
  content: "\f1c9";
}
.fi-rs-car-bolt:before {
  content: "\f1ca";
}
.fi-rs-car-circle-bolt:before {
  content: "\f1cb";
}
.fi-rs-car-side-bolt:before {
  content: "\f1cc";
}
.fi-rs-car-alt:before {
  content: "\f1cd";
}
.fi-rs-car-battery:before {
  content: "\f1ce";
}
.fi-rs-car-building:before {
  content: "\f1cf";
}
.fi-rs-car-bump:before {
  content: "\f1d0";
}
.fi-rs-car-bus:before {
  content: "\f1d1";
}
.fi-rs-car-crash:before {
  content: "\f1d2";
}
.fi-rs-car-garage:before {
  content: "\f1d3";
}
.fi-rs-car-mechanic:before {
  content: "\f1d4";
}
.fi-rs-car-side:before {
  content: "\f1d5";
}
.fi-rs-car-tilt:before {
  content: "\f1d6";
}
.fi-rs-car-wash:before {
  content: "\f1d7";
}
.fi-rs-car:before {
  content: "\f1d8";
}
.fi-rs-caravan-alt:before {
  content: "\f1d9";
}
.fi-rs-caravan:before {
  content: "\f1da";
}
.fi-rs-caret-circle-down:before {
  content: "\f1db";
}
.fi-rs-caret-circle-right:before {
  content: "\f1dc";
}
.fi-rs-caret-circle-up:before {
  content: "\f1dd";
}
.fi-rs-caret-down:before {
  content: "\f1de";
}
.fi-rs-caret-left:before {
  content: "\f1df";
}
.fi-rs-caret-quare-up:before {
  content: "\f1e0";
}
.fi-rs-caret-right:before {
  content: "\f1e1";
}
.fi-rs-caret-square-down:before {
  content: "\f1e2";
}
.fi-rs-caret-square-left_1:before {
  content: "\f1e3";
}
.fi-rs-caret-square-left:before {
  content: "\f1e4";
}
.fi-rs-caret-square-right:before {
  content: "\f1e5";
}
.fi-rs-caret-up:before {
  content: "\f1e6";
}
.fi-rs-carrot:before {
  content: "\f1e7";
}
.fi-rs-cars:before {
  content: "\f1e8";
}
.fi-rs-cash-register:before {
  content: "\f1e9";
}
.fi-rs-chair-office:before {
  content: "\f1ea";
}
.fi-rs-chair:before {
  content: "\f1eb";
}
.fi-rs-charging-station:before {
  content: "\f1ec";
}
.fi-rs-chart-area:before {
  content: "\f1ed";
}
.fi-rs-chart-connected:before {
  content: "\f1ee";
}
.fi-rs-chart-histogram:before {
  content: "\f1ef";
}
.fi-rs-chart-line-up:before {
  content: "\f1f0";
}
.fi-rs-chart-network:before {
  content: "\f1f1";
}
.fi-rs-chart-pie-alt:before {
  content: "\f1f2";
}
.fi-rs-chart-pie:before {
  content: "\f1f3";
}
.fi-rs-chart-pyramid:before {
  content: "\f1f4";
}
.fi-rs-chart-scatter:before {
  content: "\f1f5";
}
.fi-rs-chart-set-theory:before {
  content: "\f1f6";
}
.fi-rs-chart-tree:before {
  content: "\f1f7";
}
.fi-rs-chat-arrow-down:before {
  content: "\f1f8";
}
.fi-rs-chat-arrow-grow:before {
  content: "\f1f9";
}
.fi-rs-check:before {
  content: "\f1fa";
}
.fi-rs-checkbox:before {
  content: "\f1fb";
}
.fi-rs-cheese:before {
  content: "\f1fc";
}
.fi-rs-cherry:before {
  content: "\f1fd";
}
.fi-rs-chess-board:before {
  content: "\f1fe";
}
.fi-rs-chess-clock:before {
  content: "\f1ff";
}
.fi-rs-chess-pawn:before {
  content: "\f200";
}
.fi-rs-chess-piece:before {
  content: "\f201";
}
.fi-rs-chess-bishop:before {
  content: "\f202";
}
.fi-rs-chess-clock-alt:before {
  content: "\f203";
}
.fi-rs-chess-king-alt:before {
  content: "\f204";
}
.fi-rs-chess-king:before {
  content: "\f205";
}
.fi-rs-chess-knight-alt:before {
  content: "\f206";
}
.fi-rs-chess-knight:before {
  content: "\f207";
}
.fi-rs-chess-pawn-alt:before {
  content: "\f208";
}
.fi-rs-chess-queen-alt:before {
  content: "\f209";
}
.fi-rs-chess-queen:before {
  content: "\f20a";
}
.fi-rs-chess-rook-alt:before {
  content: "\f20b";
}
.fi-rs-chess-rook:before {
  content: "\f20c";
}
.fi-rs-chess:before {
  content: "\f20d";
}
.fi-rs-chevron-double-down:before {
  content: "\f20e";
}
.fi-rs-chevron-double-up:before {
  content: "\f20f";
}
.fi-rs-child-head:before {
  content: "\f210";
}
.fi-rs-chocolate:before {
  content: "\f211";
}
.fi-rs-circle-cross:before {
  content: "\f212";
}
.fi-rs-circle-phone:before {
  content: "\f213";
}
.fi-rs-circle-envelope:before {
  content: "\f214";
}
.fi-rs-circle-phone-flip:before {
  content: "\f215";
}
.fi-rs-circle-phone-hangup:before {
  content: "\f216";
}
.fi-rs-circle-small:before {
  content: "\f217";
}
.fi-rs-circle:before {
  content: "\f218";
}
.fi-rs-clean:before {
  content: "\f219";
}
.fi-rs-clip:before {
  content: "\f21a";
}
.fi-rs-clipboard-list:before {
  content: "\f21b";
}
.fi-rs-clipboard-list-check:before {
  content: "\f21c";
}
.fi-rs-clock-eight-thirty:before {
  content: "\f21d";
}
.fi-rs-clock-eleven-thirty:before {
  content: "\f21e";
}
.fi-rs-clock-eleven:before {
  content: "\f21f";
}
.fi-rs-clock-five-thirty:before {
  content: "\f220";
}
.fi-rs-clock-five:before {
  content: "\f221";
}
.fi-rs-clock-four-thirty:before {
  content: "\f222";
}
.fi-rs-clock-nine-thirty:before {
  content: "\f223";
}
.fi-rs-clock-nine:before {
  content: "\f224";
}
.fi-rs-clock-one-thirty:before {
  content: "\f225";
}
.fi-rs-clock-one:before {
  content: "\f226";
}
.fi-rs-clock-seven-thirty:before {
  content: "\f227";
}
.fi-rs-clock-seven:before {
  content: "\f228";
}
.fi-rs-clock-six-thirty:before {
  content: "\f229";
}
.fi-rs-clock-six:before {
  content: "\f22a";
}
.fi-rs-clock-ten-thirty:before {
  content: "\f22b";
}
.fi-rs-clock-ten:before {
  content: "\f22c";
}
.fi-rs-clock-three-thirty:before {
  content: "\f22d";
}
.fi-rs-clock-three:before {
  content: "\f22e";
}
.fi-rs-clock-twelve-thirty:before {
  content: "\f22f";
}
.fi-rs-clock-twelve:before {
  content: "\f230";
}
.fi-rs-clock-two-thirty:before {
  content: "\f231";
}
.fi-rs-clock-two:before {
  content: "\f232";
}
.fi-rs-clock:before {
  content: "\f233";
}
.fi-rs-cloud-upload:before {
  content: "\f234";
}
.fi-rs-cloud-check:before {
  content: "\f235";
}
.fi-rs-cloud-disabled:before {
  content: "\f236";
}
.fi-rs-cloud-download-alt:before {
  content: "\f237";
}
.fi-rs-cloud-download:before {
  content: "\f238";
}
.fi-rs-cloud-drizzle:before {
  content: "\f239";
}
.fi-rs-cloud-hail-mixed:before {
  content: "\f23a";
}
.fi-rs-cloud-hail:before {
  content: "\f23b";
}
.fi-rs-cloud-moon-rain:before {
  content: "\f23c";
}
.fi-rs-cloud-moon:before {
  content: "\f23d";
}
.fi-rs-cloud-rain:before {
  content: "\f23e";
}
.fi-rs-cloud-rainbow:before {
  content: "\f23f";
}
.fi-rs-cloud-share:before {
  content: "\f240";
}
.fi-rs-cloud-showers-heavy:before {
  content: "\f241";
}
.fi-rs-cloud-showers:before {
  content: "\f242";
}
.fi-rs-cloud-sleet:before {
  content: "\f243";
}
.fi-rs-cloud-snow:before {
  content: "\f244";
}
.fi-rs-cloud-sun-rain:before {
  content: "\f245";
}
.fi-rs-cloud-sun:before {
  content: "\f246";
}
.fi-rs-cloud-upload-alt:before {
  content: "\f247";
}
.fi-rs-cloud:before {
  content: "\f248";
}
.fi-rs-clouds-moon:before {
  content: "\f249";
}
.fi-rs-clouds-sun:before {
  content: "\f24a";
}
.fi-rs-clouds:before {
  content: "\f24b";
}
.fi-rs-club:before {
  content: "\f24c";
}
.fi-rs-cocktail-alt:before {
  content: "\f24d";
}
.fi-rs-cocktail:before {
  content: "\f24e";
}
.fi-rs-coffee-pot:before {
  content: "\f24f";
}
.fi-rs-coffee:before {
  content: "\f250";
}
.fi-rs-coin:before {
  content: "\f251";
}
.fi-rs-coins:before {
  content: "\f252";
}
.fi-rs-comet:before {
  content: "\f253";
}
.fi-rs-comment-alt-middle-top:before {
  content: "\f254";
}
.fi-rs-comment-alt-middle:before {
  content: "\f255";
}
.fi-rs-comment-alt:before {
  content: "\f256";
}
.fi-rs-comment-arrow-down:before {
  content: "\f257";
}
.fi-rs-comment-arrow-up-right:before {
  content: "\f258";
}
.fi-rs-comment-arrow-up:before {
  content: "\f259";
}
.fi-rs-comment-check:before {
  content: "\f25a";
}
.fi-rs-comment-code:before {
  content: "\f25b";
}
.fi-rs-comment-dollar:before {
  content: "\f25c";
}
.fi-rs-comment-exclamation:before {
  content: "\f25d";
}
.fi-rs-comment-heart:before {
  content: "\f25e";
}
.fi-rs-comment-image:before {
  content: "\f25f";
}
.fi-rs-comment-info:before {
  content: "\f260";
}
.fi-rs-comment-pen:before {
  content: "\f261";
}
.fi-rs-comment-question:before {
  content: "\f262";
}
.fi-rs-comment-quote:before {
  content: "\f263";
}
.fi-rs-comment-slash:before {
  content: "\f264";
}
.fi-rs-comment-sms:before {
  content: "\f265";
}
.fi-rs-comment-text:before {
  content: "\f266";
}
.fi-rs-comment-user:before {
  content: "\f267";
}
.fi-rs-comment-xmark:before {
  content: "\f268";
}
.fi-rs-comment:before {
  content: "\f269";
}
.fi-rs-comments-dollar:before {
  content: "\f26a";
}
.fi-rs-comments-question-check:before {
  content: "\f26b";
}
.fi-rs-comments-question:before {
  content: "\f26c";
}
.fi-rs-comments:before {
  content: "\f26d";
}
.fi-rs-compress-alt:before {
  content: "\f26e";
}
.fi-rs-compress:before {
  content: "\f26f";
}
.fi-rs-computer:before {
  content: "\f270";
}
.fi-rs-confetti:before {
  content: "\f271";
}
.fi-rs-cookie:before {
  content: "\f272";
}
.fi-rs-copy-alt:before {
  content: "\f273";
}
.fi-rs-copy:before {
  content: "\f274";
}
.fi-rs-copyright:before {
  content: "\f275";
}
.fi-rs-corn:before {
  content: "\f276";
}
.fi-rs-cow:before {
  content: "\f277";
}
.fi-rs-cream:before {
  content: "\f278";
}
.fi-rs-credit-card:before {
  content: "\f279";
}
.fi-rs-cricket:before {
  content: "\f27a";
}
.fi-rs-croissant:before {
  content: "\f27b";
}
.fi-rs-cross-small:before {
  content: "\f27c";
}
.fi-rs-cross:before {
  content: "\f27d";
}
.fi-rs-crossed-eye:before {
  content: "\f27e";
}
.fi-rs-crown:before {
  content: "\f27f";
}
.fi-rs-crystal-ball:before {
  content: "\f280";
}
.fi-rs-cube:before {
  content: "\f281";
}
.fi-rs-cupcake:before {
  content: "\f282";
}
.fi-rs-curling:before {
  content: "\f283";
}
.fi-rs-cursor-finger:before {
  content: "\f284";
}
.fi-rs-cursor-plus:before {
  content: "\f285";
}
.fi-rs-cursor-text-alt:before {
  content: "\f286";
}
.fi-rs-cursor-text:before {
  content: "\f287";
}
.fi-rs-cursor:before {
  content: "\f288";
}
.fi-rs-d:before {
  content: "\f289";
}
.fi-rs-dart:before {
  content: "\f28a";
}
.fi-rs-dashboard:before {
  content: "\f28b";
}
.fi-rs-data-transfer:before {
  content: "\f28c";
}
.fi-rs-database:before {
  content: "\f28d";
}
.fi-rs-delete-document:before {
  content: "\f28e";
}
.fi-rs-delete-user:before {
  content: "\f28f";
}
.fi-rs-delete:before {
  content: "\f290";
}
.fi-rs-democrat:before {
  content: "\f291";
}
.fi-rs-dewpoint:before {
  content: "\f292";
}
.fi-rs-diamond:before {
  content: "\f293";
}
.fi-rs-dice-alt:before {
  content: "\f294";
}
.fi-rs-dice-d10:before {
  content: "\f295";
}
.fi-rs-dice-d12:before {
  content: "\f296";
}
.fi-rs-dice-d20:before {
  content: "\f297";
}
.fi-rs-dice-d4:before {
  content: "\f298";
}
.fi-rs-dice-d6:before {
  content: "\f299";
}
.fi-rs-dice-d8:before {
  content: "\f29a";
}
.fi-rs-dice-four:before {
  content: "\f29b";
}
.fi-rs-dice-one:before {
  content: "\f29c";
}
.fi-rs-dice-six:before {
  content: "\f29d";
}
.fi-rs-dice-three:before {
  content: "\f29e";
}
.fi-rs-dice-two:before {
  content: "\f29f";
}
.fi-rs-dice:before {
  content: "\f2a0";
}
.fi-rs-diploma:before {
  content: "\f2a1";
}
.fi-rs-disco-ball:before {
  content: "\f2a2";
}
.fi-rs-disk:before {
  content: "\f2a3";
}
.fi-rs-dizzy:before {
  content: "\f2a4";
}
.fi-rs-doctor:before {
  content: "\f2a5";
}
.fi-rs-document-signed:before {
  content: "\f2a6";
}
.fi-rs-document:before {
  content: "\f2a7";
}
.fi-rs-dollar:before {
  content: "\f2a8";
}
.fi-rs-donate:before {
  content: "\f2a9";
}
.fi-rs-down-left-and-up-right-to-center:before {
  content: "\f2aa";
}
.fi-rs-download:before {
  content: "\f2ab";
}
.fi-rs-drafting-compass:before {
  content: "\f2ac";
}
.fi-rs-dreidel:before {
  content: "\f2ad";
}
.fi-rs-drink-alt:before {
  content: "\f2ae";
}
.fi-rs-drumstick:before {
  content: "\f2af";
}
.fi-rs-duplicate:before {
  content: "\f2b0";
}
.fi-rs-e-learning:before {
  content: "\f2b1";
}
.fi-rs-e:before {
  content: "\f2b2";
}
.fi-rs-earnings:before {
  content: "\f2b3";
}
.fi-rs-eclipse-alt:before {
  content: "\f2b4";
}
.fi-rs-eclipse:before {
  content: "\f2b5";
}
.fi-rs-edit-alt:before {
  content: "\f2b6";
}
.fi-rs-edit:before {
  content: "\f2b7";
}
.fi-rs-egg-fried:before {
  content: "\f2b8";
}
.fi-rs-egg:before {
  content: "\f2b9";
}
.fi-rs-engine-warning:before {
  content: "\f2ba";
}
.fi-rs-enter:before {
  content: "\f2bb";
}
.fi-rs-envelope-ban:before {
  content: "\f2bc";
}
.fi-rs-envelope-bulk:before {
  content: "\f2bd";
}
.fi-rs-envelope-download:before {
  content: "\f2be";
}
.fi-rs-envelope-marker:before {
  content: "\f2bf";
}
.fi-rs-envelope-open-dollar:before {
  content: "\f2c0";
}
.fi-rs-envelope-open-text:before {
  content: "\f2c1";
}
.fi-rs-envelope-open:before {
  content: "\f2c2";
}
.fi-rs-envelope-plus:before {
  content: "\f2c3";
}
.fi-rs-envelope:before {
  content: "\f2c4";
}
.fi-rs-equality:before {
  content: "\f2c5";
}
.fi-rs-euro:before {
  content: "\f2c6";
}
.fi-rs-exchange-alt:before {
  content: "\f2c7";
}
.fi-rs-exchange:before {
  content: "\f2c8";
}
.fi-rs-exclamation:before {
  content: "\f2c9";
}
.fi-rs-exit:before {
  content: "\f2ca";
}
.fi-rs-expand-arrows-alt:before {
  content: "\f2cb";
}
.fi-rs-expand-arrows:before {
  content: "\f2cc";
}
.fi-rs-expand:before {
  content: "\f2cd";
}
.fi-rs-eye-dropper:before {
  content: "\f2ce";
}
.fi-rs-eye:before {
  content: "\f2cf";
}
.fi-rs-f:before {
  content: "\f2d0";
}
.fi-rs-feather:before {
  content: "\f2d1";
}
.fi-rs-ferris-wheel:before {
  content: "\f2d2";
}
.fi-rs-field-hockey:before {
  content: "\f2d3";
}
.fi-rs-fighter-jet:before {
  content: "\f2d4";
}
.fi-rs-file-invoice-dollar:before {
  content: "\f2d5";
}
.fi-rs-file-invoice:before {
  content: "\f2d6";
}
.fi-rs-file-ai:before {
  content: "\f2d7";
}
.fi-rs-file-chart-line:before {
  content: "\f2d8";
}
.fi-rs-file-chart-pie:before {
  content: "\f2d9";
}
.fi-rs-file-eps:before {
  content: "\f2da";
}
.fi-rs-file-psd:before {
  content: "\f2db";
}
.fi-rs-file-spreadsheet:before {
  content: "\f2dc";
}
.fi-rs-file-video:before {
  content: "\f2dd";
}
.fi-rs-file:before {
  content: "\f2de";
}
.fi-rs-fill:before {
  content: "\f2df";
}
.fi-rs-film-slash:before {
  content: "\f2e0";
}
.fi-rs-film:before {
  content: "\f2e1";
}
.fi-rs-filter-slash:before {
  content: "\f2e2";
}
.fi-rs-filter:before {
  content: "\f2e3";
}
.fi-rs-filters:before {
  content: "\f2e4";
}
.fi-rs-fingerprint:before {
  content: "\f2e5";
}
.fi-rs-fish:before {
  content: "\f2e6";
}
.fi-rs-flag-alt:before {
  content: "\f2e7";
}
.fi-rs-flag-usa:before {
  content: "\f2e8";
}
.fi-rs-flag:before {
  content: "\f2e9";
}
.fi-rs-flame:before {
  content: "\f2ea";
}
.fi-rs-flip-horizontal:before {
  content: "\f2eb";
}
.fi-rs-flower-bouquet:before {
  content: "\f2ec";
}
.fi-rs-flower-tulip:before {
  content: "\f2ed";
}
.fi-rs-flower:before {
  content: "\f2ee";
}
.fi-rs-flushed:before {
  content: "\f2ef";
}
.fi-rs-fog:before {
  content: "\f2f0";
}
.fi-rs-folder-download:before {
  content: "\f2f1";
}
.fi-rs-folder-minus:before {
  content: "\f2f2";
}
.fi-rs-folder-times:before {
  content: "\f2f3";
}
.fi-rs-folder-tree:before {
  content: "\f2f4";
}
.fi-rs-folder-upload:before {
  content: "\f2f5";
}
.fi-rs-folder:before {
  content: "\f2f6";
}
.fi-rs-folders:before {
  content: "\f2f7";
}
.fi-rs-following:before {
  content: "\f2f8";
}
.fi-rs-football:before {
  content: "\f2f9";
}
.fi-rs-fork:before {
  content: "\f2fa";
}
.fi-rs-form:before {
  content: "\f2fb";
}
.fi-rs-forward:before {
  content: "\f2fc";
}
.fi-rs-fox:before {
  content: "\f2fd";
}
.fi-rs-french-fries:before {
  content: "\f2fe";
}
.fi-rs-frown:before {
  content: "\f2ff";
}
.fi-rs-ftp:before {
  content: "\f300";
}
.fi-rs-funnel-dollar:before {
  content: "\f301";
}
.fi-rs-g:before {
  content: "\f302";
}
.fi-rs-gallery:before {
  content: "\f303";
}
.fi-rs-game-board-alt:before {
  content: "\f304";
}
.fi-rs-gamepad:before {
  content: "\f305";
}
.fi-rs-garage-car:before {
  content: "\f306";
}
.fi-rs-garage-open:before {
  content: "\f307";
}
.fi-rs-garage:before {
  content: "\f308";
}
.fi-rs-garlic:before {
  content: "\f309";
}
.fi-rs-gas-pump-alt:before {
  content: "\f30a";
}
.fi-rs-gas-pump-slash:before {
  content: "\f30b";
}
.fi-rs-gas-pump:before {
  content: "\f30c";
}
.fi-rs-gem:before {
  content: "\f30d";
}
.fi-rs-gif:before {
  content: "\f30e";
}
.fi-rs-gift-card:before {
  content: "\f30f";
}
.fi-rs-gift:before {
  content: "\f310";
}
.fi-rs-gifts:before {
  content: "\f311";
}
.fi-rs-gingerbread-man:before {
  content: "\f312";
}
.fi-rs-glass-cheers:before {
  content: "\f313";
}
.fi-rs-glass:before {
  content: "\f314";
}
.fi-rs-glasses:before {
  content: "\f315";
}
.fi-rs-globe-alt:before {
  content: "\f316";
}
.fi-rs-globe:before {
  content: "\f317";
}
.fi-rs-golf-ball:before {
  content: "\f318";
}
.fi-rs-golf-club:before {
  content: "\f319";
}
.fi-rs-golf:before {
  content: "\f31a";
}
.fi-rs-graduation-cap:before {
  content: "\f31b";
}
.fi-rs-grape:before {
  content: "\f31c";
}
.fi-rs-graphic-tablet:before {
  content: "\f31d";
}
.fi-rs-grid-alt:before {
  content: "\f31e";
}
.fi-rs-grid:before {
  content: "\f31f";
}
.fi-rs-grill:before {
  content: "\f320";
}
.fi-rs-grimace:before {
  content: "\f321";
}
.fi-rs-grin-alt:before {
  content: "\f322";
}
.fi-rs-grin-beam-sweat:before {
  content: "\f323";
}
.fi-rs-grin-beam:before {
  content: "\f324";
}
.fi-rs-grin-hearts:before {
  content: "\f325";
}
.fi-rs-grin-squint-tears:before {
  content: "\f326";
}
.fi-rs-grin-squint:before {
  content: "\f327";
}
.fi-rs-grin-stars:before {
  content: "\f328";
}
.fi-rs-grin-tears:before {
  content: "\f329";
}
.fi-rs-grin-tongue-squint:before {
  content: "\f32a";
}
.fi-rs-grin-tongue-wink:before {
  content: "\f32b";
}
.fi-rs-grin-tongue:before {
  content: "\f32c";
}
.fi-rs-grin-wink:before {
  content: "\f32d";
}
.fi-rs-grin:before {
  content: "\f32e";
}
.fi-rs-guitar:before {
  content: "\f32f";
}
.fi-rs-gym:before {
  content: "\f330";
}
.fi-rs-h:before {
  content: "\f331";
}
.fi-rs-hamburger-soda:before {
  content: "\f332";
}
.fi-rs-hamburger:before {
  content: "\f333";
}
.fi-rs-hand-holding-box:before {
  content: "\f334";
}
.fi-rs-hand-holding-heart:before {
  content: "\f335";
}
.fi-rs-hand-holding-seeding:before {
  content: "\f336";
}
.fi-rs-hand:before {
  content: "\f337";
}
.fi-rs-handshake:before {
  content: "\f338";
}
.fi-rs-happy:before {
  content: "\f339";
}
.fi-rs-hastag:before {
  content: "\f33a";
}
.fi-rs-hat-birthday:before {
  content: "\f33b";
}
.fi-rs-hat-chef:before {
  content: "\f33c";
}
.fi-rs-head-side-thinking:before {
  content: "\f33d";
}
.fi-rs-headphones:before {
  content: "\f33e";
}
.fi-rs-headset:before {
  content: "\f33f";
}
.fi-rs-heart-arrow:before {
  content: "\f340";
}
.fi-rs-heart:before {
  content: "\f341";
}
.fi-rs-heat:before {
  content: "\f342";
}
.fi-rs-helicopter-side:before {
  content: "\f343";
}
.fi-rs-highlighter:before {
  content: "\f344";
}
.fi-rs-hiking:before {
  content: "\f345";
}
.fi-rs-hockey-mask:before {
  content: "\f346";
}
.fi-rs-hockey-puck:before {
  content: "\f347";
}
.fi-rs-hockey-sticks:before {
  content: "\f348";
}
.fi-rs-home-location-alt:before {
  content: "\f349";
}
.fi-rs-home-location:before {
  content: "\f34a";
}
.fi-rs-home:before {
  content: "\f34b";
}
.fi-rs-hotdog:before {
  content: "\f34c";
}
.fi-rs-hourglass-end:before {
  content: "\f34d";
}
.fi-rs-hourglass:before {
  content: "\f34e";
}
.fi-rs-house-flood:before {
  content: "\f34f";
}
.fi-rs-hryvnia:before {
  content: "\f350";
}
.fi-rs-humidity:before {
  content: "\f351";
}
.fi-rs-hurricane:before {
  content: "\f352";
}
.fi-rs-i:before {
  content: "\f353";
}
.fi-rs-ice-cream:before {
  content: "\f354";
}
.fi-rs-ice-skate:before {
  content: "\f355";
}
.fi-rs-id-badge:before {
  content: "\f356";
}
.fi-rs-inbox-in:before {
  content: "\f357";
}
.fi-rs-inbox-out:before {
  content: "\f358";
}
.fi-rs-inbox:before {
  content: "\f359";
}
.fi-rs-incognito:before {
  content: "\f35a";
}
.fi-rs-indent:before {
  content: "\f35b";
}
.fi-rs-infinity:before {
  content: "\f35c";
}
.fi-rs-info:before {
  content: "\f35d";
}
.fi-rs-interactive:before {
  content: "\f35e";
}
.fi-rs-interlining:before {
  content: "\f35f";
}
.fi-rs-interrogation:before {
  content: "\f360";
}
.fi-rs-italic:before {
  content: "\f361";
}
.fi-rs-j:before {
  content: "\f362";
}
.fi-rs-jam:before {
  content: "\f363";
}
.fi-rs-jpg:before {
  content: "\f364";
}
.fi-rs-k:before {
  content: "\f365";
}
.fi-rs-kerning:before {
  content: "\f366";
}
.fi-rs-key:before {
  content: "\f367";
}
.fi-rs-keyboard:before {
  content: "\f368";
}
.fi-rs-keynote:before {
  content: "\f369";
}
.fi-rs-kiss-beam:before {
  content: "\f36a";
}
.fi-rs-kiss-wink-heart:before {
  content: "\f36b";
}
.fi-rs-kiss:before {
  content: "\f36c";
}
.fi-rs-kite:before {
  content: "\f36d";
}
.fi-rs-knife:before {
  content: "\f36e";
}
.fi-rs-l:before {
  content: "\f36f";
}
.fi-rs-label:before {
  content: "\f370";
}
.fi-rs-laptop:before {
  content: "\f371";
}
.fi-rs-lasso:before {
  content: "\f372";
}
.fi-rs-laugh-beam:before {
  content: "\f373";
}
.fi-rs-laugh-squint:before {
  content: "\f374";
}
.fi-rs-laugh-wink:before {
  content: "\f375";
}
.fi-rs-laugh:before {
  content: "\f376";
}
.fi-rs-layer-minus:before {
  content: "\f377";
}
.fi-rs-layer-plus:before {
  content: "\f378";
}
.fi-rs-layers:before {
  content: "\f379";
}
.fi-rs-leaf:before {
  content: "\f37a";
}
.fi-rs-lemon:before {
  content: "\f37b";
}
.fi-rs-letter-case:before {
  content: "\f37c";
}
.fi-rs-lettuce:before {
  content: "\f37d";
}
.fi-rs-level-down-alt:before {
  content: "\f37e";
}
.fi-rs-level-down:before {
  content: "\f37f";
}
.fi-rs-level-up-alt:before {
  content: "\f380";
}
.fi-rs-level-up:before {
  content: "\f381";
}
.fi-rs-life-ring:before {
  content: "\f382";
}
.fi-rs-lightbulb-dollar:before {
  content: "\f383";
}
.fi-rs-line-width:before {
  content: "\f384";
}
.fi-rs-link-alt:before {
  content: "\f385";
}
.fi-rs-link-slash-alt:before {
  content: "\f386";
}
.fi-rs-link-slash:before {
  content: "\f387";
}
.fi-rs-link:before {
  content: "\f388";
}
.fi-rs-lipstick:before {
  content: "\f389";
}
.fi-rs-lira-sign:before {
  content: "\f38a";
}
.fi-rs-list-check:before {
  content: "\f38b";
}
.fi-rs-list:before {
  content: "\f38c";
}
.fi-rs-loading:before {
  content: "\f38d";
}
.fi-rs-location-alt:before {
  content: "\f38e";
}
.fi-rs-lock-alt:before {
  content: "\f38f";
}
.fi-rs-lock:before {
  content: "\f390";
}
.fi-rs-log-out:before {
  content: "\f391";
}
.fi-rs-luchador:before {
  content: "\f392";
}
.fi-rs-luggage-rolling:before {
  content: "\f393";
}
.fi-rs-m:before {
  content: "\f394";
}
.fi-rs-magic-wand:before {
  content: "\f395";
}
.fi-rs-mailbox:before {
  content: "\f396";
}
.fi-rs-makeup-brush:before {
  content: "\f397";
}
.fi-rs-man-head:before {
  content: "\f398";
}
.fi-rs-map-marker-cross:before {
  content: "\f399";
}
.fi-rs-map-marker-home:before {
  content: "\f39a";
}
.fi-rs-map-marker-minus:before {
  content: "\f39b";
}
.fi-rs-map-marker-plus:before {
  content: "\f39c";
}
.fi-rs-map-marker:before {
  content: "\f39d";
}
.fi-rs-map:before {
  content: "\f39e";
}
.fi-rs-marker-time:before {
  content: "\f39f";
}
.fi-rs-marker:before {
  content: "\f3a0";
}
.fi-rs-mars-double:before {
  content: "\f3a1";
}
.fi-rs-mars:before {
  content: "\f3a2";
}
.fi-rs-mask-carnival:before {
  content: "\f3a3";
}
.fi-rs-medicine:before {
  content: "\f3a4";
}
.fi-rs-megaphone:before {
  content: "\f3a5";
}
.fi-rs-meh-blank:before {
  content: "\f3a6";
}
.fi-rs-meh-rolling-eyes:before {
  content: "\f3a7";
}
.fi-rs-meh:before {
  content: "\f3a8";
}
.fi-rs-melon:before {
  content: "\f3a9";
}
.fi-rs-menu-dots-vertical:before {
  content: "\f3aa";
}
.fi-rs-menu-dots:before {
  content: "\f3ab";
}
.fi-rs-meteor:before {
  content: "\f3ac";
}
.fi-rs-microphone-alt:before {
  content: "\f3ad";
}
.fi-rs-microphone:before {
  content: "\f3ae";
}
.fi-rs-mind-share:before {
  content: "\f3af";
}
.fi-rs-minus-small:before {
  content: "\f3b0";
}
.fi-rs-minuss:before {
  content: "\f3b1";
}
.fi-rs-mobile-button:before {
  content: "\f3b2";
}
.fi-rs-mobile-notch:before {
  content: "\f3b3";
}
.fi-rs-mobile:before {
  content: "\f3b4";
}
.fi-rs-mode-landscape:before {
  content: "\f3b5";
}
.fi-rs-mode-portrait:before {
  content: "\f3b6";
}
.fi-rs-money-bill-wave-alt:before {
  content: "\f3b7";
}
.fi-rs-money-bill-wave:before {
  content: "\f3b8";
}
.fi-rs-money-check:before {
  content: "\f3b9";
}
.fi-rs-money-check-edit-alt:before {
  content: "\f3ba";
}
.fi-rs-money-check-edit:before {
  content: "\f3bb";
}
.fi-rs-money:before {
  content: "\f3bc";
}
.fi-rs-moon-stars:before {
  content: "\f3bd";
}
.fi-rs-moon:before {
  content: "\f3be";
}
.fi-rs-motorcycle:before {
  content: "\f3bf";
}
.fi-rs-mountains:before {
  content: "\f3c0";
}
.fi-rs-mouse:before {
  content: "\f3c1";
}
.fi-rs-mug-alt:before {
  content: "\f3c2";
}
.fi-rs-mug-hot-alt:before {
  content: "\f3c3";
}
.fi-rs-mug-hot:before {
  content: "\f3c4";
}
.fi-rs-mug-tea:before {
  content: "\f3c5";
}
.fi-rs-mug:before {
  content: "\f3c6";
}
.fi-rs-mushroom:before {
  content: "\f3c7";
}
.fi-rs-music-file:before {
  content: "\f3c8";
}
.fi-rs-music-alt:before {
  content: "\f3c9";
}
.fi-rs-music:before {
  content: "\f3ca";
}
.fi-rs-n:before {
  content: "\f3cb";
}
.fi-rs-navigation:before {
  content: "\f3cc";
}
.fi-rs-network-cloud:before {
  content: "\f3cd";
}
.fi-rs-network:before {
  content: "\f3ce";
}
.fi-rs-noodles:before {
  content: "\f3cf";
}
.fi-rs-notebook:before {
  content: "\f3d0";
}
.fi-rs-o:before {
  content: "\f3d1";
}
.fi-rs-oil-can:before {
  content: "\f3d2";
}
.fi-rs-oil-temp:before {
  content: "\f3d3";
}
.fi-rs-olive-oil:before {
  content: "\f3d4";
}
.fi-rs-olives:before {
  content: "\f3d5";
}
.fi-rs-onion:before {
  content: "\f3d6";
}
.fi-rs-opacity:before {
  content: "\f3d7";
}
.fi-rs-overline:before {
  content: "\f3d8";
}
.fi-rs-p:before {
  content: "\f3d9";
}
.fi-rs-package:before {
  content: "\f3da";
}
.fi-rs-page-break:before {
  content: "\f3db";
}
.fi-rs-paint-roller:before {
  content: "\f3dc";
}
.fi-rs-paint-brush:before {
  content: "\f3dd";
}
.fi-rs-palette:before {
  content: "\f3de";
}
.fi-rs-pan:before {
  content: "\f3df";
}
.fi-rs-paper-plane:before {
  content: "\f3e0";
}
.fi-rs-password:before {
  content: "\f3e1";
}
.fi-rs-pause:before {
  content: "\f3e2";
}
.fi-rs-paw:before {
  content: "\f3e3";
}
.fi-rs-peach:before {
  content: "\f3e4";
}
.fi-rs-pencil-ruler:before {
  content: "\f3e5";
}
.fi-rs-pencil:before {
  content: "\f3e6";
}
.fi-rs-pennant:before {
  content: "\f3e7";
}
.fi-rs-people-poll:before {
  content: "\f3e8";
}
.fi-rs-pepper-hot:before {
  content: "\f3e9";
}
.fi-rs-pepper:before {
  content: "\f3ea";
}
.fi-rs-percentage:before {
  content: "\f3eb";
}
.fi-rs-pharmacy:before {
  content: "\f3ec";
}
.fi-rs-phone-office:before {
  content: "\f3ed";
}
.fi-rs-phone-call:before {
  content: "\f3ee";
}
.fi-rs-phone-cross:before {
  content: "\f3ef";
}
.fi-rs-phone-pause:before {
  content: "\f3f0";
}
.fi-rs-phone-slash:before {
  content: "\f3f1";
}
.fi-rs-photo-film-music:before {
  content: "\f3f2";
}
.fi-rs-photo-video:before {
  content: "\f3f3";
}
.fi-rs-physics:before {
  content: "\f3f4";
}
.fi-rs-picnic:before {
  content: "\f3f5";
}
.fi-rs-picture:before {
  content: "\f3f6";
}
.fi-rs-pie:before {
  content: "\f3f7";
}
.fi-rs-piggy-bank:before {
  content: "\f3f8";
}
.fi-rs-pineapple:before {
  content: "\f3f9";
}
.fi-rs-ping-pong:before {
  content: "\f3fa";
}
.fi-rs-pixabay:before {
  content: "\f3fb";
}
.fi-rs-pizza-slice:before {
  content: "\f3fc";
}
.fi-rs-plane-alt:before {
  content: "\f3fd";
}
.fi-rs-plane:before {
  content: "\f3fe";
}
.fi-rs-plate:before {
  content: "\f3ff";
}
.fi-rs-play-alt:before {
  content: "\f400";
}
.fi-rs-play-pause:before {
  content: "\f401";
}
.fi-rs-play:before {
  content: "\f402";
}
.fi-rs-playing-cards:before {
  content: "\f403";
}
.fi-rs-plus-small:before {
  content: "\f404";
}
.fi-rs-plus:before {
  content: "\f405";
}
.fi-rs-podium-star:before {
  content: "\f406";
}
.fi-rs-podium:before {
  content: "\f407";
}
.fi-rs-poker-chip:before {
  content: "\f408";
}
.fi-rs-poo:before {
  content: "\f409";
}
.fi-rs-popcorn:before {
  content: "\f40a";
}
.fi-rs-portrait:before {
  content: "\f40b";
}
.fi-rs-pot:before {
  content: "\f40c";
}
.fi-rs-pound:before {
  content: "\f40d";
}
.fi-rs-presentation:before {
  content: "\f40e";
}
.fi-rs-print:before {
  content: "\f40f";
}
.fi-rs-protractor:before {
  content: "\f410";
}
.fi-rs-pulse:before {
  content: "\f411";
}
.fi-rs-pumpkin:before {
  content: "\f412";
}
.fi-rs-puzzle-piece:before {
  content: "\f413";
}
.fi-rs-pyramid:before {
  content: "\f414";
}
.fi-rs-q:before {
  content: "\f415";
}
.fi-rs-question-square:before {
  content: "\f416";
}
.fi-rs-question:before {
  content: "\f417";
}
.fi-rs-quote-right:before {
  content: "\f418";
}
.fi-rs-r:before {
  content: "\f419";
}
.fi-rs-racquet:before {
  content: "\f41a";
}
.fi-rs-radish:before {
  content: "\f41b";
}
.fi-rs-rainbow:before {
  content: "\f41c";
}
.fi-rs-raindrops:before {
  content: "\f41d";
}
.fi-rs-rec:before {
  content: "\f41e";
}
.fi-rs-receipt:before {
  content: "\f41f";
}
.fi-rs-record-vinyl:before {
  content: "\f420";
}
.fi-rs-rectabgle-vertical:before {
  content: "\f421";
}
.fi-rs-rectangle-horizontal:before {
  content: "\f422";
}
.fi-rs-rectangle-panoramic:before {
  content: "\f423";
}
.fi-rs-recycle:before {
  content: "\f424";
}
.fi-rs-redo-alt:before {
  content: "\f425";
}
.fi-rs-redo:before {
  content: "\f426";
}
.fi-rs-reflect:before {
  content: "\f427";
}
.fi-rs-refresh:before {
  content: "\f428";
}
.fi-rs-registered:before {
  content: "\f429";
}
.fi-rs-remove-user:before {
  content: "\f42a";
}
.fi-rs-reply-all:before {
  content: "\f42b";
}
.fi-rs-republican:before {
  content: "\f42c";
}
.fi-rs-resize:before {
  content: "\f42d";
}
.fi-rs-resources:before {
  content: "\f42e";
}
.fi-rs-restaurant:before {
  content: "\f42f";
}
.fi-rs-rewind:before {
  content: "\f430";
}
.fi-rs-rhombus:before {
  content: "\f431";
}
.fi-rs-rings-wedding:before {
  content: "\f432";
}
.fi-rs-road:before {
  content: "\f433";
}
.fi-rs-rocket-lunch:before {
  content: "\f434";
}
.fi-rs-rocket:before {
  content: "\f435";
}
.fi-rs-room-service:before {
  content: "\f436";
}
.fi-rs-rotate-left:before {
  content: "\f437";
}
.fi-rs-rotate-right:before {
  content: "\f438";
}
.fi-rs-ruble-sign:before {
  content: "\f439";
}
.fi-rs-rugby-helmet:before {
  content: "\f43a";
}
.fi-rs-rugby:before {
  content: "\f43b";
}
.fi-rs-ruler-combined:before {
  content: "\f43c";
}
.fi-rs-ruler-horizontal:before {
  content: "\f43d";
}
.fi-rs-ruler-triangle:before {
  content: "\f43e";
}
.fi-rs-ruler-vertical:before {
  content: "\f43f";
}
.fi-rs-running:before {
  content: "\f440";
}
.fi-rs-rupee-sign:before {
  content: "\f441";
}
.fi-rs-rv:before {
  content: "\f442";
}
.fi-rs-s:before {
  content: "\f443";
}
.fi-rs-sack-dollar:before {
  content: "\f444";
}
.fi-rs-sack:before {
  content: "\f445";
}
.fi-rs-sad-cry:before {
  content: "\f446";
}
.fi-rs-sad-tear:before {
  content: "\f447";
}
.fi-rs-sad:before {
  content: "\f448";
}
.fi-rs-salad:before {
  content: "\f449";
}
.fi-rs-salt-pepper:before {
  content: "\f44a";
}
.fi-rs-sandwich:before {
  content: "\f44b";
}
.fi-rs-sauce:before {
  content: "\f44c";
}
.fi-rs-sausage:before {
  content: "\f44d";
}
.fi-rs-scale:before {
  content: "\f44e";
}
.fi-rs-school-bus:before {
  content: "\f44f";
}
.fi-rs-school:before {
  content: "\f450";
}
.fi-rs-scissors:before {
  content: "\f451";
}
.fi-rs-screen:before {
  content: "\f452";
}
.fi-rs-search-dollar:before {
  content: "\f453";
}
.fi-rs-search-location:before {
  content: "\f454";
}
.fi-rs-search-alt:before {
  content: "\f455";
}
.fi-rs-search-heart:before {
  content: "\f456";
}
.fi-rs-search:before {
  content: "\f457";
}
.fi-rs-sensor-alert:before {
  content: "\f458";
}
.fi-rs-sensor-fire:before {
  content: "\f459";
}
.fi-rs-sensor-on:before {
  content: "\f45a";
}
.fi-rs-sensor-smoke:before {
  content: "\f45b";
}
.fi-rs-sensor:before {
  content: "\f45c";
}
.fi-rs-settings-sliders:before {
  content: "\f45d";
}
.fi-rs-settings:before {
  content: "\f45e";
}
.fi-rs-share:before {
  content: "\f45f";
}
.fi-rs-shekel-sign:before {
  content: "\f460";
}
.fi-rs-shield-check:before {
  content: "\f461";
}
.fi-rs-shield-exclamation:before {
  content: "\f462";
}
.fi-rs-shield-interrogation:before {
  content: "\f463";
}
.fi-rs-shield-plus:before {
  content: "\f464";
}
.fi-rs-shield:before {
  content: "\f465";
}
.fi-rs-ship-side:before {
  content: "\f466";
}
.fi-rs-ship:before {
  content: "\f467";
}
.fi-rs-shop:before {
  content: "\f468";
}
.fi-rs-shopping-basket:before {
  content: "\f469";
}
.fi-rs-shopping-bag-add:before {
  content: "\f46a";
}
.fi-rs-shopping-bag:before {
  content: "\f46b";
}
.fi-rs-shopping-cart-add:before {
  content: "\f46c";
}
.fi-rs-shopping-cart-check:before {
  content: "\f46d";
}
.fi-rs-shopping-cart:before {
  content: "\f46e";
}
.fi-rs-shrimp:before {
  content: "\f46f";
}
.fi-rs-shuffle:before {
  content: "\f470";
}
.fi-rs-shuttle-van:before {
  content: "\f471";
}
.fi-rs-shuttlecock:before {
  content: "\f472";
}
.fi-rs-sign-in-alt:before {
  content: "\f473";
}
.fi-rs-sign-out-alt:before {
  content: "\f474";
}
.fi-rs-signal-alt-1:before {
  content: "\f475";
}
.fi-rs-signal-alt-2:before {
  content: "\f476";
}
.fi-rs-signal-alt:before {
  content: "\f477";
}
.fi-rs-skateboard:before {
  content: "\f478";
}
.fi-rs-skating:before {
  content: "\f479";
}
.fi-rs-skewer:before {
  content: "\f47a";
}
.fi-rs-ski-jump:before {
  content: "\f47b";
}
.fi-rs-ski-lift:before {
  content: "\f47c";
}
.fi-rs-skiing-nordic:before {
  content: "\f47d";
}
.fi-rs-skiing:before {
  content: "\f47e";
}
.fi-rs-sledding:before {
  content: "\f47f";
}
.fi-rs-sleigh:before {
  content: "\f480";
}
.fi-rs-smartphone:before {
  content: "\f481";
}
.fi-rs-smile-beam:before {
  content: "\f482";
}
.fi-rs-smile-wink:before {
  content: "\f483";
}
.fi-rs-smog:before {
  content: "\f484";
}
.fi-rs-smoke:before {
  content: "\f485";
}
.fi-rs-snow-blowing:before {
  content: "\f486";
}
.fi-rs-snowboarding:before {
  content: "\f487";
}
.fi-rs-snowflake:before {
  content: "\f488";
}
.fi-rs-snowflakes:before {
  content: "\f489";
}
.fi-rs-snowmobile:before {
  content: "\f48a";
}
.fi-rs-snowplow:before {
  content: "\f48b";
}
.fi-rs-soap:before {
  content: "\f48c";
}
.fi-rs-social-network:before {
  content: "\f48d";
}
.fi-rs-sort-alpha-down-alt:before {
  content: "\f48e";
}
.fi-rs-sort-alpha-down:before {
  content: "\f48f";
}
.fi-rs-sort-alpha-up-alt:before {
  content: "\f490";
}
.fi-rs-sort-alpha-up:before {
  content: "\f491";
}
.fi-rs-sort-alt:before {
  content: "\f492";
}
.fi-rs-sort-amount-down-alt:before {
  content: "\f493";
}
.fi-rs-sort-amount-down:before {
  content: "\f494";
}
.fi-rs-sort-amount-up-alt:before {
  content: "\f495";
}
.fi-rs-sort-amount-up:before {
  content: "\f496";
}
.fi-rs-sort-down:before {
  content: "\f497";
}
.fi-rs-sort-numeric-down-alt:before {
  content: "\f498";
}
.fi-rs-sort-numeric-down:before {
  content: "\f499";
}
.fi-rs-sort:before {
  content: "\f49a";
}
.fi-rs-soup:before {
  content: "\f49b";
}
.fi-rs-spa:before {
  content: "\f49c";
}
.fi-rs-space-shuttle:before {
  content: "\f49d";
}
.fi-rs-spade:before {
  content: "\f49e";
}
.fi-rs-sparkles:before {
  content: "\f49f";
}
.fi-rs-speaker:before {
  content: "\f4a0";
}
.fi-rs-sphere:before {
  content: "\f4a1";
}
.fi-rs-spinner:before {
  content: "\f4a2";
}
.fi-rs-spoon:before {
  content: "\f4a3";
}
.fi-rs-square-root:before {
  content: "\f4a4";
}
.fi-rs-square:before {
  content: "\f4a5";
}
.fi-rs-stamp:before {
  content: "\f4a6";
}
.fi-rs-star-octogram:before {
  content: "\f4a7";
}
.fi-rs-star:before {
  content: "\f4a8";
}
.fi-rs-starfighter:before {
  content: "\f4a9";
}
.fi-rs-stars:before {
  content: "\f4aa";
}
.fi-rs-stats:before {
  content: "\f4ab";
}
.fi-rs-steak:before {
  content: "\f4ac";
}
.fi-rs-steering-wheel:before {
  content: "\f4ad";
}
.fi-rs-stethoscope:before {
  content: "\f4ae";
}
.fi-rs-sticker:before {
  content: "\f4af";
}
.fi-rs-stop:before {
  content: "\f4b0";
}
.fi-rs-stopwatch:before {
  content: "\f4b1";
}
.fi-rs-store-alt:before {
  content: "\f4b2";
}
.fi-rs-strawberry:before {
  content: "\f4b3";
}
.fi-rs-subtitles:before {
  content: "\f4b4";
}
.fi-rs-subway:before {
  content: "\f4b5";
}
.fi-rs-suitcase-alt:before {
  content: "\f4b6";
}
.fi-rs-summer:before {
  content: "\f4b7";
}
.fi-rs-sun:before {
  content: "\f4b8";
}
.fi-rs-sunrise-alt:before {
  content: "\f4b9";
}
.fi-rs-sunrise:before {
  content: "\f4ba";
}
.fi-rs-sunset:before {
  content: "\f4bb";
}
.fi-rs-surfing:before {
  content: "\f4bc";
}
.fi-rs-surprise:before {
  content: "\f4bd";
}
.fi-rs-sushi:before {
  content: "\f4be";
}
.fi-rs-swimmer:before {
  content: "\f4bf";
}
.fi-rs-sword:before {
  content: "\f4c0";
}
.fi-rs-symbol:before {
  content: "\f4c1";
}
.fi-rs-syringe:before {
  content: "\f4c2";
}
.fi-rs-t:before {
  content: "\f4c3";
}
.fi-rs-tablet:before {
  content: "\f4c4";
}
.fi-rs-tachometer-alt-average:before {
  content: "\f4c5";
}
.fi-rs-tachometer-alt-fastest:before {
  content: "\f4c6";
}
.fi-rs-tachometer-alt-slow:before {
  content: "\f4c7";
}
.fi-rs-tachometer-alt-slowest:before {
  content: "\f4c8";
}
.fi-rs-tachometer-average:before {
  content: "\f4c9";
}
.fi-rs-tachometer-fast:before {
  content: "\f4ca";
}
.fi-rs-tachometer-fastest:before {
  content: "\f4cb";
}
.fi-rs-tachometer-slow:before {
  content: "\f4cc";
}
.fi-rs-tachometer-slowest:before {
  content: "\f4cd";
}
.fi-rs-tachometer:before {
  content: "\f4ce";
}
.fi-rs-taco:before {
  content: "\f4cf";
}
.fi-rs-tags:before {
  content: "\f4d0";
}
.fi-rs-tally:before {
  content: "\f4d1";
}
.fi-rs-target:before {
  content: "\f4d2";
}
.fi-rs-taxi:before {
  content: "\f4d3";
}
.fi-rs-temperature-down:before {
  content: "\f4d4";
}
.fi-rs-temperature-frigid:before {
  content: "\f4d5";
}
.fi-rs-temperature-high:before {
  content: "\f4d6";
}
.fi-rs-temperature-low:before {
  content: "\f4d7";
}
.fi-rs-temperature-up:before {
  content: "\f4d8";
}
.fi-rs-tenge:before {
  content: "\f4d9";
}
.fi-rs-tennis:before {
  content: "\f4da";
}
.fi-rs-terrace:before {
  content: "\f4db";
}
.fi-rs-test-tube:before {
  content: "\f4dc";
}
.fi-rs-test:before {
  content: "\f4dd";
}
.fi-rs-text-check:before {
  content: "\f4de";
}
.fi-rs-text-slash:before {
  content: "\f4df";
}
.fi-rs-text:before {
  content: "\f4e0";
}
.fi-rs-thermometer-half:before {
  content: "\f4e1";
}
.fi-rs-thumbtack:before {
  content: "\f4e2";
}
.fi-rs-thunderstorm-moon:before {
  content: "\f4e3";
}
.fi-rs-thunderstorm-sun:before {
  content: "\f4e4";
}
.fi-rs-thunderstorm:before {
  content: "\f4e5";
}
.fi-rs-ticket:before {
  content: "\f4e6";
}
.fi-rs-time-quarter-past:before {
  content: "\f4e7";
}
.fi-rs-time-add:before {
  content: "\f4e8";
}
.fi-rs-time-check:before {
  content: "\f4e9";
}
.fi-rs-time-delete:before {
  content: "\f4ea";
}
.fi-rs-time-fast:before {
  content: "\f4eb";
}
.fi-rs-time-forward-sixty:before {
  content: "\f4ec";
}
.fi-rs-time-forward-ten:before {
  content: "\f4ed";
}
.fi-rs-time-forward:before {
  content: "\f4ee";
}
.fi-rs-time-half-past:before {
  content: "\f4ef";
}
.fi-rs-time-oclock:before {
  content: "\f4f0";
}
.fi-rs-time-past:before {
  content: "\f4f1";
}
.fi-rs-time-quarter-to:before {
  content: "\f4f2";
}
.fi-rs-time-twenty-four:before {
  content: "\f4f3";
}
.fi-rs-tire-flat:before {
  content: "\f4f4";
}
.fi-rs-tire-pressure-warning:before {
  content: "\f4f5";
}
.fi-rs-tire-rugged:before {
  content: "\f4f6";
}
.fi-rs-tire:before {
  content: "\f4f7";
}
.fi-rs-tired:before {
  content: "\f4f8";
}
.fi-rs-tomato:before {
  content: "\f4f9";
}
.fi-rs-tool-box:before {
  content: "\f4fa";
}
.fi-rs-tool-crop:before {
  content: "\f4fb";
}
.fi-rs-tool-marquee:before {
  content: "\f4fc";
}
.fi-rs-tooth:before {
  content: "\f4fd";
}
.fi-rs-tornado:before {
  content: "\f4fe";
}
.fi-rs-tractor:before {
  content: "\f4ff";
}
.fi-rs-trailer:before {
  content: "\f500";
}
.fi-rs-train-side:before {
  content: "\f501";
}
.fi-rs-train:before {
  content: "\f502";
}
.fi-rs-tram:before {
  content: "\f503";
}
.fi-rs-transform:before {
  content: "\f504";
}
.fi-rs-trash:before {
  content: "\f505";
}
.fi-rs-treasure-chest:before {
  content: "\f506";
}
.fi-rs-treatment:before {
  content: "\f507";
}
.fi-rs-tree-christmas:before {
  content: "\f508";
}
.fi-rs-tree:before {
  content: "\f509";
}
.fi-rs-triangle:before {
  content: "\f50a";
}
.fi-rs-trophy:before {
  content: "\f50b";
}
.fi-rs-truck-container:before {
  content: "\f50c";
}
.fi-rs-truck-couch:before {
  content: "\f50d";
}
.fi-rs-truck-loading:before {
  content: "\f50e";
}
.fi-rs-truck-monster:before {
  content: "\f50f";
}
.fi-rs-truck-moving:before {
  content: "\f510";
}
.fi-rs-truck-pickup:before {
  content: "\f511";
}
.fi-rs-truck-plow:before {
  content: "\f512";
}
.fi-rs-truck-ramp:before {
  content: "\f513";
}
.fi-rs-truck-side:before {
  content: "\f514";
}
.fi-rs-tty:before {
  content: "\f515";
}
.fi-rs-turkey:before {
  content: "\f516";
}
.fi-rs-tv-music:before {
  content: "\f517";
}
.fi-rs-typewriter:before {
  content: "\f518";
}
.fi-rs-u:before {
  content: "\f519";
}
.fi-rs-umbrella:before {
  content: "\f51a";
}
.fi-rs-underline:before {
  content: "\f51b";
}
.fi-rs-undo-alt:before {
  content: "\f51c";
}
.fi-rs-undo:before {
  content: "\f51d";
}
.fi-rs-unlock:before {
  content: "\f51e";
}
.fi-rs-upload:before {
  content: "\f51f";
}
.fi-rs-usb-pendrive:before {
  content: "\f520";
}
.fi-rs-usd-circle:before {
  content: "\f521";
}
.fi-rs-usd-square:before {
  content: "\f522";
}
.fi-rs-user-add:before {
  content: "\f523";
}
.fi-rs-user-time:before {
  content: "\f524";
}
.fi-rs-user:before {
  content: "\f525";
}
.fi-rs-users-alt:before {
  content: "\f526";
}
.fi-rs-users:before {
  content: "\f527";
}
.fi-rs-utensils:before {
  content: "\f528";
}
.fi-rs-v:before {
  content: "\f529";
}
.fi-rs-vector-alt:before {
  content: "\f52a";
}
.fi-rs-vector:before {
  content: "\f52b";
}
.fi-rs-venus-double:before {
  content: "\f52c";
}
.fi-rs-venus-mars:before {
  content: "\f52d";
}
.fi-rs-venus:before {
  content: "\f52e";
}
.fi-rs-video-arrow-down-left:before {
  content: "\f52f";
}
.fi-rs-video-arrow-up-right:before {
  content: "\f530";
}
.fi-rs-video-camera-alt:before {
  content: "\f531";
}
.fi-rs-video-camera:before {
  content: "\f532";
}
.fi-rs-video-plus:before {
  content: "\f533";
}
.fi-rs-video-slash:before {
  content: "\f534";
}
.fi-rs-volcano:before {
  content: "\f535";
}
.fi-rs-volleyball:before {
  content: "\f536";
}
.fi-rs-volume:before {
  content: "\f537";
}
.fi-rs-w:before {
  content: "\f538";
}
.fi-rs-wagon-covered:before {
  content: "\f539";
}
.fi-rs-wallet:before {
  content: "\f53a";
}
.fi-rs-water-bottle:before {
  content: "\f53b";
}
.fi-rs-water-lower:before {
  content: "\f53c";
}
.fi-rs-water-rise:before {
  content: "\f53d";
}
.fi-rs-water:before {
  content: "\f53e";
}
.fi-rs-watermelon:before {
  content: "\f53f";
}
.fi-rs-wheat:before {
  content: "\f540";
}
.fi-rs-wheelchair:before {
  content: "\f541";
}
.fi-rs-whistle:before {
  content: "\f542";
}
.fi-rs-wifi-alt:before {
  content: "\f543";
}
.fi-rs-wind-warning:before {
  content: "\f544";
}
.fi-rs-wind:before {
  content: "\f545";
}
.fi-rs-windsock:before {
  content: "\f546";
}
.fi-rs-woman-head:before {
  content: "\f547";
}
.fi-rs-world:before {
  content: "\f548";
}
.fi-rs-x:before {
  content: "\f549";
}
.fi-rs-y:before {
  content: "\f54a";
}
.fi-rs-yen:before {
  content: "\f54b";
}
.fi-rs-z:before {
  content: "\f54c";
}
.fi-rs-zoom-in:before {
  content: "\f54d";
}
.fi-rs-zoom-out:before {
  content: "\f54e";
}

@font-face {
  font-family: "uicons-solid-rounded";
  src: url("../fonts/uicons-solid-rounded.eot#iefix")
      format("embedded-opentype"),
    url("../fonts/uicons-solid-rounded.woff2") format("woff2"),
    url("../fonts/uicons-solid-rounded.woff") format("woff");
}

i[class^="fi-sr-"]:before,
i[class*=" fi-sr-"]:before,
span[class^="fi-sr-"]:before,
span[class*="fi-sr-"]:before {
  font-family: uicons-solid-rounded !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi-sr-a:before {
  content: "\f101";
}
.fi-sr-ad:before {
  content: "\f102";
}
.fi-sr-add-document:before {
  content: "\f103";
}
.fi-sr-add-folder:before {
  content: "\f104";
}
.fi-sr-add:before {
  content: "\f105";
}
.fi-sr-address-book:before {
  content: "\f106";
}
.fi-sr-air-conditioner:before {
  content: "\f107";
}
.fi-sr-air-freshener:before {
  content: "\f108";
}
.fi-sr-alarm-clock:before {
  content: "\f109";
}
.fi-sr-alarm-exclamation:before {
  content: "\f10a";
}
.fi-sr-alarm-plus:before {
  content: "\f10b";
}
.fi-sr-alarm-snooze:before {
  content: "\f10c";
}
.fi-sr-align-justify:before {
  content: "\f10d";
}
.fi-sr-align-left:before {
  content: "\f10e";
}
.fi-sr-ambulance:before {
  content: "\f10f";
}
.fi-sr-amusement-park:before {
  content: "\f110";
}
.fi-sr-angle-circle-down:before {
  content: "\f111";
}
.fi-sr-angle-circle-left:before {
  content: "\f112";
}
.fi-sr-angle-circle-right:before {
  content: "\f113";
}
.fi-sr-angle-circle-up:before {
  content: "\f114";
}
.fi-sr-angle-double-left:before {
  content: "\f115";
}
.fi-sr-angle-double-right:before {
  content: "\f116";
}
.fi-sr-angle-double-small-down:before {
  content: "\f117";
}
.fi-sr-angle-double-small-left:before {
  content: "\f118";
}
.fi-sr-angle-double-small-right:before {
  content: "\f119";
}
.fi-sr-angle-double-small-up:before {
  content: "\f11a";
}
.fi-sr-angle-down:before {
  content: "\f11b";
}
.fi-sr-angle-left:before {
  content: "\f11c";
}
.fi-sr-angle-right:before {
  content: "\f11d";
}
.fi-sr-angle-small-down:before {
  content: "\f11e";
}
.fi-sr-angle-small-left:before {
  content: "\f11f";
}
.fi-sr-angle-small-right:before {
  content: "\f120";
}
.fi-sr-angle-small-up:before {
  content: "\f121";
}
.fi-sr-angle-square-down:before {
  content: "\f122";
}
.fi-sr-angle-square-left:before {
  content: "\f123";
}
.fi-sr-angle-square-right:before {
  content: "\f124";
}
.fi-sr-angle-square-up:before {
  content: "\f125";
}
.fi-sr-angle-up:before {
  content: "\f126";
}
.fi-sr-angry:before {
  content: "\f127";
}
.fi-sr-apple-whole:before {
  content: "\f128";
}
.fi-sr-apps-add:before {
  content: "\f129";
}
.fi-sr-apps-delete:before {
  content: "\f12a";
}
.fi-sr-apps-sort:before {
  content: "\f12b";
}
.fi-sr-apps:before {
  content: "\f12c";
}
.fi-sr-archive:before {
  content: "\f12d";
}
.fi-sr-arrow-alt-circle-down:before {
  content: "\f12e";
}
.fi-sr-arrow-alt-circle-left:before {
  content: "\f12f";
}
.fi-sr-arrow-alt-circle-right:before {
  content: "\f130";
}
.fi-sr-arrow-alt-circle-up:before {
  content: "\f131";
}
.fi-sr-arrow-alt-down:before {
  content: "\f132";
}
.fi-sr-arrow-alt-from-bottom:before {
  content: "\f133";
}
.fi-sr-arrow-alt-from-left:before {
  content: "\f134";
}
.fi-sr-arrow-alt-from-right:before {
  content: "\f135";
}
.fi-sr-arrow-alt-from-top:before {
  content: "\f136";
}
.fi-sr-arrow-alt-left:before {
  content: "\f137";
}
.fi-sr-arrow-alt-right:before {
  content: "\f138";
}
.fi-sr-arrow-alt-square-down:before {
  content: "\f139";
}
.fi-sr-arrow-alt-square-left:before {
  content: "\f13a";
}
.fi-sr-arrow-alt-square-right:before {
  content: "\f13b";
}
.fi-sr-arrow-alt-square-up:before {
  content: "\f13c";
}
.fi-sr-arrow-alt-to-bottom:before {
  content: "\f13d";
}
.fi-sr-arrow-alt-to-left:before {
  content: "\f13e";
}
.fi-sr-arrow-alt-to-right:before {
  content: "\f13f";
}
.fi-sr-arrow-alt-to-top:before {
  content: "\f140";
}
.fi-sr-arrow-alt-up:before {
  content: "\f141";
}
.fi-sr-arrow-circle-down:before {
  content: "\f142";
}
.fi-sr-arrow-circle-left:before {
  content: "\f143";
}
.fi-sr-arrow-circle-right:before {
  content: "\f144";
}
.fi-sr-arrow-circle-up:before {
  content: "\f145";
}
.fi-sr-arrow-down:before {
  content: "\f146";
}
.fi-sr-arrow-from-bottom:before {
  content: "\f147";
}
.fi-sr-arrow-from-left:before {
  content: "\f148";
}
.fi-sr-arrow-from-right:before {
  content: "\f149";
}
.fi-sr-arrow-from-top:before {
  content: "\f14a";
}
.fi-sr-arrow-left:before {
  content: "\f14b";
}
.fi-sr-arrow-right:before {
  content: "\f14c";
}
.fi-sr-arrow-small-down:before {
  content: "\f14d";
}
.fi-sr-arrow-small-left:before {
  content: "\f14e";
}
.fi-sr-arrow-small-right:before {
  content: "\f14f";
}
.fi-sr-arrow-small-up:before {
  content: "\f150";
}
.fi-sr-arrow-square-down:before {
  content: "\f151";
}
.fi-sr-arrow-square-left:before {
  content: "\f152";
}
.fi-sr-arrow-square-right:before {
  content: "\f153";
}
.fi-sr-arrow-square-up:before {
  content: "\f154";
}
.fi-sr-arrow-to-bottom:before {
  content: "\f155";
}
.fi-sr-arrow-to-left:before {
  content: "\f156";
}
.fi-sr-arrow-to-right:before {
  content: "\f157";
}
.fi-sr-arrow-to-top:before {
  content: "\f158";
}
.fi-sr-arrow-up:before {
  content: "\f159";
}
.fi-sr-arrows-alt-h:before {
  content: "\f15a";
}
.fi-sr-arrows-alt-v:before {
  content: "\f15b";
}
.fi-sr-arrows-alt:before {
  content: "\f15c";
}
.fi-sr-arrows-h-copy:before {
  content: "\f15d";
}
.fi-sr-arrows-h:before {
  content: "\f15e";
}
.fi-sr-arrows:before {
  content: "\f15f";
}
.fi-sr-assept-document:before {
  content: "\f160";
}
.fi-sr-assistive-listening-systems:before {
  content: "\f161";
}
.fi-sr-asterik:before {
  content: "\f162";
}
.fi-sr-at:before {
  content: "\f163";
}
.fi-sr-aubergine:before {
  content: "\f164";
}
.fi-sr-avocado:before {
  content: "\f165";
}
.fi-sr-b:before {
  content: "\f166";
}
.fi-sr-baby-carriage:before {
  content: "\f167";
}
.fi-sr-backpack:before {
  content: "\f168";
}
.fi-sr-bacon:before {
  content: "\f169";
}
.fi-sr-badge-sheriff:before {
  content: "\f16a";
}
.fi-sr-badge-check:before {
  content: "\f16b";
}
.fi-sr-badge-dollar:before {
  content: "\f16c";
}
.fi-sr-badge-percent:before {
  content: "\f16d";
}
.fi-sr-badge:before {
  content: "\f16e";
}
.fi-sr-bags-shopping:before {
  content: "\f16f";
}
.fi-sr-bahai:before {
  content: "\f170";
}
.fi-sr-balance-scale-left:before {
  content: "\f171";
}
.fi-sr-balance-scale-right:before {
  content: "\f172";
}
.fi-sr-balance-scale:before {
  content: "\f173";
}
.fi-sr-balloons:before {
  content: "\f174";
}
.fi-sr-ballot:before {
  content: "\f175";
}
.fi-sr-ban:before {
  content: "\f176";
}
.fi-sr-band-aid:before {
  content: "\f177";
}
.fi-sr-bank:before {
  content: "\f178";
}
.fi-sr-barber-shop:before {
  content: "\f179";
}
.fi-sr-bars-progress:before {
  content: "\f17a";
}
.fi-sr-baseball-alt:before {
  content: "\f17b";
}
.fi-sr-baseball:before {
  content: "\f17c";
}
.fi-sr-basketball-hoop:before {
  content: "\f17d";
}
.fi-sr-basketball:before {
  content: "\f17e";
}
.fi-sr-bed-alt:before {
  content: "\f17f";
}
.fi-sr-bed:before {
  content: "\f180";
}
.fi-sr-beer:before {
  content: "\f181";
}
.fi-sr-bell-ring:before {
  content: "\f182";
}
.fi-sr-bell-school:before {
  content: "\f183";
}
.fi-sr-bell:before {
  content: "\f184";
}
.fi-sr-bike:before {
  content: "\f185";
}
.fi-sr-biking-mountain:before {
  content: "\f186";
}
.fi-sr-biking:before {
  content: "\f187";
}
.fi-sr-billiard:before {
  content: "\f188";
}
.fi-sr-blinds-open:before {
  content: "\f189";
}
.fi-sr-blinds-raised:before {
  content: "\f18a";
}
.fi-sr-blinds:before {
  content: "\f18b";
}
.fi-sr-blossom:before {
  content: "\f18c";
}
.fi-sr-bold:before {
  content: "\f18d";
}
.fi-sr-bolt:before {
  content: "\f18e";
}
.fi-sr-book-alt:before {
  content: "\f18f";
}
.fi-sr-book-arrow-right:before {
  content: "\f190";
}
.fi-sr-book-arrow-up:before {
  content: "\f191";
}
.fi-sr-book-bookmark:before {
  content: "\f192";
}
.fi-sr-book-open-cover:before {
  content: "\f193";
}
.fi-sr-book-open-reader:before {
  content: "\f194";
}
.fi-sr-book:before {
  content: "\f195";
}
.fi-sr-bookmark:before {
  content: "\f196";
}
.fi-sr-books:before {
  content: "\f197";
}
.fi-sr-bottle:before {
  content: "\f198";
}
.fi-sr-bow-arrow:before {
  content: "\f199";
}
.fi-sr-bowling-ball:before {
  content: "\f19a";
}
.fi-sr-bowling-pins:before {
  content: "\f19b";
}
.fi-sr-bowling:before {
  content: "\f19c";
}
.fi-sr-box-check:before {
  content: "\f19d";
}
.fi-sr-box-tissue:before {
  content: "\f19e";
}
.fi-sr-box-alt:before {
  content: "\f19f";
}
.fi-sr-box-ballot:before {
  content: "\f1a0";
}
.fi-sr-box:before {
  content: "\f1a1";
}
.fi-sr-boxes:before {
  content: "\f1a2";
}
.fi-sr-boxing-glove:before {
  content: "\f1a3";
}
.fi-sr-braille:before {
  content: "\f1a4";
}
.fi-sr-bread-slice:before {
  content: "\f1a5";
}
.fi-sr-bread:before {
  content: "\f1a6";
}
.fi-sr-briefcase:before {
  content: "\f1a7";
}
.fi-sr-broccoli:before {
  content: "\f1a8";
}
.fi-sr-broom:before {
  content: "\f1a9";
}
.fi-sr-browser:before {
  content: "\f1aa";
}
.fi-sr-brush:before {
  content: "\f1ab";
}
.fi-sr-bug:before {
  content: "\f1ac";
}
.fi-sr-building:before {
  content: "\f1ad";
}
.fi-sr-bulb:before {
  content: "\f1ae";
}
.fi-sr-bullseye-pointer:before {
  content: "\f1af";
}
.fi-sr-bullseye:before {
  content: "\f1b0";
}
.fi-sr-burrito:before {
  content: "\f1b1";
}
.fi-sr-bus-alt:before {
  content: "\f1b2";
}
.fi-sr-bus:before {
  content: "\f1b3";
}
.fi-sr-business-time:before {
  content: "\f1b4";
}
.fi-sr-butterfly:before {
  content: "\f1b5";
}
.fi-sr-cake-birthday:before {
  content: "\f1b6";
}
.fi-sr-cake-wedding:before {
  content: "\f1b7";
}
.fi-sr-calculator:before {
  content: "\f1b8";
}
.fi-sr-calendar-check:before {
  content: "\f1b9";
}
.fi-sr-calendar-clock:before {
  content: "\f1ba";
}
.fi-sr-calendar-exclamation:before {
  content: "\f1bb";
}
.fi-sr-calendar-lines-pen:before {
  content: "\f1bc";
}
.fi-sr-calendar-lines:before {
  content: "\f1bd";
}
.fi-sr-calendar-minus:before {
  content: "\f1be";
}
.fi-sr-calendar-pen:before {
  content: "\f1bf";
}
.fi-sr-calendar-plus:before {
  content: "\f1c0";
}
.fi-sr-calendar:before {
  content: "\f1c1";
}
.fi-sr-calendars:before {
  content: "\f1c2";
}
.fi-sr-call-history:before {
  content: "\f1c3";
}
.fi-sr-call-incoming:before {
  content: "\f1c4";
}
.fi-sr-call-missed:before {
  content: "\f1c5";
}
.fi-sr-call-outgoing:before {
  content: "\f1c6";
}
.fi-sr-camcorder:before {
  content: "\f1c7";
}
.fi-sr-camera:before {
  content: "\f1c8";
}
.fi-sr-camping:before {
  content: "\f1c9";
}
.fi-sr-candy-alt:before {
  content: "\f1ca";
}
.fi-sr-candy:before {
  content: "\f1cb";
}
.fi-sr-canned-food:before {
  content: "\f1cc";
}
.fi-sr-car-bolt:before {
  content: "\f1cd";
}
.fi-sr-car-circle-bolt:before {
  content: "\f1ce";
}
.fi-sr-car-side-bolt:before {
  content: "\f1cf";
}
.fi-sr-car-alt:before {
  content: "\f1d0";
}
.fi-sr-car-battery:before {
  content: "\f1d1";
}
.fi-sr-car-building:before {
  content: "\f1d2";
}
.fi-sr-car-bump:before {
  content: "\f1d3";
}
.fi-sr-car-bus:before {
  content: "\f1d4";
}
.fi-sr-car-crash:before {
  content: "\f1d5";
}
.fi-sr-car-garage:before {
  content: "\f1d6";
}
.fi-sr-car-mechanic:before {
  content: "\f1d7";
}
.fi-sr-car-side:before {
  content: "\f1d8";
}
.fi-sr-car-tilt:before {
  content: "\f1d9";
}
.fi-sr-car-wash:before {
  content: "\f1da";
}
.fi-sr-car:before {
  content: "\f1db";
}
.fi-sr-caravan-alt:before {
  content: "\f1dc";
}
.fi-sr-caravan:before {
  content: "\f1dd";
}
.fi-sr-caret-circle-down:before {
  content: "\f1de";
}
.fi-sr-caret-circle-right:before {
  content: "\f1df";
}
.fi-sr-caret-circle-up:before {
  content: "\f1e0";
}
.fi-sr-caret-down:before {
  content: "\f1e1";
}
.fi-sr-caret-left:before {
  content: "\f1e2";
}
.fi-sr-caret-quare-up:before {
  content: "\f1e3";
}
.fi-sr-caret-right:before {
  content: "\f1e4";
}
.fi-sr-caret-square-down:before {
  content: "\f1e5";
}
.fi-sr-caret-square-left_1:before {
  content: "\f1e6";
}
.fi-sr-caret-square-left:before {
  content: "\f1e7";
}
.fi-sr-caret-square-right:before {
  content: "\f1e8";
}
.fi-sr-caret-up:before {
  content: "\f1e9";
}
.fi-sr-carrot:before {
  content: "\f1ea";
}
.fi-sr-cars:before {
  content: "\f1eb";
}
.fi-sr-cash-register:before {
  content: "\f1ec";
}
.fi-sr-chair-office:before {
  content: "\f1ed";
}
.fi-sr-chair:before {
  content: "\f1ee";
}
.fi-sr-charging-station:before {
  content: "\f1ef";
}
.fi-sr-chart-area:before {
  content: "\f1f0";
}
.fi-sr-chart-connected:before {
  content: "\f1f1";
}
.fi-sr-chart-histogram:before {
  content: "\f1f2";
}
.fi-sr-chart-line-up:before {
  content: "\f1f3";
}
.fi-sr-chart-network:before {
  content: "\f1f4";
}
.fi-sr-chart-pie-alt:before {
  content: "\f1f5";
}
.fi-sr-chart-pie:before {
  content: "\f1f6";
}
.fi-sr-chart-pyramid:before {
  content: "\f1f7";
}
.fi-sr-chart-scatter:before {
  content: "\f1f8";
}
.fi-sr-chart-set-theory:before {
  content: "\f1f9";
}
.fi-sr-chart-tree:before {
  content: "\f1fa";
}
.fi-sr-chat-arrow-down:before {
  content: "\f1fb";
}
.fi-sr-chat-arrow-grow:before {
  content: "\f1fc";
}
.fi-sr-check:before {
  content: "\f1fd";
}
.fi-sr-checkbox:before {
  content: "\f1fe";
}
.fi-sr-cheese:before {
  content: "\f1ff";
}
.fi-sr-cherry:before {
  content: "\f200";
}
.fi-sr-chess-board:before {
  content: "\f201";
}
.fi-sr-chess-clock:before {
  content: "\f202";
}
.fi-sr-chess-pawn:before {
  content: "\f203";
}
.fi-sr-chess-piece:before {
  content: "\f204";
}
.fi-sr-chess-bishop:before {
  content: "\f205";
}
.fi-sr-chess-clock-alt:before {
  content: "\f206";
}
.fi-sr-chess-king-alt:before {
  content: "\f207";
}
.fi-sr-chess-king:before {
  content: "\f208";
}
.fi-sr-chess-knight-alt:before {
  content: "\f209";
}
.fi-sr-chess-knight:before {
  content: "\f20a";
}
.fi-sr-chess-pawn-alt:before {
  content: "\f20b";
}
.fi-sr-chess-queen-alt:before {
  content: "\f20c";
}
.fi-sr-chess-queen:before {
  content: "\f20d";
}
.fi-sr-chess-rook-alt:before {
  content: "\f20e";
}
.fi-sr-chess-rook:before {
  content: "\f20f";
}
.fi-sr-chess:before {
  content: "\f210";
}
.fi-sr-chevron-double-down:before {
  content: "\f211";
}
.fi-sr-chevron-double-up:before {
  content: "\f212";
}
.fi-sr-child-head:before {
  content: "\f213";
}
.fi-sr-chocolate:before {
  content: "\f214";
}
.fi-sr-circle-phone:before {
  content: "\f215";
}
.fi-sr-circle-envelope:before {
  content: "\f216";
}
.fi-sr-circle-phone-flip:before {
  content: "\f217";
}
.fi-sr-circle-phone-hangup:before {
  content: "\f218";
}
.fi-sr-circle-small:before {
  content: "\f219";
}
.fi-sr-circle:before {
  content: "\f21a";
}
.fi-sr-clip:before {
  content: "\f21b";
}
.fi-sr-clipboard-list:before {
  content: "\f21c";
}
.fi-sr-clipboard-list-check:before {
  content: "\f21d";
}
.fi-sr-clock-eight-thirty:before {
  content: "\f21e";
}
.fi-sr-clock-eleven-thirty:before {
  content: "\f21f";
}
.fi-sr-clock-eleven:before {
  content: "\f220";
}
.fi-sr-clock-five-thirty:before {
  content: "\f221";
}
.fi-sr-clock-five:before {
  content: "\f222";
}
.fi-sr-clock-four-thirty:before {
  content: "\f223";
}
.fi-sr-clock-nine-thirty:before {
  content: "\f224";
}
.fi-sr-clock-nine:before {
  content: "\f225";
}
.fi-sr-clock-one-thirty:before {
  content: "\f226";
}
.fi-sr-clock-one:before {
  content: "\f227";
}
.fi-sr-clock-seven-thirty:before {
  content: "\f228";
}
.fi-sr-clock-seven:before {
  content: "\f229";
}
.fi-sr-clock-six-thirty:before {
  content: "\f22a";
}
.fi-sr-clock-six:before {
  content: "\f22b";
}
.fi-sr-clock-ten-thirty:before {
  content: "\f22c";
}
.fi-sr-clock-ten:before {
  content: "\f22d";
}
.fi-sr-clock-three-thirty:before {
  content: "\f22e";
}
.fi-sr-clock-three:before {
  content: "\f22f";
}
.fi-sr-clock-twelve-thirty:before {
  content: "\f230";
}
.fi-sr-clock-twelve:before {
  content: "\f231";
}
.fi-sr-clock-two-thirty:before {
  content: "\f232";
}
.fi-sr-clock-two:before {
  content: "\f233";
}
.fi-sr-clock:before {
  content: "\f234";
}
.fi-sr-cloud-upload:before {
  content: "\f235";
}
.fi-sr-cloud-check:before {
  content: "\f236";
}
.fi-sr-cloud-disabled:before {
  content: "\f237";
}
.fi-sr-cloud-download-alt:before {
  content: "\f238";
}
.fi-sr-cloud-download:before {
  content: "\f239";
}
.fi-sr-cloud-drizzle:before {
  content: "\f23a";
}
.fi-sr-cloud-hail-mixed:before {
  content: "\f23b";
}
.fi-sr-cloud-hail:before {
  content: "\f23c";
}
.fi-sr-cloud-moon-rain:before {
  content: "\f23d";
}
.fi-sr-cloud-moon:before {
  content: "\f23e";
}
.fi-sr-cloud-rain:before {
  content: "\f23f";
}
.fi-sr-cloud-rainbow:before {
  content: "\f240";
}
.fi-sr-cloud-share:before {
  content: "\f241";
}
.fi-sr-cloud-showers-heavy:before {
  content: "\f242";
}
.fi-sr-cloud-showers:before {
  content: "\f243";
}
.fi-sr-cloud-sleet:before {
  content: "\f244";
}
.fi-sr-cloud-snow:before {
  content: "\f245";
}
.fi-sr-cloud-sun-rain:before {
  content: "\f246";
}
.fi-sr-cloud-sun:before {
  content: "\f247";
}
.fi-sr-cloud-upload-alt:before {
  content: "\f248";
}
.fi-sr-cloud:before {
  content: "\f249";
}
.fi-sr-clouds-moon:before {
  content: "\f24a";
}
.fi-sr-clouds-sun:before {
  content: "\f24b";
}
.fi-sr-clouds:before {
  content: "\f24c";
}
.fi-sr-club:before {
  content: "\f24d";
}
.fi-sr-cocktail-alt:before {
  content: "\f24e";
}
.fi-sr-cocktail:before {
  content: "\f24f";
}
.fi-sr-coffee-pot:before {
  content: "\f250";
}
.fi-sr-coffee:before {
  content: "\f251";
}
.fi-sr-coin:before {
  content: "\f252";
}
.fi-sr-coins:before {
  content: "\f253";
}
.fi-sr-comet:before {
  content: "\f254";
}
.fi-sr-comment-alt-middle-top:before {
  content: "\f255";
}
.fi-sr-comment-alt-middle:before {
  content: "\f256";
}
.fi-sr-comment-alt:before {
  content: "\f257";
}
.fi-sr-comment-arrow-down:before {
  content: "\f258";
}
.fi-sr-comment-arrow-up-right:before {
  content: "\f259";
}
.fi-sr-comment-arrow-up:before {
  content: "\f25a";
}
.fi-sr-comment-check:before {
  content: "\f25b";
}
.fi-sr-comment-code:before {
  content: "\f25c";
}
.fi-sr-comment-dollar:before {
  content: "\f25d";
}
.fi-sr-comment-exclamation:before {
  content: "\f25e";
}
.fi-sr-comment-heart:before {
  content: "\f25f";
}
.fi-sr-comment-image:before {
  content: "\f260";
}
.fi-sr-comment-info:before {
  content: "\f261";
}
.fi-sr-comment-pen:before {
  content: "\f262";
}
.fi-sr-comment-question:before {
  content: "\f263";
}
.fi-sr-comment-quote:before {
  content: "\f264";
}
.fi-sr-comment-slash:before {
  content: "\f265";
}
.fi-sr-comment-sms:before {
  content: "\f266";
}
.fi-sr-comment-text:before {
  content: "\f267";
}
.fi-sr-comment-user:before {
  content: "\f268";
}
.fi-sr-comment-xmark:before {
  content: "\f269";
}
.fi-sr-comment:before {
  content: "\f26a";
}
.fi-sr-comments-dollar:before {
  content: "\f26b";
}
.fi-sr-comments-question-check:before {
  content: "\f26c";
}
.fi-sr-comments-question:before {
  content: "\f26d";
}
.fi-sr-comments:before {
  content: "\f26e";
}
.fi-sr-compress-alt:before {
  content: "\f26f";
}
.fi-sr-compress:before {
  content: "\f270";
}
.fi-sr-computer:before {
  content: "\f271";
}
.fi-sr-confetti:before {
  content: "\f272";
}
.fi-sr-cookie:before {
  content: "\f273";
}
.fi-sr-copy-alt:before {
  content: "\f274";
}
.fi-sr-copy:before {
  content: "\f275";
}
.fi-sr-copyright:before {
  content: "\f276";
}
.fi-sr-corn:before {
  content: "\f277";
}
.fi-sr-cow:before {
  content: "\f278";
}
.fi-sr-cream:before {
  content: "\f279";
}
.fi-sr-credit-card:before {
  content: "\f27a";
}
.fi-sr-cricket:before {
  content: "\f27b";
}
.fi-sr-croissant:before {
  content: "\f27c";
}
.fi-sr-cross-circle:before {
  content: "\f27d";
}
.fi-sr-cross-small:before {
  content: "\f27e";
}
.fi-sr-cross:before {
  content: "\f27f";
}
.fi-sr-crown:before {
  content: "\f280";
}
.fi-sr-crystal-ball:before {
  content: "\f281";
}
.fi-sr-cube:before {
  content: "\f282";
}
.fi-sr-cupcake:before {
  content: "\f283";
}
.fi-sr-curling:before {
  content: "\f284";
}
.fi-sr-cursor-finger:before {
  content: "\f285";
}
.fi-sr-cursor-plus:before {
  content: "\f286";
}
.fi-sr-cursor-text-alt:before {
  content: "\f287";
}
.fi-sr-cursor-text:before {
  content: "\f288";
}
.fi-sr-cursor:before {
  content: "\f289";
}
.fi-sr-d:before {
  content: "\f28a";
}
.fi-sr-dart:before {
  content: "\f28b";
}
.fi-sr-dashboard:before {
  content: "\f28c";
}
.fi-sr-data-transfer:before {
  content: "\f28d";
}
.fi-sr-database:before {
  content: "\f28e";
}
.fi-sr-delete-document:before {
  content: "\f28f";
}
.fi-sr-delete-user:before {
  content: "\f290";
}
.fi-sr-delete:before {
  content: "\f291";
}
.fi-sr-democrat:before {
  content: "\f292";
}
.fi-sr-dewpoint:before {
  content: "\f293";
}
.fi-sr-diamond:before {
  content: "\f294";
}
.fi-sr-dice-alt:before {
  content: "\f295";
}
.fi-sr-dice-d10:before {
  content: "\f296";
}
.fi-sr-dice-d12:before {
  content: "\f297";
}
.fi-sr-dice-d20:before {
  content: "\f298";
}
.fi-sr-dice-d4:before {
  content: "\f299";
}
.fi-sr-dice-d6:before {
  content: "\f29a";
}
.fi-sr-dice-d8:before {
  content: "\f29b";
}
.fi-sr-dice-four:before {
  content: "\f29c";
}
.fi-sr-dice-one:before {
  content: "\f29d";
}
.fi-sr-dice-six:before {
  content: "\f29e";
}
.fi-sr-dice-three:before {
  content: "\f29f";
}
.fi-sr-dice-two:before {
  content: "\f2a0";
}
.fi-sr-dice:before {
  content: "\f2a1";
}
.fi-sr-diploma:before {
  content: "\f2a2";
}
.fi-sr-disk:before {
  content: "\f2a3";
}
.fi-sr-dizzy:before {
  content: "\f2a4";
}
.fi-sr-doctor:before {
  content: "\f2a5";
}
.fi-sr-document-signed:before {
  content: "\f2a6";
}
.fi-sr-document:before {
  content: "\f2a7";
}
.fi-sr-dollar:before {
  content: "\f2a8";
}
.fi-sr-donate:before {
  content: "\f2a9";
}
.fi-sr-down-left-and-up-right-to-center:before {
  content: "\f2aa";
}
.fi-sr-download:before {
  content: "\f2ab";
}
.fi-sr-drafting-compass:before {
  content: "\f2ac";
}
.fi-sr-dreidel:before {
  content: "\f2ad";
}
.fi-sr-drink-alt:before {
  content: "\f2ae";
}
.fi-sr-drumstick:before {
  content: "\f2af";
}
.fi-sr-duplicate:before {
  content: "\f2b0";
}
.fi-sr-e-learning:before {
  content: "\f2b1";
}
.fi-sr-e:before {
  content: "\f2b2";
}
.fi-sr-earnings:before {
  content: "\f2b3";
}
.fi-sr-eclipse-alt:before {
  content: "\f2b4";
}
.fi-sr-eclipse:before {
  content: "\f2b5";
}
.fi-sr-edit-alt:before {
  content: "\f2b6";
}
.fi-sr-edit:before {
  content: "\f2b7";
}
.fi-sr-egg-fried:before {
  content: "\f2b8";
}
.fi-sr-egg:before {
  content: "\f2b9";
}
.fi-sr-engine-warning:before {
  content: "\f2ba";
}
.fi-sr-enter:before {
  content: "\f2bb";
}
.fi-sr-entertainment:before {
  content: "\f2bc";
}
.fi-sr-envelope-ban:before {
  content: "\f2bd";
}
.fi-sr-envelope-bulk:before {
  content: "\f2be";
}
.fi-sr-envelope-download:before {
  content: "\f2bf";
}
.fi-sr-envelope-marker:before {
  content: "\f2c0";
}
.fi-sr-envelope-open-dollar:before {
  content: "\f2c1";
}
.fi-sr-envelope-open-text:before {
  content: "\f2c2";
}
.fi-sr-envelope-open:before {
  content: "\f2c3";
}
.fi-sr-envelope-plus:before {
  content: "\f2c4";
}
.fi-sr-envelope:before {
  content: "\f2c5";
}
.fi-sr-equipment:before {
  content: "\f2c6";
}
.fi-sr-euro:before {
  content: "\f2c7";
}
.fi-sr-exchange-alt:before {
  content: "\f2c8";
}
.fi-sr-exchange:before {
  content: "\f2c9";
}
.fi-sr-exclamation:before {
  content: "\f2ca";
}
.fi-sr-exit:before {
  content: "\f2cb";
}
.fi-sr-expand-arrows-alt:before {
  content: "\f2cc";
}
.fi-sr-expand-arrows:before {
  content: "\f2cd";
}
.fi-sr-expand:before {
  content: "\f2ce";
}
.fi-sr-eye-crossed:before {
  content: "\f2cf";
}
.fi-sr-eye:before {
  content: "\f2d0";
}
.fi-sr-f:before {
  content: "\f2d1";
}
.fi-sr-feather:before {
  content: "\f2d2";
}
.fi-sr-field-hockey:before {
  content: "\f2d3";
}
.fi-sr-fighter-jet:before {
  content: "\f2d4";
}
.fi-sr-file-invoice-dollar:before {
  content: "\f2d5";
}
.fi-sr-file-invoice:before {
  content: "\f2d6";
}
.fi-sr-file-ai:before {
  content: "\f2d7";
}
.fi-sr-file-chart-line:before {
  content: "\f2d8";
}
.fi-sr-file-chart-pie:before {
  content: "\f2d9";
}
.fi-sr-file-eps:before {
  content: "\f2da";
}
.fi-sr-file-psd:before {
  content: "\f2db";
}
.fi-sr-file-spreadsheet:before {
  content: "\f2dc";
}
.fi-sr-file-video:before {
  content: "\f2dd";
}
.fi-sr-file:before {
  content: "\f2de";
}
.fi-sr-fill:before {
  content: "\f2df";
}
.fi-sr-film-slash:before {
  content: "\f2e0";
}
.fi-sr-film:before {
  content: "\f2e1";
}
.fi-sr-filter-slash:before {
  content: "\f2e2";
}
.fi-sr-filter:before {
  content: "\f2e3";
}
.fi-sr-filters:before {
  content: "\f2e4";
}
.fi-sr-fingerprint:before {
  content: "\f2e5";
}
.fi-sr-fish:before {
  content: "\f2e6";
}
.fi-sr-flag-alt:before {
  content: "\f2e7";
}
.fi-sr-flag-usa:before {
  content: "\f2e8";
}
.fi-sr-flag:before {
  content: "\f2e9";
}
.fi-sr-flame:before {
  content: "\f2ea";
}
.fi-sr-flip-horizontal:before {
  content: "\f2eb";
}
.fi-sr-flower-tulip:before {
  content: "\f2ec";
}
.fi-sr-flower:before {
  content: "\f2ed";
}
.fi-sr-flushed:before {
  content: "\f2ee";
}
.fi-sr-fog:before {
  content: "\f2ef";
}
.fi-sr-folder-download:before {
  content: "\f2f0";
}
.fi-sr-folder-minus:before {
  content: "\f2f1";
}
.fi-sr-folder-times:before {
  content: "\f2f2";
}
.fi-sr-folder-tree:before {
  content: "\f2f3";
}
.fi-sr-folder-upload:before {
  content: "\f2f4";
}
.fi-sr-folder:before {
  content: "\f2f5";
}
.fi-sr-folders:before {
  content: "\f2f6";
}
.fi-sr-following:before {
  content: "\f2f7";
}
.fi-sr-football:before {
  content: "\f2f8";
}
.fi-sr-fork:before {
  content: "\f2f9";
}
.fi-sr-form:before {
  content: "\f2fa";
}
.fi-sr-forward:before {
  content: "\f2fb";
}
.fi-sr-fox:before {
  content: "\f2fc";
}
.fi-sr-french-fries:before {
  content: "\f2fd";
}
.fi-sr-frown:before {
  content: "\f2fe";
}
.fi-sr-ftp:before {
  content: "\f2ff";
}
.fi-sr-funnel-dollar:before {
  content: "\f300";
}
.fi-sr-g:before {
  content: "\f301";
}
.fi-sr-gallery:before {
  content: "\f302";
}
.fi-sr-game-board-alt:before {
  content: "\f303";
}
.fi-sr-game:before {
  content: "\f304";
}
.fi-sr-gamepad:before {
  content: "\f305";
}
.fi-sr-games:before {
  content: "\f306";
}
.fi-sr-garage-car:before {
  content: "\f307";
}
.fi-sr-garage-open:before {
  content: "\f308";
}
.fi-sr-garage:before {
  content: "\f309";
}
.fi-sr-garlic:before {
  content: "\f30a";
}
.fi-sr-gas-pump-alt:before {
  content: "\f30b";
}
.fi-sr-gas-pump-slash:before {
  content: "\f30c";
}
.fi-sr-gas-pump:before {
  content: "\f30d";
}
.fi-sr-gem:before {
  content: "\f30e";
}
.fi-sr-gif:before {
  content: "\f30f";
}
.fi-sr-gift-card:before {
  content: "\f310";
}
.fi-sr-gift:before {
  content: "\f311";
}
.fi-sr-gifts:before {
  content: "\f312";
}
.fi-sr-gingerbread-man:before {
  content: "\f313";
}
.fi-sr-glass-cheers:before {
  content: "\f314";
}
.fi-sr-glass:before {
  content: "\f315";
}
.fi-sr-glasses:before {
  content: "\f316";
}
.fi-sr-globe-alt:before {
  content: "\f317";
}
.fi-sr-globe:before {
  content: "\f318";
}
.fi-sr-golf-ball:before {
  content: "\f319";
}
.fi-sr-golf-club:before {
  content: "\f31a";
}
.fi-sr-golf:before {
  content: "\f31b";
}
.fi-sr-graduation-cap:before {
  content: "\f31c";
}
.fi-sr-grape:before {
  content: "\f31d";
}
.fi-sr-graphic-tablet:before {
  content: "\f31e";
}
.fi-sr-grid-alt:before {
  content: "\f31f";
}
.fi-sr-grid:before {
  content: "\f320";
}
.fi-sr-grill:before {
  content: "\f321";
}
.fi-sr-grimace:before {
  content: "\f322";
}
.fi-sr-grin-alt:before {
  content: "\f323";
}
.fi-sr-grin-beam-sweat:before {
  content: "\f324";
}
.fi-sr-grin-beam:before {
  content: "\f325";
}
.fi-sr-grin-hearts:before {
  content: "\f326";
}
.fi-sr-grin-squint-tears:before {
  content: "\f327";
}
.fi-sr-grin-squint:before {
  content: "\f328";
}
.fi-sr-grin-stars:before {
  content: "\f329";
}
.fi-sr-grin-tears:before {
  content: "\f32a";
}
.fi-sr-grin-tongue-squint:before {
  content: "\f32b";
}
.fi-sr-grin-tongue-wink:before {
  content: "\f32c";
}
.fi-sr-grin-tongue:before {
  content: "\f32d";
}
.fi-sr-grin-wink:before {
  content: "\f32e";
}
.fi-sr-grin:before {
  content: "\f32f";
}
.fi-sr-guitar:before {
  content: "\f330";
}
.fi-sr-gym:before {
  content: "\f331";
}
.fi-sr-h:before {
  content: "\f332";
}
.fi-sr-hamburger-soda:before {
  content: "\f333";
}
.fi-sr-hamburger:before {
  content: "\f334";
}
.fi-sr-hand-holding-box:before {
  content: "\f335";
}
.fi-sr-hand-holding-heart:before {
  content: "\f336";
}
.fi-sr-hand-holding-seeding:before {
  content: "\f337";
}
.fi-sr-handshake:before {
  content: "\f338";
}
.fi-sr-hastag:before {
  content: "\f339";
}
.fi-sr-hat-birthday:before {
  content: "\f33a";
}
.fi-sr-hat-chef:before {
  content: "\f33b";
}
.fi-sr-head-side-thinking:before {
  content: "\f33c";
}
.fi-sr-headphones:before {
  content: "\f33d";
}
.fi-sr-headset:before {
  content: "\f33e";
}
.fi-sr-heart-arrow:before {
  content: "\f33f";
}
.fi-sr-heart:before {
  content: "\f340";
}
.fi-sr-heat:before {
  content: "\f341";
}
.fi-sr-helicopter-side:before {
  content: "\f342";
}
.fi-sr-highlighter:before {
  content: "\f343";
}
.fi-sr-hiking:before {
  content: "\f344";
}
.fi-sr-hockey-mask:before {
  content: "\f345";
}
.fi-sr-hockey-puck:before {
  content: "\f346";
}
.fi-sr-hockey-sticks:before {
  content: "\f347";
}
.fi-sr-home-location-alt:before {
  content: "\f348";
}
.fi-sr-home-location:before {
  content: "\f349";
}
.fi-sr-home:before {
  content: "\f34a";
}
.fi-sr-hotdog:before {
  content: "\f34b";
}
.fi-sr-hourglass-end:before {
  content: "\f34c";
}
.fi-sr-hourglass:before {
  content: "\f34d";
}
.fi-sr-house-flood:before {
  content: "\f34e";
}
.fi-sr-hryvnia:before {
  content: "\f34f";
}
.fi-sr-humidity:before {
  content: "\f350";
}
.fi-sr-hurricane:before {
  content: "\f351";
}
.fi-sr-i:before {
  content: "\f352";
}
.fi-sr-ice-cream:before {
  content: "\f353";
}
.fi-sr-id-badge:before {
  content: "\f354";
}
.fi-sr-inbox-in:before {
  content: "\f355";
}
.fi-sr-inbox-out:before {
  content: "\f356";
}
.fi-sr-inbox:before {
  content: "\f357";
}
.fi-sr-incognito:before {
  content: "\f358";
}
.fi-sr-indent:before {
  content: "\f359";
}
.fi-sr-infinity:before {
  content: "\f35a";
}
.fi-sr-info:before {
  content: "\f35b";
}
.fi-sr-interactive:before {
  content: "\f35c";
}
.fi-sr-interlining:before {
  content: "\f35d";
}
.fi-sr-interrogation:before {
  content: "\f35e";
}
.fi-sr-italic:before {
  content: "\f35f";
}
.fi-sr-j:before {
  content: "\f360";
}
.fi-sr-jam:before {
  content: "\f361";
}
.fi-sr-jpg:before {
  content: "\f362";
}
.fi-sr-k:before {
  content: "\f363";
}
.fi-sr-kerning:before {
  content: "\f364";
}
.fi-sr-key:before {
  content: "\f365";
}
.fi-sr-keyboard:before {
  content: "\f366";
}
.fi-sr-keynote:before {
  content: "\f367";
}
.fi-sr-kiss-beam:before {
  content: "\f368";
}
.fi-sr-kiss-wink-heart:before {
  content: "\f369";
}
.fi-sr-kiss:before {
  content: "\f36a";
}
.fi-sr-kite:before {
  content: "\f36b";
}
.fi-sr-knife:before {
  content: "\f36c";
}
.fi-sr-l:before {
  content: "\f36d";
}
.fi-sr-label:before {
  content: "\f36e";
}
.fi-sr-laptop:before {
  content: "\f36f";
}
.fi-sr-lasso:before {
  content: "\f370";
}
.fi-sr-laugh-beam:before {
  content: "\f371";
}
.fi-sr-laugh-squint:before {
  content: "\f372";
}
.fi-sr-laugh-wink:before {
  content: "\f373";
}
.fi-sr-laugh:before {
  content: "\f374";
}
.fi-sr-layer-minus:before {
  content: "\f375";
}
.fi-sr-layer-plus:before {
  content: "\f376";
}
.fi-sr-layers:before {
  content: "\f377";
}
.fi-sr-layout-fluid:before {
  content: "\f378";
}
.fi-sr-leaf:before {
  content: "\f379";
}
.fi-sr-lemon:before {
  content: "\f37a";
}
.fi-sr-letter-case:before {
  content: "\f37b";
}
.fi-sr-lettuce:before {
  content: "\f37c";
}
.fi-sr-level-down-alt:before {
  content: "\f37d";
}
.fi-sr-level-down:before {
  content: "\f37e";
}
.fi-sr-level-up-alt:before {
  content: "\f37f";
}
.fi-sr-level-up:before {
  content: "\f380";
}
.fi-sr-life-ring:before {
  content: "\f381";
}
.fi-sr-lightbulb-dollar:before {
  content: "\f382";
}
.fi-sr-line-width:before {
  content: "\f383";
}
.fi-sr-link-alt:before {
  content: "\f384";
}
.fi-sr-link-slash-alt:before {
  content: "\f385";
}
.fi-sr-link-slash:before {
  content: "\f386";
}
.fi-sr-link:before {
  content: "\f387";
}
.fi-sr-lipstick:before {
  content: "\f388";
}
.fi-sr-lira-sign:before {
  content: "\f389";
}
.fi-sr-list-check:before {
  content: "\f38a";
}
.fi-sr-list:before {
  content: "\f38b";
}
.fi-sr-loading:before {
  content: "\f38c";
}
.fi-sr-location-alt:before {
  content: "\f38d";
}
.fi-sr-lock-alt:before {
  content: "\f38e";
}
.fi-sr-lock:before {
  content: "\f38f";
}
.fi-sr-luchador:before {
  content: "\f390";
}
.fi-sr-luggage-rolling:before {
  content: "\f391";
}
.fi-sr-m:before {
  content: "\f392";
}
.fi-sr-mailbox:before {
  content: "\f393";
}
.fi-sr-makeup-brush:before {
  content: "\f394";
}
.fi-sr-man-head:before {
  content: "\f395";
}
.fi-sr-map-marker-cross:before {
  content: "\f396";
}
.fi-sr-map-marker-home:before {
  content: "\f397";
}
.fi-sr-map-marker-minus:before {
  content: "\f398";
}
.fi-sr-map-marker-plus:before {
  content: "\f399";
}
.fi-sr-map-marker:before {
  content: "\f39a";
}
.fi-sr-map:before {
  content: "\f39b";
}
.fi-sr-marker-time:before {
  content: "\f39c";
}
.fi-sr-marker:before {
  content: "\f39d";
}
.fi-sr-mars-double:before {
  content: "\f39e";
}
.fi-sr-mars:before {
  content: "\f39f";
}
.fi-sr-mask-carnival:before {
  content: "\f3a0";
}
.fi-sr-medicine:before {
  content: "\f3a1";
}
.fi-sr-megaphone:before {
  content: "\f3a2";
}
.fi-sr-meh-blank:before {
  content: "\f3a3";
}
.fi-sr-meh-rolling-eyes:before {
  content: "\f3a4";
}
.fi-sr-meh:before {
  content: "\f3a5";
}
.fi-sr-melon:before {
  content: "\f3a6";
}
.fi-sr-menu-burger:before {
  content: "\f3a7";
}
.fi-sr-menu-dots-vertical:before {
  content: "\f3a8";
}
.fi-sr-menu-dots:before {
  content: "\f3a9";
}
.fi-sr-meteor:before {
  content: "\f3aa";
}
.fi-sr-microphone-alt:before {
  content: "\f3ab";
}
.fi-sr-microphone:before {
  content: "\f3ac";
}
.fi-sr-mind-share:before {
  content: "\f3ad";
}
.fi-sr-minus-small:before {
  content: "\f3ae";
}
.fi-sr-minus:before {
  content: "\f3af";
}
.fi-sr-mobile-button:before {
  content: "\f3b0";
}
.fi-sr-mobile-notch:before {
  content: "\f3b1";
}
.fi-sr-mobile:before {
  content: "\f3b2";
}
.fi-sr-mode-landscape:before {
  content: "\f3b3";
}
.fi-sr-mode-portrait:before {
  content: "\f3b4";
}
.fi-sr-money-bill-wave-alt:before {
  content: "\f3b5";
}
.fi-sr-money-bill-wave:before {
  content: "\f3b6";
}
.fi-sr-money-check:before {
  content: "\f3b7";
}
.fi-sr-money-check-edit-alt:before {
  content: "\f3b8";
}
.fi-sr-money-check-edit:before {
  content: "\f3b9";
}
.fi-sr-money:before {
  content: "\f3ba";
}
.fi-sr-moon-stars:before {
  content: "\f3bb";
}
.fi-sr-moon:before {
  content: "\f3bc";
}
.fi-sr-motorcycle:before {
  content: "\f3bd";
}
.fi-sr-mountains:before {
  content: "\f3be";
}
.fi-sr-mouse:before {
  content: "\f3bf";
}
.fi-sr-mug-alt:before {
  content: "\f3c0";
}
.fi-sr-mug-hot-alt:before {
  content: "\f3c1";
}
.fi-sr-mug-hot:before {
  content: "\f3c2";
}
.fi-sr-mug-tea:before {
  content: "\f3c3";
}
.fi-sr-mug:before {
  content: "\f3c4";
}
.fi-sr-mushroom:before {
  content: "\f3c5";
}
.fi-sr-music-file:before {
  content: "\f3c6";
}
.fi-sr-music-alt:before {
  content: "\f3c7";
}
.fi-sr-music:before {
  content: "\f3c8";
}
.fi-sr-n:before {
  content: "\f3c9";
}
.fi-sr-navigation:before {
  content: "\f3ca";
}
.fi-sr-network-cloud:before {
  content: "\f3cb";
}
.fi-sr-network:before {
  content: "\f3cc";
}
.fi-sr-noodles:before {
  content: "\f3cd";
}
.fi-sr-notebook:before {
  content: "\f3ce";
}
.fi-sr-o:before {
  content: "\f3cf";
}
.fi-sr-oil-can:before {
  content: "\f3d0";
}
.fi-sr-oil-temp:before {
  content: "\f3d1";
}
.fi-sr-olive-oil:before {
  content: "\f3d2";
}
.fi-sr-olives:before {
  content: "\f3d3";
}
.fi-sr-onion:before {
  content: "\f3d4";
}
.fi-sr-opacity:before {
  content: "\f3d5";
}
.fi-sr-overline:before {
  content: "\f3d6";
}
.fi-sr-p:before {
  content: "\f3d7";
}
.fi-sr-package:before {
  content: "\f3d8";
}
.fi-sr-page-break:before {
  content: "\f3d9";
}
.fi-sr-paint-roller:before {
  content: "\f3da";
}
.fi-sr-paint:before {
  content: "\f3db";
}
.fi-sr-palette:before {
  content: "\f3dc";
}
.fi-sr-pan:before {
  content: "\f3dd";
}
.fi-sr-paper-plane:before {
  content: "\f3de";
}
.fi-sr-party:before {
  content: "\f3df";
}
.fi-sr-password:before {
  content: "\f3e0";
}
.fi-sr-pause:before {
  content: "\f3e1";
}
.fi-sr-paw:before {
  content: "\f3e2";
}
.fi-sr-peach:before {
  content: "\f3e3";
}
.fi-sr-pencil-ruler:before {
  content: "\f3e4";
}
.fi-sr-pencil:before {
  content: "\f3e5";
}
.fi-sr-pennant:before {
  content: "\f3e6";
}
.fi-sr-people-poll:before {
  content: "\f3e7";
}
.fi-sr-pepper-hot:before {
  content: "\f3e8";
}
.fi-sr-pepper:before {
  content: "\f3e9";
}
.fi-sr-percentage:before {
  content: "\f3ea";
}
.fi-sr-pharmacy:before {
  content: "\f3eb";
}
.fi-sr-phone-office:before {
  content: "\f3ec";
}
.fi-sr-phone-call:before {
  content: "\f3ed";
}
.fi-sr-phone-cross:before {
  content: "\f3ee";
}
.fi-sr-phone-pause:before {
  content: "\f3ef";
}
.fi-sr-phone-slash:before {
  content: "\f3f0";
}
.fi-sr-photo-film-music:before {
  content: "\f3f1";
}
.fi-sr-photo-video:before {
  content: "\f3f2";
}
.fi-sr-physics:before {
  content: "\f3f3";
}
.fi-sr-picnic:before {
  content: "\f3f4";
}
.fi-sr-picture:before {
  content: "\f3f5";
}
.fi-sr-pie:before {
  content: "\f3f6";
}
.fi-sr-piggy-bank:before {
  content: "\f3f7";
}
.fi-sr-pineapple:before {
  content: "\f3f8";
}
.fi-sr-pipette:before {
  content: "\f3f9";
}
.fi-sr-pizza-slice:before {
  content: "\f3fa";
}
.fi-sr-plane-alt:before {
  content: "\f3fb";
}
.fi-sr-plane:before {
  content: "\f3fc";
}
.fi-sr-plate:before {
  content: "\f3fd";
}
.fi-sr-play-alt:before {
  content: "\f3fe";
}
.fi-sr-play-pause:before {
  content: "\f3ff";
}
.fi-sr-play:before {
  content: "\f400";
}
.fi-sr-plus-small:before {
  content: "\f401";
}
.fi-sr-plus:before {
  content: "\f402";
}
.fi-sr-podium-star:before {
  content: "\f403";
}
.fi-sr-podium:before {
  content: "\f404";
}
.fi-sr-poo:before {
  content: "\f405";
}
.fi-sr-popcorn:before {
  content: "\f406";
}
.fi-sr-portrait:before {
  content: "\f407";
}
.fi-sr-pot:before {
  content: "\f408";
}
.fi-sr-pound:before {
  content: "\f409";
}
.fi-sr-power:before {
  content: "\f40a";
}
.fi-sr-presentation:before {
  content: "\f40b";
}
.fi-sr-print:before {
  content: "\f40c";
}
.fi-sr-protractor:before {
  content: "\f40d";
}
.fi-sr-pulse:before {
  content: "\f40e";
}
.fi-sr-pumpkin:before {
  content: "\f40f";
}
.fi-sr-puzzle-piece:before {
  content: "\f410";
}
.fi-sr-pyramid:before {
  content: "\f411";
}
.fi-sr-q:before {
  content: "\f412";
}
.fi-sr-question-square:before {
  content: "\f413";
}
.fi-sr-question:before {
  content: "\f414";
}
.fi-sr-quote-right:before {
  content: "\f415";
}
.fi-sr-r:before {
  content: "\f416";
}
.fi-sr-racquet:before {
  content: "\f417";
}
.fi-sr-radish:before {
  content: "\f418";
}
.fi-sr-rainbow:before {
  content: "\f419";
}
.fi-sr-raindrops:before {
  content: "\f41a";
}
.fi-sr-rec:before {
  content: "\f41b";
}
.fi-sr-receipt:before {
  content: "\f41c";
}
.fi-sr-record-vinyl:before {
  content: "\f41d";
}
.fi-sr-rectabgle-vertical:before {
  content: "\f41e";
}
.fi-sr-rectangle-horizontal:before {
  content: "\f41f";
}
.fi-sr-rectangle-panoramic:before {
  content: "\f420";
}
.fi-sr-recycle:before {
  content: "\f421";
}
.fi-sr-redo-alt:before {
  content: "\f422";
}
.fi-sr-redo:before {
  content: "\f423";
}
.fi-sr-reflect:before {
  content: "\f424";
}
.fi-sr-refresh:before {
  content: "\f425";
}
.fi-sr-registered:before {
  content: "\f426";
}
.fi-sr-remove-user:before {
  content: "\f427";
}
.fi-sr-reply-all:before {
  content: "\f428";
}
.fi-sr-republican:before {
  content: "\f429";
}
.fi-sr-resize:before {
  content: "\f42a";
}
.fi-sr-resources:before {
  content: "\f42b";
}
.fi-sr-restaurant:before {
  content: "\f42c";
}
.fi-sr-rewind:before {
  content: "\f42d";
}
.fi-sr-rhombus:before {
  content: "\f42e";
}
.fi-sr-rings-wedding:before {
  content: "\f42f";
}
.fi-sr-road:before {
  content: "\f430";
}
.fi-sr-rocket-lunch:before {
  content: "\f431";
}
.fi-sr-rocket:before {
  content: "\f432";
}
.fi-sr-room-service:before {
  content: "\f433";
}
.fi-sr-rotate-left:before {
  content: "\f434";
}
.fi-sr-rotate-right:before {
  content: "\f435";
}
.fi-sr-ruble-sign:before {
  content: "\f436";
}
.fi-sr-rugby-helmet:before {
  content: "\f437";
}
.fi-sr-rugby:before {
  content: "\f438";
}
.fi-sr-ruler-combined:before {
  content: "\f439";
}
.fi-sr-ruler-horizontal:before {
  content: "\f43a";
}
.fi-sr-ruler-triangle:before {
  content: "\f43b";
}
.fi-sr-ruler-vertical:before {
  content: "\f43c";
}
.fi-sr-running:before {
  content: "\f43d";
}
.fi-sr-rupee-sign:before {
  content: "\f43e";
}
.fi-sr-rv:before {
  content: "\f43f";
}
.fi-sr-s:before {
  content: "\f440";
}
.fi-sr-sack-dollar:before {
  content: "\f441";
}
.fi-sr-sack:before {
  content: "\f442";
}
.fi-sr-sad-cry:before {
  content: "\f443";
}
.fi-sr-sad-tear:before {
  content: "\f444";
}
.fi-sr-sad:before {
  content: "\f445";
}
.fi-sr-salad:before {
  content: "\f446";
}
.fi-sr-salt-pepper:before {
  content: "\f447";
}
.fi-sr-sandwich:before {
  content: "\f448";
}
.fi-sr-sauce:before {
  content: "\f449";
}
.fi-sr-sausage:before {
  content: "\f44a";
}
.fi-sr-scale:before {
  content: "\f44b";
}
.fi-sr-school-bus:before {
  content: "\f44c";
}
.fi-sr-school:before {
  content: "\f44d";
}
.fi-sr-scissors:before {
  content: "\f44e";
}
.fi-sr-screen:before {
  content: "\f44f";
}
.fi-sr-search-dollar:before {
  content: "\f450";
}
.fi-sr-search-location:before {
  content: "\f451";
}
.fi-sr-search-alt:before {
  content: "\f452";
}
.fi-sr-search-heart:before {
  content: "\f453";
}
.fi-sr-search:before {
  content: "\f454";
}
.fi-sr-select:before {
  content: "\f455";
}
.fi-sr-sensor-alert:before {
  content: "\f456";
}
.fi-sr-sensor-fire:before {
  content: "\f457";
}
.fi-sr-sensor-on:before {
  content: "\f458";
}
.fi-sr-sensor-smoke:before {
  content: "\f459";
}
.fi-sr-sensor:before {
  content: "\f45a";
}
.fi-sr-settings-sliders:before {
  content: "\f45b";
}
.fi-sr-settings:before {
  content: "\f45c";
}
.fi-sr-share:before {
  content: "\f45d";
}
.fi-sr-shekel-sign:before {
  content: "\f45e";
}
.fi-sr-shield-check:before {
  content: "\f45f";
}
.fi-sr-shield-exclamation:before {
  content: "\f460";
}
.fi-sr-shield-interrogation:before {
  content: "\f461";
}
.fi-sr-shield-plus:before {
  content: "\f462";
}
.fi-sr-shield:before {
  content: "\f463";
}
.fi-sr-ship-side:before {
  content: "\f464";
}
.fi-sr-ship:before {
  content: "\f465";
}
.fi-sr-shop:before {
  content: "\f466";
}
.fi-sr-shopping-basket:before {
  content: "\f467";
}
.fi-sr-shopping-bag-add:before {
  content: "\f468";
}
.fi-sr-shopping-bag:before {
  content: "\f469";
}
.fi-sr-shopping-cart-add:before {
  content: "\f46a";
}
.fi-sr-shopping-cart-check:before {
  content: "\f46b";
}
.fi-sr-shopping-cart:before {
  content: "\f46c";
}
.fi-sr-shrimp:before {
  content: "\f46d";
}
.fi-sr-shuffle:before {
  content: "\f46e";
}
.fi-sr-shuttle-van:before {
  content: "\f46f";
}
.fi-sr-shuttlecock:before {
  content: "\f470";
}
.fi-sr-sign-in-alt:before {
  content: "\f471";
}
.fi-sr-sign-out-alt:before {
  content: "\f472";
}
.fi-sr-signal-alt-1:before {
  content: "\f473";
}
.fi-sr-signal-alt-2:before {
  content: "\f474";
}
.fi-sr-signal-alt:before {
  content: "\f475";
}
.fi-sr-skateboard:before {
  content: "\f476";
}
.fi-sr-skating:before {
  content: "\f477";
}
.fi-sr-skewer:before {
  content: "\f478";
}
.fi-sr-ski-jump:before {
  content: "\f479";
}
.fi-sr-ski-lift:before {
  content: "\f47a";
}
.fi-sr-skiing-nordic:before {
  content: "\f47b";
}
.fi-sr-skiing:before {
  content: "\f47c";
}
.fi-sr-sledding:before {
  content: "\f47d";
}
.fi-sr-sleigh:before {
  content: "\f47e";
}
.fi-sr-smartphone:before {
  content: "\f47f";
}
.fi-sr-smile-beam:before {
  content: "\f480";
}
.fi-sr-smile-wink:before {
  content: "\f481";
}
.fi-sr-smile:before {
  content: "\f482";
}
.fi-sr-smog:before {
  content: "\f483";
}
.fi-sr-smoke:before {
  content: "\f484";
}
.fi-sr-snow-blowing:before {
  content: "\f485";
}
.fi-sr-snowboarding:before {
  content: "\f486";
}
.fi-sr-snowflake:before {
  content: "\f487";
}
.fi-sr-snowflakes:before {
  content: "\f488";
}
.fi-sr-snowmobile:before {
  content: "\f489";
}
.fi-sr-snowplow:before {
  content: "\f48a";
}
.fi-sr-soap:before {
  content: "\f48b";
}
.fi-sr-sort-alpha-down-alt:before {
  content: "\f48c";
}
.fi-sr-sort-alpha-down:before {
  content: "\f48d";
}
.fi-sr-sort-alpha-up-alt:before {
  content: "\f48e";
}
.fi-sr-sort-alpha-up:before {
  content: "\f48f";
}
.fi-sr-sort-alt:before {
  content: "\f490";
}
.fi-sr-sort-amount-down-alt:before {
  content: "\f491";
}
.fi-sr-sort-amount-down:before {
  content: "\f492";
}
.fi-sr-sort-amount-up-alt:before {
  content: "\f493";
}
.fi-sr-sort-amount-up:before {
  content: "\f494";
}
.fi-sr-sort-down:before {
  content: "\f495";
}
.fi-sr-sort-numeric-down-alt:before {
  content: "\f496";
}
.fi-sr-sort-numeric-down:before {
  content: "\f497";
}
.fi-sr-sort:before {
  content: "\f498";
}
.fi-sr-soup:before {
  content: "\f499";
}
.fi-sr-spa:before {
  content: "\f49a";
}
.fi-sr-space-shuttle:before {
  content: "\f49b";
}
.fi-sr-spade:before {
  content: "\f49c";
}
.fi-sr-sparkles:before {
  content: "\f49d";
}
.fi-sr-speaker:before {
  content: "\f49e";
}
.fi-sr-sphere:before {
  content: "\f49f";
}
.fi-sr-spinner:before {
  content: "\f4a0";
}
.fi-sr-spoon:before {
  content: "\f4a1";
}
.fi-sr-square-root:before {
  content: "\f4a2";
}
.fi-sr-square:before {
  content: "\f4a3";
}
.fi-sr-stamp:before {
  content: "\f4a4";
}
.fi-sr-star-octogram:before {
  content: "\f4a5";
}
.fi-sr-star:before {
  content: "\f4a6";
}
.fi-sr-starfighter:before {
  content: "\f4a7";
}
.fi-sr-stars:before {
  content: "\f4a8";
}
.fi-sr-stats:before {
  content: "\f4a9";
}
.fi-sr-steak:before {
  content: "\f4aa";
}
.fi-sr-steering-wheel:before {
  content: "\f4ab";
}
.fi-sr-stethoscope:before {
  content: "\f4ac";
}
.fi-sr-sticker:before {
  content: "\f4ad";
}
.fi-sr-stop:before {
  content: "\f4ae";
}
.fi-sr-stopwatch:before {
  content: "\f4af";
}
.fi-sr-store-alt:before {
  content: "\f4b0";
}
.fi-sr-strawberry:before {
  content: "\f4b1";
}
.fi-sr-subtitles:before {
  content: "\f4b2";
}
.fi-sr-subway:before {
  content: "\f4b3";
}
.fi-sr-suitcase-alt:before {
  content: "\f4b4";
}
.fi-sr-summer:before {
  content: "\f4b5";
}
.fi-sr-sun:before {
  content: "\f4b6";
}
.fi-sr-sunrise-alt:before {
  content: "\f4b7";
}
.fi-sr-sunrise:before {
  content: "\f4b8";
}
.fi-sr-sunset:before {
  content: "\f4b9";
}
.fi-sr-surfing:before {
  content: "\f4ba";
}
.fi-sr-surprise:before {
  content: "\f4bb";
}
.fi-sr-sushi:before {
  content: "\f4bc";
}
.fi-sr-swimmer:before {
  content: "\f4bd";
}
.fi-sr-sword:before {
  content: "\f4be";
}
.fi-sr-symbol:before {
  content: "\f4bf";
}
.fi-sr-syringe:before {
  content: "\f4c0";
}
.fi-sr-t:before {
  content: "\f4c1";
}
.fi-sr-tablet:before {
  content: "\f4c2";
}
.fi-sr-tachometer-alt-average:before {
  content: "\f4c3";
}
.fi-sr-tachometer-alt-fastest:before {
  content: "\f4c4";
}
.fi-sr-tachometer-alt-slow:before {
  content: "\f4c5";
}
.fi-sr-tachometer-alt-slowest:before {
  content: "\f4c6";
}
.fi-sr-tachometer-average:before {
  content: "\f4c7";
}
.fi-sr-tachometer-fast:before {
  content: "\f4c8";
}
.fi-sr-tachometer-fastest:before {
  content: "\f4c9";
}
.fi-sr-tachometer-slow:before {
  content: "\f4ca";
}
.fi-sr-tachometer-slowest:before {
  content: "\f4cb";
}
.fi-sr-tachometer:before {
  content: "\f4cc";
}
.fi-sr-taco:before {
  content: "\f4cd";
}
.fi-sr-tags:before {
  content: "\f4ce";
}
.fi-sr-tally:before {
  content: "\f4cf";
}
.fi-sr-target:before {
  content: "\f4d0";
}
.fi-sr-taxi:before {
  content: "\f4d1";
}
.fi-sr-temperature-down:before {
  content: "\f4d2";
}
.fi-sr-temperature-frigid:before {
  content: "\f4d3";
}
.fi-sr-temperature-high:before {
  content: "\f4d4";
}
.fi-sr-temperature-low:before {
  content: "\f4d5";
}
.fi-sr-temperature-up:before {
  content: "\f4d6";
}
.fi-sr-tenge:before {
  content: "\f4d7";
}
.fi-sr-tennis:before {
  content: "\f4d8";
}
.fi-sr-terrace:before {
  content: "\f4d9";
}
.fi-sr-test-tube:before {
  content: "\f4da";
}
.fi-sr-test:before {
  content: "\f4db";
}
.fi-sr-text-check:before {
  content: "\f4dc";
}
.fi-sr-text-slash:before {
  content: "\f4dd";
}
.fi-sr-text:before {
  content: "\f4de";
}
.fi-sr-thermometer-half:before {
  content: "\f4df";
}
.fi-sr-thumbs-down:before {
  content: "\f4e0";
}
.fi-sr-thumbs-up:before {
  content: "\f4e1";
}
.fi-sr-thumbtack:before {
  content: "\f4e2";
}
.fi-sr-thunderstorm-moon:before {
  content: "\f4e3";
}
.fi-sr-thunderstorm-sun:before {
  content: "\f4e4";
}
.fi-sr-thunderstorm:before {
  content: "\f4e5";
}
.fi-sr-ticket:before {
  content: "\f4e6";
}
.fi-sr-time-quarter-past:before {
  content: "\f4e7";
}
.fi-sr-time-add:before {
  content: "\f4e8";
}
.fi-sr-time-check:before {
  content: "\f4e9";
}
.fi-sr-time-delete:before {
  content: "\f4ea";
}
.fi-sr-time-fast:before {
  content: "\f4eb";
}
.fi-sr-time-forward-sixty:before {
  content: "\f4ec";
}
.fi-sr-time-forward-ten:before {
  content: "\f4ed";
}
.fi-sr-time-forward:before {
  content: "\f4ee";
}
.fi-sr-time-half-past:before {
  content: "\f4ef";
}
.fi-sr-time-oclock:before {
  content: "\f4f0";
}
.fi-sr-time-past:before {
  content: "\f4f1";
}
.fi-sr-time-quarter-to:before {
  content: "\f4f2";
}
.fi-sr-time-twenty-four:before {
  content: "\f4f3";
}
.fi-sr-tire-flat:before {
  content: "\f4f4";
}
.fi-sr-tire-pressure-warning:before {
  content: "\f4f5";
}
.fi-sr-tire-rugged:before {
  content: "\f4f6";
}
.fi-sr-tire:before {
  content: "\f4f7";
}
.fi-sr-tired:before {
  content: "\f4f8";
}
.fi-sr-tomato:before {
  content: "\f4f9";
}
.fi-sr-tool-box:before {
  content: "\f4fa";
}
.fi-sr-tool-crop:before {
  content: "\f4fb";
}
.fi-sr-tool-marquee:before {
  content: "\f4fc";
}
.fi-sr-tooth:before {
  content: "\f4fd";
}
.fi-sr-tornado:before {
  content: "\f4fe";
}
.fi-sr-tractor:before {
  content: "\f4ff";
}
.fi-sr-trailer:before {
  content: "\f500";
}
.fi-sr-train-side:before {
  content: "\f501";
}
.fi-sr-train:before {
  content: "\f502";
}
.fi-sr-tram:before {
  content: "\f503";
}
.fi-sr-transform:before {
  content: "\f504";
}
.fi-sr-trash:before {
  content: "\f505";
}
.fi-sr-treasure-chest:before {
  content: "\f506";
}
.fi-sr-treatment:before {
  content: "\f507";
}
.fi-sr-tree-christmas:before {
  content: "\f508";
}
.fi-sr-tree:before {
  content: "\f509";
}
.fi-sr-triangle:before {
  content: "\f50a";
}
.fi-sr-trophy:before {
  content: "\f50b";
}
.fi-sr-truck-container:before {
  content: "\f50c";
}
.fi-sr-truck-couch:before {
  content: "\f50d";
}
.fi-sr-truck-loading:before {
  content: "\f50e";
}
.fi-sr-truck-monster:before {
  content: "\f50f";
}
.fi-sr-truck-moving:before {
  content: "\f510";
}
.fi-sr-truck-pickup:before {
  content: "\f511";
}
.fi-sr-truck-plow:before {
  content: "\f512";
}
.fi-sr-truck-ramp:before {
  content: "\f513";
}
.fi-sr-truck-side:before {
  content: "\f514";
}
.fi-sr-tty:before {
  content: "\f515";
}
.fi-sr-turkey:before {
  content: "\f516";
}
.fi-sr-tv-music:before {
  content: "\f517";
}
.fi-sr-typewriter:before {
  content: "\f518";
}
.fi-sr-u:before {
  content: "\f519";
}
.fi-sr-umbrella:before {
  content: "\f51a";
}
.fi-sr-underline:before {
  content: "\f51b";
}
.fi-sr-undo-alt:before {
  content: "\f51c";
}
.fi-sr-undo:before {
  content: "\f51d";
}
.fi-sr-unlock:before {
  content: "\f51e";
}
.fi-sr-upload:before {
  content: "\f51f";
}
.fi-sr-usb-pendrive:before {
  content: "\f520";
}
.fi-sr-usd-circle:before {
  content: "\f521";
}
.fi-sr-usd-square:before {
  content: "\f522";
}
.fi-sr-user-add:before {
  content: "\f523";
}
.fi-sr-user-time:before {
  content: "\f524";
}
.fi-sr-user:before {
  content: "\f525";
}
.fi-sr-users-alt:before {
  content: "\f526";
}
.fi-sr-users:before {
  content: "\f527";
}
.fi-sr-utensils:before {
  content: "\f528";
}
.fi-sr-v:before {
  content: "\f529";
}
.fi-sr-vector-alt:before {
  content: "\f52a";
}
.fi-sr-vector:before {
  content: "\f52b";
}
.fi-sr-venus-double:before {
  content: "\f52c";
}
.fi-sr-venus-mars:before {
  content: "\f52d";
}
.fi-sr-venus:before {
  content: "\f52e";
}
.fi-sr-video-arrow-down-left:before {
  content: "\f52f";
}
.fi-sr-video-arrow-up-right:before {
  content: "\f530";
}
.fi-sr-video-camera-alt:before {
  content: "\f531";
}
.fi-sr-video-camera:before {
  content: "\f532";
}
.fi-sr-video-plus:before {
  content: "\f533";
}
.fi-sr-video-slash:before {
  content: "\f534";
}
.fi-sr-volcano:before {
  content: "\f535";
}
.fi-sr-volleyball:before {
  content: "\f536";
}
.fi-sr-volume:before {
  content: "\f537";
}
.fi-sr-w:before {
  content: "\f538";
}
.fi-sr-wagon-covered:before {
  content: "\f539";
}
.fi-sr-wallet:before {
  content: "\f53a";
}
.fi-sr-water-bottle:before {
  content: "\f53b";
}
.fi-sr-water-lower:before {
  content: "\f53c";
}
.fi-sr-water-rise:before {
  content: "\f53d";
}
.fi-sr-water:before {
  content: "\f53e";
}
.fi-sr-watermelon:before {
  content: "\f53f";
}
.fi-sr-wheat:before {
  content: "\f540";
}
.fi-sr-wheelchair:before {
  content: "\f541";
}
.fi-sr-whistle:before {
  content: "\f542";
}
.fi-sr-wifi-alt:before {
  content: "\f543";
}
.fi-sr-wind-warning:before {
  content: "\f544";
}
.fi-sr-wind:before {
  content: "\f545";
}
.fi-sr-windsock:before {
  content: "\f546";
}
.fi-sr-woman-head:before {
  content: "\f547";
}
.fi-sr-world:before {
  content: "\f548";
}
.fi-sr-x:before {
  content: "\f549";
}
.fi-sr-y:before {
  content: "\f54a";
}
.fi-sr-yen:before {
  content: "\f54b";
}
.fi-sr-z:before {
  content: "\f54c";
}
.fi-sr-zoom-in:before {
  content: "\f54d";
}
.fi-sr-zoom-out:before {
  content: "\f54e";
}

@font-face {
  font-family: "uicons-solid-straight";
  src: url("../fonts/uicons-solid-straight.eot#iefix")
      format("embedded-opentype"),
    url("../fonts/uicons-solid-straight.woff2") format("woff2"),
    url("../fonts/uicons-solid-straight.woff") format("woff");
}

i[class^="fi-ss-"]:before,
i[class*=" fi-ss-"]:before,
span[class^="fi-ss-"]:before,
span[class*="fi-ss-"]:before {
  font-family: uicons-solid-straight !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi-ss-a:before {
  content: "\f101";
}
.fi-ss-ad:before {
  content: "\f102";
}
.fi-ss-add-document:before {
  content: "\f103";
}
.fi-ss-add-folder:before {
  content: "\f104";
}
.fi-ss-add:before {
  content: "\f105";
}
.fi-ss-address-book:before {
  content: "\f106";
}
.fi-ss-air-conditioner:before {
  content: "\f107";
}
.fi-ss-air-freshener:before {
  content: "\f108";
}
.fi-ss-alarm-clock:before {
  content: "\f109";
}
.fi-ss-alarm-exclamation:before {
  content: "\f10a";
}
.fi-ss-alarm-plus:before {
  content: "\f10b";
}
.fi-ss-alarm-snooze:before {
  content: "\f10c";
}
.fi-ss-align-justify:before {
  content: "\f10d";
}
.fi-ss-align-left:before {
  content: "\f10e";
}
.fi-ss-ambulance:before {
  content: "\f10f";
}
.fi-ss-amusement-park:before {
  content: "\f110";
}
.fi-ss-angle-circle-down:before {
  content: "\f111";
}
.fi-ss-angle-circle-left:before {
  content: "\f112";
}
.fi-ss-angle-circle-right:before {
  content: "\f113";
}
.fi-ss-angle-circle-up:before {
  content: "\f114";
}
.fi-ss-angle-double-left:before {
  content: "\f115";
}
.fi-ss-angle-double-right:before {
  content: "\f116";
}
.fi-ss-angle-double-small-down:before {
  content: "\f117";
}
.fi-ss-angle-double-small-left:before {
  content: "\f118";
}
.fi-ss-angle-double-small-right:before {
  content: "\f119";
}
.fi-ss-angle-double-small-up:before {
  content: "\f11a";
}
.fi-ss-angle-down:before {
  content: "\f11b";
}
.fi-ss-angle-left:before {
  content: "\f11c";
}
.fi-ss-angle-right:before {
  content: "\f11d";
}
.fi-ss-angle-small-down:before {
  content: "\f11e";
}
.fi-ss-angle-small-left:before {
  content: "\f11f";
}
.fi-ss-angle-small-right:before {
  content: "\f120";
}
.fi-ss-angle-small-up:before {
  content: "\f121";
}
.fi-ss-angle-square-down:before {
  content: "\f122";
}
.fi-ss-angle-square-left:before {
  content: "\f123";
}
.fi-ss-angle-square-right:before {
  content: "\f124";
}
.fi-ss-angle-square-up:before {
  content: "\f125";
}
.fi-ss-angle-up:before {
  content: "\f126";
}
.fi-ss-angry:before {
  content: "\f127";
}
.fi-ss-apple-whole:before {
  content: "\f128";
}
.fi-ss-apps-add:before {
  content: "\f129";
}
.fi-ss-apps-delete:before {
  content: "\f12a";
}
.fi-ss-apps-sort:before {
  content: "\f12b";
}
.fi-ss-apps:before {
  content: "\f12c";
}
.fi-ss-archive:before {
  content: "\f12d";
}
.fi-ss-arrow-alt-circle-down:before {
  content: "\f12e";
}
.fi-ss-arrow-alt-circle-left:before {
  content: "\f12f";
}
.fi-ss-arrow-alt-circle-right:before {
  content: "\f130";
}
.fi-ss-arrow-alt-circle-up:before {
  content: "\f131";
}
.fi-ss-arrow-alt-down:before {
  content: "\f132";
}
.fi-ss-arrow-alt-from-bottom:before {
  content: "\f133";
}
.fi-ss-arrow-alt-from-left:before {
  content: "\f134";
}
.fi-ss-arrow-alt-from-right:before {
  content: "\f135";
}
.fi-ss-arrow-alt-from-top:before {
  content: "\f136";
}
.fi-ss-arrow-alt-left:before {
  content: "\f137";
}
.fi-ss-arrow-alt-right:before {
  content: "\f138";
}
.fi-ss-arrow-alt-square-down:before {
  content: "\f139";
}
.fi-ss-arrow-alt-square-left:before {
  content: "\f13a";
}
.fi-ss-arrow-alt-square-right:before {
  content: "\f13b";
}
.fi-ss-arrow-alt-square-up:before {
  content: "\f13c";
}
.fi-ss-arrow-alt-to-bottom:before {
  content: "\f13d";
}
.fi-ss-arrow-alt-to-left:before {
  content: "\f13e";
}
.fi-ss-arrow-alt-to-right:before {
  content: "\f13f";
}
.fi-ss-arrow-alt-to-top:before {
  content: "\f140";
}
.fi-ss-arrow-alt-up:before {
  content: "\f141";
}
.fi-ss-arrow-circle-down:before {
  content: "\f142";
}
.fi-ss-arrow-circle-left:before {
  content: "\f143";
}
.fi-ss-arrow-circle-right:before {
  content: "\f144";
}
.fi-ss-arrow-circle-up:before {
  content: "\f145";
}
.fi-ss-arrow-down:before {
  content: "\f146";
}
.fi-ss-arrow-from-bottom:before {
  content: "\f147";
}
.fi-ss-arrow-from-left:before {
  content: "\f148";
}
.fi-ss-arrow-from-right:before {
  content: "\f149";
}
.fi-ss-arrow-from-top:before {
  content: "\f14a";
}
.fi-ss-arrow-left:before {
  content: "\f14b";
}
.fi-ss-arrow-right:before {
  content: "\f14c";
}
.fi-ss-arrow-small-down:before {
  content: "\f14d";
}
.fi-ss-arrow-small-left:before {
  content: "\f14e";
}
.fi-ss-arrow-small-right:before {
  content: "\f14f";
}
.fi-ss-arrow-small-up:before {
  content: "\f150";
}
.fi-ss-arrow-square-down:before {
  content: "\f151";
}
.fi-ss-arrow-square-left:before {
  content: "\f152";
}
.fi-ss-arrow-square-right:before {
  content: "\f153";
}
.fi-ss-arrow-square-up:before {
  content: "\f154";
}
.fi-ss-arrow-to-bottom:before {
  content: "\f155";
}
.fi-ss-arrow-to-left:before {
  content: "\f156";
}
.fi-ss-arrow-to-right:before {
  content: "\f157";
}
.fi-ss-arrow-to-top:before {
  content: "\f158";
}
.fi-ss-arrow-up:before {
  content: "\f159";
}
.fi-ss-arrows-alt-h:before {
  content: "\f15a";
}
.fi-ss-arrows-alt-v:before {
  content: "\f15b";
}
.fi-ss-arrows-alt:before {
  content: "\f15c";
}
.fi-ss-arrows-h-copy:before {
  content: "\f15d";
}
.fi-ss-arrows-h:before {
  content: "\f15e";
}
.fi-ss-arrows:before {
  content: "\f15f";
}
.fi-ss-assept-document:before {
  content: "\f160";
}
.fi-ss-assistive-listening-systems:before {
  content: "\f161";
}
.fi-ss-asterik:before {
  content: "\f162";
}
.fi-ss-at:before {
  content: "\f163";
}
.fi-ss-aubergine:before {
  content: "\f164";
}
.fi-ss-avocado:before {
  content: "\f165";
}
.fi-ss-b:before {
  content: "\f166";
}
.fi-ss-baby-carriage:before {
  content: "\f167";
}
.fi-ss-backpack:before {
  content: "\f168";
}
.fi-ss-bacon:before {
  content: "\f169";
}
.fi-ss-badge-sheriff:before {
  content: "\f16a";
}
.fi-ss-badge-check:before {
  content: "\f16b";
}
.fi-ss-badge-dollar:before {
  content: "\f16c";
}
.fi-ss-badge-percent:before {
  content: "\f16d";
}
.fi-ss-badge:before {
  content: "\f16e";
}
.fi-ss-bags-shopping:before {
  content: "\f16f";
}
.fi-ss-bahai:before {
  content: "\f170";
}
.fi-ss-balance-scale-left:before {
  content: "\f171";
}
.fi-ss-balance-scale-right:before {
  content: "\f172";
}
.fi-ss-balloons:before {
  content: "\f173";
}
.fi-ss-ballot:before {
  content: "\f174";
}
.fi-ss-ban:before {
  content: "\f175";
}
.fi-ss-band-aid:before {
  content: "\f176";
}
.fi-ss-bank:before {
  content: "\f177";
}
.fi-ss-barber-shop:before {
  content: "\f178";
}
.fi-ss-bars-progress:before {
  content: "\f179";
}
.fi-ss-baseball-alt:before {
  content: "\f17a";
}
.fi-ss-baseball:before {
  content: "\f17b";
}
.fi-ss-basketball-hoop:before {
  content: "\f17c";
}
.fi-ss-basketball:before {
  content: "\f17d";
}
.fi-ss-bed-alt:before {
  content: "\f17e";
}
.fi-ss-bed:before {
  content: "\f17f";
}
.fi-ss-beer:before {
  content: "\f180";
}
.fi-ss-bell-ring:before {
  content: "\f181";
}
.fi-ss-bell-school:before {
  content: "\f182";
}
.fi-ss-bell:before {
  content: "\f183";
}
.fi-ss-bike:before {
  content: "\f184";
}
.fi-ss-biking-mountain:before {
  content: "\f185";
}
.fi-ss-biking:before {
  content: "\f186";
}
.fi-ss-billiard:before {
  content: "\f187";
}
.fi-ss-blinds-open:before {
  content: "\f188";
}
.fi-ss-blinds-raised:before {
  content: "\f189";
}
.fi-ss-blinds:before {
  content: "\f18a";
}
.fi-ss-blossom:before {
  content: "\f18b";
}
.fi-ss-bold:before {
  content: "\f18c";
}
.fi-ss-bolt:before {
  content: "\f18d";
}
.fi-ss-book-alt:before {
  content: "\f18e";
}
.fi-ss-book-arrow-right:before {
  content: "\f18f";
}
.fi-ss-book-arrow-up:before {
  content: "\f190";
}
.fi-ss-book-bookmark:before {
  content: "\f191";
}
.fi-ss-book-open-cover:before {
  content: "\f192";
}
.fi-ss-book-open-reader:before {
  content: "\f193";
}
.fi-ss-book:before {
  content: "\f194";
}
.fi-ss-bookmark:before {
  content: "\f195";
}
.fi-ss-books:before {
  content: "\f196";
}
.fi-ss-bottle:before {
  content: "\f197";
}
.fi-ss-bow-arrow:before {
  content: "\f198";
}
.fi-ss-bowling-ball:before {
  content: "\f199";
}
.fi-ss-bowling-pins:before {
  content: "\f19a";
}
.fi-ss-bowling:before {
  content: "\f19b";
}
.fi-ss-box-check:before {
  content: "\f19c";
}
.fi-ss-box-tissue:before {
  content: "\f19d";
}
.fi-ss-box-alt:before {
  content: "\f19e";
}
.fi-ss-box-ballot:before {
  content: "\f19f";
}
.fi-ss-box:before {
  content: "\f1a0";
}
.fi-ss-boxes:before {
  content: "\f1a1";
}
.fi-ss-boxing-glove:before {
  content: "\f1a2";
}
.fi-ss-braille:before {
  content: "\f1a3";
}
.fi-ss-bread-slice:before {
  content: "\f1a4";
}
.fi-ss-bread:before {
  content: "\f1a5";
}
.fi-ss-briefcase:before {
  content: "\f1a6";
}
.fi-ss-broccoli:before {
  content: "\f1a7";
}
.fi-ss-broom:before {
  content: "\f1a8";
}
.fi-ss-browser:before {
  content: "\f1a9";
}
.fi-ss-brush:before {
  content: "\f1aa";
}
.fi-ss-bug:before {
  content: "\f1ab";
}
.fi-ss-building:before {
  content: "\f1ac";
}
.fi-ss-bulb:before {
  content: "\f1ad";
}
.fi-ss-bullseye-pointer:before {
  content: "\f1ae";
}
.fi-ss-bullseye:before {
  content: "\f1af";
}
.fi-ss-burrito:before {
  content: "\f1b0";
}
.fi-ss-bus-alt:before {
  content: "\f1b1";
}
.fi-ss-bus:before {
  content: "\f1b2";
}
.fi-ss-business-time:before {
  content: "\f1b3";
}
.fi-ss-butterfly:before {
  content: "\f1b4";
}
.fi-ss-cake-birthday:before {
  content: "\f1b5";
}
.fi-ss-cake-wedding:before {
  content: "\f1b6";
}
.fi-ss-calculator:before {
  content: "\f1b7";
}
.fi-ss-calendar-check:before {
  content: "\f1b8";
}
.fi-ss-calendar-clock:before {
  content: "\f1b9";
}
.fi-ss-calendar-exclamation:before {
  content: "\f1ba";
}
.fi-ss-calendar-lines-pen:before {
  content: "\f1bb";
}
.fi-ss-calendar-lines:before {
  content: "\f1bc";
}
.fi-ss-calendar-minus:before {
  content: "\f1bd";
}
.fi-ss-calendar-pen:before {
  content: "\f1be";
}
.fi-ss-calendar-plus:before {
  content: "\f1bf";
}
.fi-ss-calendar:before {
  content: "\f1c0";
}
.fi-ss-calendars:before {
  content: "\f1c1";
}
.fi-ss-call-history:before {
  content: "\f1c2";
}
.fi-ss-call-incoming:before {
  content: "\f1c3";
}
.fi-ss-call-missed:before {
  content: "\f1c4";
}
.fi-ss-call-outgoing:before {
  content: "\f1c5";
}
.fi-ss-camcorder:before {
  content: "\f1c6";
}
.fi-ss-camera:before {
  content: "\f1c7";
}
.fi-ss-camping:before {
  content: "\f1c8";
}
.fi-ss-candy-alt:before {
  content: "\f1c9";
}
.fi-ss-candy:before {
  content: "\f1ca";
}
.fi-ss-canned-food:before {
  content: "\f1cb";
}
.fi-ss-car-bolt:before {
  content: "\f1cc";
}
.fi-ss-car-circle-bolt:before {
  content: "\f1cd";
}
.fi-ss-car-side-bolt:before {
  content: "\f1ce";
}
.fi-ss-car-alt:before {
  content: "\f1cf";
}
.fi-ss-car-battery:before {
  content: "\f1d0";
}
.fi-ss-car-building:before {
  content: "\f1d1";
}
.fi-ss-car-bump:before {
  content: "\f1d2";
}
.fi-ss-car-bus:before {
  content: "\f1d3";
}
.fi-ss-car-crash:before {
  content: "\f1d4";
}
.fi-ss-car-garage:before {
  content: "\f1d5";
}
.fi-ss-car-mechanic:before {
  content: "\f1d6";
}
.fi-ss-car-side:before {
  content: "\f1d7";
}
.fi-ss-car-tilt:before {
  content: "\f1d8";
}
.fi-ss-car-wash:before {
  content: "\f1d9";
}
.fi-ss-car:before {
  content: "\f1da";
}
.fi-ss-caravan-alt:before {
  content: "\f1db";
}
.fi-ss-caravan:before {
  content: "\f1dc";
}
.fi-ss-caret-circle-down:before {
  content: "\f1dd";
}
.fi-ss-caret-circle-right:before {
  content: "\f1de";
}
.fi-ss-caret-circle-up:before {
  content: "\f1df";
}
.fi-ss-caret-down:before {
  content: "\f1e0";
}
.fi-ss-caret-left:before {
  content: "\f1e1";
}
.fi-ss-caret-quare-up:before {
  content: "\f1e2";
}
.fi-ss-caret-right:before {
  content: "\f1e3";
}
.fi-ss-caret-square-down:before {
  content: "\f1e4";
}
.fi-ss-caret-square-left_1:before {
  content: "\f1e5";
}
.fi-ss-caret-square-left:before {
  content: "\f1e6";
}
.fi-ss-caret-square-right:before {
  content: "\f1e7";
}
.fi-ss-caret-up:before {
  content: "\f1e8";
}
.fi-ss-carrot:before {
  content: "\f1e9";
}
.fi-ss-cars:before {
  content: "\f1ea";
}
.fi-ss-cash-register:before {
  content: "\f1eb";
}
.fi-ss-chair-office:before {
  content: "\f1ec";
}
.fi-ss-chair:before {
  content: "\f1ed";
}
.fi-ss-charging-station:before {
  content: "\f1ee";
}
.fi-ss-chart-area:before {
  content: "\f1ef";
}
.fi-ss-chart-connected:before {
  content: "\f1f0";
}
.fi-ss-chart-histogram:before {
  content: "\f1f1";
}
.fi-ss-chart-line-up:before {
  content: "\f1f2";
}
.fi-ss-chart-network:before {
  content: "\f1f3";
}
.fi-ss-chart-pie-alt:before {
  content: "\f1f4";
}
.fi-ss-chart-pie:before {
  content: "\f1f5";
}
.fi-ss-chart-pyramid:before {
  content: "\f1f6";
}
.fi-ss-chart-scatter:before {
  content: "\f1f7";
}
.fi-ss-chart-set-theory:before {
  content: "\f1f8";
}
.fi-ss-chart-tree:before {
  content: "\f1f9";
}
.fi-ss-chat-arrow-down:before {
  content: "\f1fa";
}
.fi-ss-chat-arrow-grow:before {
  content: "\f1fb";
}
.fi-ss-check:before {
  content: "\f1fc";
}
.fi-ss-checkbox:before {
  content: "\f1fd";
}
.fi-ss-cheese:before {
  content: "\f1fe";
}
.fi-ss-cherry:before {
  content: "\f1ff";
}
.fi-ss-chess-board:before {
  content: "\f200";
}
.fi-ss-chess-clock:before {
  content: "\f201";
}
.fi-ss-chess-pawn:before {
  content: "\f202";
}
.fi-ss-chess-piece:before {
  content: "\f203";
}
.fi-ss-chess-bishop:before {
  content: "\f204";
}
.fi-ss-chess-clock-alt:before {
  content: "\f205";
}
.fi-ss-chess-king-alt:before {
  content: "\f206";
}
.fi-ss-chess-king:before {
  content: "\f207";
}
.fi-ss-chess-knight-alt:before {
  content: "\f208";
}
.fi-ss-chess-knight:before {
  content: "\f209";
}
.fi-ss-chess-pawn-alt:before {
  content: "\f20a";
}
.fi-ss-chess-queen-alt:before {
  content: "\f20b";
}
.fi-ss-chess-queen:before {
  content: "\f20c";
}
.fi-ss-chess-rook-alt:before {
  content: "\f20d";
}
.fi-ss-chess-rook:before {
  content: "\f20e";
}
.fi-ss-chess:before {
  content: "\f20f";
}
.fi-ss-chevron-double-down:before {
  content: "\f210";
}
.fi-ss-chevron-double-up:before {
  content: "\f211";
}
.fi-ss-child-head:before {
  content: "\f212";
}
.fi-ss-chocolate:before {
  content: "\f213";
}
.fi-ss-circle-phone:before {
  content: "\f214";
}
.fi-ss-circle-envelope:before {
  content: "\f215";
}
.fi-ss-circle-phone-flip:before {
  content: "\f216";
}
.fi-ss-circle-phone-hangup:before {
  content: "\f217";
}
.fi-ss-circle-small:before {
  content: "\f218";
}
.fi-ss-circle:before {
  content: "\f219";
}
.fi-ss-clip:before {
  content: "\f21a";
}
.fi-ss-clipboard-list:before {
  content: "\f21b";
}
.fi-ss-clipboard-list-check:before {
  content: "\f21c";
}
.fi-ss-clock-eight-thirty:before {
  content: "\f21d";
}
.fi-ss-clock-eleven-thirty:before {
  content: "\f21e";
}
.fi-ss-clock-eleven:before {
  content: "\f21f";
}
.fi-ss-clock-five-thirty:before {
  content: "\f220";
}
.fi-ss-clock-five:before {
  content: "\f221";
}
.fi-ss-clock-four-thirty:before {
  content: "\f222";
}
.fi-ss-clock-nine-thirty:before {
  content: "\f223";
}
.fi-ss-clock-nine:before {
  content: "\f224";
}
.fi-ss-clock-one-thirty:before {
  content: "\f225";
}
.fi-ss-clock-one:before {
  content: "\f226";
}
.fi-ss-clock-seven-thirty:before {
  content: "\f227";
}
.fi-ss-clock-seven:before {
  content: "\f228";
}
.fi-ss-clock-six-thirty:before {
  content: "\f229";
}
.fi-ss-clock-six:before {
  content: "\f22a";
}
.fi-ss-clock-ten-thirty:before {
  content: "\f22b";
}
.fi-ss-clock-ten:before {
  content: "\f22c";
}
.fi-ss-clock-three-thirty:before {
  content: "\f22d";
}
.fi-ss-clock-three:before {
  content: "\f22e";
}
.fi-ss-clock-twelve-thirty:before {
  content: "\f22f";
}
.fi-ss-clock-twelve:before {
  content: "\f230";
}
.fi-ss-clock-two-thirty:before {
  content: "\f231";
}
.fi-ss-clock-two:before {
  content: "\f232";
}
.fi-ss-clock:before {
  content: "\f233";
}
.fi-ss-cloud-upload:before {
  content: "\f234";
}
.fi-ss-cloud-check:before {
  content: "\f235";
}
.fi-ss-cloud-disabled:before {
  content: "\f236";
}
.fi-ss-cloud-download-alt:before {
  content: "\f237";
}
.fi-ss-cloud-download:before {
  content: "\f238";
}
.fi-ss-cloud-drizzle:before {
  content: "\f239";
}
.fi-ss-cloud-hail-mixed:before {
  content: "\f23a";
}
.fi-ss-cloud-hail:before {
  content: "\f23b";
}
.fi-ss-cloud-moon-rain:before {
  content: "\f23c";
}
.fi-ss-cloud-moon:before {
  content: "\f23d";
}
.fi-ss-cloud-rain:before {
  content: "\f23e";
}
.fi-ss-cloud-rainbow:before {
  content: "\f23f";
}
.fi-ss-cloud-share:before {
  content: "\f240";
}
.fi-ss-cloud-showers-heavy:before {
  content: "\f241";
}
.fi-ss-cloud-showers:before {
  content: "\f242";
}
.fi-ss-cloud-sleet:before {
  content: "\f243";
}
.fi-ss-cloud-snow:before {
  content: "\f244";
}
.fi-ss-cloud-sun-rain:before {
  content: "\f245";
}
.fi-ss-cloud-sun:before {
  content: "\f246";
}
.fi-ss-cloud-upload-alt:before {
  content: "\f247";
}
.fi-ss-cloud:before {
  content: "\f248";
}
.fi-ss-clouds-moon:before {
  content: "\f249";
}
.fi-ss-clouds-sun:before {
  content: "\f24a";
}
.fi-ss-clouds:before {
  content: "\f24b";
}
.fi-ss-club:before {
  content: "\f24c";
}
.fi-ss-cocktail-alt:before {
  content: "\f24d";
}
.fi-ss-cocktail:before {
  content: "\f24e";
}
.fi-ss-coffee-pot:before {
  content: "\f24f";
}
.fi-ss-coffee:before {
  content: "\f250";
}
.fi-ss-coin:before {
  content: "\f251";
}
.fi-ss-coins:before {
  content: "\f252";
}
.fi-ss-comet:before {
  content: "\f253";
}
.fi-ss-comment-alt-middle-top:before {
  content: "\f254";
}
.fi-ss-comment-alt-middle:before {
  content: "\f255";
}
.fi-ss-comment-alt:before {
  content: "\f256";
}
.fi-ss-comment-arrow-down:before {
  content: "\f257";
}
.fi-ss-comment-arrow-up-right:before {
  content: "\f258";
}
.fi-ss-comment-arrow-up:before {
  content: "\f259";
}
.fi-ss-comment-check:before {
  content: "\f25a";
}
.fi-ss-comment-code:before {
  content: "\f25b";
}
.fi-ss-comment-dollar:before {
  content: "\f25c";
}
.fi-ss-comment-exclamation:before {
  content: "\f25d";
}
.fi-ss-comment-heart:before {
  content: "\f25e";
}
.fi-ss-comment-image:before {
  content: "\f25f";
}
.fi-ss-comment-info:before {
  content: "\f260";
}
.fi-ss-comment-pen:before {
  content: "\f261";
}
.fi-ss-comment-question:before {
  content: "\f262";
}
.fi-ss-comment-quote:before {
  content: "\f263";
}
.fi-ss-comment-slash:before {
  content: "\f264";
}
.fi-ss-comment-sms:before {
  content: "\f265";
}
.fi-ss-comment-text:before {
  content: "\f266";
}
.fi-ss-comment-user:before {
  content: "\f267";
}
.fi-ss-comment-xmark:before {
  content: "\f268";
}
.fi-ss-comment:before {
  content: "\f269";
}
.fi-ss-comments-dollar:before {
  content: "\f26a";
}
.fi-ss-comments-question-check:before {
  content: "\f26b";
}
.fi-ss-comments-question:before {
  content: "\f26c";
}
.fi-ss-comments:before {
  content: "\f26d";
}
.fi-ss-compress-alt:before {
  content: "\f26e";
}
.fi-ss-compress:before {
  content: "\f26f";
}
.fi-ss-computer:before {
  content: "\f270";
}
.fi-ss-confetti:before {
  content: "\f271";
}
.fi-ss-cookie:before {
  content: "\f272";
}
.fi-ss-copy-alt:before {
  content: "\f273";
}
.fi-ss-copy:before {
  content: "\f274";
}
.fi-ss-copyright:before {
  content: "\f275";
}
.fi-ss-corn:before {
  content: "\f276";
}
.fi-ss-cow:before {
  content: "\f277";
}
.fi-ss-cream:before {
  content: "\f278";
}
.fi-ss-credit-card:before {
  content: "\f279";
}
.fi-ss-cricket:before {
  content: "\f27a";
}
.fi-ss-croissant:before {
  content: "\f27b";
}
.fi-ss-cross-circle:before {
  content: "\f27c";
}
.fi-ss-cross-small:before {
  content: "\f27d";
}
.fi-ss-cross:before {
  content: "\f27e";
}
.fi-ss-crown:before {
  content: "\f27f";
}
.fi-ss-crystal-ball:before {
  content: "\f280";
}
.fi-ss-cube:before {
  content: "\f281";
}
.fi-ss-cupcake:before {
  content: "\f282";
}
.fi-ss-curling:before {
  content: "\f283";
}
.fi-ss-cursor-finger:before {
  content: "\f284";
}
.fi-ss-cursor-plus:before {
  content: "\f285";
}
.fi-ss-cursor-text-alt:before {
  content: "\f286";
}
.fi-ss-cursor-text:before {
  content: "\f287";
}
.fi-ss-cursor:before {
  content: "\f288";
}
.fi-ss-d:before {
  content: "\f289";
}
.fi-ss-dart:before {
  content: "\f28a";
}
.fi-ss-dashboard:before {
  content: "\f28b";
}
.fi-ss-data-transfer:before {
  content: "\f28c";
}
.fi-ss-database:before {
  content: "\f28d";
}
.fi-ss-delete-document:before {
  content: "\f28e";
}
.fi-ss-delete-user:before {
  content: "\f28f";
}
.fi-ss-delete:before {
  content: "\f290";
}
.fi-ss-democrat:before {
  content: "\f291";
}
.fi-ss-dewpoint:before {
  content: "\f292";
}
.fi-ss-diamond:before {
  content: "\f293";
}
.fi-ss-dice-alt:before {
  content: "\f294";
}
.fi-ss-dice-d10:before {
  content: "\f295";
}
.fi-ss-dice-d12:before {
  content: "\f296";
}
.fi-ss-dice-d20:before {
  content: "\f297";
}
.fi-ss-dice-d4:before {
  content: "\f298";
}
.fi-ss-dice-d6:before {
  content: "\f299";
}
.fi-ss-dice-d8:before {
  content: "\f29a";
}
.fi-ss-dice-four:before {
  content: "\f29b";
}
.fi-ss-dice-one:before {
  content: "\f29c";
}
.fi-ss-dice-six:before {
  content: "\f29d";
}
.fi-ss-dice-three:before {
  content: "\f29e";
}
.fi-ss-dice-two:before {
  content: "\f29f";
}
.fi-ss-dice:before {
  content: "\f2a0";
}
.fi-ss-diploma:before {
  content: "\f2a1";
}
.fi-ss-disk:before {
  content: "\f2a2";
}
.fi-ss-dizzy:before {
  content: "\f2a3";
}
.fi-ss-doctor:before {
  content: "\f2a4";
}
.fi-ss-document-signed:before {
  content: "\f2a5";
}
.fi-ss-document:before {
  content: "\f2a6";
}
.fi-ss-dollar:before {
  content: "\f2a7";
}
.fi-ss-donate:before {
  content: "\f2a8";
}
.fi-ss-down-left-and-up-right-to-center:before {
  content: "\f2a9";
}
.fi-ss-download:before {
  content: "\f2aa";
}
.fi-ss-drafting-compass:before {
  content: "\f2ab";
}
.fi-ss-dreidel:before {
  content: "\f2ac";
}
.fi-ss-drink-alt:before {
  content: "\f2ad";
}
.fi-ss-drumstick:before {
  content: "\f2ae";
}
.fi-ss-duplicate:before {
  content: "\f2af";
}
.fi-ss-e-learning:before {
  content: "\f2b0";
}
.fi-ss-e:before {
  content: "\f2b1";
}
.fi-ss-earnings:before {
  content: "\f2b2";
}
.fi-ss-eclipse-alt:before {
  content: "\f2b3";
}
.fi-ss-eclipse:before {
  content: "\f2b4";
}
.fi-ss-edit-alt:before {
  content: "\f2b5";
}
.fi-ss-edit:before {
  content: "\f2b6";
}
.fi-ss-egg-fried:before {
  content: "\f2b7";
}
.fi-ss-egg:before {
  content: "\f2b8";
}
.fi-ss-engine-warning:before {
  content: "\f2b9";
}
.fi-ss-enter:before {
  content: "\f2ba";
}
.fi-ss-entertainment:before {
  content: "\f2bb";
}
.fi-ss-envelope-ban:before {
  content: "\f2bc";
}
.fi-ss-envelope-bulk:before {
  content: "\f2bd";
}
.fi-ss-envelope-download:before {
  content: "\f2be";
}
.fi-ss-envelope-marker:before {
  content: "\f2bf";
}
.fi-ss-envelope-open-dollar:before {
  content: "\f2c0";
}
.fi-ss-envelope-open-text:before {
  content: "\f2c1";
}
.fi-ss-envelope-open:before {
  content: "\f2c2";
}
.fi-ss-envelope-plus:before {
  content: "\f2c3";
}
.fi-ss-envelope:before {
  content: "\f2c4";
}
.fi-ss-equality:before {
  content: "\f2c5";
}
.fi-ss-equipment:before {
  content: "\f2c6";
}
.fi-ss-euro:before {
  content: "\f2c7";
}
.fi-ss-exchange-alt:before {
  content: "\f2c8";
}
.fi-ss-exchange:before {
  content: "\f2c9";
}
.fi-ss-exclamation:before {
  content: "\f2ca";
}
.fi-ss-exit:before {
  content: "\f2cb";
}
.fi-ss-expand-arrows-alt:before {
  content: "\f2cc";
}
.fi-ss-expand-arrows:before {
  content: "\f2cd";
}
.fi-ss-expand:before {
  content: "\f2ce";
}
.fi-ss-eye-crossed:before {
  content: "\f2cf";
}
.fi-ss-eye:before {
  content: "\f2d0";
}
.fi-ss-f:before {
  content: "\f2d1";
}
.fi-ss-feather:before {
  content: "\f2d2";
}
.fi-ss-field-hockey:before {
  content: "\f2d3";
}
.fi-ss-fighter-jet:before {
  content: "\f2d4";
}
.fi-ss-file-invoice-dollar:before {
  content: "\f2d5";
}
.fi-ss-file-invoice:before {
  content: "\f2d6";
}
.fi-ss-file-ai:before {
  content: "\f2d7";
}
.fi-ss-file-chart-line:before {
  content: "\f2d8";
}
.fi-ss-file-chart-pie:before {
  content: "\f2d9";
}
.fi-ss-file-eps:before {
  content: "\f2da";
}
.fi-ss-file-psd:before {
  content: "\f2db";
}
.fi-ss-file-spreadsheet:before {
  content: "\f2dc";
}
.fi-ss-file-video:before {
  content: "\f2dd";
}
.fi-ss-file:before {
  content: "\f2de";
}
.fi-ss-fill:before {
  content: "\f2df";
}
.fi-ss-film-slash:before {
  content: "\f2e0";
}
.fi-ss-film:before {
  content: "\f2e1";
}
.fi-ss-filter-slash:before {
  content: "\f2e2";
}
.fi-ss-filter:before {
  content: "\f2e3";
}
.fi-ss-filters:before {
  content: "\f2e4";
}
.fi-ss-fingerprint:before {
  content: "\f2e5";
}
.fi-ss-fish:before {
  content: "\f2e6";
}
.fi-ss-flag-alt:before {
  content: "\f2e7";
}
.fi-ss-flag-usa:before {
  content: "\f2e8";
}
.fi-ss-flag:before {
  content: "\f2e9";
}
.fi-ss-flame:before {
  content: "\f2ea";
}
.fi-ss-flip-horizontal:before {
  content: "\f2eb";
}
.fi-ss-flower-tulip:before {
  content: "\f2ec";
}
.fi-ss-flower:before {
  content: "\f2ed";
}
.fi-ss-flushed:before {
  content: "\f2ee";
}
.fi-ss-fog:before {
  content: "\f2ef";
}
.fi-ss-folder-download:before {
  content: "\f2f0";
}
.fi-ss-folder-minus:before {
  content: "\f2f1";
}
.fi-ss-folder-times:before {
  content: "\f2f2";
}
.fi-ss-folder-tree:before {
  content: "\f2f3";
}
.fi-ss-folder-upload:before {
  content: "\f2f4";
}
.fi-ss-folder:before {
  content: "\f2f5";
}
.fi-ss-folders:before {
  content: "\f2f6";
}
.fi-ss-following:before {
  content: "\f2f7";
}
.fi-ss-football:before {
  content: "\f2f8";
}
.fi-ss-fork:before {
  content: "\f2f9";
}
.fi-ss-form:before {
  content: "\f2fa";
}
.fi-ss-forward:before {
  content: "\f2fb";
}
.fi-ss-fox:before {
  content: "\f2fc";
}
.fi-ss-french-fries:before {
  content: "\f2fd";
}
.fi-ss-frown:before {
  content: "\f2fe";
}
.fi-ss-ftp:before {
  content: "\f2ff";
}
.fi-ss-funnel-dollar:before {
  content: "\f300";
}
.fi-ss-g:before {
  content: "\f301";
}
.fi-ss-gallery:before {
  content: "\f302";
}
.fi-ss-game-board-alt:before {
  content: "\f303";
}
.fi-ss-game:before {
  content: "\f304";
}
.fi-ss-gamepad:before {
  content: "\f305";
}
.fi-ss-games:before {
  content: "\f306";
}
.fi-ss-garage-car:before {
  content: "\f307";
}
.fi-ss-garage-open:before {
  content: "\f308";
}
.fi-ss-garage:before {
  content: "\f309";
}
.fi-ss-garlic:before {
  content: "\f30a";
}
.fi-ss-gas-pump-alt:before {
  content: "\f30b";
}
.fi-ss-gas-pump-slash:before {
  content: "\f30c";
}
.fi-ss-gas-pump:before {
  content: "\f30d";
}
.fi-ss-gem:before {
  content: "\f30e";
}
.fi-ss-gif:before {
  content: "\f30f";
}
.fi-ss-gift-card:before {
  content: "\f310";
}
.fi-ss-gift:before {
  content: "\f311";
}
.fi-ss-gifts:before {
  content: "\f312";
}
.fi-ss-gingerbread-man:before {
  content: "\f313";
}
.fi-ss-glass-cheers:before {
  content: "\f314";
}
.fi-ss-glass:before {
  content: "\f315";
}
.fi-ss-glasses:before {
  content: "\f316";
}
.fi-ss-globe-alt:before {
  content: "\f317";
}
.fi-ss-globe:before {
  content: "\f318";
}
.fi-ss-golf-ball:before {
  content: "\f319";
}
.fi-ss-golf-club:before {
  content: "\f31a";
}
.fi-ss-golf:before {
  content: "\f31b";
}
.fi-ss-graduation-cap:before {
  content: "\f31c";
}
.fi-ss-grape:before {
  content: "\f31d";
}
.fi-ss-graphic-tablet:before {
  content: "\f31e";
}
.fi-ss-grid-alt:before {
  content: "\f31f";
}
.fi-ss-grid:before {
  content: "\f320";
}
.fi-ss-grill:before {
  content: "\f321";
}
.fi-ss-grimace:before {
  content: "\f322";
}
.fi-ss-grin-alt:before {
  content: "\f323";
}
.fi-ss-grin-beam-sweat:before {
  content: "\f324";
}
.fi-ss-grin-beam:before {
  content: "\f325";
}
.fi-ss-grin-hearts:before {
  content: "\f326";
}
.fi-ss-grin-squint-tears:before {
  content: "\f327";
}
.fi-ss-grin-squint:before {
  content: "\f328";
}
.fi-ss-grin-stars:before {
  content: "\f329";
}
.fi-ss-grin-tears:before {
  content: "\f32a";
}
.fi-ss-grin-tongue-squint:before {
  content: "\f32b";
}
.fi-ss-grin-tongue-wink:before {
  content: "\f32c";
}
.fi-ss-grin-tongue:before {
  content: "\f32d";
}
.fi-ss-grin-wink:before {
  content: "\f32e";
}
.fi-ss-grin:before {
  content: "\f32f";
}
.fi-ss-guitar:before {
  content: "\f330";
}
.fi-ss-gym:before {
  content: "\f331";
}
.fi-ss-h:before {
  content: "\f332";
}
.fi-ss-hamburger-soda:before {
  content: "\f333";
}
.fi-ss-hamburger:before {
  content: "\f334";
}
.fi-ss-hand-holding-box:before {
  content: "\f335";
}
.fi-ss-hand-holding-heart:before {
  content: "\f336";
}
.fi-ss-hand-holding-seeding:before {
  content: "\f337";
}
.fi-ss-hand:before {
  content: "\f338";
}
.fi-ss-handshake:before {
  content: "\f339";
}
.fi-ss-hastag:before {
  content: "\f33a";
}
.fi-ss-hat-birthday:before {
  content: "\f33b";
}
.fi-ss-hat-chef:before {
  content: "\f33c";
}
.fi-ss-head-side-thinking:before {
  content: "\f33d";
}
.fi-ss-headphones:before {
  content: "\f33e";
}
.fi-ss-headset:before {
  content: "\f33f";
}
.fi-ss-heart-arrow:before {
  content: "\f340";
}
.fi-ss-heart:before {
  content: "\f341";
}
.fi-ss-heat:before {
  content: "\f342";
}
.fi-ss-helicopter-side:before {
  content: "\f343";
}
.fi-ss-highlighter:before {
  content: "\f344";
}
.fi-ss-hiking:before {
  content: "\f345";
}
.fi-ss-hockey-mask:before {
  content: "\f346";
}
.fi-ss-hockey-puck:before {
  content: "\f347";
}
.fi-ss-hockey-sticks:before {
  content: "\f348";
}
.fi-ss-home-location-alt:before {
  content: "\f349";
}
.fi-ss-home-location:before {
  content: "\f34a";
}
.fi-ss-home:before {
  content: "\f34b";
}
.fi-ss-hotdog:before {
  content: "\f34c";
}
.fi-ss-hourglass-end:before {
  content: "\f34d";
}
.fi-ss-hourglass:before {
  content: "\f34e";
}
.fi-ss-house-flood:before {
  content: "\f34f";
}
.fi-ss-hryvnia:before {
  content: "\f350";
}
.fi-ss-humidity:before {
  content: "\f351";
}
.fi-ss-hurricane:before {
  content: "\f352";
}
.fi-ss-i:before {
  content: "\f353";
}
.fi-ss-ice-cream:before {
  content: "\f354";
}
.fi-ss-id-badge:before {
  content: "\f355";
}
.fi-ss-inbox-in:before {
  content: "\f356";
}
.fi-ss-inbox-out:before {
  content: "\f357";
}
.fi-ss-inbox:before {
  content: "\f358";
}
.fi-ss-incognito:before {
  content: "\f359";
}
.fi-ss-indent:before {
  content: "\f35a";
}
.fi-ss-infinity:before {
  content: "\f35b";
}
.fi-ss-info:before {
  content: "\f35c";
}
.fi-ss-interactive:before {
  content: "\f35d";
}
.fi-ss-interlining:before {
  content: "\f35e";
}
.fi-ss-interrogation:before {
  content: "\f35f";
}
.fi-ss-italic:before {
  content: "\f360";
}
.fi-ss-j:before {
  content: "\f361";
}
.fi-ss-jam:before {
  content: "\f362";
}
.fi-ss-jpg:before {
  content: "\f363";
}
.fi-ss-k:before {
  content: "\f364";
}
.fi-ss-kerning:before {
  content: "\f365";
}
.fi-ss-key:before {
  content: "\f366";
}
.fi-ss-keyboard:before {
  content: "\f367";
}
.fi-ss-keynote:before {
  content: "\f368";
}
.fi-ss-kiss-beam:before {
  content: "\f369";
}
.fi-ss-kiss-wink-heart:before {
  content: "\f36a";
}
.fi-ss-kiss:before {
  content: "\f36b";
}
.fi-ss-kite:before {
  content: "\f36c";
}
.fi-ss-knife:before {
  content: "\f36d";
}
.fi-ss-l:before {
  content: "\f36e";
}
.fi-ss-label:before {
  content: "\f36f";
}
.fi-ss-laptop:before {
  content: "\f370";
}
.fi-ss-lasso:before {
  content: "\f371";
}
.fi-ss-laugh-beam:before {
  content: "\f372";
}
.fi-ss-laugh-squint:before {
  content: "\f373";
}
.fi-ss-laugh-wink:before {
  content: "\f374";
}
.fi-ss-laugh:before {
  content: "\f375";
}
.fi-ss-layer-minus:before {
  content: "\f376";
}
.fi-ss-layer-plus:before {
  content: "\f377";
}
.fi-ss-layers:before {
  content: "\f378";
}
.fi-ss-layout-fluid:before {
  content: "\f379";
}
.fi-ss-leaf:before {
  content: "\f37a";
}
.fi-ss-lemon:before {
  content: "\f37b";
}
.fi-ss-letter-case:before {
  content: "\f37c";
}
.fi-ss-lettuce:before {
  content: "\f37d";
}
.fi-ss-level-down-alt:before {
  content: "\f37e";
}
.fi-ss-level-down:before {
  content: "\f37f";
}
.fi-ss-level-up-alt:before {
  content: "\f380";
}
.fi-ss-level-up:before {
  content: "\f381";
}
.fi-ss-life-ring:before {
  content: "\f382";
}
.fi-ss-lightbulb-dollar:before {
  content: "\f383";
}
.fi-ss-line-width:before {
  content: "\f384";
}
.fi-ss-link-alt:before {
  content: "\f385";
}
.fi-ss-link-slash-alt:before {
  content: "\f386";
}
.fi-ss-link-slash:before {
  content: "\f387";
}
.fi-ss-link:before {
  content: "\f388";
}
.fi-ss-lipstick:before {
  content: "\f389";
}
.fi-ss-lira-sign:before {
  content: "\f38a";
}
.fi-ss-list-check:before {
  content: "\f38b";
}
.fi-ss-list:before {
  content: "\f38c";
}
.fi-ss-loading:before {
  content: "\f38d";
}
.fi-ss-location-alt:before {
  content: "\f38e";
}
.fi-ss-lock-alt:before {
  content: "\f38f";
}
.fi-ss-lock:before {
  content: "\f390";
}
.fi-ss-luchador:before {
  content: "\f391";
}
.fi-ss-luggage-rolling:before {
  content: "\f392";
}
.fi-ss-m:before {
  content: "\f393";
}
.fi-ss-mailbox:before {
  content: "\f394";
}
.fi-ss-makeup-brush:before {
  content: "\f395";
}
.fi-ss-man-head:before {
  content: "\f396";
}
.fi-ss-map-marker-cross:before {
  content: "\f397";
}
.fi-ss-map-marker-home:before {
  content: "\f398";
}
.fi-ss-map-marker-minus:before {
  content: "\f399";
}
.fi-ss-map-marker-plus:before {
  content: "\f39a";
}
.fi-ss-map-marker:before {
  content: "\f39b";
}
.fi-ss-map:before {
  content: "\f39c";
}
.fi-ss-marker-time:before {
  content: "\f39d";
}
.fi-ss-marker:before {
  content: "\f39e";
}
.fi-ss-mars-double:before {
  content: "\f39f";
}
.fi-ss-mars:before {
  content: "\f3a0";
}
.fi-ss-mask-carnival:before {
  content: "\f3a1";
}
.fi-ss-medicine:before {
  content: "\f3a2";
}
.fi-ss-megaphone:before {
  content: "\f3a3";
}
.fi-ss-meh-blank:before {
  content: "\f3a4";
}
.fi-ss-meh-rolling-eyes:before {
  content: "\f3a5";
}
.fi-ss-meh:before {
  content: "\f3a6";
}
.fi-ss-melon:before {
  content: "\f3a7";
}
.fi-ss-menu-burger:before {
  content: "\f3a8";
}
.fi-ss-menu-dots-vertical:before {
  content: "\f3a9";
}
.fi-ss-menu-dots:before {
  content: "\f3aa";
}
.fi-ss-meteor:before {
  content: "\f3ab";
}
.fi-ss-microphone-alt:before {
  content: "\f3ac";
}
.fi-ss-microphone:before {
  content: "\f3ad";
}
.fi-ss-mind-share:before {
  content: "\f3ae";
}
.fi-ss-minus-small:before {
  content: "\f3af";
}
.fi-ss-minus:before {
  content: "\f3b0";
}
.fi-ss-mobile-button:before {
  content: "\f3b1";
}
.fi-ss-mobile-notch:before {
  content: "\f3b2";
}
.fi-ss-mobile:before {
  content: "\f3b3";
}
.fi-ss-mode-landscape:before {
  content: "\f3b4";
}
.fi-ss-mode-portrait:before {
  content: "\f3b5";
}
.fi-ss-money-bill-wave-alt:before {
  content: "\f3b6";
}
.fi-ss-money-bill-wave:before {
  content: "\f3b7";
}
.fi-ss-money-check:before {
  content: "\f3b8";
}
.fi-ss-money-check-edit-alt:before {
  content: "\f3b9";
}
.fi-ss-money-check-edit:before {
  content: "\f3ba";
}
.fi-ss-money:before {
  content: "\f3bb";
}
.fi-ss-moon-stars:before {
  content: "\f3bc";
}
.fi-ss-moon:before {
  content: "\f3bd";
}
.fi-ss-motorcycle:before {
  content: "\f3be";
}
.fi-ss-mountains:before {
  content: "\f3bf";
}
.fi-ss-mouse:before {
  content: "\f3c0";
}
.fi-ss-mug-alt:before {
  content: "\f3c1";
}
.fi-ss-mug-hot-alt:before {
  content: "\f3c2";
}
.fi-ss-mug-hot:before {
  content: "\f3c3";
}
.fi-ss-mug-tea:before {
  content: "\f3c4";
}
.fi-ss-mug:before {
  content: "\f3c5";
}
.fi-ss-mushroom:before {
  content: "\f3c6";
}
.fi-ss-music-file:before {
  content: "\f3c7";
}
.fi-ss-music-alt:before {
  content: "\f3c8";
}
.fi-ss-music:before {
  content: "\f3c9";
}
.fi-ss-n:before {
  content: "\f3ca";
}
.fi-ss-navigation:before {
  content: "\f3cb";
}
.fi-ss-network-cloud:before {
  content: "\f3cc";
}
.fi-ss-network:before {
  content: "\f3cd";
}
.fi-ss-noodles:before {
  content: "\f3ce";
}
.fi-ss-notebook:before {
  content: "\f3cf";
}
.fi-ss-o:before {
  content: "\f3d0";
}
.fi-ss-oil-can:before {
  content: "\f3d1";
}
.fi-ss-oil-temp:before {
  content: "\f3d2";
}
.fi-ss-olive-oil:before {
  content: "\f3d3";
}
.fi-ss-olives:before {
  content: "\f3d4";
}
.fi-ss-onion:before {
  content: "\f3d5";
}
.fi-ss-opacity:before {
  content: "\f3d6";
}
.fi-ss-overline:before {
  content: "\f3d7";
}
.fi-ss-p:before {
  content: "\f3d8";
}
.fi-ss-package:before {
  content: "\f3d9";
}
.fi-ss-page-break:before {
  content: "\f3da";
}
.fi-ss-paint-roller:before {
  content: "\f3db";
}
.fi-ss-paint:before {
  content: "\f3dc";
}
.fi-ss-palette:before {
  content: "\f3dd";
}
.fi-ss-pan:before {
  content: "\f3de";
}
.fi-ss-paper-plane:before {
  content: "\f3df";
}
.fi-ss-party:before {
  content: "\f3e0";
}
.fi-ss-password:before {
  content: "\f3e1";
}
.fi-ss-pause:before {
  content: "\f3e2";
}
.fi-ss-paw:before {
  content: "\f3e3";
}
.fi-ss-peach:before {
  content: "\f3e4";
}
.fi-ss-pencil-ruler:before {
  content: "\f3e5";
}
.fi-ss-pencil:before {
  content: "\f3e6";
}
.fi-ss-pennant:before {
  content: "\f3e7";
}
.fi-ss-people-poll:before {
  content: "\f3e8";
}
.fi-ss-pepper-hot:before {
  content: "\f3e9";
}
.fi-ss-pepper:before {
  content: "\f3ea";
}
.fi-ss-percentage:before {
  content: "\f3eb";
}
.fi-ss-pharmacy:before {
  content: "\f3ec";
}
.fi-ss-phone-office:before {
  content: "\f3ed";
}
.fi-ss-phone-call:before {
  content: "\f3ee";
}
.fi-ss-phone-cross:before {
  content: "\f3ef";
}
.fi-ss-phone-pause:before {
  content: "\f3f0";
}
.fi-ss-phone-slash:before {
  content: "\f3f1";
}
.fi-ss-photo-film-music:before {
  content: "\f3f2";
}
.fi-ss-photo-video:before {
  content: "\f3f3";
}
.fi-ss-physics:before {
  content: "\f3f4";
}
.fi-ss-picnic:before {
  content: "\f3f5";
}
.fi-ss-picture:before {
  content: "\f3f6";
}
.fi-ss-pie:before {
  content: "\f3f7";
}
.fi-ss-piggy-bank:before {
  content: "\f3f8";
}
.fi-ss-pineapple:before {
  content: "\f3f9";
}
.fi-ss-pipette:before {
  content: "\f3fa";
}
.fi-ss-pizza-slice:before {
  content: "\f3fb";
}
.fi-ss-plane-alt:before {
  content: "\f3fc";
}
.fi-ss-plane:before {
  content: "\f3fd";
}
.fi-ss-plate:before {
  content: "\f3fe";
}
.fi-ss-play-alt:before {
  content: "\f3ff";
}
.fi-ss-play-pause:before {
  content: "\f400";
}
.fi-ss-play:before {
  content: "\f401";
}
.fi-ss-plus-small:before {
  content: "\f402";
}
.fi-ss-plus:before {
  content: "\f403";
}
.fi-ss-podium-star:before {
  content: "\f404";
}
.fi-ss-podium:before {
  content: "\f405";
}
.fi-ss-poo:before {
  content: "\f406";
}
.fi-ss-popcorn:before {
  content: "\f407";
}
.fi-ss-portrait:before {
  content: "\f408";
}
.fi-ss-pot:before {
  content: "\f409";
}
.fi-ss-pound:before {
  content: "\f40a";
}
.fi-ss-power:before {
  content: "\f40b";
}
.fi-ss-presentation:before {
  content: "\f40c";
}
.fi-ss-print:before {
  content: "\f40d";
}
.fi-ss-protractor:before {
  content: "\f40e";
}
.fi-ss-pulse:before {
  content: "\f40f";
}
.fi-ss-pumpkin:before {
  content: "\f410";
}
.fi-ss-puzzle-piece:before {
  content: "\f411";
}
.fi-ss-pyramid:before {
  content: "\f412";
}
.fi-ss-q:before {
  content: "\f413";
}
.fi-ss-question-square:before {
  content: "\f414";
}
.fi-ss-question:before {
  content: "\f415";
}
.fi-ss-quote-right:before {
  content: "\f416";
}
.fi-ss-r:before {
  content: "\f417";
}
.fi-ss-racquet:before {
  content: "\f418";
}
.fi-ss-radish:before {
  content: "\f419";
}
.fi-ss-rainbow:before {
  content: "\f41a";
}
.fi-ss-raindrops:before {
  content: "\f41b";
}
.fi-ss-rec:before {
  content: "\f41c";
}
.fi-ss-receipt:before {
  content: "\f41d";
}
.fi-ss-record-vinyl:before {
  content: "\f41e";
}
.fi-ss-rectabgle-vertical:before {
  content: "\f41f";
}
.fi-ss-rectangle-horizontal:before {
  content: "\f420";
}
.fi-ss-rectangle-panoramic:before {
  content: "\f421";
}
.fi-ss-recycle:before {
  content: "\f422";
}
.fi-ss-redo-alt:before {
  content: "\f423";
}
.fi-ss-redo:before {
  content: "\f424";
}
.fi-ss-reflect:before {
  content: "\f425";
}
.fi-ss-refresh:before {
  content: "\f426";
}
.fi-ss-registered:before {
  content: "\f427";
}
.fi-ss-remove-user:before {
  content: "\f428";
}
.fi-ss-reply-all:before {
  content: "\f429";
}
.fi-ss-republican:before {
  content: "\f42a";
}
.fi-ss-resize:before {
  content: "\f42b";
}
.fi-ss-resources:before {
  content: "\f42c";
}
.fi-ss-restaurant:before {
  content: "\f42d";
}
.fi-ss-rewind:before {
  content: "\f42e";
}
.fi-ss-rhombus:before {
  content: "\f42f";
}
.fi-ss-rings-wedding:before {
  content: "\f430";
}
.fi-ss-road:before {
  content: "\f431";
}
.fi-ss-rocket-lunch:before {
  content: "\f432";
}
.fi-ss-rocket:before {
  content: "\f433";
}
.fi-ss-room-service:before {
  content: "\f434";
}
.fi-ss-rotate-left:before {
  content: "\f435";
}
.fi-ss-rotate-right:before {
  content: "\f436";
}
.fi-ss-ruble-sign:before {
  content: "\f437";
}
.fi-ss-rugby-helmet:before {
  content: "\f438";
}
.fi-ss-rugby:before {
  content: "\f439";
}
.fi-ss-ruler-combined:before {
  content: "\f43a";
}
.fi-ss-ruler-horizontal:before {
  content: "\f43b";
}
.fi-ss-ruler-triangle:before {
  content: "\f43c";
}
.fi-ss-ruler-vertical:before {
  content: "\f43d";
}
.fi-ss-running:before {
  content: "\f43e";
}
.fi-ss-rupee-sign:before {
  content: "\f43f";
}
.fi-ss-rv:before {
  content: "\f440";
}
.fi-ss-s:before {
  content: "\f441";
}
.fi-ss-sack-dollar:before {
  content: "\f442";
}
.fi-ss-sack:before {
  content: "\f443";
}
.fi-ss-sad-cry:before {
  content: "\f444";
}
.fi-ss-sad-tear:before {
  content: "\f445";
}
.fi-ss-sad:before {
  content: "\f446";
}
.fi-ss-salad:before {
  content: "\f447";
}
.fi-ss-salt-pepper:before {
  content: "\f448";
}
.fi-ss-sandwich:before {
  content: "\f449";
}
.fi-ss-sauce:before {
  content: "\f44a";
}
.fi-ss-sausage:before {
  content: "\f44b";
}
.fi-ss-scale:before {
  content: "\f44c";
}
.fi-ss-school-bus:before {
  content: "\f44d";
}
.fi-ss-school:before {
  content: "\f44e";
}
.fi-ss-scissors:before {
  content: "\f44f";
}
.fi-ss-screen:before {
  content: "\f450";
}
.fi-ss-search-dollar:before {
  content: "\f451";
}
.fi-ss-search-location:before {
  content: "\f452";
}
.fi-ss-search-alt:before {
  content: "\f453";
}
.fi-ss-search-heart:before {
  content: "\f454";
}
.fi-ss-search:before {
  content: "\f455";
}
.fi-ss-select:before {
  content: "\f456";
}
.fi-ss-sensor-alert:before {
  content: "\f457";
}
.fi-ss-sensor-fire:before {
  content: "\f458";
}
.fi-ss-sensor-on:before {
  content: "\f459";
}
.fi-ss-sensor-smoke:before {
  content: "\f45a";
}
.fi-ss-sensor:before {
  content: "\f45b";
}
.fi-ss-settings-sliders:before {
  content: "\f45c";
}
.fi-ss-settings:before {
  content: "\f45d";
}
.fi-ss-share:before {
  content: "\f45e";
}
.fi-ss-shekel-sign:before {
  content: "\f45f";
}
.fi-ss-shield-check:before {
  content: "\f460";
}
.fi-ss-shield-exclamation:before {
  content: "\f461";
}
.fi-ss-shield-interrogation:before {
  content: "\f462";
}
.fi-ss-shield-plus:before {
  content: "\f463";
}
.fi-ss-shield:before {
  content: "\f464";
}
.fi-ss-ship-side:before {
  content: "\f465";
}
.fi-ss-ship:before {
  content: "\f466";
}
.fi-ss-shop:before {
  content: "\f467";
}
.fi-ss-shopping-basket:before {
  content: "\f468";
}
.fi-ss-shopping-bag-add:before {
  content: "\f469";
}
.fi-ss-shopping-bag:before {
  content: "\f46a";
}
.fi-ss-shopping-cart-add:before {
  content: "\f46b";
}
.fi-ss-shopping-cart-check:before {
  content: "\f46c";
}
.fi-ss-shopping-cart:before {
  content: "\f46d";
}
.fi-ss-shrimp:before {
  content: "\f46e";
}
.fi-ss-shuffle:before {
  content: "\f46f";
}
.fi-ss-shuttle-van:before {
  content: "\f470";
}
.fi-ss-shuttlecock:before {
  content: "\f471";
}
.fi-ss-sign-in-alt:before {
  content: "\f472";
}
.fi-ss-sign-out-alt:before {
  content: "\f473";
}
.fi-ss-signal-alt-1:before {
  content: "\f474";
}
.fi-ss-signal-alt-2:before {
  content: "\f475";
}
.fi-ss-signal-alt:before {
  content: "\f476";
}
.fi-ss-skateboard:before {
  content: "\f477";
}
.fi-ss-skating:before {
  content: "\f478";
}
.fi-ss-skewer:before {
  content: "\f479";
}
.fi-ss-ski-jump:before {
  content: "\f47a";
}
.fi-ss-ski-lift:before {
  content: "\f47b";
}
.fi-ss-skiing-nordic:before {
  content: "\f47c";
}
.fi-ss-skiing:before {
  content: "\f47d";
}
.fi-ss-sledding:before {
  content: "\f47e";
}
.fi-ss-sleigh:before {
  content: "\f47f";
}
.fi-ss-smartphone:before {
  content: "\f480";
}
.fi-ss-smile-beam:before {
  content: "\f481";
}
.fi-ss-smile-wink:before {
  content: "\f482";
}
.fi-ss-smile:before {
  content: "\f483";
}
.fi-ss-smog:before {
  content: "\f484";
}
.fi-ss-smoke:before {
  content: "\f485";
}
.fi-ss-snow-blowing:before {
  content: "\f486";
}
.fi-ss-snowboarding:before {
  content: "\f487";
}
.fi-ss-snowflake:before {
  content: "\f488";
}
.fi-ss-snowflakes:before {
  content: "\f489";
}
.fi-ss-snowmobile:before {
  content: "\f48a";
}
.fi-ss-snowplow:before {
  content: "\f48b";
}
.fi-ss-soap:before {
  content: "\f48c";
}
.fi-ss-social-network:before {
  content: "\f48d";
}
.fi-ss-sort-alpha-down-alt:before {
  content: "\f48e";
}
.fi-ss-sort-alpha-down:before {
  content: "\f48f";
}
.fi-ss-sort-alpha-up-alt:before {
  content: "\f490";
}
.fi-ss-sort-alpha-up:before {
  content: "\f491";
}
.fi-ss-sort-alt:before {
  content: "\f492";
}
.fi-ss-sort-amount-down-alt:before {
  content: "\f493";
}
.fi-ss-sort-amount-down:before {
  content: "\f494";
}
.fi-ss-sort-amount-up-alt:before {
  content: "\f495";
}
.fi-ss-sort-amount-up:before {
  content: "\f496";
}
.fi-ss-sort-down:before {
  content: "\f497";
}
.fi-ss-sort-numeric-down-alt:before {
  content: "\f498";
}
.fi-ss-sort-numeric-down:before {
  content: "\f499";
}
.fi-ss-sort:before {
  content: "\f49a";
}
.fi-ss-soup:before {
  content: "\f49b";
}
.fi-ss-spa:before {
  content: "\f49c";
}
.fi-ss-space-shuttle:before {
  content: "\f49d";
}
.fi-ss-spade:before {
  content: "\f49e";
}
.fi-ss-sparkles:before {
  content: "\f49f";
}
.fi-ss-speaker:before {
  content: "\f4a0";
}
.fi-ss-sphere:before {
  content: "\f4a1";
}
.fi-ss-spinner:before {
  content: "\f4a2";
}
.fi-ss-spoon:before {
  content: "\f4a3";
}
.fi-ss-square-root:before {
  content: "\f4a4";
}
.fi-ss-square:before {
  content: "\f4a5";
}
.fi-ss-stamp:before {
  content: "\f4a6";
}
.fi-ss-star-octogram:before {
  content: "\f4a7";
}
.fi-ss-star:before {
  content: "\f4a8";
}
.fi-ss-starfighter:before {
  content: "\f4a9";
}
.fi-ss-stars:before {
  content: "\f4aa";
}
.fi-ss-stats:before {
  content: "\f4ab";
}
.fi-ss-steak:before {
  content: "\f4ac";
}
.fi-ss-steering-wheel:before {
  content: "\f4ad";
}
.fi-ss-stethoscope:before {
  content: "\f4ae";
}
.fi-ss-sticker:before {
  content: "\f4af";
}
.fi-ss-stop:before {
  content: "\f4b0";
}
.fi-ss-stopwatch:before {
  content: "\f4b1";
}
.fi-ss-store-alt:before {
  content: "\f4b2";
}
.fi-ss-strawberry:before {
  content: "\f4b3";
}
.fi-ss-subtitles:before {
  content: "\f4b4";
}
.fi-ss-subway:before {
  content: "\f4b5";
}
.fi-ss-suitcase-alt:before {
  content: "\f4b6";
}
.fi-ss-summer:before {
  content: "\f4b7";
}
.fi-ss-sun:before {
  content: "\f4b8";
}
.fi-ss-sunrise-alt:before {
  content: "\f4b9";
}
.fi-ss-sunrise:before {
  content: "\f4ba";
}
.fi-ss-sunset:before {
  content: "\f4bb";
}
.fi-ss-surfing:before {
  content: "\f4bc";
}
.fi-ss-surprise:before {
  content: "\f4bd";
}
.fi-ss-sushi:before {
  content: "\f4be";
}
.fi-ss-swimmer:before {
  content: "\f4bf";
}
.fi-ss-sword:before {
  content: "\f4c0";
}
.fi-ss-symbol:before {
  content: "\f4c1";
}
.fi-ss-syringe:before {
  content: "\f4c2";
}
.fi-ss-t:before {
  content: "\f4c3";
}
.fi-ss-tablet:before {
  content: "\f4c4";
}
.fi-ss-tachometer-alt-average:before {
  content: "\f4c5";
}
.fi-ss-tachometer-alt-fastest:before {
  content: "\f4c6";
}
.fi-ss-tachometer-alt-slow:before {
  content: "\f4c7";
}
.fi-ss-tachometer-alt-slowest:before {
  content: "\f4c8";
}
.fi-ss-tachometer-average:before {
  content: "\f4c9";
}
.fi-ss-tachometer-fast:before {
  content: "\f4ca";
}
.fi-ss-tachometer-fastest:before {
  content: "\f4cb";
}
.fi-ss-tachometer-slow:before {
  content: "\f4cc";
}
.fi-ss-tachometer-slowest:before {
  content: "\f4cd";
}
.fi-ss-tachometer:before {
  content: "\f4ce";
}
.fi-ss-taco:before {
  content: "\f4cf";
}
.fi-ss-tags:before {
  content: "\f4d0";
}
.fi-ss-tally:before {
  content: "\f4d1";
}
.fi-ss-target:before {
  content: "\f4d2";
}
.fi-ss-taxi:before {
  content: "\f4d3";
}
.fi-ss-temperature-down:before {
  content: "\f4d4";
}
.fi-ss-temperature-frigid:before {
  content: "\f4d5";
}
.fi-ss-temperature-high:before {
  content: "\f4d6";
}
.fi-ss-temperature-low:before {
  content: "\f4d7";
}
.fi-ss-temperature-up:before {
  content: "\f4d8";
}
.fi-ss-tenge:before {
  content: "\f4d9";
}
.fi-ss-tennis:before {
  content: "\f4da";
}
.fi-ss-terrace:before {
  content: "\f4db";
}
.fi-ss-test-tube:before {
  content: "\f4dc";
}
.fi-ss-test:before {
  content: "\f4dd";
}
.fi-ss-text-check:before {
  content: "\f4de";
}
.fi-ss-text-slash:before {
  content: "\f4df";
}
.fi-ss-text:before {
  content: "\f4e0";
}
.fi-ss-thermometer-half:before {
  content: "\f4e1";
}
.fi-ss-thumbtack:before {
  content: "\f4e2";
}
.fi-ss-thunderstorm-moon:before {
  content: "\f4e3";
}
.fi-ss-thunderstorm-sun:before {
  content: "\f4e4";
}
.fi-ss-thunderstorm:before {
  content: "\f4e5";
}
.fi-ss-ticket:before {
  content: "\f4e6";
}
.fi-ss-time-quarter-past:before {
  content: "\f4e7";
}
.fi-ss-time-add:before {
  content: "\f4e8";
}
.fi-ss-time-check:before {
  content: "\f4e9";
}
.fi-ss-time-delete:before {
  content: "\f4ea";
}
.fi-ss-time-fast:before {
  content: "\f4eb";
}
.fi-ss-time-forward-sixty:before {
  content: "\f4ec";
}
.fi-ss-time-forward-ten:before {
  content: "\f4ed";
}
.fi-ss-time-forward:before {
  content: "\f4ee";
}
.fi-ss-time-half-past:before {
  content: "\f4ef";
}
.fi-ss-time-oclock:before {
  content: "\f4f0";
}
.fi-ss-time-past:before {
  content: "\f4f1";
}
.fi-ss-time-quarter-to:before {
  content: "\f4f2";
}
.fi-ss-time-twenty-four:before {
  content: "\f4f3";
}
.fi-ss-tire-flat:before {
  content: "\f4f4";
}
.fi-ss-tire-pressure-warning:before {
  content: "\f4f5";
}
.fi-ss-tire-rugged:before {
  content: "\f4f6";
}
.fi-ss-tire:before {
  content: "\f4f7";
}
.fi-ss-tired:before {
  content: "\f4f8";
}
.fi-ss-tomato:before {
  content: "\f4f9";
}
.fi-ss-tool-box:before {
  content: "\f4fa";
}
.fi-ss-tool-crop:before {
  content: "\f4fb";
}
.fi-ss-tool-marquee:before {
  content: "\f4fc";
}
.fi-ss-tooth:before {
  content: "\f4fd";
}
.fi-ss-tornado:before {
  content: "\f4fe";
}
.fi-ss-tractor:before {
  content: "\f4ff";
}
.fi-ss-trailer:before {
  content: "\f500";
}
.fi-ss-train-side:before {
  content: "\f501";
}
.fi-ss-train:before {
  content: "\f502";
}
.fi-ss-tram:before {
  content: "\f503";
}
.fi-ss-transform:before {
  content: "\f504";
}
.fi-ss-trash:before {
  content: "\f505";
}
.fi-ss-treasure-chest:before {
  content: "\f506";
}
.fi-ss-treatment:before {
  content: "\f507";
}
.fi-ss-tree-christmas:before {
  content: "\f508";
}
.fi-ss-tree:before {
  content: "\f509";
}
.fi-ss-triangle:before {
  content: "\f50a";
}
.fi-ss-trophy:before {
  content: "\f50b";
}
.fi-ss-truck-container:before {
  content: "\f50c";
}
.fi-ss-truck-couch:before {
  content: "\f50d";
}
.fi-ss-truck-loading:before {
  content: "\f50e";
}
.fi-ss-truck-monster:before {
  content: "\f50f";
}
.fi-ss-truck-moving:before {
  content: "\f510";
}
.fi-ss-truck-pickup:before {
  content: "\f511";
}
.fi-ss-truck-plow:before {
  content: "\f512";
}
.fi-ss-truck-ramp:before {
  content: "\f513";
}
.fi-ss-truck-side:before {
  content: "\f514";
}
.fi-ss-tty:before {
  content: "\f515";
}
.fi-ss-turkey:before {
  content: "\f516";
}
.fi-ss-tv-music:before {
  content: "\f517";
}
.fi-ss-typewriter:before {
  content: "\f518";
}
.fi-ss-u:before {
  content: "\f519";
}
.fi-ss-umbrella:before {
  content: "\f51a";
}
.fi-ss-underline:before {
  content: "\f51b";
}
.fi-ss-undo-alt:before {
  content: "\f51c";
}
.fi-ss-undo:before {
  content: "\f51d";
}
.fi-ss-unlock:before {
  content: "\f51e";
}
.fi-ss-upload:before {
  content: "\f51f";
}
.fi-ss-usb-pendrive:before {
  content: "\f520";
}
.fi-ss-usd-circle:before {
  content: "\f521";
}
.fi-ss-usd-square:before {
  content: "\f522";
}
.fi-ss-user-add:before {
  content: "\f523";
}
.fi-ss-user-time:before {
  content: "\f524";
}
.fi-ss-user:before {
  content: "\f525";
}
.fi-ss-users-alt:before {
  content: "\f526";
}
.fi-ss-users:before {
  content: "\f527";
}
.fi-ss-utensils:before {
  content: "\f528";
}
.fi-ss-v:before {
  content: "\f529";
}
.fi-ss-vector-alt:before {
  content: "\f52a";
}
.fi-ss-vector:before {
  content: "\f52b";
}
.fi-ss-venus-double:before {
  content: "\f52c";
}
.fi-ss-venus-mars:before {
  content: "\f52d";
}
.fi-ss-venus:before {
  content: "\f52e";
}
.fi-ss-video-arrow-down-left:before {
  content: "\f52f";
}
.fi-ss-video-arrow-up-right:before {
  content: "\f530";
}
.fi-ss-video-camera-alt:before {
  content: "\f531";
}
.fi-ss-video-camera:before {
  content: "\f532";
}
.fi-ss-video-plus:before {
  content: "\f533";
}
.fi-ss-video-slash:before {
  content: "\f534";
}
.fi-ss-volcano:before {
  content: "\f535";
}
.fi-ss-volleyball:before {
  content: "\f536";
}
.fi-ss-volume:before {
  content: "\f537";
}
.fi-ss-w:before {
  content: "\f538";
}
.fi-ss-wagon-covered:before {
  content: "\f539";
}
.fi-ss-wallet:before {
  content: "\f53a";
}
.fi-ss-water-bottle:before {
  content: "\f53b";
}
.fi-ss-water-lower:before {
  content: "\f53c";
}
.fi-ss-water-rise:before {
  content: "\f53d";
}
.fi-ss-water:before {
  content: "\f53e";
}
.fi-ss-watermelon:before {
  content: "\f53f";
}
.fi-ss-wheat:before {
  content: "\f540";
}
.fi-ss-wheelchair:before {
  content: "\f541";
}
.fi-ss-whistle:before {
  content: "\f542";
}
.fi-ss-wifi-alt:before {
  content: "\f543";
}
.fi-ss-wind-warning:before {
  content: "\f544";
}
.fi-ss-wind:before {
  content: "\f545";
}
.fi-ss-windsock:before {
  content: "\f546";
}
.fi-ss-woman-head:before {
  content: "\f547";
}
.fi-ss-world:before {
  content: "\f548";
}
.fi-ss-x:before {
  content: "\f549";
}
.fi-ss-y:before {
  content: "\f54a";
}
.fi-ss-yen:before {
  content: "\f54b";
}
.fi-ss-z:before {
  content: "\f54c";
}
.fi-ss-zoom-in:before {
  content: "\f54d";
}
.fi-ss-zoom-out:before {
  content: "\f54e";
}
@font-face {
  font-family: "uicons-thin-rounded";
  src: url("../fonts/uicons-thin-rounded.eot#iefix") format("embedded-opentype"),
    url("../fonts/uicons-thin-rounded.woff2") format("woff2"),
    url("../fonts/uicons-thin-rounded.woff") format("woff");
}

i[class^="fi-tr-"]:before,
i[class*=" fi-tr-"]:before,
span[class^="fi-tr-"]:before,
span[class*="fi-tr-"]:before {
  font-family: uicons-thin-rounded !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi-tr-a:before {
  content: "\f101";
}
.fi-tr-ad:before {
  content: "\f102";
}
.fi-tr-air-conditioner:before {
  content: "\f103";
}
.fi-tr-air-freshener:before {
  content: "\f104";
}
.fi-tr-alarm-exclamation:before {
  content: "\f105";
}
.fi-tr-alarm-plus:before {
  content: "\f106";
}
.fi-tr-alarm-snooze:before {
  content: "\f107";
}
.fi-tr-angle-circle-down:before {
  content: "\f108";
}
.fi-tr-angle-circle-left:before {
  content: "\f109";
}
.fi-tr-angle-circle-right:before {
  content: "\f10a";
}
.fi-tr-angle-circle-up:before {
  content: "\f10b";
}
.fi-tr-angle-double-small-down:before {
  content: "\f10c";
}
.fi-tr-angle-double-small-up:before {
  content: "\f10d";
}
.fi-tr-angle-square-down:before {
  content: "\f10e";
}
.fi-tr-angle-square-left:before {
  content: "\f10f";
}
.fi-tr-angle-square-right:before {
  content: "\f110";
}
.fi-tr-angle-square-up:before {
  content: "\f111";
}
.fi-tr-angry:before {
  content: "\f112";
}
.fi-tr-arrow-alt-circle-down:before {
  content: "\f113";
}
.fi-tr-arrow-alt-circle-left:before {
  content: "\f114";
}
.fi-tr-arrow-alt-circle-right:before {
  content: "\f115";
}
.fi-tr-arrow-alt-circle-up:before {
  content: "\f116";
}
.fi-tr-arrow-alt-down:before {
  content: "\f117";
}
.fi-tr-arrow-alt-from-bottom:before {
  content: "\f118";
}
.fi-tr-arrow-alt-from-left:before {
  content: "\f119";
}
.fi-tr-arrow-alt-from-right:before {
  content: "\f11a";
}
.fi-tr-arrow-alt-from-top:before {
  content: "\f11b";
}
.fi-tr-arrow-alt-left:before {
  content: "\f11c";
}
.fi-tr-arrow-alt-right:before {
  content: "\f11d";
}
.fi-tr-arrow-alt-square-down:before {
  content: "\f11e";
}
.fi-tr-arrow-alt-square-left:before {
  content: "\f11f";
}
.fi-tr-arrow-alt-square-right:before {
  content: "\f120";
}
.fi-tr-arrow-alt-square-up:before {
  content: "\f121";
}
.fi-tr-arrow-alt-to-bottom:before {
  content: "\f122";
}
.fi-tr-arrow-alt-to-left:before {
  content: "\f123";
}
.fi-tr-arrow-alt-to-right:before {
  content: "\f124";
}
.fi-tr-arrow-alt-to-top:before {
  content: "\f125";
}
.fi-tr-arrow-alt-up:before {
  content: "\f126";
}
.fi-tr-arrow-circle-down:before {
  content: "\f127";
}
.fi-tr-arrow-circle-left:before {
  content: "\f128";
}
.fi-tr-arrow-circle-right:before {
  content: "\f129";
}
.fi-tr-arrow-circle-up:before {
  content: "\f12a";
}
.fi-tr-arrow-from-left:before {
  content: "\f12b";
}
.fi-tr-arrow-from-right:before {
  content: "\f12c";
}
.fi-tr-arrow-from-top:before {
  content: "\f12d";
}
.fi-tr-arrow-square-down:before {
  content: "\f12e";
}
.fi-tr-arrow-square-left:before {
  content: "\f12f";
}
.fi-tr-arrow-square-right:before {
  content: "\f130";
}
.fi-tr-arrow-square-up:before {
  content: "\f131";
}
.fi-tr-arrow-to-bottom:before {
  content: "\f132";
}
.fi-tr-arrow-to-left:before {
  content: "\f133";
}
.fi-tr-arrow-to-right:before {
  content: "\f134";
}
.fi-tr-arrow-to-top:before {
  content: "\f135";
}
.fi-tr-arrow-trend-down:before {
  content: "\f136";
}
.fi-tr-arrow-trend-up:before {
  content: "\f137";
}
.fi-tr-arrow-up-from-square:before {
  content: "\f138";
}
.fi-tr-arrow-up-left-from-circle:before {
  content: "\f139";
}
.fi-tr-arrow-up-left:before {
  content: "\f13a";
}
.fi-tr-arrow-up-right-and-arrow-down-left-from-center:before {
  content: "\f13b";
}
.fi-tr-arrow-up-right-from-square:before {
  content: "\f13c";
}
.fi-tr-arrow-up-right:before {
  content: "\f13d";
}
.fi-tr-arrow-up-square-triangle:before {
  content: "\f13e";
}
.fi-tr-arrow-up-to-dotted-line:before {
  content: "\f13f";
}
.fi-tr-arrows-alt-h:before {
  content: "\f140";
}
.fi-tr-arrows-alt-v:before {
  content: "\f141";
}
.fi-tr-arrows-alt:before {
  content: "\f142";
}
.fi-tr-arrows-cross:before {
  content: "\f143";
}
.fi-tr-arrows-from-dotted-line:before {
  content: "\f144";
}
.fi-tr-arrows-from-line:before {
  content: "\f145";
}
.fi-tr-arrows-h-copy:before {
  content: "\f146";
}
.fi-tr-arrows-h:before {
  content: "\f147";
}
.fi-tr-arrows-repeat-1:before {
  content: "\f148";
}
.fi-tr-arrows-repeat:before {
  content: "\f149";
}
.fi-tr-arrows-to-dotted-line:before {
  content: "\f14a";
}
.fi-tr-arrows-to-line:before {
  content: "\f14b";
}
.fi-tr-arrows:before {
  content: "\f14c";
}
.fi-tr-assistive-listening-systems:before {
  content: "\f14d";
}
.fi-tr-aubergine:before {
  content: "\f14e";
}
.fi-tr-avocado:before {
  content: "\f14f";
}
.fi-tr-b:before {
  content: "\f150";
}
.fi-tr-baby-carriage:before {
  content: "\f151";
}
.fi-tr-bacon:before {
  content: "\f152";
}
.fi-tr-badge-check:before {
  content: "\f153";
}
.fi-tr-badge-dollar:before {
  content: "\f154";
}
.fi-tr-badge-percent:before {
  content: "\f155";
}
.fi-tr-badge-sheriff:before {
  content: "\f156";
}
.fi-tr-bags-shopping:before {
  content: "\f157";
}
.fi-tr-bahai:before {
  content: "\f158";
}
.fi-tr-balance-scale-left:before {
  content: "\f159";
}
.fi-tr-balance-scale-right:before {
  content: "\f15a";
}
.fi-tr-balance-scale:before {
  content: "\f15b";
}
.fi-tr-ballot:before {
  content: "\f15c";
}
.fi-tr-barcode-read:before {
  content: "\f15d";
}
.fi-tr-barcode-scan:before {
  content: "\f15e";
}
.fi-tr-barcode:before {
  content: "\f15f";
}
.fi-tr-bars-progress:before {
  content: "\f160";
}
.fi-tr-baseball-alt:before {
  content: "\f161";
}
.fi-tr-basketball-hoop:before {
  content: "\f162";
}
.fi-tr-bed-alt:before {
  content: "\f163";
}
.fi-tr-biking-mountain:before {
  content: "\f164";
}
.fi-tr-biking:before {
  content: "\f165";
}
.fi-tr-blinds-open:before {
  content: "\f166";
}
.fi-tr-blinds-raised:before {
  content: "\f167";
}
.fi-tr-blinds:before {
  content: "\f168";
}
.fi-tr-bolt:before {
  content: "\f169";
}
.fi-tr-book-arrow-right:before {
  content: "\f16a";
}
.fi-tr-book-arrow-up:before {
  content: "\f16b";
}
.fi-tr-book-bookmark:before {
  content: "\f16c";
}
.fi-tr-book-open-cover:before {
  content: "\f16d";
}
.fi-tr-book-open-reader:before {
  content: "\f16e";
}
.fi-tr-books:before {
  content: "\f16f";
}
.fi-tr-bottle:before {
  content: "\f170";
}
.fi-tr-bow-arrow:before {
  content: "\f171";
}
.fi-tr-bowling-ball:before {
  content: "\f172";
}
.fi-tr-bowling-pins:before {
  content: "\f173";
}
.fi-tr-box-ballot:before {
  content: "\f174";
}
.fi-tr-box-check:before {
  content: "\f175";
}
.fi-tr-box-tissue:before {
  content: "\f176";
}
.fi-tr-boxes:before {
  content: "\f177";
}
.fi-tr-boxing-glove:before {
  content: "\f178";
}
.fi-tr-braille:before {
  content: "\f179";
}
.fi-tr-brain-circuit:before {
  content: "\f17a";
}
.fi-tr-bread:before {
  content: "\f17b";
}
.fi-tr-broccoli:before {
  content: "\f17c";
}
.fi-tr-bullseye-pointer:before {
  content: "\f17d";
}
.fi-tr-bullseye:before {
  content: "\f17e";
}
.fi-tr-burrito:before {
  content: "\f17f";
}
.fi-tr-bus-alt:before {
  content: "\f180";
}
.fi-tr-bus:before {
  content: "\f181";
}
.fi-tr-business-time:before {
  content: "\f182";
}
.fi-tr-c:before {
  content: "\f183";
}
.fi-tr-calendar-check:before {
  content: "\f184";
}
.fi-tr-calendar-clock:before {
  content: "\f185";
}
.fi-tr-calendar-exclamation:before {
  content: "\f186";
}
.fi-tr-calendar-lines-pen:before {
  content: "\f187";
}
.fi-tr-calendar-lines:before {
  content: "\f188";
}
.fi-tr-calendar-minus:before {
  content: "\f189";
}
.fi-tr-calendar-pen:before {
  content: "\f18a";
}
.fi-tr-calendar-plus:before {
  content: "\f18b";
}
.fi-tr-calendars:before {
  content: "\f18c";
}
.fi-tr-camcorder:before {
  content: "\f18d";
}
.fi-tr-candy-cane:before {
  content: "\f18e";
}
.fi-tr-candy:before {
  content: "\f18f";
}
.fi-tr-canned-food:before {
  content: "\f190";
}
.fi-tr-car-alt:before {
  content: "\f191";
}
.fi-tr-car-battery:before {
  content: "\f192";
}
.fi-tr-car-bolt:before {
  content: "\f193";
}
.fi-tr-car-building:before {
  content: "\f194";
}
.fi-tr-car-bump:before {
  content: "\f195";
}
.fi-tr-car-bus:before {
  content: "\f196";
}
.fi-tr-car-circle-bolt:before {
  content: "\f197";
}
.fi-tr-car-crash:before {
  content: "\f198";
}
.fi-tr-car-garage:before {
  content: "\f199";
}
.fi-tr-car-mechanic:before {
  content: "\f19a";
}
.fi-tr-car-side-bolt:before {
  content: "\f19b";
}
.fi-tr-car-side:before {
  content: "\f19c";
}
.fi-tr-car-tilt:before {
  content: "\f19d";
}
.fi-tr-car-wash:before {
  content: "\f19e";
}
.fi-tr-caravan-alt:before {
  content: "\f19f";
}
.fi-tr-caravan:before {
  content: "\f1a0";
}
.fi-tr-caret-circle-down:before {
  content: "\f1a1";
}
.fi-tr-caret-circle-right:before {
  content: "\f1a2";
}
.fi-tr-caret-circle-up:before {
  content: "\f1a3";
}
.fi-tr-caret-square-down:before {
  content: "\f1a4";
}
.fi-tr-caret-square-left_1:before {
  content: "\f1a5";
}
.fi-tr-caret-square-left:before {
  content: "\f1a6";
}
.fi-tr-caret-square-right:before {
  content: "\f1a7";
}
.fi-tr-caret-square-up:before {
  content: "\f1a8";
}
.fi-tr-cars:before {
  content: "\f1a9";
}
.fi-tr-cash-register:before {
  content: "\f1aa";
}
.fi-tr-chair-office:before {
  content: "\f1ab";
}
.fi-tr-chair:before {
  content: "\f1ac";
}
.fi-tr-charging-station:before {
  content: "\f1ad";
}
.fi-tr-chart-area:before {
  content: "\f1ae";
}
.fi-tr-chart-line-up:before {
  content: "\f1af";
}
.fi-tr-chart-scatter:before {
  content: "\f1b0";
}
.fi-tr-cherry:before {
  content: "\f1b1";
}
.fi-tr-chess-bishop:before {
  content: "\f1b2";
}
.fi-tr-chess-board:before {
  content: "\f1b3";
}
.fi-tr-chess-clock-alt:before {
  content: "\f1b4";
}
.fi-tr-chess-clock:before {
  content: "\f1b5";
}
.fi-tr-chess-king-alt:before {
  content: "\f1b6";
}
.fi-tr-chess-king:before {
  content: "\f1b7";
}
.fi-tr-chess-knight-alt:before {
  content: "\f1b8";
}
.fi-tr-chess-knight:before {
  content: "\f1b9";
}
.fi-tr-chess-pawn-alt:before {
  content: "\f1ba";
}
.fi-tr-chess-pawn:before {
  content: "\f1bb";
}
.fi-tr-chess-queen-alt:before {
  content: "\f1bc";
}
.fi-tr-chess-queen:before {
  content: "\f1bd";
}
.fi-tr-chess-rook-alt:before {
  content: "\f1be";
}
.fi-tr-chess-rook:before {
  content: "\f1bf";
}
.fi-tr-chess:before {
  content: "\f1c0";
}
.fi-tr-chevron-double-down:before {
  content: "\f1c1";
}
.fi-tr-chevron-double-up:before {
  content: "\f1c2";
}
.fi-tr-chocolate-bar:before {
  content: "\f1c3";
}
.fi-tr-circle-envelope:before {
  content: "\f1c4";
}
.fi-tr-circle-phone-flip:before {
  content: "\f1c5";
}
.fi-tr-circle-phone-hangup:before {
  content: "\f1c6";
}
.fi-tr-circle-phone:before {
  content: "\f1c7";
}
.fi-tr-clipboard-list-check:before {
  content: "\f1c8";
}
.fi-tr-clipboard-list:before {
  content: "\f1c9";
}
.fi-tr-clock-eight-thirty:before {
  content: "\f1ca";
}
.fi-tr-clock-eleven-thirty:before {
  content: "\f1cb";
}
.fi-tr-clock-eleven:before {
  content: "\f1cc";
}
.fi-tr-clock-five-thirty:before {
  content: "\f1cd";
}
.fi-tr-clock-five:before {
  content: "\f1ce";
}
.fi-tr-clock-four-thirty:before {
  content: "\f1cf";
}
.fi-tr-clock-nine-thirty:before {
  content: "\f1d0";
}
.fi-tr-clock-nine:before {
  content: "\f1d1";
}
.fi-tr-clock-one-thirty:before {
  content: "\f1d2";
}
.fi-tr-clock-one:before {
  content: "\f1d3";
}
.fi-tr-clock-seven-thirty:before {
  content: "\f1d4";
}
.fi-tr-clock-seven:before {
  content: "\f1d5";
}
.fi-tr-clock-six-thirty:before {
  content: "\f1d6";
}
.fi-tr-clock-six:before {
  content: "\f1d7";
}
.fi-tr-clock-ten-thirty:before {
  content: "\f1d8";
}
.fi-tr-clock-ten:before {
  content: "\f1d9";
}
.fi-tr-clock-three-thirty:before {
  content: "\f1da";
}
.fi-tr-clock-three:before {
  content: "\f1db";
}
.fi-tr-clock-twelve-thirty:before {
  content: "\f1dc";
}
.fi-tr-clock-twelve:before {
  content: "\f1dd";
}
.fi-tr-clock-two-thirty:before {
  content: "\f1de";
}
.fi-tr-clock-two:before {
  content: "\f1df";
}
.fi-tr-cloud-download-alt:before {
  content: "\f1e0";
}
.fi-tr-cloud-drizzle:before {
  content: "\f1e1";
}
.fi-tr-cloud-hail-mixed:before {
  content: "\f1e2";
}
.fi-tr-cloud-hail:before {
  content: "\f1e3";
}
.fi-tr-cloud-moon-rain:before {
  content: "\f1e4";
}
.fi-tr-cloud-moon:before {
  content: "\f1e5";
}
.fi-tr-cloud-rain:before {
  content: "\f1e6";
}
.fi-tr-cloud-rainbow:before {
  content: "\f1e7";
}
.fi-tr-cloud-showers-heavy:before {
  content: "\f1e8";
}
.fi-tr-cloud-showers:before {
  content: "\f1e9";
}
.fi-tr-cloud-sleet:before {
  content: "\f1ea";
}
.fi-tr-cloud-snow:before {
  content: "\f1eb";
}
.fi-tr-cloud-sun-rain:before {
  content: "\f1ec";
}
.fi-tr-cloud-sun:before {
  content: "\f1ed";
}
.fi-tr-cloud-upload-alt:before {
  content: "\f1ee";
}
.fi-tr-clouds-moon:before {
  content: "\f1ef";
}
.fi-tr-clouds-sun:before {
  content: "\f1f0";
}
.fi-tr-club:before {
  content: "\f1f1";
}
.fi-tr-code-branch:before {
  content: "\f1f2";
}
.fi-tr-code-commit:before {
  content: "\f1f3";
}
.fi-tr-code-compare:before {
  content: "\f1f4";
}
.fi-tr-code-fork:before {
  content: "\f1f5";
}
.fi-tr-code-merge:before {
  content: "\f1f6";
}
.fi-tr-code-pull-request-closed:before {
  content: "\f1f7";
}
.fi-tr-code-pull-request-draft:before {
  content: "\f1f8";
}
.fi-tr-code-pull-request:before {
  content: "\f1f9";
}
.fi-tr-code-simple:before {
  content: "\f1fa";
}
.fi-tr-coffee-pot:before {
  content: "\f1fb";
}
.fi-tr-coin:before {
  content: "\f1fc";
}
.fi-tr-coins:before {
  content: "\f1fd";
}
.fi-tr-comet:before {
  content: "\f1fe";
}
.fi-tr-comment-alt-middle-top:before {
  content: "\f1ff";
}
.fi-tr-comment-alt-middle:before {
  content: "\f200";
}
.fi-tr-comment-arrow-down:before {
  content: "\f201";
}
.fi-tr-comment-arrow-up-right:before {
  content: "\f202";
}
.fi-tr-comment-arrow-up:before {
  content: "\f203";
}
.fi-tr-comment-code:before {
  content: "\f204";
}
.fi-tr-comment-dollar:before {
  content: "\f205";
}
.fi-tr-comment-exclamation:before {
  content: "\f206";
}
.fi-tr-comment-image:before {
  content: "\f207";
}
.fi-tr-comment-pen:before {
  content: "\f208";
}
.fi-tr-comment-question:before {
  content: "\f209";
}
.fi-tr-comment-quote:before {
  content: "\f20a";
}
.fi-tr-comment-slash:before {
  content: "\f20b";
}
.fi-tr-comment-sms:before {
  content: "\f20c";
}
.fi-tr-comment-text:before {
  content: "\f20d";
}
.fi-tr-comment-xmark:before {
  content: "\f20e";
}
.fi-tr-comments-dollar:before {
  content: "\f20f";
}
.fi-tr-comments-question-check:before {
  content: "\f210";
}
.fi-tr-comments-question:before {
  content: "\f211";
}
.fi-tr-compress-alt:before {
  content: "\f212";
}
.fi-tr-corn:before {
  content: "\f213";
}
.fi-tr-cricket:before {
  content: "\f214";
}
.fi-tr-crystal-ball:before {
  content: "\f215";
}
.fi-tr-curling:before {
  content: "\f216";
}
.fi-tr-d:before {
  content: "\f217";
}
.fi-tr-democrat:before {
  content: "\f218";
}
.fi-tr-dewpoint:before {
  content: "\f219";
}
.fi-tr-diagram-project:before {
  content: "\f21a";
}
.fi-tr-dice-alt:before {
  content: "\f21b";
}
.fi-tr-dice-d10:before {
  content: "\f21c";
}
.fi-tr-dice-d12:before {
  content: "\f21d";
}
.fi-tr-dice-d20:before {
  content: "\f21e";
}
.fi-tr-dice-d4:before {
  content: "\f21f";
}
.fi-tr-dice-d6:before {
  content: "\f220";
}
.fi-tr-dice-d8:before {
  content: "\f221";
}
.fi-tr-dice-four:before {
  content: "\f222";
}
.fi-tr-dice-one:before {
  content: "\f223";
}
.fi-tr-dice-six:before {
  content: "\f224";
}
.fi-tr-dice-three:before {
  content: "\f225";
}
.fi-tr-dice-two:before {
  content: "\f226";
}
.fi-tr-display-code:before {
  content: "\f227";
}
.fi-tr-dizzy:before {
  content: "\f228";
}
.fi-tr-donate:before {
  content: "\f229";
}
.fi-tr-drafting-compass:before {
  content: "\f22a";
}
.fi-tr-dreidel:before {
  content: "\f22b";
}
.fi-tr-drink:before {
  content: "\f22c";
}
.fi-tr-e:before {
  content: "\f22d";
}
.fi-tr-eclipse-alt:before {
  content: "\f22e";
}
.fi-tr-eclipse:before {
  content: "\f22f";
}
.fi-tr-egg-fried:before {
  content: "\f230";
}
.fi-tr-egg:before {
  content: "\f231";
}
.fi-tr-engine-warning:before {
  content: "\f232";
}
.fi-tr-envelope-bulk:before {
  content: "\f233";
}
.fi-tr-envelope-open-dollar:before {
  content: "\f234";
}
.fi-tr-envelope-open-text:before {
  content: "\f235";
}
.fi-tr-equality:before {
  content: "\f236";
}
.fi-tr-exchange-alt:before {
  content: "\f237";
}
.fi-tr-exchange:before {
  content: "\f238";
}
.fi-tr-expand-arrows-alt:before {
  content: "\f239";
}
.fi-tr-expand-arrows:before {
  content: "\f23a";
}
.fi-tr-f:before {
  content: "\f23b";
}
.fi-tr-field-hockey:before {
  content: "\f23c";
}
.fi-tr-fighter-jet:before {
  content: "\f23d";
}
.fi-tr-file-chart-line:before {
  content: "\f23e";
}
.fi-tr-file-chart-pie:before {
  content: "\f23f";
}
.fi-tr-file-code:before {
  content: "\f240";
}
.fi-tr-file-invoice-dollar:before {
  content: "\f241";
}
.fi-tr-file-invoice:before {
  content: "\f242";
}
.fi-tr-file-spreadsheet:before {
  content: "\f243";
}
.fi-tr-file-video:before {
  content: "\f244";
}
.fi-tr-film-slash:before {
  content: "\f245";
}
.fi-tr-filter-slash:before {
  content: "\f246";
}
.fi-tr-filters:before {
  content: "\f247";
}
.fi-tr-flag-alt:before {
  content: "\f248";
}
.fi-tr-flag-usa:before {
  content: "\f249";
}
.fi-tr-flushed:before {
  content: "\f24a";
}
.fi-tr-fog:before {
  content: "\f24b";
}
.fi-tr-folder-download:before {
  content: "\f24c";
}
.fi-tr-folder-minus:before {
  content: "\f24d";
}
.fi-tr-folder-times:before {
  content: "\f24e";
}
.fi-tr-folder-tree:before {
  content: "\f24f";
}
.fi-tr-folder-upload:before {
  content: "\f250";
}
.fi-tr-folders:before {
  content: "\f251";
}
.fi-tr-fork:before {
  content: "\f252";
}
.fi-tr-french-fries:before {
  content: "\f253";
}
.fi-tr-funnel-dollar:before {
  content: "\f254";
}
.fi-tr-g:before {
  content: "\f255";
}
.fi-tr-game-board-alt:before {
  content: "\f256";
}
.fi-tr-garage-car:before {
  content: "\f257";
}
.fi-tr-garage-open:before {
  content: "\f258";
}
.fi-tr-garage:before {
  content: "\f259";
}
.fi-tr-garlic:before {
  content: "\f25a";
}
.fi-tr-gas-pump-slash:before {
  content: "\f25b";
}
.fi-tr-gas-pump:before {
  content: "\f25c";
}
.fi-tr-gift-card:before {
  content: "\f25d";
}
.fi-tr-gifts:before {
  content: "\f25e";
}
.fi-tr-gingerbread-man:before {
  content: "\f25f";
}
.fi-tr-glass:before {
  content: "\f260";
}
.fi-tr-golf-ball:before {
  content: "\f261";
}
.fi-tr-golf-club:before {
  content: "\f262";
}
.fi-tr-grape:before {
  content: "\f263";
}
.fi-tr-grill:before {
  content: "\f264";
}
.fi-tr-grimace:before {
  content: "\f265";
}
.fi-tr-grin-alt:before {
  content: "\f266";
}
.fi-tr-grin-beam-sweat:before {
  content: "\f267";
}
.fi-tr-grin-beam:before {
  content: "\f268";
}
.fi-tr-grin-hearts:before {
  content: "\f269";
}
.fi-tr-grin-squint-tears:before {
  content: "\f26a";
}
.fi-tr-grin-squint:before {
  content: "\f26b";
}
.fi-tr-grin-stars:before {
  content: "\f26c";
}
.fi-tr-grin-tears:before {
  content: "\f26d";
}
.fi-tr-grin-tongue-squint:before {
  content: "\f26e";
}
.fi-tr-grin-tongue-wink:before {
  content: "\f26f";
}
.fi-tr-grin-tongue:before {
  content: "\f270";
}
.fi-tr-grin-wink:before {
  content: "\f271";
}
.fi-tr-grin:before {
  content: "\f272";
}
.fi-tr-h:before {
  content: "\f273";
}
.fi-tr-hamburger-soda:before {
  content: "\f274";
}
.fi-tr-hand-holding-box:before {
  content: "\f275";
}
.fi-tr-hand-holding-seeding:before {
  content: "\f276";
}
.fi-tr-handshake:before {
  content: "\f277";
}
.fi-tr-hat-chef:before {
  content: "\f278";
}
.fi-tr-heat:before {
  content: "\f279";
}
.fi-tr-helicopter-side:before {
  content: "\f27a";
}
.fi-tr-highlighter:before {
  content: "\f27b";
}
.fi-tr-hiking:before {
  content: "\f27c";
}
.fi-tr-hockey-mask:before {
  content: "\f27d";
}
.fi-tr-hockey-puck:before {
  content: "\f27e";
}
.fi-tr-hockey-sticks:before {
  content: "\f27f";
}
.fi-tr-hotdog:before {
  content: "\f280";
}
.fi-tr-house-flood:before {
  content: "\f281";
}
.fi-tr-hryvnia:before {
  content: "\f282";
}
.fi-tr-humidity:before {
  content: "\f283";
}
.fi-tr-hurricane:before {
  content: "\f284";
}
.fi-tr-i:before {
  content: "\f285";
}
.fi-tr-inbox-in:before {
  content: "\f286";
}
.fi-tr-inbox-out:before {
  content: "\f287";
}
.fi-tr-j:before {
  content: "\f288";
}
.fi-tr-jam:before {
  content: "\f289";
}
.fi-tr-k:before {
  content: "\f28a";
}
.fi-tr-kerning:before {
  content: "\f28b";
}
.fi-tr-keynote:before {
  content: "\f28c";
}
.fi-tr-kiss-beam:before {
  content: "\f28d";
}
.fi-tr-kiss-wink-heart:before {
  content: "\f28e";
}
.fi-tr-kiss:before {
  content: "\f28f";
}
.fi-tr-knife:before {
  content: "\f290";
}
.fi-tr-l:before {
  content: "\f291";
}
.fi-tr-laptop-code:before {
  content: "\f292";
}
.fi-tr-laugh-beam:before {
  content: "\f293";
}
.fi-tr-laugh-squint:before {
  content: "\f294";
}
.fi-tr-laugh-wink:before {
  content: "\f295";
}
.fi-tr-layer-minus:before {
  content: "\f296";
}
.fi-tr-layer-plus:before {
  content: "\f297";
}
.fi-tr-lemon:before {
  content: "\f298";
}
.fi-tr-lettuce:before {
  content: "\f299";
}
.fi-tr-level-down-alt:before {
  content: "\f29a";
}
.fi-tr-level-down:before {
  content: "\f29b";
}
.fi-tr-level-up-alt:before {
  content: "\f29c";
}
.fi-tr-level-up:before {
  content: "\f29d";
}
.fi-tr-lightbulb-dollar:before {
  content: "\f29e";
}
.fi-tr-link-alt:before {
  content: "\f29f";
}
.fi-tr-link-horizontal-slash:before {
  content: "\f2a0";
}
.fi-tr-link-horizontal:before {
  content: "\f2a1";
}
.fi-tr-link-slash-alt:before {
  content: "\f2a2";
}
.fi-tr-link-slash:before {
  content: "\f2a3";
}
.fi-tr-lira-sign:before {
  content: "\f2a4";
}
.fi-tr-luchador:before {
  content: "\f2a5";
}
.fi-tr-m:before {
  content: "\f2a6";
}
.fi-tr-mailbox:before {
  content: "\f2a7";
}
.fi-tr-meh-blank:before {
  content: "\f2a8";
}
.fi-tr-meh-rolling-eyes:before {
  content: "\f2a9";
}
.fi-tr-melon:before {
  content: "\f2aa";
}
.fi-tr-message-code:before {
  content: "\f2ab";
}
.fi-tr-meteor:before {
  content: "\f2ac";
}
.fi-tr-mind-share:before {
  content: "\f2ad";
}
.fi-tr-mobile-button:before {
  content: "\f2ae";
}
.fi-tr-mobile-notch:before {
  content: "\f2af";
}
.fi-tr-money-bill-wave-alt:before {
  content: "\f2b0";
}
.fi-tr-money-bill-wave:before {
  content: "\f2b1";
}
.fi-tr-money-check-edit-alt:before {
  content: "\f2b2";
}
.fi-tr-money-check-edit:before {
  content: "\f2b3";
}
.fi-tr-money-check:before {
  content: "\f2b4";
}
.fi-tr-money:before {
  content: "\f2b5";
}
.fi-tr-moon-stars:before {
  content: "\f2b6";
}
.fi-tr-motorcycle:before {
  content: "\f2b7";
}
.fi-tr-mug-hot-alt:before {
  content: "\f2b8";
}
.fi-tr-mug-hot:before {
  content: "\f2b9";
}
.fi-tr-mug-tea:before {
  content: "\f2ba";
}
.fi-tr-mug:before {
  content: "\f2bb";
}
.fi-tr-mushroom:before {
  content: "\f2bc";
}
.fi-tr-n:before {
  content: "\f2bd";
}
.fi-tr-noodles:before {
  content: "\f2be";
}
.fi-tr-o:before {
  content: "\f2bf";
}
.fi-tr-oil-can:before {
  content: "\f2c0";
}
.fi-tr-oil-temp:before {
  content: "\f2c1";
}
.fi-tr-olive-oil:before {
  content: "\f2c2";
}
.fi-tr-olives:before {
  content: "\f2c3";
}
.fi-tr-onion:before {
  content: "\f2c4";
}
.fi-tr-overline:before {
  content: "\f2c5";
}
.fi-tr-p:before {
  content: "\f2c6";
}
.fi-tr-page-break:before {
  content: "\f2c7";
}
.fi-tr-paint-roller:before {
  content: "\f2c8";
}
.fi-tr-pan:before {
  content: "\f2c9";
}
.fi-tr-peach:before {
  content: "\f2ca";
}
.fi-tr-pencil-ruler:before {
  content: "\f2cb";
}
.fi-tr-pennant:before {
  content: "\f2cc";
}
.fi-tr-people-poll:before {
  content: "\f2cd";
}
.fi-tr-pepper-hot:before {
  content: "\f2ce";
}
.fi-tr-pepper:before {
  content: "\f2cf";
}
.fi-tr-percentage:before {
  content: "\f2d0";
}
.fi-tr-phone-office:before {
  content: "\f2d1";
}
.fi-tr-photo-film-music:before {
  content: "\f2d2";
}
.fi-tr-photo-video:before {
  content: "\f2d3";
}
.fi-tr-picnic:before {
  content: "\f2d4";
}
.fi-tr-pie:before {
  content: "\f2d5";
}
.fi-tr-piggy-bank:before {
  content: "\f2d6";
}
.fi-tr-pineapple:before {
  content: "\f2d7";
}
.fi-tr-plane-alt:before {
  content: "\f2d8";
}
.fi-tr-plate:before {
  content: "\f2d9";
}
.fi-tr-play-pause:before {
  content: "\f2da";
}
.fi-tr-podium-star:before {
  content: "\f2db";
}
.fi-tr-podium:before {
  content: "\f2dc";
}
.fi-tr-poo:before {
  content: "\f2dd";
}
.fi-tr-popcorn:before {
  content: "\f2de";
}
.fi-tr-pot:before {
  content: "\f2df";
}
.fi-tr-pumpkin:before {
  content: "\f2e0";
}
.fi-tr-puzzle-piece:before {
  content: "\f2e1";
}
.fi-tr-q:before {
  content: "\f2e2";
}
.fi-tr-qrcode:before {
  content: "\f2e3";
}
.fi-tr-question-square:before {
  content: "\f2e4";
}
.fi-tr-question:before {
  content: "\f2e5";
}
.fi-tr-r:before {
  content: "\f2e6";
}
.fi-tr-racquet:before {
  content: "\f2e7";
}
.fi-tr-radish:before {
  content: "\f2e8";
}
.fi-tr-rectangle-barcode:before {
  content: "\f2e9";
}
.fi-tr-rectangle-code:before {
  content: "\f2ea";
}
.fi-tr-rectangle-xmark:before {
  content: "\f2eb";
}
.fi-tr-registered:before {
  content: "\f2ec";
}
.fi-tr-reply-all:before {
  content: "\f2ed";
}
.fi-tr-republican:before {
  content: "\f2ee";
}
.fi-tr-restaurant:before {
  content: "\f2ef";
}
.fi-tr-rocket-lunch:before {
  content: "\f2f0";
}
.fi-tr-ruble-sign:before {
  content: "\f2f1";
}
.fi-tr-rugby-helmet:before {
  content: "\f2f2";
}
.fi-tr-ruler-combined:before {
  content: "\f2f3";
}
.fi-tr-ruler-horizontal:before {
  content: "\f2f4";
}
.fi-tr-ruler-triangle:before {
  content: "\f2f5";
}
.fi-tr-ruler-vertical:before {
  content: "\f2f6";
}
.fi-tr-running:before {
  content: "\f2f7";
}
.fi-tr-rupee-sign:before {
  content: "\f2f8";
}
.fi-tr-rv:before {
  content: "\f2f9";
}
.fi-tr-s:before {
  content: "\f2fa";
}
.fi-tr-sack-dollar:before {
  content: "\f2fb";
}
.fi-tr-sack:before {
  content: "\f2fc";
}
.fi-tr-sad-cry:before {
  content: "\f2fd";
}
.fi-tr-sad-tear:before {
  content: "\f2fe";
}
.fi-tr-salt-pepper:before {
  content: "\f2ff";
}
.fi-tr-sandwich:before {
  content: "\f300";
}
.fi-tr-sauce:before {
  content: "\f301";
}
.fi-tr-sausage:before {
  content: "\f302";
}
.fi-tr-search-dollar:before {
  content: "\f303";
}
.fi-tr-search-location:before {
  content: "\f304";
}
.fi-tr-sensor-alert:before {
  content: "\f305";
}
.fi-tr-sensor-fire:before {
  content: "\f306";
}
.fi-tr-sensor-on:before {
  content: "\f307";
}
.fi-tr-sensor-smoke:before {
  content: "\f308";
}
.fi-tr-sensor:before {
  content: "\f309";
}
.fi-tr-shekel-sign:before {
  content: "\f30a";
}
.fi-tr-shopping-basket:before {
  content: "\f30b";
}
.fi-tr-shrimp:before {
  content: "\f30c";
}
.fi-tr-shuttle-van:before {
  content: "\f30d";
}
.fi-tr-shuttlecock:before {
  content: "\f30e";
}
.fi-tr-sidebar-flip:before {
  content: "\f30f";
}
.fi-tr-sidebar:before {
  content: "\f310";
}
.fi-tr-skating:before {
  content: "\f311";
}
.fi-tr-skewer:before {
  content: "\f312";
}
.fi-tr-ski-jump:before {
  content: "\f313";
}
.fi-tr-ski-lift:before {
  content: "\f314";
}
.fi-tr-skiing-nordic:before {
  content: "\f315";
}
.fi-tr-skiing:before {
  content: "\f316";
}
.fi-tr-sledding:before {
  content: "\f317";
}
.fi-tr-sleigh:before {
  content: "\f318";
}
.fi-tr-smile-beam:before {
  content: "\f319";
}
.fi-tr-smog:before {
  content: "\f31a";
}
.fi-tr-smoke:before {
  content: "\f31b";
}
.fi-tr-snow-blowing:before {
  content: "\f31c";
}
.fi-tr-snowboarding:before {
  content: "\f31d";
}
.fi-tr-snowflakes:before {
  content: "\f31e";
}
.fi-tr-snowmobile:before {
  content: "\f31f";
}
.fi-tr-snowplow:before {
  content: "\f320";
}
.fi-tr-sort-alpha-down-alt:before {
  content: "\f321";
}
.fi-tr-sort-alpha-down:before {
  content: "\f322";
}
.fi-tr-sort-alpha-up-alt:before {
  content: "\f323";
}
.fi-tr-sort-alpha-up:before {
  content: "\f324";
}
.fi-tr-sort-alt:before {
  content: "\f325";
}
.fi-tr-sort-amount-down-alt:before {
  content: "\f326";
}
.fi-tr-sort-amount-down:before {
  content: "\f327";
}
.fi-tr-sort-amount-up-alt:before {
  content: "\f328";
}
.fi-tr-sort-amount-up:before {
  content: "\f329";
}
.fi-tr-sort-down:before {
  content: "\f32a";
}
.fi-tr-sort-numeric-down-alt:before {
  content: "\f32b";
}
.fi-tr-sort-numeric-down:before {
  content: "\f32c";
}
.fi-tr-sort:before {
  content: "\f32d";
}
.fi-tr-space-shuttle:before {
  content: "\f32e";
}
.fi-tr-spade:before {
  content: "\f32f";
}
.fi-tr-sparkles:before {
  content: "\f330";
}
.fi-tr-spoon:before {
  content: "\f331";
}
.fi-tr-square-code:before {
  content: "\f332";
}
.fi-tr-square-terminal:before {
  content: "\f333";
}
.fi-tr-stamp:before {
  content: "\f334";
}
.fi-tr-starfighter:before {
  content: "\f335";
}
.fi-tr-stars:before {
  content: "\f336";
}
.fi-tr-steak:before {
  content: "\f337";
}
.fi-tr-steering-wheel:before {
  content: "\f338";
}
.fi-tr-store-alt:before {
  content: "\f339";
}
.fi-tr-strawberry:before {
  content: "\f33a";
}
.fi-tr-subway:before {
  content: "\f33b";
}
.fi-tr-suitcase-alt:before {
  content: "\f33c";
}
.fi-tr-summer:before {
  content: "\f33d";
}
.fi-tr-sunrise-alt:before {
  content: "\f33e";
}
.fi-tr-sunset:before {
  content: "\f33f";
}
.fi-tr-surprise:before {
  content: "\f340";
}
.fi-tr-sushi:before {
  content: "\f341";
}
.fi-tr-swimmer:before {
  content: "\f342";
}
.fi-tr-t:before {
  content: "\f343";
}
.fi-tr-table-columns:before {
  content: "\f344";
}
.fi-tr-table-layout:before {
  content: "\f345";
}
.fi-tr-table-pivot:before {
  content: "\f346";
}
.fi-tr-table-rows:before {
  content: "\f347";
}
.fi-tr-table-tree:before {
  content: "\f348";
}
.fi-tr-tachometer-alt-average:before {
  content: "\f349";
}
.fi-tr-tachometer-alt-fastest:before {
  content: "\f34a";
}
.fi-tr-tachometer-alt-slow:before {
  content: "\f34b";
}
.fi-tr-tachometer-alt-slowest:before {
  content: "\f34c";
}
.fi-tr-tachometer-average:before {
  content: "\f34d";
}
.fi-tr-tachometer-fast:before {
  content: "\f34e";
}
.fi-tr-tachometer-fastest:before {
  content: "\f34f";
}
.fi-tr-tachometer-slow:before {
  content: "\f350";
}
.fi-tr-tachometer-slowest:before {
  content: "\f351";
}
.fi-tr-tachometer:before {
  content: "\f352";
}
.fi-tr-taco:before {
  content: "\f353";
}
.fi-tr-tags:before {
  content: "\f354";
}
.fi-tr-tally:before {
  content: "\f355";
}
.fi-tr-temperature-down:before {
  content: "\f356";
}
.fi-tr-temperature-frigid:before {
  content: "\f357";
}
.fi-tr-temperature-high:before {
  content: "\f358";
}
.fi-tr-temperature-low:before {
  content: "\f359";
}
.fi-tr-temperature-up:before {
  content: "\f35a";
}
.fi-tr-tenge:before {
  content: "\f35b";
}
.fi-tr-text-slash:before {
  content: "\f35c";
}
.fi-tr-thunderstorm-moon:before {
  content: "\f35d";
}
.fi-tr-thunderstorm-sun:before {
  content: "\f35e";
}
.fi-tr-thunderstorm:before {
  content: "\f35f";
}
.fi-tr-tire-flat:before {
  content: "\f360";
}
.fi-tr-tire-pressure-warning:before {
  content: "\f361";
}
.fi-tr-tire-rugged:before {
  content: "\f362";
}
.fi-tr-tire:before {
  content: "\f363";
}
.fi-tr-tired:before {
  content: "\f364";
}
.fi-tr-tomato:before {
  content: "\f365";
}
.fi-tr-tool-box:before {
  content: "\f366";
}
.fi-tr-tractor:before {
  content: "\f367";
}
.fi-tr-trailer:before {
  content: "\f368";
}
.fi-tr-tram:before {
  content: "\f369";
}
.fi-tr-treasure-chest:before {
  content: "\f36a";
}
.fi-tr-truck-container:before {
  content: "\f36b";
}
.fi-tr-truck-couch:before {
  content: "\f36c";
}
.fi-tr-truck-loading:before {
  content: "\f36d";
}
.fi-tr-truck-monster:before {
  content: "\f36e";
}
.fi-tr-truck-moving:before {
  content: "\f36f";
}
.fi-tr-truck-pickup:before {
  content: "\f370";
}
.fi-tr-truck-plow:before {
  content: "\f371";
}
.fi-tr-truck-ramp:before {
  content: "\f372";
}
.fi-tr-tty:before {
  content: "\f373";
}
.fi-tr-turkey:before {
  content: "\f374";
}
.fi-tr-tv-music:before {
  content: "\f375";
}
.fi-tr-typewriter:before {
  content: "\f376";
}
.fi-tr-u:before {
  content: "\f377";
}
.fi-tr-undo:before {
  content: "\f378";
}
.fi-tr-usd-circle:before {
  content: "\f379";
}
.fi-tr-usd-square:before {
  content: "\f37a";
}
.fi-tr-v:before {
  content: "\f37b";
}
.fi-tr-video-arrow-down-left:before {
  content: "\f37c";
}
.fi-tr-video-arrow-up-right:before {
  content: "\f37d";
}
.fi-tr-video-camera-alt:before {
  content: "\f37e";
}
.fi-tr-video-plus:before {
  content: "\f37f";
}
.fi-tr-video-slash:before {
  content: "\f380";
}
.fi-tr-volcano:before {
  content: "\f381";
}
.fi-tr-w:before {
  content: "\f382";
}
.fi-tr-wagon-covered:before {
  content: "\f383";
}
.fi-tr-wall-calendars:before {
  content: "\f384";
}
.fi-tr-wallet:before {
  content: "\f385";
}
.fi-tr-water-bottle:before {
  content: "\f386";
}
.fi-tr-water-lower:before {
  content: "\f387";
}
.fi-tr-water-rise:before {
  content: "\f388";
}
.fi-tr-water:before {
  content: "\f389";
}
.fi-tr-watermelon:before {
  content: "\f38a";
}
.fi-tr-wheat:before {
  content: "\f38b";
}
.fi-tr-whistle:before {
  content: "\f38c";
}
.fi-tr-wind-warning:before {
  content: "\f38d";
}
.fi-tr-windsock:before {
  content: "\f38e";
}
.fi-tr-wrench-simple:before {
  content: "\f38f";
}
.fi-tr-x:before {
  content: "\f390";
}
.fi-tr-y:before {
  content: "\f391";
}
.fi-tr-z:before {
  content: "\f392";
}
