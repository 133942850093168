/*========================================================
	Sodainagar Main Area
===========================================================*/
.sodainagar-main {
    position: relative;
}

:root {
    --bg-color: #000811;
    --primary-color: #006ce0;
    --border-radius: 6px;
    --color: #2b82df;
}

:root {
    --sodainagar-primary-color: #57cc99;
    --sodainagar-secondary-color: #22577a;
}

button.load-more-btn.btn.btn-link {
    box-shadow: none;
}

button.Previous-more-btn.btn.btn-link {
    box-shadow: none;
}

button:focus:not(:focus-visible) {
    box-shadow: none;
}
.Toastify__toast-container--top-right {
    top: 5em;
    right: 1px;
    /* position: relative; */
}

/* --box-shadow: #0b488a 5px 0px 50px 0px; */

/*======================================================
	Theme Reset
========================================================*/

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400;1,600;1,700;1,900&display=swap");

*,
*::after *::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 1320px;
}

.container-fluid {
    padding: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .container {
        padding: 0px 5%;
    }
}

@media only screen and (max-width: 767px) {
    .container {
        padding: 0 5%;
    }

    .container-fluid {
        padding: 0 3%;
    }
}
tbody,
td,
tfoot,
th,
thead,
tr {
    border-style: solid;
    border-width: 1px;
    border-color: #ddd;
}
.sodainagar-sidemenu .sidebar {
    min-height: auto;
    position: fixed;
    top: 72px;
    width: 16%;
    transition: all 0.3s ease;
    background: #ffffff;
    box-shadow: 12px 36px 36px rgba(34, 87, 122, 0.08);
    overflow-y: scroll;
    z-index: 999;
    padding-left: 24px;
    padding-top: 20px;
    padding-bottom: 24px;
    max-height: 80%;
}
.sidebar ul {
    list-style: none;
    margin-top: 0;
    height: 100%;
}

.sidebar ul li {
    padding: 0.3rem;
    width: 100%;
    display: block;
    align-items: center;
    position: relative;
}

/* .sidebar ul li.active {
	background: var(--primary-color);
	border-bottom-left-radius: var(--border-radius);
	border-top-left-radius: var(--border-radius);
	transition: background 0.5s linear;
	box-shadow: rgba(20, 114, 238, 0.918) 0px 2px 10px 0px;
  }
*/
body .toggle.sidebar {
    left: -100%;
}
.sodainagar-sidemenu {
    transition: all 0.4s ease;
}
.sodainagar-sidemenu .sidebar li a {
    color: #383838;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
}

.sidebar li a:hover {
    margin-left: 2px;
}

.hamburger-menu {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    font-size: 3rem;
    color: #006ce0;
    cursor: pointer;
}

body.toggle.section {
    margin-left: 0;
    width: 100%;
}

.sodainagar-main-inner {
    width: 100%;
    float: right;
    margin-top: 72px;
}

.sidebar {
    background: transparent;
    overflow-y: auto;
    margin: 0 auto;
    padding: 0px;
}

.sidebar::-webkit-scrollbar {
    width: 11px;
}

.sidebar {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.sidebar::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
}

.payment-area {
    --scrollbarBG: #cfd8dc;
    --thumbBG: #90a4ae;
}

.sidebar::-webkit-scrollbar {
    width: 10px;
}

.menu-bar li {
    margin-top: 5px;
    position: relative;
    transition: all 0.3s ease;
}

.menu-bar li:hover,
.menu-bar li.active {
    background: rgba(243, 245, 249, 0.05);
    border-radius: 8px;
}

.menu-bar li::before {
    font-family: "uicons-regular-rounded";
    content: "\f112";
    position: absolute;
    top: 10px;
    right: 10px;
    color: #0a9c81;
    opacity: 1;
    visibility: visible;
    font-size: 22px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.menu-bar li.active-icon:before {
    opacity: 1;
    visibility: visible;
}

.menu-bar li.show-dropdown:before {
    content: "\f110";
}

.menu-arrow-none::before {
    display: none;
}

.menu-bar li a {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #000;
    padding: 10px 20px;
    display: inline-block;
    width: 100%;
}

.menu-bar li ul li a {
    color: #000;
    font-size: 14px;
    padding: 10px 20px;
}

.menu-bar li ul li a img {
    max-width: 17px;
}

.menu-bar li ul {
    padding-left: 20px;
    opacity: 0;
    visibility: hidden;
    height: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.menu-bar li.show-dropdown ul {
    opacity: 1;
    visibility: visible;
    height: 100%;
}

.menu-bar img {
    margin-right: 10px;
}

/* .pages {
  padding-left: 24px;
} */

/*========================================================
	End Sodainagar Main Area
===========================================================*/

/*========================================================
	Sodainagar Hero Area
===========================================================*/
.sodainagar-hero {
    padding-top: 20px;
    padding-bottom: 80px;
}
.hero-single-slider {
    width: 100%;
    height: 480px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
}
.hero-single-banner-img {
    width: 100%;
    height: 228px;
    object-fit: cover;
    display: block;
}

.hero-single-banner-img img {
    height: 228px;
    width: 100%;
    object-fit: cover;
}
.s-hero-cont-title {
    color: var(--sodainagar-secondary-color);
    margin-bottom: 24px;
}
.hero-single-banner {
    margin-bottom: 24px;
}
.hero-single-banner:last-child {
    margin: 0;
}

/* Hero Slider Dot */
.hero-slider-main .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    opacity: 0.4;
    background: var(--sodainagar-primary-color) !important;
}

.hero-slider-main .swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--sodainagar-primary-color) !important;
}

/*========================================================
	Sodainagar Browse Item
===========================================================*/
.sodainagar-browse-item {
    padding-bottom: 80px;
    overflow: hidden;
}

.s-browse-item-wrapper {
    margin-right: 24px;
    margin-top: 40px;
    border-radius: 4px;
    text-align: center;
}

.s-browse-item-img {
    position: relative;
    border-radius: 4px;
}

.s-browse-item-img img {
    border-radius: 4px;
}

.s-browse-item-btn {
    position: absolute;
    left: 50%;
    margin-left: -88px;
    top: 50%;
    margin-top: -23px;
}

.s-browse-item-btn .theme-btn {
    text-align: center;
    padding: 8px 12px;
    overflow: hidden;
}

.sodainagar-main-inner.toggle .sodainagar-browse-item {
    padding-left: 24px;
}

/*========================================================
	End Sodainagar Browse Item
===========================================================*/

/*========================================================
	Sodainagar Shop Categories
===========================================================*/
.sodainagar-shop-categories {
    padding-bottom: 80px;
    overflow: hidden;
}
.s-categories-main-inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-top: 40px;
}
.s-categories-wrapper-img {
    position: relative;
}

.s-categories-wrapper-img::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: var(--sodainagar-secondary-color);
    opacity: 0.7;
    top: 0;
    left: 0;
    z-index: 22;
    border-radius: 4px;
}

.s-categories-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 222;
    line-height: 14px;
}

.s-categories-btn .theme-btn {
    border-radius: 4px;
    font-weight: 500;
    font-size: 19px;
    line-height: 140%;
    color: var(--sodainagar-secondary-color);
    text-align: center;
    height: 46px;
    padding: 10px;
    overflow: hidden;
    background: #fbfcfc;
    transition: all 0.4s ease;
    z-index: 333;
    position: relative;
}

.s-categories-btn .theme-btn:hover {
    background: var(--sodainagar-primary-color);
    color: #fbfcfc;
}

.s-categories-wrapper-img img {
    border-radius: 4px;
    width: 100%;
    height: 140px;
    object-fit: cover;
}
.s-categories-wrapper {
    text-align: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    overflow: hidden;
}

.s-categories-wrapper-img {
    overflow: hidden;
}

.s-categories-wrapper:hover .theme-btn {
    background: var(--sodainagar-primary-color);
    color: #fbfcfc;
}

.s-categories-wrapper:hover .s-categories-wrapper-img img {
    transform: scale(1.1);
}

/*========================================================
	End Sodainagar Shop Categories
===========================================================*/

/*========================================================
	Popular Product Area
===========================================================*/
.popular-products-area {
    padding-bottom: 40px;
    overflow: hidden;
}

.popular-products-wrapper {
    text-align: center;
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    border-radius: 4px;
}

.p-products-wrapper-img {
    position: relative;
}

.p-products-wrapper-img img {
    border-radius: 4px 4px 0px 0px;
    width: 100%;
    height: 180px;
    object-fit: fill;
}

.p-products-wrapper-img::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--sodainagar-secondary-color);
    opacity: 0;
    visibility: hidden;
    border-radius: 4px;
    transition: all 0.4s ease;
    z-index: 2;
}

.p-product-weight {
    position: absolute;
    top: 12px;
    right: 12px;
    background: var(--sodainagar-secondary-color);
    border-radius: 11px;
    color: #fbfcfc;
    font-size: 13px;
    font-weight: 400;
    padding: 2px 9px;
    line-height: 19px;
}

.p-products-cont-title {
    font-weight: 600;
    margin-bottom: 4px;
    color: #202020;
    margin-top: 0;
    height: 26px;
    overflow: hidden;
}
.p-products-cont-btn {
    margin-top: 12px;
    padding: 0px 12px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.p-products-cont-btn .theme-btn {
    border: 1px solid #e4eaef;
    border-radius: 4px;
    background: transparent;
    color: var(--sodainagar-secondary-color);
    font-weight: 600;
    font-size: 14px;
    padding: 9px 7px;
    width: 105px;
}

.p-products-cont-btn .theme-btn i {
    font-size: 14px;
    margin-right: 6px;
    position: relative;
    top: 2px;
}
.p-products-buy-now-btn .theme-btn {
    background: var(--sodainagar-primary-color);
    color: #fff;
    border: none !important;
    padding: 9px 15px;
    font-size: 14px;
    font-weight: 600;
}
.p-products-buy-now-btn .theme-btn:hover {
    background: var(--sodainagar-secondary-color) !important;
}
.p-products-cont-btn .theme-btn:hover {
    background: var(--sodainagar-primary-color);
    color: #fbfcfc;
    border: 1px solid transparent;
}

.p-products-price del {
    color: #999a9a;
    margin-right: 4px;
}

.p-products-price span {
    font-weight: 600;
    font-size: 16px;
    display: inline-block;
    margin-left: 4px;
}

.p-item-hover-btn .theme-btn {
    background: #fbfcfc;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: var(--sodainagar-primary-color);
    padding: 6px 8px;
    width: 134px;
    position: absolute;
    left: 50%;
    margin-left: -67px;
    top: 50%;
    margin-top: -17px;
    opacity: 0;
    visibility: hidden;
    z-index: 22;
}

.p-item-hover-btn .theme-btn i {
    font-size: 16px;
    margin-right: 4px;
    position: relative;
    top: 3px;
}

.p-item-hover-btn .theme-btn:hover {
    background: var(--sodainagar-primary-color);
    color: #fbfcfc;
}

.p-item-hover-cont .theme-btn.p-details-btn {
    background: var(--sodainagar-secondary-color);
    border-radius: 0px 0px 4px 4px;
    color: #fbfcfc;
    font-weight: 600;
    padding: 12px 20px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
}

.p-item-hover-cont .theme-btn.p-details-btn i {
    font-size: 20px;
    color: var(--sodainagar-primary-color);
    position: relative;
    top: 4px;
    margin-right: 8px;
}

.p-products-cont-top {
    position: relative;
}

.p-products-cont-top-title {
    padding: 11px 10px 10px;
    transition: all 0.4s ease;
}
.theme-btn.pp-quantity.remove-btn {
    opacity: 0;
    visibility: hidden;
    display: none;
}

.p-products-cont-btn .qty-input {
    opacity: 0;
    visibility: hidden;
    display: none;
}

.qty-input.add-quantity-box {
    opacity: 1;
    visibility: visible;
    display: block;
}

.p-products-cont-btn .product-qty {
    width: 40px;
    height: 34px;
    appearance: textfield;
    text-align: center;
    font-size: 18px;
    background: transparent;
    border: none !important;
    color: #383838 !important;
    font-weight: 500;
    padding-left: 12px;
    line-height: 34px;
    -moz-padding-start: 0;
}

.p-products-cont-btn .qty-count {
    width: 34px;
    height: 34px;
    background: var(--sodainagar-primary-color);
    text-align: center;
    color: #fbfcfc;
    font-size: 20px;
    border: none;
    line-height: 37px;
}

.p-products-cont-btn .qty-count:hover {
    background: var(--sodainagar-secondary-color);
}

.p-products-cont-btn .qty-count.qty-count--minus {
    border-right: 0;
}

.p-products-cont-btn .qty-count.qty-count--add {
    border-left: 0;
}

.popular-products-area.promo-items {
    padding-bottom: 80px;
}

.products-inner {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 24px;
    margin-top: 40px;
}

.wishlist-products-inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-top: 24px;
    padding-bottom: 80px;
}
.p-products-review-list li {
    display: inline-block;
    margin-right: 4px;
    font-size: 15px;
}
.p-products-review-list li i {
    color: #fea82f;
    position: relative;
    top: 2px;
}
.p-products-review-list li:last-child {
    margin: 0;
}
.product-single-stock-out {
    margin-top: 13px;
    align-items: center;
    display: flex;
}
.product-stock-out {
    margin-top: 13px;
    padding-bottom: 12px;
}
.product-stock-out-btn {
    padding: 3px 14px;
    display: inline-block;
    border: 1px solid #ff1818;
    border-radius: 2px;
    color: #ff1818;
    font-weight: 600;
    cursor: no-drop;
    font-size: 14px;
    line-height: 28px;
    height: 36px;
}

.products-load-more-btn {
    text-align: center;
    margin-top: 40px;
}
.products-load-more-btn .theme-btn {
    border: none;
    transition: all 0.4s ease;
    padding: 12px 22px;
}

/* Product Details Modal */

.p-product-details-modal .modal-dialog {
    margin: 0;
    position: absolute;
    left: 50%;

    margin-left: -485px;
    top: 0px;
    margin-top: 145px;
    transition: all 0.4s ease;
    max-width: 100%;
    background: #fbfcfc;
    box-shadow: 0px 24px 72px rgba(34, 87, 122, 0.16);
    border-radius: 12px;
    max-width: 100%;
    transform: scale(1) !important;
}

.p-product-details-modal .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 970px;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
    background: #fbfcfc;
    box-shadow: 0px 24px 72px rgba(34, 87, 122, 0.16);
    border-radius: 12px;
}

.gallery-modal .gallery-modal--overlay {
    background-color: transparent !important;
}

.d-process-card {
    display: flex;
    align-items: center;
}

.d-process-card-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin: 0;
    margin-left: 16px;
}

.d-process-card-img img {
    max-width: 80px;
    max-height: 80px;
}

.p-details-modal-des-title {
    background: rgba(34, 87, 122, 0.06);
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    color: var(--sodainagar-secondary-color);
    padding: 10px 24px;
    position: relative;
}

.p-details-modal-des-title::before {
    position: absolute;
    width: 108px;
    height: 4px;
    background: var(--sodainagar-secondary-color);
    bottom: 0;
    left: 16px;
    content: "";
    display: inline-block;
}

.p-details-modal-des-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
    margin-bottom: 30px;
}

.p-details-modal-des-list li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 15px;
}

.p-details-modal-des-list li:last-child {
    margin: 0;
}

.p-details-modal-des-list li::before {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    background: #383838;
    border-radius: 100%;
    top: 11px;
    left: 0;
}

.modal-backdrop.show {
    background: #383838;
    opacity: 0.5;
}

.d-process-card {
    margin-top: 30px;
}

.p-details-modal-d-weight {
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    border-radius: 19px;
    padding: 4px 12px;
    font-size: 16px;
    line-height: 140%;
    color: var(--sodainagar-secondary-color);
    font-weight: 400;
}

.p-details-modal-d-weight b {
    font-weight: 600;
}

.p-details-modal-d-title {
    font-weight: 700;
    font-size: 33px;
    line-height: 140%;
    color: #202020;
}

.p-details-modal-price span {
    font-weight: 700;
    font-size: 28px;
    line-height: 140%;
    color: #383838;
    margin-right: 8px;
}

.p-details-modal-price del {
    font-weight: 500;
    font-size: 28px;
    line-height: 140%;
    text-decoration-line: line-through;
    color: #383838;
    margin-right: 10px;
}

.p-details-modal-price {
    margin-top: 24px;
}

.p-details-modal-price-disnt {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
    margin: 0;
    margin-top: 5px;
}

.p-details-modal-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 72%;
}
.p-details-modal-buttons .theme-btn {
    background: var(--sodainagar-primary-color);
    border-radius: 4px;
    width: 179px;
    height: 46px;
    text-align: center;
    color: #e4eaef;
    line-height: 10px;
    margin-top: 20px;
    font-size: 18px;
    text-decoration: none;

    padding: 9px 20px;
}

.p-details-modal-buttons .theme-btn i {
    position: relative;
    top: 0px;
    margin-right: 5px;
    font-size: 15px;
    color: #fbfcfc;
}

.buy-cart-btn .theme-btn {
    background: var(--sodainagar-secondary-color);
}

.add-wishlist .theme-btn {
    background: #38a3a5;
}

.p-details-modal-qty-btn .qty-count {
    width: 46px;
    height: 46px;
    background: rgba(34, 87, 122, 0.16);
    text-align: center;
    color: var(--sodainagar-secondary-color);
    font-size: 20px;
    line-height: 37px;
    border: 2px solid #e4eaef;
    transition: all 0.4s ease;
    border-radius: 4px;
}

.p-details-modal-qty-btn .qty-count:hover {
    background: var(--sodainagar-secondary-color);
    color: #fbfcfc;
}

.p-details-modal-qty-btn .product-qty {
    width: 50px;
    height: 46px;
    appearance: textfield;
    text-align: center;
    font-size: 23px;
    background: transparent;
    border: none !important;
    color: #383838 !important;
    font-weight: 700;
    line-height: 34px;
    padding: 0;
}

.p-details-modal-qty-btn {
    background: #eef2f4;
    display: inline-block;
    text-align: center;
    width: 179px;
    height: 46px;
    margin-top: 20px;
}

.p-details-modal-qty-btn .qty-count.qty-count--minus {
    float: left;
}

.p-details-modal-qty-btn .qty-count.qty-count--add {
    float: right;
}

.p-details-modal-qty-btn .qty-count {
    text-align: center;
    color: var(--sodainagar-secondary-color);
    font-size: 20px;
    line-height: 44px;
}

.p-product-details-modal.modal.show .modal-header {
    padding: 20px 20px 0px 0px;
    border-bottom: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.p-product-details-modal.modal.show .modal-body {
    padding: 10px 30px 80px 30px;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
    border: 1.5px solid #57cc99 !important;
}

.carousel .thumb {
    transition: border 0.15s ease-in;
    display: inline-block;
    margin-right: 12px !important;
    white-space: nowrap;
    overflow: hidden;
    border: none;
    padding: 0px !important;
    height: 72px !important;
    width: 72px !important;
    border: 1.5px solid transparent !important;
    border-radius: 2px !important;
}
.carousel img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.carousel .slide img {
    width: 100%;
    object-fit: contain;
    height: 480px;
}

.carousel .thumb:last-child {
    margin: 0px !important;
}

.carousel .thumbs-wrapper {
    margin: 0 !important;
    overflow: hidden;
    margin-top: 16px !important;
}

.control-dots {
    display: none !important;
}

.carousel img {
    border-radius: 2px !important;
}

.carousel .carousel-status {
    font-size: 0px !important;
}

.p-details-modal-bottom {
    margin-top: 30px;
}

.carousel .control-arrow::before,
.carousel.carousel-slider .control-arrow::before {
    margin: 0px;
    display: inline-block;
    border: 0px !important;
    font-family: "uicons-regular-rounded";
    content: "\f11f" !important;
    font-size: 20px;
    color: #e9eef2;
    width: 28px;
    height: 28px;
    background: var(--sodainagar-secondary-color);
    border-radius: 4px;
    opacity: 1;
    line-height: 28px;
}

.carousel .control-prev.control-arrow::before {
    font-family: "uicons-regular-rounded" !important;
    content: "\f11e" !important;
}

.carousel.carousel-slider .control-arrow {
    top: 0;
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
    border-radius: 4px;
    opacity: 1;
}

.carousel.carousel-slider .control-arrow:hover {
    background: transparent !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    opacity: 1 !important;
}

.product-signle-main {
    overflow: hidden;
    padding-top: 56px;
    padding-bottom: 80px;
}
.p-details-modal-g-details {
    margin-left: 24px;
}
.sodainagar-main-inner.toggle .product-signle-main {
    padding-left: 24px;
}

/* Product Single Page */
.p-details-tab-area {
    margin-top: 40px;
}
.p-details-tab-menu {
    background: rgba(34, 87, 122, 0.06);
    padding: 0px 20px;
    margin-bottom: 32px;
    border-radius: 4px;
}
.p-details-tab-menu .list-group {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-bottom: 0;
    padding-left: 0;
}

.p-details-tab-menu .list-group-item {
    border: none;
    color: var(--sodainagar-secondary-color);
    background: transparent;
    line-height: 120%;
    margin-right: 12px;
    padding: 16px 12px;
    position: relative;
    transition: all 0.4s ease;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
}
.p-details-tab-menu .list-group-item::before {
    background: var(--sodainagar-secondary-color);
    bottom: 0;
    content: "";
    display: inline-block;
    height: 4px;
    left: 16px;
    position: absolute;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    left: 0;
}

.p-details-tab-menu .list-group-item.active::before {
    opacity: 1;
    visibility: visible;
}
.list-group-item + .list-group-item.active {
    margin-top: 0px;
    border-top-width: 0px;
}
#uncontrolled-tab-example-tabpane-description ul {
    margin: 16px;
}
#uncontrolled-tab-example-tabpane-description ul li {
    list-style: disc;
}
/* Product Video */
.product-video-overview {
    position: relative;
}
.product-video-thumb-img img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 4px;
}
.video-thumb-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -24px;
    margin-top: -24px;
}

.video-thumb-icon .waves-block .waves {
    position: absolute;
    width: 112px;
    height: 112px;
    opacity: 0;
    border-radius: 100%;
    animation: waves 3s ease-in-out infinite;
    right: 50%;
    bottom: 50%;
    z-index: 1;
    border: none;
    background: var(--sodainagar-primary-color);
    margin-right: -56px;
    margin-bottom: -56px;
    opacity: 0.4;
}
.video-thumb-icon .waves-block .wave-1 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}
.video-thumb-icon .waves-block .wave-2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}
.video-thumb-icon .waves-block .wave-3 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.video-thumb-icon .video-popup {
    color: #fff;
    font-size: 20px;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: var(--sodainagar-primary-color);
    display: block;
    text-align: center;
    line-height: 52px;
    z-index: 2;
    position: relative;
}
.product-video-description {
    position: absolute;
    width: 100%;
    bottom: 0;
}
.product-video-thumb-title {
    color: #fff;
    background: var(--sodainagar-primary-color);
    border-radius: 0px 0px 4px 4px;
    padding: 17px 24px;
    text-align: center;
    font-size: 19px;
    font-weight: 600;
}

.product-video-thumb-img {
    position: relative;
    border-radius: 4px;
}
.product-video-thumb-img::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--sodainagar-secondary-color);
    opacity: 0.6;
    border-radius: 4px;
}

@-webkit-keyframes waves {
    0% {
        -webkit-transform: scale(0.2, 0.2);
        transform: scale(0.2, 0.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
        opacity: 0.9;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }
    100% {
        -webkit-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}
@keyframes waves {
    0% {
        -webkit-transform: scale(0.2, 0.2);
        transform: scale(0.2, 0.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
        opacity: 0.9;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }
    100% {
        -webkit-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}

/* Product Review */

.product-review-form {
    background: var(--bestu-white-color);
    box-shadow: 0px 16px 48px rgba(34, 40, 49, 0.08);
    border-radius: 4px 4px 12px 12px;
    padding: 20px 24px 24px;
}

.product-review-form-head {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9eaea;
    padding-bottom: 16px;
    margin-bottom: 24px;
}

.product-review-form-head-icon {
    width: 72px;
    height: 72px;
    line-height: 72px;
    background: #f5f9fe;
    border-radius: 20px;
    text-align: center;
}
.product-review-form-head-icon img {
    width: 48px;
    height: 48px;
}
.product-review-form-head-content {
    margin-left: 16px;
}
.product-review-form-head-title {
    margin-bottom: 6px;
}
.product-review-form-head-text {
    line-height: 120%;
}

.product-review-form .form-group-single label {
    font-weight: 600;
    line-height: 120%;
    width: 12%;
}
.your-rattings-list {
    width: 88%;
}
.your-rattings-list li {
    display: inline-block;
    margin-right: 12px;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    color: #fea82f;
}
.your-rattings-list li i {
    position: relative;
    top: 3px;
}
.your-rattings-list li span {
    color: #000;
}
.your-rattings-list li:last-child {
    margin: 0;
}
.your-rattings-list li img {
    width: 24px;
    height: 24px;
}
.form-group-single textarea {
    background: #fff;
    border-radius: 4px;
    border: none;
    width: 88%;
    height: 180px;
    font-weight: 600;
    font-size: 16px;
    color: #000;
    padding: 15px 12px;
}
.product-review-form-button {
    text-align: right;
    margin-top: 24px;
}
.product-review-form-button .theme-btn {
    padding: 16px 24px;
    border: none;
    transition: all 0.4s ease;
}
.form-group-single.your-rattings {
    display: flex;
    align-items: center;
}

.form-group-single.your-opinion {
    display: flex;
    margin-top: 24px;
}

/* User Review Area */
.user-review-area {
    padding-top: 16px;
}
.user-review-info,
.user-review-reply-info {
    display: flex;
}

.user-review-info-img,
.user-review-reply-img {
    position: relative;
    width: 88px;
    height: 88px;
    line-height: 82px;
    text-align: center;
    /* background-image: url("../images/img-mask.svg"); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.user-review-reply-img {
    width: 56px;
    height: 56px;
    line-height: 53px;
}
.user-review-info-img img {
    width: 80px;
    height: 80px;
    border-radius: 24px;
    object-fit: cover;
}
.user-review-reply-img img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 16px;
}
.user-review-count-list li {
    display: inline-block;
    margin-right: 4px;
}
.user-review-count-list li:last-child {
    margin: 0;
}
.user-review-count-list li i {
    font-size: 16px;
    color: #ffc107;
}
.user-review-info-data {
    margin-left: 12px;
}
.user-review-name,
.user-review-reply-company {
    font-weight: 600;
    margin-bottom: 2px;
}
.user-review-date,
.user-review-reply-date {
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
    opacity: 0.5;
    display: block;
    margin: 0;
}
.user-review-count-list {
    line-height: 16px;
    margin-top: 6px;
}
.ueser-review-text-icon i {
    font-size: 36px;
    color: var(--sodainagar-primary-color);
}
.ueser-review-info-text {
    margin-left: 16px;
    border-bottom: 1px dashed #e9eaea;
    padding-bottom: 24px;
}
.ueser-review-text {
    margin-top: 8px !important;
}
.user-review-single {
    margin-top: 24px;
}
.user-review-reply-data {
    margin-left: 16px;
}
.user-review-reply-tag {
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
    display: block;
    margin-top: 6px;
}
.user-review-reply-tag i {
    font-size: 12px;
    margin-right: 5px;
    position: relative;
    top: 1px;
    color: var(--sodainagar-primary-color);
}
.ueser-review-reply-text {
    margin: 0;
    margin-top: 16px !important;
}
.user-review-reply-single {
    margin-top: 24px;
    margin-left: 16px;
    border-bottom: 1px dashed #e9eaea;
    padding-bottom: 24px;
}

/* Pagination */
.pagination-area {
    text-align: right;
    margin-top: 24px;
}
.pagination-list li {
    display: inline-block;
    margin-right: 12px;
}
.pagination-list li:last-child {
    margin: 0;
}
.pagination-list li a {
    padding: 12px 20px;
    width: 49px;
    height: 43px;
    line-height: 120%;
    background: #fff;
    border-radius: 2px;
    font-weight: 600;
}
.pagination-list li.active a,
.pagination-list li a:hover {
    color: #fff;
    background: var(--sodainagar-primary-color);
}

/*========================================================
	End Popular Product Area
===========================================================*/

/*========================================================
	Offer Banner Area
===========================================================*/
.offer-banner-wrapper {
    margin: 0px;
    text-align: center;
    position: relative;
}

.offer-banner-area {
    margin-bottom: 80px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    padding-right: 24px;
}

.sodainagar-main-inner.toggle .offer-banner-area {
    padding-left: 24px;
}

.offer-content {
    text-align: left;
    position: absolute;
    top: 0;
    margin-top: 90px;
    padding-left: 50px;
}

.offer-content-title {
    font-size: 31px;
    margin-bottom: 16px;
}

.offer-banner-wrapper img {
    width: 100%;
    height: 100%;
}

/*========================================================
	End Offer Banner Area
===========================================================*/

/*========================================================
	Order Steps Area
===========================================================*/
.order-steps-area {
    background: #f6f8fa;
    padding: 80px 0px;
    overflow: hidden;
    padding-left: 24px;
}

.order-steps-area .section-head {
    margin-bottom: 10px;
}

.order-steps-wrapper {
    margin-top: 40px;
    margin-right: 24px;
}

.order-steps-icon {
    margin-bottom: 24px;
}

.order-steps-cont-title {
    margin-bottom: 16px;
}

.order-steps-icon img {
    width: 104px;
    height: 104px;
}

.order-steps-cont-text {
    margin: 0;
}

html {
    font-family: "Source Sans Pro", sans-serif !important;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

html,
body {
    height: 100%;
    margin: 0;
}

img {
    max-width: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden],
template {
    display: none;
}

a {
    text-decoration: none;
}

a:active,
a:hover {
    outline: 0;
    text-decoration: none;
}

.animated:focus-visible {
    outline: none;
}

.table {
    display: table;
    width: 100%;
    height: 100%;
}

.table-cell {
    display: table-cell;
    vertical-align: middle;
}

img {
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 1em 40px;
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}

pre {
    overflow: auto;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

button:focus-visible {
    outline: none;
}

input:focus-visible {
    outline: none;
}

textarea:focus-visible {
    outline: none;
}

button {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input {
    line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

legend {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
}

optgroup {
    font-weight: bold;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 10px;
}

.btn-close:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

body,
button,
input,
select,
optgroup,
textarea {
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #383838;
    background: #f6f8fa;
}

.c-font,
.c-font a {
    font-family: "Source Sans Pro", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 15px;
}

h1,
h1 a,
h2,
h2 a,
h3,
h3 a,
h4,
h4 a,
h5,
h5 a,
h6,
h6 a {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
    line-height: 140%;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 40px;
}

h3 {
    font-size: 33px;
}

h4 {
    font-size: 28px;
}

h5 {
    font-size: 23px;
}

h6 {
    font-size: 19px;
}

p {
    color: #383838;
    margin: 0;
    line-height: 160%;
    font-weight: 400;
    font-size: 16px;
    margin: 0 0 15px;
}

a,
button,
input,
textarea {
    font-weight: 400;
}

img,
a,
input,
h1,
h2,
h3,
h4,
h5,
h6 {
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

dfn,
cite,
em,
i {
    font-style: italic;
}

address {
    margin: 0 0 1.5em;
}

pre {
    background: #eee;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1.6em;
}

code,
kbd,
tt,
var {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
    font-size: 0.9375rem;
}

abbr,
acronym {
    border-bottom: 1px dotted #666;
    cursor: help;
}

mark,
ins {
    background: #fff9c0;
    text-decoration: none;
}

big {
    font-size: 125%;
}

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    scroll-behavior: auto !important;
}

hr {
    background-color: #ccc;
    border: 0;
    height: 1px;
    margin-bottom: 1.5em;
}

/*========================================================
	End Order Steps Area
===========================================================*/

/*========================================================
	Why Choose Area
===========================================================*/
.whychoose-area {
    padding: 80px 0px;
    overflow: hidden;
}

.whychoose-wrapper {
    margin-top: 64px;
    margin-right: 24px;
}

.whychoose-icon img {
    width: 104px;
    height: 104px;
}

.whychoose-icon {
    margin-bottom: 24px;
}

.whychoose-cont-title {
    margin-bottom: 16px;
}

.whychoose-cont-text {
    margin: 0;
}

.sodainagar-main-inner.toggle .whychoose-area {
    padding-left: 24px;
}

/*========================================================
	End Why Choose Area
===========================================================*/

/*================================================================================================
	  Testimonial Area
==================================================================================================*/

.testimonial-area {
    padding: 80px 0px;
    background: #edf2f4;
}
.testimonial-single {
    background: #fff;
    padding: 32px 24px;
    text-align: center;
    margin-bottom: 80px;
}
.testimonial-rattings-list li {
    display: inline-block;
    margin-right: 8px;
}
.testimonial-rattings-list li i {
    font-size: 20px;
    margin: 0;
    color: #fea82f;
}
.testimonial-rattings-list li:last-child {
    margin: 0;
}
.testimonial-rattings-list li img {
    width: 16px;
    height: 16px;
}
.testimonial-img img {
    width: 94px;
    height: 94px;
    object-fit: cover;
    border-radius: 100%;
}
.testimonial-content-info {
    margin-top: 14px;
}
.testimonial-info-name {
    font-size: 20px;
    font-weight: 600 !important;
    margin-bottom: 2px;
}
.testimonial-info-label {
    font-size: 14px;
    color: var(--sodainagar-primary-color);
    margin: 0;
}
.testimonial-single-bottom {
    margin-top: 24px;
}
.testimonial-single-top i {
    font-size: 54px;
    line-height: 30px;
    color: #fea82f;
    margin-bottom: 12px;
    display: inline-block;
}
.testimonial-content-text {
    color: var(--ISPTemplateV1-sub-heading-color);
    line-height: 140% !important;
    margin-bottom: 12px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}
.testimonial-slider-inner {
    margin-top: 40px;
}

.testimonial-slider-inner .swiper-button-next,
.testimonial-slider-inner .swiper-button-prev {
    align-items: center;
    background: #fff;
    border-radius: 0px;
    bottom: 0;
    color: #383838;
    display: flex;
    font-size: 25px !important;
    height: 40px;
    justify-content: center;
    line-height: 40px;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    top: auto;
    transition: all 0.4s ease;
    width: 40px;
    z-index: 10;
}
.testimonial-slider-inner .swiper-button-next:after,
.testimonial-slider-inner .swiper-button-prev:after {
    font-size: 14px;
    font-weight: 400 !important;
}
.testimonial-slider-inner .swiper-button-next:hover,
.testimonial-slider-inner .swiper-button-prev:hover {
    background: var(--sodainagar-primary-color);
    color: #fff;
}
.testimonial-slider-inner .swiper-button-prev {
    left: 50%;
    margin-left: -45px;
}
.testimonial-slider-inner .swiper-button-next {
    margin-right: -45px !important;
    right: 50%;
}
.testimonial-slider-inner .swiper-button-next:after,
.testimonial-slider-inner .swiper-button-prev:after {
    font-family: uicons-regular-rounded !important;
    font-size: 16px;
    font-variant: normal;
    letter-spacing: 0;
    line-height: 1;
    text-transform: none !important;
}
.testimonial-slider-inner .swiper-button-next:after,
.testimonial-slider-inner .swiper-rtl .swiper-button-prev:after {
    content: "\f14b";
}
.testimonial-slider-inner .swiper-button-prev::after,
.testimonial-slider-inner .swiper-rtl .swiper-button-next::after {
    content: "\f14a";
}

@media only screen and (max-width: 767px) {
    .ISPTemplateV1-testimonial .section-head-big-title::before {
        top: initial;
        left: 50%;
        bottom: -1.5px;
        margin-left: -24px;
    }
    .testimonial-single {
        margin-bottom: 84px;
    }
}
/*================================================================================================
	  End Testimonial Area
==================================================================================================*/

/*========================================================
	Blog Area
===========================================================*/
.blog-area {
    padding: 80px 0px;
    overflow: hidden;
}

.blog-content-title {
    margin-bottom: 12px;
    font-size: 24px;
}

.blog-btn {
    color: var(--sodainagar-primary-color);
    text-decoration: underline;
    font-weight: 600;
    font-size: 19px;
}

.blog-btn:hover {
    color: var(--sodainagar-primary-color);
    text-decoration: underline;
    letter-spacing: 1px;
}

.blog-area .section-head {
    margin-bottom: 10px;
}

.blog-area .section-title {
    margin-bottom: 16px;
}

.blog-content {
    margin-top: 20px;
    text-align: left;
}

.blog-wrapper {
    margin-top: 40px;
    margin-right: 24px;
    text-align: center;
}

.blog-img img {
    border-radius: 0px 0px 4px 4px;
    width: 100%;
    height: 100%;
}

.sodainagar-main-inner.toggle .blog-area {
    padding-left: 24px;
}

.blog-card-widget-inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0;
}

/*========================================================
	End Blog Area
===========================================================*/

/*========================================================
	Promotion Banner Area
===========================================================*/
.promotion-banner-wrapper {
    margin-right: 24px;
}

.promotion-banner {
    z-index: 4444;
    position: relative;
}

/*========================================================
	End Promotion Banner Area
===========================================================*/

/*========================================================
	Footer Area
===========================================================*/
.footer-top {
    background: #fff;
    padding-top: 25px;
}

.f-about-para {
    font-weight: 400;
    margin-bottom: 5px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.f-about-para-a {
    color: var(--sodainagar-primary-color);
    font-weight: 500;
}

.footer-widget {
    margin-top: 40px;
}

.footer-widget-title {
    font-weight: 600;
    margin-bottom: 24px;
}
.footer-logo img {
    width: 220px;
}
.f-about-social-title {
    font-weight: 400;
    margin-bottom: 16px;
    display: block;
}

.f-about-social-list li {
    display: inline-block;
    margin-right: 15px;
}

.f-about-social-list li a {
    color: #393e46;
    width: 48px;
    height: 48px;
    text-align: center;
    background: #e6ecf0;
    border-radius: 100%;
    line-height: 45px;
}
.f-about-social-list li a img {
    width: 22px;
    height: 22px;
}

.f-about-social-list li a:hover {
    color: #5463ff;
}

.f-about-social-list li:last-child {
    margin: 0;
}

.footer-download-form {
    margin-top: 25px;
}

.footer-download-form .f-subs-form-content {
    font-weight: 400;
    margin-bottom: 12px;
}

.f-download-form-main {
    position: relative;
    width: 330px;
}

.f-download-form-main input {
    width: 330px;
    height: 50px;
    background: rgba(34, 87, 122, 0.06);
    border: none !important;
    border-radius: 4px !important;
    color: #383838 !important;
    padding: 10px 118px 10px 16px !important;
    position: relative;
}

.f-download-form-main .button {
    background: var(--sodainagar-primary-color);
    color: #f8f9ff;
    font-size: 16px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
    padding: 5px 16px;
    position: absolute;
    width: 93px;
    height: 34px;
    right: 8px;
    top: 8px;
    transition: all 0.4s ease;
}

.f-download-form-main .button:hover {
    background: var(--sodainagar-secondary-color);
}

.main-maps {
    position: relative;
    margin-bottom: 40px;
}

.main-maps iframe {
    width: 100%;
    height: 240px;
}

.map-direction-btn {
    position: absolute;
    left: 50%;
    margin-left: -70.5px;
    top: 50%;
    margin-top: -22.5px;
}

.map-direction-btn .theme-btn {
    background: #f8f9ff;
    -webkit-box-shadow: 0px 8px 24px rgba(57, 62, 70, 0.24);
    box-shadow: 0px 8px 24px rgba(57, 62, 70, 0.24);
    color: #5463ff;
    font-size: 14px;
    padding: 6px 17px;
}

.map-direction-btn .theme-btn i {
    margin: 0;
    margin-right: 0px;
    margin-right: 4px;
    font-size: 16px;
    position: relative;
    top: 3px;
}

.map-direction-btn .theme-btn:hover {
    background: #5463ff;
    color: #f8f9ff;
}

.footer-widget-title {
    font-weight: 700;
    font-size: 23px;
    line-height: 140%;
    margin-bottom: 16px;
}

.f-download-app .footer-widget-title {
    margin-bottom: 16px;
    font-size: 23px;
}

.footer-quick-links {
    margin-left: 105px;
}

.f-quick-links-list li {
    line-height: 145%;
    margin-bottom: 24px;
}

.f-quick-links-list li:last-child {
    margin: 0;
}

.f-quick-links-list li a:hover {
    color: #5463ff;
}

.footer-address-main {
    border-bottom: 1px solid #dde0ff;
    margin-bottom: 32px;
    padding-bottom: 25px;
}

.footer-address-wrapper {
    margin-top: 40px;
    position: relative;
}

.footer-address-wrapper .footer-address-icon {
    position: absolute;
    left: 0;
    top: 0;
}

.footer-address-wrapper .footer-address-icon i {
    color: var(--sodainagar-primary-color);
    font-size: 22px;
    width: 48px;
    height: 48px;
    line-height: 54px;
    background: rgba(34, 87, 122, 0.08);
    border-radius: 30px;
    display: block;
    text-align: center;
}

.footer-address-wrapper .footer-address-content {
    padding-left: 62px;
}

.f-address-cont-list li a {
    color: #393e46;
    font-weight: 400;
}

.footer-bottom {
    background: #fff;
    padding: 0px 0px 30px;
}

.f-copyright-main-text,
.f-designby-text {
    color: #383838;
    font-weight: 400;
    margin: 0;
}

.f-copyright-main-text a,
.f-designby-text a {
    color: var(--sodainagar-primary-color);
}

.f-copyright-main-text {
    margin-bottom: 16px;
}

.footer-right-content {
    text-align: right;
}

.payment-currency-img li {
    display: inline-block;
    margin-right: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    text-align: right;
    color: #383838;
}

.payment-currency-img li:last-child {
    margin: 0;
}

.footer-bottom-notice {
    margin-top: 20px;
}

.footer-bottom-notice li {
    display: inline-block;
    margin-right: 16px;
    position: relative;
    padding-right: 15px;
}

.footer-bottom-notice li::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 16px;
    background: #dde0ff;
    right: 0;
    top: 7px;
}

.footer-bottom-notice li a {
    color: #00b7aa;
    font-weight: 500;
    font-size: 16px;
}

.footer-bottom-notice li:last-child {
    margin: 0;
    padding: 0;
}

.google-play-btn {
    margin-right: 12px;
}

.google-play-btn:last-child {
    margin: 0px;
}

.f-subs-download-content {
    font-size: 13px;
    margin-bottom: 12px;
}

.f-stay-update-content {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    margin-bottom: 12px;
}

.footer-stay-update-form input {
    background: rgba(34, 87, 122, 0.06);
    border-radius: 4px !important;
    border: none !important;
    width: 100%;
    height: 50px;
    padding: 12px 100px 12px 16px !important;
    font-size: 16px !important;
    color: #383838;
    position: relative;
}

.f-update-form-main {
    position: relative;
}

.footer-stay-update-form .button {
    position: absolute;
    right: 8px;
    background: var(--sodainagar-secondary-color);
    border-radius: 4px;
    color: #fbfcfc;
    font-weight: 600;
    border: none;
    padding: 4px 16px;
    width: 108px;
    height: 34px;
    top: 8px;
    transition: all 0.4s ease;
}

.footer-stay-update-form .button:hover {
    background: var(--sodainagar-primary-color);
}

.f-address-cont-title {
    font-size: 13px;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 2px;
}

.f-address-cont-list li,
.f-address-cont-list li a {
    color: var(--sodainagar-secondary-color);
    font-weight: 500;
}

.footer-left-content-list li {
    display: inline-block;
    position: relative;
    padding-right: 16px;
    margin-right: 16px;
}

.footer-left-content-list li::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 16px;
    background: #dde0ff;
    right: 0;
    top: 7px;
}

.footer-left-content-list li.arrow-none::before,
.footer-bottom-notice li.arrow-none::before {
    display: none;
}

.footer-left-content-list li:last-child {
    margin: 0;
    padding: 0;
}
.footer-left-content-list li:last-child::before {
    display: none;
}

.footer-left-content-list {
    margin-bottom: 18px;
}

.footer-left-content-list li a {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
}

.footer-left-content-list li a:hover {
    color: var(--sodainagar-primary-color);
}

.footer-widget.f-download-app {
    margin-left: 70px;
}

.footer-widget.footer-stay-update {
    margin-left: 70px;
}

.f-download-btn {
    display: flex;
    align-items: center;
}
.f-download-btn img {
    width: 140px;
    height: auto;
    object-fit: contain;
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .f-subscribe-form-main input {
        width: 100%;
    }

    .payment-currency-img li {
        margin-bottom: 8px;
    }

    .payment-currency-img li:last-child {
        margin-right: 8px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-quick-links {
        margin-left: 80px;
    }

    .f-copyright-main-text {
        text-align: center;
        margin-bottom: 10px;
    }

    .f-copyright-main-text,
    .f-designby-text {
        text-align: center;
    }

    .footer-right-content {
        text-align: center;
        margin-top: 25px;
    }
}

@media only screen and (max-width: 767px) {
    .footer-quick-links {
        margin-left: 0;
    }

    .f-copyright-main-text {
        text-align: center;
        margin-bottom: 10px;
    }

    .f-copyright-main-text,
    .f-designby-text {
        text-align: center;
    }

    .footer-right-content {
        text-align: center;
        margin-top: 25px;
    }

    .payment-currency-img li {
        margin-bottom: 8px;
    }

    .f-subscribe-form-main input {
        width: 100%;
    }
}

/*========================================================
	End Footer Area
===========================================================*/

.list-none,
.list-none li {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul {
    list-style-type: disc;
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
    padding: 0;
    margin: 0;
}

a {
    display: inline-block;
}

dt {
    font-weight: bold;
}

dd {
    margin: 0 1.5em 1.5em;
}

img {
    height: auto;
    max-width: 100%;
}

figure {
    margin: 1em 0;
}

table {
    margin: 0 0 1.5em;
    width: 100%;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    border: 1px solid;
    border-color: #ccc #ccc #bbb;
    border-radius: 3px;
    background: #e6e6e6;
    color: rgba(0, 0, 0, 0.8);
    line-height: 1;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
    color: #666;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 3px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
    color: #818790;
}

select {
    border: 1px solid #ccc;
}

textarea {
    width: 100%;
}

a {
    color: inherit;
}

a:hover,
a:focus,
a:active {
    color: inherit;
}

a:hover,
a:active {
    outline: 0;
}

.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}

.screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    -webkit-clip-path: none;
    clip-path: none;
    color: #21759b;
    display: block;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
}

#content[tabindex="-1"]:focus {
    outline: 0;
}

.alignleft {
    display: inline;
    float: left;
    margin-right: 1.5em;
}

.alignright {
    display: inline;
    float: right;
    margin-left: 1.5em;
}

.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
    content: "";
    display: table;
    table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
    clear: both;
}

.widget {
    margin: 0 0 1.5em;
}

.widget select {
    max-width: 100%;
}

.sticky {
    display: block;
}

.hentry {
    margin: 0 0 1.5em;
}

.updated:not(.published) {
    display: none;
}

.page-content,
.entry-content,
.entry-summary {
    margin: 1.5em 0 0;
}

.page-links {
    clear: both;
    margin: 0 0 1.5em;
}

.comment-content a {
    word-wrap: break-word;
}

.bypostauthor {
    display: block;
}

.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
    display: none;
}

.infinity-end.neverending .site-footer {
    display: block;
}

.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
    border: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
}

embed,
iframe,
object {
    max-width: 100%;
}

.custom-logo-link {
    display: inline-block;
}

/*======================================
    Padding Margin
========================================*/
.pd-top-10 {
    padding-top: 10px;
}

.pd-top-20 {
    padding-top: 20px;
}

.pd-top-30 {
    padding-top: 30px;
}

.pd-top-40 {
    padding-top: 40px;
}

.pd-top-50 {
    padding-top: 50px;
}

.pd-top-60 {
    padding-top: 60px;
}

.pd-top-70 {
    padding-top: 70px;
}

.pd-top-80 {
    padding-top: 80px;
}

.pd-top-90 {
    padding-top: 90px;
}

.pd-top-100 {
    padding-top: 100px;
}

.pd-btm-10 {
    padding-bottom: 10px;
}

.pd-btm-20 {
    padding-bottom: 20px;
}

.pd-btm-30 {
    padding-bottom: 30px;
}

.pd-btm-40 {
    padding-bottom: 40px;
}

.pd-btm-50 {
    padding-bottom: 50px;
}

.pd-btm-60 {
    padding-bottom: 60px;
}

.pd-btm-70 {
    padding-bottom: 70px;
}

.pd-btm-80 {
    padding-bottom: 80px;
}

.pd-btm-90 {
    padding-bottom: 90px;
}

.pd-btm-100 {
    padding-bottom: 100px;
}

.mg-top-10 {
    margin-top: 10px;
}

.mg-top-20 {
    margin-top: 20px;
}

.mg-top-30 {
    margin-top: 30px;
}

.mg-top-40 {
    margin-top: 40px;
}

.mg-top-50 {
    margin-top: 50px;
}

.mg-top-60 {
    margin-top: 60px;
}

.mg-top-70 {
    margin-top: 70px;
}

.mg-btm-10 {
    margin-bottom: 10px;
}

.mg-btm-20 {
    margin-bottom: 20px;
}

.mg-btm-30 {
    margin-bottom: 30px;
}

.mg-btm-35 {
    margin-bottom: 35px;
}

.mg-btm-40 {
    margin-bottom: 40px;
}

.mg-btm-50 {
    margin-bottom: 50px;
}

/*======================================================
	End Theme Reset
========================================================*/

/*======================================================
	Theme Other CSS
========================================================*/
.theme-btn {
    background: var(--sodainagar-primary-color);
    border-radius: 4px;
    display: inline-block;
    padding: 12px 20px;
    color: #fbfcfc;
    font-weight: 500;
    text-decoration: none;
}

.theme-btn:hover {
    background: var(--sodainagar-secondary-color);
    color: #fbfcfc;
}

.section-title.text-center {
    margin: 0;
}

.section-head.style-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-right: 24px;
}

.section-title {
    margin: 0;
}

.section-h-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    background-color: #fff;
    border: none;
    transition: all 0.4s ease;
    padding: 0px;
}

.section-h-btn i {
    font-size: 22px;
    position: relative;
    top: 5px;
    margin-left: 8px;
}

.section-h-btn:hover {
    color: var(--sodainagar-primary-color);
}

.section-head-text {
    margin: 0;
}

.cart-active {
    position: fixed;
    right: 0;
    top: 400px;
    z-index: 555;
    background: var(--sodainagar-secondary-color);
    box-shadow: 0px 12px 24px rgba(34, 87, 122, 0.24);
    border-radius: 12px 0px 0px 12px;
    text-align: center;
    color: #fbfcfc;
    display: block;
    padding: 10px;
    border: none;
    font-size: 20px;
}

.cart-active:hover {
    background: var(--sodainagar-secondary-color);
}

.cart-active-text {
    color: #fbfcfc;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    line-height: 17px;
}

.cart-active a i {
    font-size: 24px;
    margin-bottom: 4px;
}

.cart-active.btn.btn-primary:focus {
    border: none;
    box-shadow: none;
    background: var(--sodainagar-secondary-color);
}

/* Active Cart Modal */

.cart-sidebar-modal .modal-dialog {
    width: 372px;
    background: #fbfcfc;
    box-shadow: -16px 0px 32px rgba(34, 87, 122, 0.16);
    z-index: 9999;
    float: right;
    height: 100%;
}

.cart-sidebar-modal .modal-header {
    display: flex;
    flex-shrink: initial;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    border-bottom: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background: var(--sodainagar-secondary-color);
}

.cart-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
}

.cart-modal-header-title {
    margin: 0;
    font-weight: 700;
    font-size: 23px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
    color: #fbfcfc;
    display: flex;
    align-items: center;
}

.cart-modal-header-title span {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    text-align: center;
    color: #fbfcfc;
    margin-left: 5px;
}

.cart-modal-header .btn.btn-secondary {
    background: #f55353;
    border-radius: 27px;
    border: none;
    width: 58px;
    height: 26px;
    padding: 5px;
    font-weight: 500;
    font-size: 13px;
    line-height: 11px;
    text-align: center;
    color: #fbfcfc;
}

.cart-modal-header .btn.btn-secondary:focus {
    border: none;
    box-shadow: none;
}

.cart-sidebar-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4eaef;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.cart-sidebar-item-info {
    display: flex;
    align-items: center;
    width: 65%;
}

.cart-sidebar-item-info img {
    max-width: 68px;
    max-height: 68px;
}

.cart-sidebar-item-content a {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #202020;
    margin-bottom: 4px;
}

.cart-sidebar-item-price {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: #383838;
    display: block;
}

.cart-sidebar-item-content {
    margin-left: 8px;
}

.cart-sidebar-item-qty {
    text-align: right;
    margin-right: 10px;
}

.cart-s-item-qty-price {
    font-weight: 700;
    font-size: 19px;
    line-height: 140%;
    text-align: right;
    color: #202020;
    margin-bottom: 8px;
}

.cart-sidebar-item-qty .p-details-modal-qty-btn {
    width: 85px;
    height: 24px;
    border-radius: 4px;
    margin: 0;
}

.cart-sidebar-item-qty .p-details-modal-qty-btn .qty-count {
    width: 20px;
    height: 20px;
    background: #ffffff;
    text-align: center;
    color: var(--sodainagar-secondary-color);
    font-size: 20px;
    line-height: 37px;
    border: none;
    transition: all 0.4s ease;
    border-radius: 4px;
    line-height: 20px;
}

.cart-sidebar-item-qty .p-details-modal-qty-btn .qty-count:hover {
    background: var(--sodainagar-secondary-color);
    color: #ffffff;
}

.cart-sidebar-item-qty .p-details-modal-qty-btn .product-qty {
    width: 40px;
    height: 24px;
    appearance: textfield;
    text-align: right;
    background: transparent;
    border: none !important;
    color: #383838 !important;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
}

.cart-sidebar-item-qty .p-details-modal-qty-btn .qty-count {
    text-align: center;
    color: var(--sodainagar-secondary-color);
    font-size: 16px;
    line-height: 24px;
    padding: 0;
    position: relative;
    top: 2px;
}

.cart-sidebar-item-qty .p-details-modal-qty-btn .qty-count.qty-count--minus {
    float: left;
    margin-left: 2px;
}

.cart-sidebar-item-qty .p-details-modal-qty-btn .qty-count.qty-count--add {
    float: right;
    margin-right: 2px;
}

.cart-sidebar-modal .modal-content {
    background-color: #fbfcfc;
    border: none;
    border-radius: 0px;
    outline: 0;
}

.cart-sidebar-item-delete {
    color: #f55353;
    font-size: 20px;
    border: 0px !important;
}

.coupon-applied-price-title {
    display: flex;
    font-weight: 700;
    font-size: 28px;
    line-height: 140%;
    color: var(--sodainagar-secondary-color);
    align-items: center;
    justify-content: space-between;
}

.coupon-applied-price-title del {
    font-weight: 400;
    font-size: 19px;
    line-height: 140%;
    text-decoration-line: line-through;
    color: var(--sodainagar-secondary-color);
}

.sidebar-cart-b-btn button {
    background: #22577a !important;
    border-radius: 4px;
    display: block !important;
    width: 100% !important;
    text-align: center;
    border: none;
    padding: 15px 20px !important;
    margin: 0px !important;
    height: 54px !important;
}

.sidebar-cart-b-btn button:hover {
    background: #57cc99 !important;
}

.sidebar-cart-b-btn button .theme-btn.btn.btn-primary:focus {
    border: none;
    box-shadow: none;
    background: var(--sodainagar-primary-color);
}

.cart-coupon-form input {
    width: 188px;
    height: 46px;
    background: #ebf0f3;
    border-radius: 2px;
    border: none;
    padding: 10px 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
}

.cart-coupon-form button {
    background: var(--sodainagar-primary-color);
    border-radius: 4px;
    border: none;
    margin-left: 8px;
    display: block;
    width: 137px;
    height: 46px;
    padding: 10px;
}

.cart-coupon-form button.theme-btn.btn.btn-primary:focus {
    border: none;
    box-shadow: none;
    background: var(--sodainagar-primary-color);
}

.cart-coupon-form label {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
    margin-bottom: 8px;
    margin-left: 10px;
}

.cart-coupon-form-input {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.cart-coupon-form-input .theme-btn {
    background: var(--sodainagar-primary-color);
    width: 100%;
}

.cart-sidebar-bottom {
    border-top: 1px solid #e5ecef;
    padding: 20px 24px 20px 15px;
}

.coupon-applied-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #383838;
    margin-bottom: 24px;
    margin-left: 10px;
}

.coupon-applied-text2 {
    display: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #383838;
    margin-bottom: 24px;
    margin-left: 10px;
}

.cart-sidebar-modal .modal-dialog {
    transform: translate(0, 50px) !important;
}

.cart-sidebar-modal .modal-body {
    padding: 20px 0px 0px 20px;
}

.cart-sidebar-item-main {
    overflow-y: scroll;
    height: 300px;
}

.cart-sidebar-item-main::-webkit-scrollbar {
    width: 11px;
}

.cart-sidebar-item-main {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.cart-sidebar-item-main::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}

.cart-sidebar-item-main::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
}

.cart-sidebar-item-main {
    --scrollbarBG: #cfd8dc;
    --thumbBG: #90a4ae;
}

.cart-sidebar-item-main::-webkit-scrollbar {
    width: 10px;
}

.offcanvas-body {
    flex-grow: 1;
    padding: 10px 5px;
    overflow-y: auto;
}

.offcanvas-title {
    margin-bottom: 0;
    line-height: 1.5;
    width: 100%;
}

.offcanvas-start {
    left: inherit;
    right: 0 !important;
    transform: translateX(100%) !important;
    margin-top: 72px;
    background: #fbfcfc;
    box-shadow: -16px 0px 32px rgba(34, 87, 122, 0.16);
    z-index: 99999;
}

.offcanvas.show {
    transform: none !important;
}

.offcanvas-backdrop.show {
    opacity: 0;
}

.offcanvas-header {
    padding: 5px;
    background: var(--sodainagar-secondary-color);
    border-radius: 0px;
    color: #fbfcfc;
}

.cart-sidebar-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
}

.offcanvas-body {
    position: relative;
}

.cart-sidebar-item-main {
    height: 500px;
}

/*======================================================
	End Theme Other CSS
========================================================*/

/*========================================================
	Header Area
===========================================================*/
.header-area {
    background: #fff;
    padding: 12px 0px;
    z-index: 999;
    position: relative;
}
.header-logo img {
    width: 220px;
    height: auto;
    object-fit: contain;
}
.header-searchbar-top-right {
    display: flex;
    align-items: center;
}
.header-inner-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-right {
    position: relative;
}

.h-right-main-accounts {
    display: inline-block;
    position: relative;
    top: -2px;
}

.header-searchbar {
    position: relative;
}

.header-searchbar input {
    background: #ffffff;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    padding: 11px 40px 11px 11px;
    width: 535px;
    height: 48px;
    font-weight: 400;
    font-size: 16px;
}

.header-searchbar .search-btn {
    position: absolute;
    right: 12px;
    font-size: 20px;
    background: transparent;
    border: none;
    color: var(--sodainagar-secondary-color);
    top: 14px;
    padding: 0;
}

.h-delivery-address {
    display: flex;
    background: #ffffff;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    padding: 10px 16px;
    align-items: center;
    height: 48px;
    width: 305px;
    overflow: hidden;
}

.h-delivery-address-map .h-delivery-address-text {
    border: none;
    padding: 0;
    color: #383838;
    font-weight: 400;
}

.h-delivery-address-text:focus {
    color: #383838 !important;
}

.h-delivery-address-inner i {
    color: var(--sodainagar-secondary-color);
    font-size: 20px;
    line-height: 0;
    position: relative;
    top: 2px;
    margin-right: 5px;
}

.h-delivery-address-text {
    margin: 0;
}

.h-delivery-address-map {
    display: flex;
    align-items: center;
}

.h-delivery-address {
    margin-left: 25px;
}

.h-right-cart {
    font-size: 24px;
    color: var(--sodainagar-secondary-color);
    position: relative;
}

.h-right-cart-item {
    width: 20px;
    height: 20px;
    background: var(--sodainagar-primary-color);
    display: block;
    font-size: 11px;
    text-align: center;
    line-height: 19px;
    color: #fbfcfc;
    border-radius: 100%;
    position: absolute;
    top: 1px;
    left: 27px;
}

.header-right-main {
    margin-left: 30px;
}

.h-right-cart {
    font-size: 24px;
    color: var(--sodainagar-secondary-color);
    position: relative;
    padding-right: 25px;
    margin-right: 10px;
    top: 4px;
    text-decoration: none;
}
.h-right-cart:hover {
    color: var(--sodainagar-secondary-color);
}

.h-right-cart::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 26px;
    background: #e4eaef;
    right: 0;
}

.h-right-main-accounts .nav-link.dropdown-toggle {
    padding: 0;
    color: var(--sodainagar-secondary-color);
    font-size: 16px;
    font-weight: 400;
    margin: 0px 20px 0px 27px;
}

.h-right-main-accounts .nav-link.dropdown-toggle i {
    font-size: 24px;
    position: relative;
    top: 5px;
    margin-right: 7px;
}

.h-right-main-accounts .navbar {
    padding: 0;
    display: block;
    padding-right: 25px;
}

.h-right-main-accounts .dropdown-menu.show {
    display: block;
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    padding: 0px;
    box-shadow: 0px 16px 36px rgba(34, 87, 122, 0.16);
    width: 175px;
    left: -63px !important;
    top: 12px !important;
}
.h-right-main-accounts .dropdown-menu.show::before {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-left: -6px;
    -webkit-transition: none;
    transition: none;
    right: 13px;
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    top: -7px;
}

.h-right-main-accounts .dropdown-item:hover {
    color: #fbfcfc;
    background-color: var(--sodainagar-primary-color);
}

.h-right-main-accounts .dropdown-item {
    font-weight: 400;
    color: #383838;
    font-size: 14px;
    transition: none;
    padding: 5px 12px;
    z-index: 333;
    position: relative;
    background: #fbfcfc;
}

.h-right-main-accounts .dropdown-item i {
    margin-right: 8px;
    position: relative;
    top: 2px;
}

.h-right-main-accounts .dropdown-toggle::before {
    position: absolute;
    content: "\f525";
    font-family: "uicons-regular-rounded";
    border-top: transparent;
    border-right: transparent;
    border-bottom: 0;
    border-left: transparent;
    font-size: 24px;
    top: 0px;
    left: 0;
}

.h-right-main-accounts .dropdown-toggle::after {
    position: absolute;
    content: "\f11d";
    font-family: "uicons-regular-rounded";
    border-top: transparent;
    border-right: transparent;
    border-bottom: 0;
    border-left: transparent;
    font-size: 20px;
    top: 2px;
    margin-left: 5px;
}

.header-logo-main {
    position: relative;
    display: flex;
    align-items: center;
}

.header-logo-main .sidebar-icon {
    font-size: 24px;
    cursor: pointer;
    margin-right: 25px;
    position: relative;
    top: 0px;
    width: 24px;
    height: 24px;
    transition: all 0.4s ease;
}

.header-area.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 16px 0px 32px rgba(34, 87, 122, 0.16);
    z-index: 9999;
}

@media only screen and (max-width: 1400px) {
    .header-inner {
        padding: 0px 15px;
    }
}

.map-locations-modal:hover {
    cursor: pointer;
}

.map-location-modal-inner .modal-content {
    margin-top: 250px !important;
    border-radius: 0;
    width: 550px;
    margin-top: 200px;
    left: 50%;
    margin-left: -275px;
    border-radius: 5px !important;
}

.map-location-modal-inner .form-input-wrapper .form-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #383838;
    margin: 0;
    background: #fbfcfc;
    top: 0;
    margin-left: 12px;
    z-index: 33;
    margin-bottom: 8px;
    padding: 0;
    border: none;
}

.map-location-modal-inner .modal-header {
    background: transparent !important;
    border: none;
    padding: 0 !important;
    border-radius: 0;
    padding-top: 20px !important;
}

.add-new-address-modal-form.map-location-modal-inner .btn-close {
    top: 5px !important;
    right: 33px !important;
    z-index: 3333;
}

/*========================================================
	End Header Area
===========================================================*/

/*========================================================
  Popular Category Top
===========================================================*/

.p-category-top-menu-list {
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    display: inline-block;
    padding: 4px 8px;
    margin-bottom: 16px;
}

.p-category-top-menu-list li {
    display: inline-block;
    margin-right: 3px;
}

.p-category-top-menu-list li a {
    color: #999a9a;
    font-weight: 400;
}

.p-category-top-menu-list li a:hover {
    color: #383838;
}

.p-category-top-menu-list li.active a {
    color: #383838;
}

.p-category-top-menu-list li:last-child {
    margin: 0;
}

.p-category-top-menu-list li i {
    font-size: 20px;
    position: relative;
    top: 5px;
    margin-left: 3px;
    display: inline-block;
    color: #999a9a;
}

.p-item-found-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 140%;
    color: #202020;
    margin-bottom: 4px;
}

.p-item-found-text {
    color: #9b9b9b;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    margin: 0;
}

.product-category-select {
    display: flex;
    align-items: center;
}

.product-category-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 24px;
    margin-top: 24px;
}

.product-category-select .form-select {
    width: 179px;
    height: 38px;
    border: 1px solid #e4eaef;
    border-radius: 4px;
}

.product-category-select .form-select:focus {
    outline: none;
    box-shadow: none;
}

.product-category-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 24px;
}

.product-category-select .form-select option {
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    box-shadow: 0px 16px 36px rgba(34, 87, 122, 0.16);
}

.product-category-select .form-select option:hover {
    background: var(--sodainagar-primary-color);
    color: #fbfcfc;
}

.product-category-select label {
    margin-right: 8px;
}

.p-category-top-menu {
    margin-top: 32px;
}

/*========================================================
  End Popular Category Top
===========================================================*/

/*========================================================
  LogIn Page Area
===========================================================*/
.login-page-area {
    overflow: hidden;
    padding-top: 55px;
    padding-bottom: 215px;
}
.login-card {
    background: #ffffff;
    box-shadow: 0px 16px 36px rgba(34, 87, 122, 0.08);
    border-radius: 4px;
    padding: 40px 25px;
    margin: 0px 60px;
}
.login-card-top-title {
    margin-bottom: 8px;
}

.login-card-top-text {
    margin: 0;
}

.login-card-top {
    border-bottom: 1px solid #e4eaef;
    padding-bottom: 16px;
    margin-bottom: 24px;
}

.login-card .form-label-text {
    justify-content: center;
    display: flex;
    position: relative;
    margin-bottom: 16px;
}

.login-card .form-label-text::before {
    position: absolute;
    content: "";
    width: 93px;
    height: 1px;
    background: #e4eaef;
    left: 0;
    top: 50%;
}

.login-card .form-label-text::after {
    position: absolute;
    content: "";
    width: 93px;
    height: 1px;
    background: #e4eaef;
    right: 0;
    top: 50%;
}

.form-input-main input {
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    height: 46px;
    width: 100%;
    padding: 10px 85px;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
}

.form-input-main .input:focus {
    box-shadow: none;
    border: none;
}

.form-input-main .form-control:focus {
    box-shadow: none;
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    color: #383838;
}

.form-input-main .theme-btn {
    border: none;
    box-shadow: none;
}

.form-input-main .theme-btn.btn.btn-primary:focus {
    background: var(--sodainagar-secondary-color);
    box-shadow: none;
}

.form-input-main {
    position: relative;
}

.form-input-main .theme-btn {
    border: none;
    box-shadow: none;
    font-weight: 600;
    padding: 4px 16px;
    top: 7px;
    position: absolute;
    right: 6px;
}

.form-country-number {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 12px;
}

.form-country-number-text {
    margin: 0;
    margin-left: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
}

.login-card-options-title {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #000000;
    position: relative;
}

.login-card-options-title::before {
    position: absolute;
    content: "";
    width: 93px;
    height: 1px;
    background: #e4eaef;
    left: 0;
    top: 50%;
}

.login-card-options-title::after {
    position: absolute;
    content: "";
    width: 93px;
    height: 1px;
    background: #e4eaef;
    right: 0;
    top: 50%;
}

.login-card-options {
    margin-top: 40px;
}

.login-card-options-wrapper {
    display: flex;
    align-items: center;
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    padding: 9px 16px;
    margin-bottom: 12px;
    justify-content: center;
    position: relative;
}

.login-card-options-wrapper:last-child {
    margin: 0;
}

.l-card-options-content-text {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
}

.l-card-options-wrapper-icon img {
    width: 28px;
    height: 28px;
    position: absolute;
    left: 16px;
    top: 9px;
}

.dont-have-account-text {
    text-align: center;
    margin: 0;
    margin-top: 28px;
    font-weight: 500;
}

.dont-have-account-text a {
    color: var(--sodainagar-primary-color);
    text-decoration: underline;
    font-weight: 600;
}

.login-product-side {
    padding-left: 80px;
    margin-top: -42px;
}

.login-page-banner {
    margin-top: 40px;
    padding-right: 24px;
}

.login-page-banner img {
    width: 100%;
    height: 100%;
}

.form-control.w-full.max-w-xs {
    padding: 0;
    border: none;
}

.login-form-email .form-title-label {
    display: inline-block;
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #383838;
    padding: 4px 8px;
    position: relative;
    top: 15px;
    left: 12px;
    margin: 0;
    z-index: 222;
}
.login-form-email .form-control {
    background: transparent;
}
.login-form-email .input.input-bordered.w-full.max-w-xs {
    width: 100%;
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    height: 52px;
    padding: 18px 12px 8px 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
}

.btn.btn-accent.w-full.max-w-xs.text-white {
    width: 100%;
    background: var(--sodainagar-primary-color);
    border-radius: 4px;
    border: none;
    height: 48px;
    margin-top: 24px !important;
}

.btn.btn-accent.w-full.max-w-xs.text-white:hover {
    background: var(--sodainagar-secondary-color);
}

.forgot-pass-text {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
    margin: 0;
    margin-top: 30px;
}

.forgot-pass-text a {
    color: #f55353;
    text-decoration: underline;
    font-weight: 600;
}

.pass-input-main {
    position: relative;
}

.pass-input-main i {
    position: absolute;
    right: 16px;
    top: 16px;
}

.pass-input-main .input.input-bordered.w-full.max-w-xs {
    padding-right: 35px;
}

.reset-password-area {
    overflow: hidden;
    padding-top: 80px;
    padding-bottom: 215px;
}

.reset-password-card {
    background: #ffffff;
    box-shadow: 0px 16px 36px rgba(34, 87, 122, 0.08);
    border-radius: 4px;
    padding: 40px 25px;
    margin: 0px 60px;
}

.signup-card .login-card-top {
    text-align: center;
}

.label-text-alt.text-red-500 {
    font-size: 14px;
    margin-top: 8px;
    display: block;
    color: #f55353;
}

.reset-password-top {
    text-align: center;
    margin-bottom: 35px;
}

.reset-password-top.border-add {
    margin-bottom: 24px;
    border-bottom: 1px solid #e4eaef;
    padding-bottom: 16px;
}

.remember-login-text {
    margin: 0;
    margin-top: 32px;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: #383838;
}

.remember-login-text a {
    color: var(--sodainagar-primary-color);
    font-weight: 600;
    text-decoration: underline;
}

.reset-password-top img {
    width: 96px;
    height: 96px;
    margin-bottom: 16px;
}

.reset-password-top-text {
    margin: 0;
}

.back-login-btn {
    text-align: center;
}

.back-login-btn .theme-btn {
    background: var(--sodainagar-secondary-color);
    border-radius: 4px;
    padding: 10px 16px;
}

.back-login-btn .theme-btn i {
    position: relative;
    font-size: 20px;
    margin-right: 5px;
    top: 4px;
}

.back-login-btn .theme-btn:hover {
    background: var(--sodainagar-primary-color);
    color: #fbfcfc;
}

.login-page-banner {
    margin-top: 40px;
}

.login-product-side .swiper-button-next,
.login-product-side .swiper-button-prev {
    top: 22px !important;
    right: -62px;
}

.login-product-side h4 {
    display: block;
    position: relative;
    top: 44px;
}

.login-product-side .popular-products-wrapper {
    margin-top: 65px;
}

.login-product-side .swiper-button-prev,
.login-product-side .swiper-rtl .swiper-button-next {
    right: -23px !important;
    right: auto;
}

/*=======================================================
  End  LogIn Page Area
===========================================================*/

.phone-verification-top {
    text-align: center;
}

.phone-verification-top img {
    width: 72px;
    height: 72px;
    margin-bottom: 23px;
}

.phone-v-top-text {
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
    margin-bottom: 24px;
}

.phone-v-top-didnot-received {
    text-align: left;
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
}

.phone-v-top-didnot-received button {
    color: var(--sodainagar-primary-color);
    font-weight: 600;
    text-decoration: none;
    padding: 0;
}

.phone-v-top-didnot-received button:hover {
    color: var(--sodainagar-primary-color);
}

.phone-verification-form .form-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #383838;
    margin-left: 12px;
}

.phone-verification-form .form-input-main input {
    padding: 12px;
    font-weight: 500;
}

.form-input-main .theme-btn {
    margin-top: 24px;
    padding: 10px 40px;
    position: initial;
}

.form-input-main .number-theme-btn {
    border: none;
    box-shadow: none;
    font-weight: 600;
    padding: 4px 16px;
    top: 7px;
    position: absolute;
    right: 6px;
    background: var(--sodainagar-primary-color);
}

.phone-verification-form {
    margin-top: 24px;
}

/*========================================================
  End  LogIn Page Area
===========================================================*/

/*========================================================
  CheckOut Page Area
===========================================================*/
.checkout-shopping-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.checkout-s-tab-wrapper {
    text-align: center;
    padding: 0;
    margin-right: 70px;
    display: flex;
    align-items: center;
    opacity: 0.24;
    margin-top: 40px;
}

.checkout-s-tab-wrapper:last-child {
    margin-right: 0;
}

.checkout-s-tab-wrapper-title {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: var(--sodainagar-secondary-color);
    margin-top: 12px;
}

.checkout-s-tab-wrapper-icon img {
    width: 48px;
    height: 48px;
}

.checkout-s-tab-wrapper i {
    margin-left: 70px;
    font-size: 20px;
    color: #cad7df !important;
}

.checkout-s-tab-wrapper.active {
    opacity: 1;
}

.shopping-info-accordian {
    margin-top: 45px;
    background: #ffffff;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    padding-top: 24px;
}

.shopping-info-accordian .accordion-header {
    margin-bottom: 0;
    margin: 0px 24px;
}

.shopping-info-accordian .accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
    font-weight: 600;
    font-size: 23px;
    line-height: 140%;
    color: var(--sodainagar-secondary-color);
    width: 100%;
    background: transparent;
    box-shadow: none;
    border-bottom: 1px solid #e4eaef;
    padding-left: 0;
    padding-right: 0;
}

.border-none.accordion-header .accordion-button.collapsed {
    border-bottom: none !important;
}

.shopping-info-accordian .accordion-flush .accordion-item {
    border-radius: 4px;
    border-bottom: 0;
}

.shopping-info-accordian .accordion-button:not(.collapsed)::after {
    font-family: "uicons-regular-rounded";
    background-image: none !important;
    content: "\f113";
    transform: none !important;
    color: #22577a !important;
}

.accordion-button::after {
    font-family: "uicons-regular-rounded";
    content: "\f110";
    background-image: none !important;
    color: #22577a !important;
    width: 0;
    height: 0;
    margin-right: 0;
    position: absolute;
    right: 20px;
    top: 18px;
    font-weight: 400;
    font-size: 24px;
}

.form-input-wrapper input {
    width: 100%;
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    padding: 18px 12px 8px 12px;
}

.form-input-wrapper .form-control:focus {
    border: 1px solid #e4eaef;
    box-shadow: none;
    background: #fbfcfc;
    color: #383838;
}

.form-input-wrapper .form-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #383838;
    margin: 0;
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    padding: 4px 8px;
    top: 14px;
    position: relative;
    margin-left: 12px;
    z-index: 33;
}

.form-input-wrapper .form-select {
    width: 100%;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    padding: 18px 12px 8px 12px;
}

.form-input-wrapper .form-select:focus {
    border: 1px solid #e4eaef;
    box-shadow: none;
}

.form-input-wrapper .form-select {
    font-size: 16px;
    color: #383838;
    background-color: #fbfcfc;
}

.form-input-wrapper-icon i {
    position: absolute;
    right: 0;
    top: 20px;
    font-size: 20px;
    right: 13px;
}

.form-input-wrapper-icon input {
    padding: 18px 35px 8px 12px;
}

.form-input-wrapper {
    margin-bottom: 8px;
}

.form-input-wrapper-icon {
    position: relative;
}

.shopping-info-accordian .accordion-body {
    padding: 24px;
    padding-top: 20px;
}

.form-input-checkbox {
    margin-bottom: 5px;
}

.form-input-checkbox .form-check label {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
}
.form-input-checkbox form div div {
    height: 0;
}
.form-input-checkbox .form-check-input:checked {
    background-color: #57cc99 !important;
    border-color: transparent !important;
    box-shadow: none;
}

.form-input-checkbox .form-check-input:focus {
    border-color: var(--sodainagar-secondary-color);
    outline: 0;
    box-shadow: none;
    border-radius: 4px;
}

.form-input-checkbox .form-check-input {
    border: 1.5px solid #22577a !important;
}

.shopping-info-accordian-bottom {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 24px;
}

.return-shop-btn {
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    padding: 10px 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #383838;
}

.return-shop-btn i {
    font-size: 22px;
    position: relative;
    top: 5px;
    margin-right: 8px;
}

.continue-payment-btn {
    background: var(--sodainagar-primary-color);
    border-radius: 4px;
    display: block;
    padding-right: 16px;
    cursor: pointer;
}

.continue-payment-btn input {
    background: transparent;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #fbfcfc;
    padding: 13px 16px;
    padding-right: 0;
}

.continue-payment-btn i {
    color: #fbfcfc;
    font-size: 22px;
    position: relative;
    top: 5px;
    margin-left: 8px;
}

.shopping-info-area {
    padding-bottom: 130px;
}

.pay-with-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
}
.pay-with-options .one {
    position: relative;
    transition: all 0.4s ease;
}
.pay-with-options .one::before {
    position: absolute;
    font-family: uicons-bold-rounded !important;
    content: "\f1fc";
    width: 32px;
    height: 32px;
    border-radius: 100%;
    color: #fff;
    font-size: 16px;
    background: var(--sodainagar-primary-color);
    text-align: center;
    line-height: 32px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    transition: all 0.4s ease;
}
.pay-with-options .one::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--sodainagar-primary-color);
    opacity: 0.1;
    transition: all 0.4s ease;
}
.pay-with-options-wrapper {
    background: #fbfcfc;
    border: 2px solid #e4eaef;
    border-radius: 4px;
    text-align: center;
    padding: 16px;
    display: block;
    cursor: pointer;
    transition: all 0.4s ease;
}

.pay-with-options-wrapper img {
    width: 80px;
    height: 32px;
}

.pay-with-options {
    border-top: 1px solid #e4eaef;
    margin-top: 24px;
    padding-top: 24px;
}

.pay-with-options-title {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: #383838;
    margin-top: 5px;
}

.form-input-wrapper-icon.add-payment-card {
    position: relative;
}

.add-payment-card-list {
    position: absolute;
    right: 14px;
    top: 10px;
}

.add-payment-card-list img {
    width: 28px;
    height: 20px;
    margin-right: 5px;
}

.add-payment-card-list img:last-child {
    margin: 0;
}

.form-input-wrapper-icon.add-payment-card input {
    background: #fbfcfc;
    border: 2px solid var(--sodainagar-primary-color);
    border-radius: 4px;
    height: 48px;
    padding: 16px 150px 16px 12px;
}

.payment-info-inner-title {
    font-weight: 600;
    font-size: 23px;
    line-height: 140%;
    color: var(--sodainagar-secondary-color);
    border-bottom: 1px solid #e4eaef;
    padding-bottom: 12px;
    margin: 0;
}

.payment-info-inner-main {
    background: #ffffff;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    padding: 24px 24px 32px 24px;
}

.payment-info-area {
    margin-top: 45px;
    margin-bottom: 80px;
}

.p-info-inner-wrapper-title {
    font-weight: 500;
    font-size: 19px;
    line-height: 140%;
    color: #202020;
    margin-bottom: 20px;
}

.p-info-inner-wrapper {
    margin-top: 30px;
}

.p-info-inner-wrapper-title span {
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #383838;
    margin-left: 5px;
}

.p-info-inner-wrapper-title.border-add {
    border-bottom: 1px solid #e4eaef;
    padding-bottom: 12px;
    margin-bottom: 0px;
}

.p-order-summery-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}
.p-order-summery-main {
    display: flex;
}
.p-order-summery-details {
}
.p-order-summery-main-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    margin-bottom: 4px;
}

.p-order-summery-qty {
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #383838;
    margin: 0;
    margin-left: 19px;
}

.p-order-summery-qty-price {
    margin: 0;
    font-weight: 500;
    font-size: 19px;
    line-height: 140%;
    color: #202020;
}

.p-order-summery-list {
    overflow-y: scroll;
    height: 255px;
    padding-right: 15px;
}

.order-summery-form {
    margin-top: 30px;
}

.order-summery-form .form-group label {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
    margin-bottom: 8px;
    margin-left: 8px;
}

.order-summery-form-input-hide {
    display: none;
}

.order-summery-form-input input {
    background: #ebf0f3;
    border-radius: 2px;
    border: none;
    padding: 10px 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
    width: 60%;
    margin-right: 8px;
    height: 46px;
}

.order-summery-form-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-summery-form-input .theme-btn {
    background: var(--sodainagar-primary-color);
    border-radius: 4px;
    border: none;
    height: 46px;
    font-weight: 500;
    font-size: 16px;
    padding: 0;
    width: 40%;
}

.order-summery-form-input .button.theme-btn.btn.btn-primary:focus {
    box-shadow: none;
}

.order-summery-form-input .theme-btn:hover {
    background: var(--sodainagar-secondary-color);
}

.order-summery-form-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #383838;
    margin: 0;
    margin-left: 8px;
    margin-top: 8px;
}

.order-summery-memo-list li {
    line-height: 27px;
    color: #383838;
    margin-top: 12px;
    font-weight: 400;
    font-size: 16px;
}

.order-summery-memo-list li span {
    font-weight: 600;
    font-size: 19px;
    text-align: right;
    color: #383838;
    float: right;
    line-height: 140%;
}

.order-summery-memo {
    margin-top: 25px;
}

.shopping-info-accordian-bottom .theme-btn i {
    font-size: 20px;
    margin-left: 8px;
    position: relative;
    top: 5px;
}

.shopping-info-accordian-bottom .theme-btn {
    padding: 11px 18px;
}

.order-confirmation-inner {
    background: #ffffff;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    padding: 24px 24px 50px 24px;
    margin-top: 45px;
}

.ord-confirmation-inner-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4eaef;
    padding-bottom: 12px;
    margin-bottom: 22px;
}

.ord-confirmation-inner-top-title {
    font-weight: 600;
    font-size: 23px;
    line-height: 140%;
    color: var(--sodainagar-secondary-color);
    margin: 0;
}

.ord-confirmation-inner-top-title span {
    font-weight: 400;
}

.order-confirm-wrapper-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f2f5f7;
    border-radius: 2px;
    padding: 8px 12px;
    margin-bottom: 20px;
}

.order-confirm-top-left {
    display: flex;
    align-items: center;
}

.order-confirm-widget-title {
    margin: 0;
    font-weight: 500;
    font-size: 19px;
    line-height: 140%;
    color: #202020;
}

.order-confirm-top-left img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

.order-confirm-wrapper-top a i {
    font-size: 16px;
    position: relative;
    top: 2px;
    color: #202020;
}

.order-confirm-details-list li {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
    margin-bottom: 8px;
}

.order-confirm-details-list li:last-child {
    margin: 0;
}

.order-confirm-details-list {
    margin-left: 40px;
}

.order-confirm-visacard .order-confirm-details-list li {
    margin-bottom: 0px;
    display: inline-block;
    margin-right: 8px;
}

.order-confirm-visacard .order-confirm-details-list li:last-child {
    margin: 0;
}

.order-confirm-visacard .order-confirm-details-list li img {
    width: 34px;
    height: 24px;
    position: relative;
    top: -3px;
}

.visa-card-link {
    margin-left: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: var(--sodainagar-secondary-color);
    margin-top: 2px;
}

.order-confirm-main-bottom {
    margin-top: 25px;
}

.order-confirm-main-bottom-text {
    display: flex;
}

.order-confirm-terms-text {
    margin: 0;
    margin-left: 8px;
}

.order-confirm-terms-text a {
    color: var(--sodainagar-primary-color);
}

.place-order-btn {
    text-align: right;
}

.place-order-btn .theme-btn i {
    margin-left: 8px;
    position: relative;
    top: 3px;
}

.order-confirmation-area {
    margin-bottom: 100px;
}

.thankyou-widget-area {
    overflow: hidden;
}

.thankyou-widget {
    text-align: center;
}

.thankyou-content {
    margin-top: 25px;
}

.thankyou-content-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 140%;
    color: #202020;
    margin-bottom: 8px;
}

.thankyou-content-text {
    margin: 0;
}

.thankyou-content-btn {
    margin-top: 24px;
}

.thankyou-content-btn .theme-btn {
    background: var(--sodainagar-secondary-color);
    border-radius: 4px;
}

.thankyou-content-btn .theme-btn:hover {
    background: var(--sodainagar-primary-color);
}

.thankyou-widget-area {
    padding: 120px 0px;
}

.order-tracking-area {
    overflow: hidden;
}

.order-tracking-box {
    background: #ffffff;
    box-shadow: 0px 12px 40px rgba(34, 87, 122, 0.08);
    border-radius: 6px;
    padding: 40px 24px 40px 24px;
    text-align: center;
}

.order-tracking-area {
    padding-top: 50px;
    padding-bottom: 100px;
}

.order-tracking-widget-icon img {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
}

.order-t-widget-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #202020;
    position: relative;
    padding-bottom: 6px;
    margin-bottom: 16px;
}

.order-t-widget-title::before {
    position: absolute;
    content: "";
    width: 32px;
    height: 2px;
    background: #dce4ea;
    left: 50%;
    margin-left: -16px;
    bottom: 0;
}

.order-t-widget-cont-title {
    color: #202020;
    margin: 0;
}

.order-t-box-top-title {
    position: relative;
    padding-bottom: 12px;
    margin: 0;
}

.order-t-box-top-title::before {
    position: absolute;
    content: "";
    width: 48px;
    height: 3px;
    background: #dce4ea;
    left: 50%;
    margin-left: -24px;
    bottom: 0;
}

.order-tracking-widget {
    margin-top: 40px;
}

.confirm-steps-icon i {
    width: 56px;
    height: 56px;
    line-height: 56px;
    background: var(--sodainagar-primary-color);
    border-radius: 100%;
    display: inline-block;
    color: #fbfcfc;
    font-size: 18px;
}

.confirm-steps-icon {
    margin-bottom: 20px;
}

.confirm-steps-cont-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: #202020;
    margin-bottom: 5px;
}

.confirm-steps-cont-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #383838;
    margin: 0;
}

.order-confirm-steps-bottom {
    gap: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirm-steps-wrapper.close-icon i {
    background: rgba(34, 87, 122, 0.04);
    color: #f55353;
}

.confirm-steps-wrapper {
    position: relative;
    margin-top: 30px;
    z-index: 222;
}

.confirm-steps-wrapper::before {
    position: absolute;
    content: "";
    background-image: url("../src/Assets/img/order-tracking-icon/arrow.svg");
    width: 115px;
    height: 9px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    top: 26px;
    left: 126px;
    z-index: -1;
}

.ready-pickup::before {
    display: none;
}
.order-tracking-maps .gmap_canvas svg {
    width: 100% !important;
    height: 320px !important;
}
.order-tracking-maps iframe {
    width: 100%;
    height: 320px;
}

.order-t-widget-content p {
    margin: 0;
}

.order-tracking-maps {
    margin-top: 30px;
}

.shopping-info-widget-title {
    background: transparent;
    border-bottom: 1px solid #e4eaef;
    border-radius: 0;
    box-shadow: none;
    color: var(--sodainagar-secondary-color);
    font-size: 23px;
    font-weight: 600;
    line-height: 140%;
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
    margin: 0 24px;
    padding-bottom: 12px;
}

.shopping-info-widget-body {
    padding: 20px 24px 24px;
}

.sodainagar-main-inner .pages {
    padding-bottom: 80px;
}

/*========================================================
  End CheckOut Page Area
===========================================================*/

/*========================================================
   SideBar item
===========================================================*/
.sidebar-item {
    padding: 0px;
    display: block;
    transition: background-color 0.15s;
    border-radius: 5px;
    position: relative;
    padding-top: 16px;
}

.sidebar-item:first-child {
    padding-top: 0px;
}

.sidebar-item.open {
    position: relative;
}

.sidebar-item.open::before {
    position: absolute;
    content: "";
    height: 100%;
    left: 10px;
    top: 25px;
    z-index: -1;
    width: 0.5px;
    background: var(--sodainagar-secondary-color);
    opacity: 0.1;
}

.sidebar-content {
    padding-left: 32px;
}

.sidebar-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
}

.sidebar-title span {
    display: inline-block;
    color: #383838;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
}

.sidebarIMG {
    padding-right: 8px;
    width: 24px;
    height: 24px;
    object-fit: contain;
}
.toggle-btn {
    color: #383838;
    font-size: 15px;
    position: absolute;
    right: 24px;
}

.sidebar-title .toggle-btn {
    cursor: pointer;
    transition: transform 0.3s;
}

.sidebar-item.open > .sidebar-title .toggle-btn {
    transform: rotate(90deg);
}

.sidebar-content {
    padding-top: 0px;
    height: 0;
    overflow: hidden;
}

.sidebar-item.open > .sidebar-content {
    height: auto;
}

.sidebar-item.plain {
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
}

.sidebar-item.plain:hover {
    text-decoration: none;
}

.sidebar-item.plain i {
    display: inline-block;
    width: 1.7em;
}

.sidebar-item.plain.g-3 {
    padding: 0px;
    padding-top: 15px;
}

.sidebar-title a {
    display: block;
}

/*========================================================
   End SideBar item
===========================================================*/

/*========================================================
   User Dashboard
===========================================================*/
.dashboard-area {
    overflow: hidden;
    padding-right: 24px;
    margin-top: 30px;
}

.user-profile {
}

.user-profile-title {
    font-weight: 600;
    font-size: 19px;
    line-height: 140%;
    color: #ffffff;
    margin-bottom: 5px;
}

.user-profile-text {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #fbfcfc;
}

.user-profile-img img {
    border-radius: 100%;
    width: 80px;
    height: 80px;
    border: 2px solid #fbfcfc;
}

.user-profile-img {
    margin-bottom: 10px;
}

.user-profile-top {
    position: relative;
    background: conic-gradient(
        from 150.22deg at 50.2% 35.68%,
        #22577a 0deg,
        #38a3a5 14.37deg,
        #57cc99 108.12deg,
        #80ed99 224.38deg,
        #38a3a5 360deg
    );
    border-radius: 4px;
    text-align: center;
    padding: 24px;
    position: relative;
}

.user-profile-edit {
    background: #38a3a5;
    border-radius: 4px;
    color: #fbfcfc;
    padding: 1px 7px;
    font-weight: 500;
    font-size: 13px;
    position: absolute;
    right: 12px;
    top: 12px;
}

.user-profile-edit:hover {
    background: var(--sodainagar-secondary-color);
    color: #fbfcfc;
}

.user-profile-edit i {
    margin-right: 4px;
    font-size: 12px;
    position: relative;
    top: 1px;
}

.user-profile {
    background: #f2f5f7;
    border-radius: 2px;
    height: 100% !important;
    position: relative;
    padding-bottom: 100px;
}

.user-profile-menu li a {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #383838;
    padding: 12px 16px;
    display: block;
}

.user-profile-menu li a i {
    font-size: 16px;
    margin-right: 12px;
    color: var(--sodainagar-primary-color);
    position: relative;
    top: 2px;
}

.user-profile-menu li .active {
    background: #38a3a5;
    width: 100%;
    font-weight: 600;
    color: #fbfcfc;
}

.user-profile-menu li .active i {
    color: #fbfcfc;
}

.user-profile-menu {
    margin-top: 16px;
}

.dashboard-product-wrapper {
    border-radius: 4px;
    padding: 16px;
    height: 140px;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
}

.dashboard-product-wrapper::before {
    position: absolute;
    content: "";
    width: 56px;
    height: 56px;
    top: 12px;
    right: 12px;
}

.dashboard-product-wrapper::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    bottom: -43px;
    left: 0px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.dashboard-product-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 140%;
    color: #ffffff;
    margin-bottom: 4px;
    z-index: 22;
    position: relative;
}

.dashboard-product-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #ffffff;
    margin: 0;
    z-index: 22;
    position: relative;
}

.dashboard-product-wrapper.bg-color-1 {
    background: linear-gradient(270deg, #2196f3 0%, #5f02ef 100%);
}

.dashboard-product-wrapper.bg-color-2 {
    background: linear-gradient(270deg, #80ed99 0%, #57cc99 100%);
}

.dashboard-product-wrapper.bg-color-3 {
    background: linear-gradient(270deg, #fda92f 0%, #f06d01 100%);
}

.dashboard-product-wrapper.bg-color-1::before {
    background-image: url("../src/Assets/img/dashboard-product-icon/icon-1.svg");
}

.dashboard-product-wrapper.bg-color-1::after {
    background-image: url("../src/Assets/img/dashboard-product-icon/shape-1.svg");
}

.dashboard-product-wrapper.bg-color-2::before {
    background-image: url("../src/Assets/img/dashboard-product-icon/icon-2.svg");
}

.dashboard-product-wrapper.bg-color-2::after {
    background-image: url("../src/Assets/img/dashboard-product-icon/shape-2.svg");
}

.dashboard-product-wrapper.bg-color-3::before {
    background-image: url("../src/Assets/img/dashboard-product-icon/icon-3.svg");
}

.dashboard-product-wrapper.bg-color-3::after {
    background-image: url("../src/Assets/img/dashboard-product-icon/shape-3.svg");
}

.order-history-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.order-history-title {
    margin: 0;
    text-transform: capitalize;
    font-weight: 600;
    color: #202020;
}

.order-h-top-btn {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: var(--sodainagar-secondary-color);
}

.order-h-top-btn i {
    font-size: 16px;
    position: relative;
    top: 2px;
    margin-left: 5px;
    transition: all 0.4s ease;
}

.order-h-top-btn:hover {
    color: var(--sodainagar-primary-color);
}

.order-history-table-main > :not(:first-child) {
    border-top: 1px solid var(--sodainagar-secondary-color);
}

.order-history-table-main tbody tr {
    background: rgba(34, 87, 122, 0.04);
    border-radius: 4px;
    vertical-align: middle;
}

.wishlist-items-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 26px;
}

.paid-status,
.cancel-status,
.pending-status {
    background: var(--sodainagar-primary-color);
    border-radius: 4px;
    color: #fbfcfc;
    font-weight: 500;
    font-size: 13px;
    display: block;
    text-align: center;
    position: relative;
    top: 3px;
    line-height: 24px;
    padding: 3px 8px;
}

.cancel-status {
    background: #f55353 !important;
}

.pending-status {
    background: #fbcb0a !important;
}

.table-view-btn {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: #383838;
}

.table-view-btn i {
    position: relative;
    top: 4px;
    font-size: 16px;
    margin-right: 5px;
}

.table-view-btn:hover {
    color: var(--sodainagar-primary-color);
}

.order-history-table {
    margin-top: 40px;
}

.order-history-table-main > :not(caption) > * > * {
    padding: 10px 16px !important;
}

.order-history-table-main {
    margin: 0;
    border-color: #fff;
}

.order-history-table-main > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 8px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.dash-table-head {
    position: relative;
}

.dash-table-head::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background: #dce4ea;
    bottom: 5px;
}

.dashboard-area {
    overflow: hidden;
    padding-right: 24px;
}

.sodainagar-main-inner.toggle .user-dashboard-area {
    padding-left: 24px;
}

.table-paid-btn.cancel-btn {
    background: #f55353;
}

.live-order-tracking-maps {
    background: #ffffff;
    box-shadow: 0px 12px 40px rgba(34, 87, 122, 0.08);
    border-radius: 6px;
    padding: 20px;
    margin-top: 40px;
}

.live-order-tracking-maps iframe {
    width: 100%;
    height: 306px;
}

.dashboard-main-title {
    margin: 0;
}

.order-history-table-bottom {
    text-align: center;
    margin-top: 40px;
}

.order-history-table-btn {
    border: 1px solid #e4eaef;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #383838;
    padding: 8px 16px;
}

.order-history-table-btn:hover {
    background: var(--sodainagar-secondary-color);
    color: #fbfcfc;
    border: 1px solid transparent;
}

.user-dashboard-main.pd-bottom {
    padding-bottom: 100px;
}

.order-details-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4eaef;
    color: #202020;
    padding-bottom: 8px;
}

.order-d-print-btn {
    border: 1px solid #e4eaef;
    border-radius: 4px;
    padding: 8px 12px;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: #383838;
    margin-right: 12px;
    background: transparent;
}

.order-d-print-btn:hover {
    background: var(--sodainagar-secondary-color);
    color: #fbfcfc;
    border: 1px solid transparent;
}

.order-d-print-btn i {
    font-size: 16px;
    margin-right: 5px;
    position: relative;
    top: 3px;
}

.download-invoice-btn {
    border: 1px solid var(--sodainagar-primary-color);
    border-radius: 4px;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: #383838;
    padding: 7px 8px;
    background: transparent;
}

.download-invoice-btn i {
    color: var(--sodainagar-primary-color);
    font-size: 16px;
    position: relative;
    top: 3px;
    margin-right: 5px;
    transition: all 0.4s ease;
}

.download-invoice-btn:hover {
    background: var(--sodainagar-primary-color);
    color: #fbfcfc;
}

.download-invoice-btn:hover i {
    color: #fbfcfc;
}

.order-details-top-title {
    margin: 0;
    color: #202020;
}

.order-d-formation-single {
    margin-top: 30px;
}

.order-d-formation-title {
    font-weight: 600;
    font-size: 19px;
    line-height: 140%;
    color: var(--sodainagar-secondary-color);
    margin-bottom: 16px;
}

.order-payment-list li {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
}

.order-payment-list li span {
    text-align: right;
    float: right;
    font-weight: 600;
    font-size: 19px;
    line-height: 140%;
    color: #202020;
}

.payment-method-title,
.order-d-address-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: var(--sodainagar-secondary-color);
    margin-bottom: 5px;
}

.payment-method-text {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
}

.order-d-address-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
    margin: 0;
}

.order-d-address-single {
    margin-bottom: 16px;
}

.order-d-address-single:last-child {
    margin: 0;
}

.order-details-amount li {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
    margin-bottom: 8px;
}

.order-details-amount li span {
    text-align: right;
    float: right;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
}

.order-d-formation-single.order-d-amount {
    margin-left: 60px;
}

.order-d-formation-single.order-d-address {
    margin-left: 85px;
}

.order-status-icon {
    width: 72px;
    height: 72px;
    background: var(--sodainagar-primary-color);
    border-radius: 100%;
    text-align: center;
    line-height: 72px;
    font-size: 24px;
    color: #fbfcfc;
    margin-bottom: 16px;
    display: inline-block;
}
.view-order-detail-tracking {
    justify-content: start;
    gap: 185px;
}
.order-status-title {
    font-weight: 600;
    font-size: 19px;
    line-height: 140%;
    color: #202020;
    margin: 0;
}

.order-status-wrapper {
    margin-top: 24px;
    text-align: center;
    position: relative;
}

.order-status-wrapper::before {
    position: absolute;
    content: "";
    width: 160px;
    height: 9px;
    background-image: url(http://localhost:3000/static/media/arrow.e48fb786ff1596b619f8.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    top: 33px;
    left: 100px;
}

.order-status-wrapper-two {
    margin-top: 24px;
    text-align: center;
    position: relative;
}

.order-status-wrapper-two::before {
    position: absolute;
    content: "";
    width: 160px;
    height: 9px;
    background-image: url(http://localhost:3000/static/media/arrow.e48fb786ff1596b619f8.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    top: 26px;
    left: 143px;
}

.order-status-icon.close-icon {
    background: rgba(34, 87, 122, 0.04);
    color: #f55353;
}
.confirm-steps-icon.close-icon i {
    background: rgba(34, 87, 122, 0.04);
    color: #f55353;
}

.order-status-main {
    margin-top: 50px;
}

.order-status-wrapper.order-deliverd-confirmd::before {
    display: none;
}
.order-status-wrapper-two.order-deliverd-confirmd::before {
    display: none;
}

.order-details-item-btn {
    text-align: center;
    margin-top: 32px;
}

.order-details-item-btn .theme-btn {
    background: var(--sodainagar-secondary-color);
    color: #fbfcfc;
}

.order-details-item-btn .theme-btn:hover {
    background: var(--sodainagar-primary-color);
    color: #fbfcfc;
}

.order-details {
    padding-bottom: 180px;
}

.order-details-item-main img {
    margin-right: 8px;
    filter: drop-shadow(0px 1px 2px rgba(34, 87, 122, 0.08));
    border-radius: 8px;
    width: 50px;
    height: 40px;
    object-fit: fill;
}

.order-details-item-main td {
    font-weight: 600;
}

.d-payment-h-option-single {
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    border-radius: 8px;
    padding: 25px;
    margin-top: 25px;
    position: relative;
}

.d-payment-option-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
    margin-bottom: 4px;
}

.d-payment-option-total {
    font-weight: 700;
    font-size: 28px;
    line-height: 140%;
    color: var(--sodainagar-secondary-color);
    margin-bottom: 2px;
}

.d-payment-option-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: #383838;
    display: block;
}

.d-payment-option-btn {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: var(--sodainagar-primary-color);
    display: block;
}

.d-payment-h-option-icon {
    position: absolute;
    right: 16px;
    top: 50%;
    margin-top: -24px;
}

.d-payment-h-option-icon img {
    width: 48px;
    height: 48px;
}

.d-payment-option-btn:hover {
    color: var(--sodainagar-primary-color);
}

.payment-m-options-select {
    display: flex;
    align-items: center;
}

.payment-m-options-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.payment-m-options-top-title {
    margin: 0;
    font-weight: 600;
    font-size: 23px;
    line-height: 140%;
    color: #202020;
}

.payment-method-options {
    margin-top: 50px;
}

.payment-m-options-select .product-category-select {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.payment-m-options-select .product-category-select:last-child {
    margin: 0;
}

.payment-method-wrapper {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(34, 87, 122, 0.12);
    border-radius: 8px;
    padding: 12px 16px;
    justify-content: space-between;
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: 120px 500px auto auto;
}

.payment-method-wrapper:last-child {
    margin: 0;
}

.p-method-single-title {
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #383838;
    opacity: 0.5;
    margin-bottom: 8px;
}

.p-method-single-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #202020;
    margin: 0;
}

.p-method-single-order-complt {
    background: var(--sodainagar-primary-color);
    border-radius: 4px;
    color: #fbfcfc;
    padding: 2px 6px;
    margin-top: 4px;
    display: inline-block;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
}

.payment-method-bottom-btn {
    text-align: center;
    margin-top: 25px;
}

.payment-method-bottom-btn .btn {
    border: 1px solid #e4eaef;
    background: #fbfcfc;
    border-radius: 8px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #383838;
    text-decoration: none;
}

.payment-method-bottom-btn .btn:hover {
    background: var(--sodainagar-secondary-color);
    color: #fbfcfc;
    border: 1px solid transparent;
}

.payment-method-bottom-btn .Previous-more-btn {
    border: 1px solid #e4eaef;
    background: #fbfcfc;
    border-radius: 8px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #383838;
    margin-right: 10px;
    width: 117px;
}

.dashboard-payment-history {
    padding-bottom: 80px;
}

.p-method-single-order-complt.cancel-order {
    background: #f55353;
}

.p-method-single-order-complt.pay-hold {
    background: #f7d716;
}

.dashboard-main-total {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #383838;
    margin-top: 10px;
    display: block;
}

.support-ticket-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
}

.support-ticket-top-left {
    display: flex;
    align-items: center;
}

.support-searchbar input {
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    height: 38px;
    padding: 8px 30px 8px 12px;
    width: 348px;
    color: #383838;
}

.support-searchbar {
    position: relative;
    margin-right: 25px;
}

.support-searchbar .search-btn {
    background: transparent;
    border: none;
    font-size: 16px;
    color: var(--sodainagar-primary-color);
    position: absolute;
    right: 13px;
    top: 10px;
    padding: 0;
}

.support-ticket-top .theme-btn {
    background: var(--sodainagar-secondary-color);
    border-radius: 4px;
    padding: 8px 20px;
}

.support-ticket-top .theme-btn:hover {
    background: var(--sodainagar-primary-color);
}

.support-ticket-top .theme-btn i {
    position: relative;
    top: 2px;
    font-size: 16px;
    margin-right: 5px;
}

.s-ticket-accordian-list-title {
    display: grid;
    align-items: center;
    grid-template-columns: 155px 500px 138px 187px 160px;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 16px;
    padding-left: 16px;
}

.s-ticket-accordian-list-title li {
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
    opacity: 0.5;
}

.support-ticket-accordian {
    margin-top: 25px;
}

.s-ticket-accordian-form .accordion-button::after {
    display: none;
}

.s-ticket-accordian-head-list {
    display: grid;
    grid-template-columns: 160px 360px 110px 187px 160px;
}

.s-ticket-accordian-form .accordion-button.collapsed {
    padding: 12px 16px;
    background: rgba(34, 87, 122, 0.06);
    border-radius: 8px;
    margin-top: 12px;
}

.s-ticket-accordian-head-list li {
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
}

.s-ticket-accordian-head-list .progress-box {
    background: var(--sodainagar-secondary-color);
    border-radius: 4px;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #fbfcfc;
    padding: 4px 8px;
}

.s-ticket-accordian-form .accordion-item {
    border: none !important;
    background: transparent;
}

.s-ticket-accordian-form .accordion-button:not(.collapsed) {
    box-shadow: none;
    background: var(--sodainagar-primary-color);
    border-radius: 8px !important;
    color: #fbfcfc;
    margin-top: 12px;
    padding: 12px 16px;
    position: relative;
    z-index: 33;
}

.s-ticket-accordian-form .accordion-button:not(.collapsed)::before {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    background: var(--sodainagar-primary-color);
    transform: rotate(45deg);
    left: 50%;
    margin-left: 0px;
    bottom: -8px;
    z-index: -1;
}

.s-ticket-accordian-form .accordion-button:not(.collapsed) .s-ticket-accordian-head-list li {
    color: #fbfcfc;
}

.s-ticket-accordian-form .accordion-header:focus-visible {
    border: none;
    outline: none;
}

.s-ticket-accordian-form .accordion-button.collapsed:focus {
    box-shadow: none;
}

.s-ticket-accordian-form .accordion-body {
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    padding: 20px 16px;
    margin-top: 20px;
}

.s-ticket-accordian-comments {
    position: relative;
}

.accordian-comments-box .form-group textarea {
    padding: 12px 16px;
    width: 100%;
    background: rgba(34, 87, 122, 0.04);
    border-radius: 4px 4px 0px 0px;
    border: none;
    color: #383838;
    height: 80px;
}

.accordian-comments-profile {
    position: absolute;
    left: 0;
    top: 0;
}

.accordian-comments-box {
    padding-left: 95px;
    margin-bottom: 30px;
}

.a-comments-attach-file .inputfile-1 + label {
    background: rgba(34, 87, 122, 0.06);
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: var(--sodainagar-secondary-color);
    padding: 8px 24px;
    transition: all 0.1s ease-in;
    cursor: pointer;
}

.a-comments-attach-file .inputfile-1 + label i {
    margin-right: 5px;
    position: relative;
    top: 2px;
    font-size: 16px;
}

.a-comments-attach-file .inputfile-1:focus + label,
.a-comments-attach-file .inputfile-1.has-focus + label,
.a-comments-attach-file .inputfile-1 + label:hover {
    background-color: var(--sodainagar-primary-color);
    color: #fbfcfc;
}

.a-comments-attach-file #file-1 {
    display: none;
}

.accordian-comments-box-btm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #d1dce3;
    margin-top: -2px;
    background: rgba(34, 87, 122, 0.04);
}

.accordian-comments-box-btns .theme-btn {
    border: none;
    font-weight: 500;
    font-size: 16px;
    height: 38px;
    line-height: 30px;
    border-radius: 0;
    padding: 5px;
    width: 126px;
    transition: all 0.4s ease;
}

.accordian-comments-box-btns .btn-save.theme-btn {
    background: rgba(34, 87, 122, 0.06);
    color: var(--sodainagar-secondary-color);
    margin-right: 5px;
}

.accordian-comments-box-btns .btn-post.button.theme-btn {
    background: var(--sodainagar-secondary-color);
    color: #fbfcfc;
}

.accordian-comments-box-btns .btn-save.theme-btn:hover {
    background: var(--sodainagar-secondary-color);
    color: #fbfcfc;
}

.accordian-comments-box-btns {
    margin-top: -1px;
}

.a-c-user-content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.a-comments-user-single {
    position: relative;
}

.a-comments-user-img {
    position: absolute;
    left: 0;
    top: 0;
}
.a-comments-user-img-customer {
    position: absolute;
    right: 0;
    top: 0;
}

.a-comments-user-content {
    padding-left: 70px;
}
.a-comments-user-content-customer {
    padding-right: 70px;
}

.a-c-user-content-top h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    color: var(--sodainagar-secondary-color);
    margin: 0;
}

.a-comments-user-content p,
.a-comments-user-content-customer p {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
}

.a-c-user-content-top span {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: #383838;
    opacity: 0.5;
}

.a-comments-user-content .text-border,
.a-comments-user-content-customer .text-border {
    border-bottom: 1px dashed #38a3a5;
    padding-bottom: 12px;
    margin-bottom: 12px;
}

.accordian-comments-profile img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
}

.a-comments-user-img img,
.a-comments-user-img-customer img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
}

.on-hold-box {
    background: #f7d716;
    border-radius: 4px;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #fbfcfc;
    padding: 4px 8px;
}

.complete-box {
    background: var(--sodainagar-primary-color);
    border-radius: 4px;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #fbfcfc;
    padding: 4px 8px;
}

.support-ticket-main {
    padding-bottom: 120px;
}

.s-ticket-accordian-form .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.create-ticket-main-top-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #383838;
    margin: 0;
    margin-top: 8px;
}

.c-ticket-inner-title {
    font-weight: 700;
    font-size: 23px;
    line-height: 140%;
    color: var(--sodainagar-secondary-color);
    border-bottom: 1px solid #dce4ea;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.create-ticket-main-inner {
    margin-top: 30px;
    padding-bottom: 300px;
}

.create-ticket-from .form-group label {
}

.create-ticket-from .form-group input {
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    width: 100%;
    height: 52px;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #383838;
    padding: 18px 12px 8px 12px;
}

.create-ticket-from .form-group label {
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #383838;
    padding: 4px 8px;
    position: relative;
    top: 13px;
    left: 12px;
}

.create-ticket-from .form-group {
    margin-bottom: 20px;
}

.create-ticket-from .form-group span {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #202020;
    margin-bottom: 8px;
    margin-left: 12px;
    display: block;
}

.create-ticket-from .tox-statusbar {
    display: none !important;
}

.create-ticket-from .tox-tinymce {
    border: 1px solid #e4eaef;
    border-radius: 4px;
    height: 332px !important;
    width: 100%;
}

.create-ticket-from .tox:not(.tox-tinymce-inline) .tox-editor-header {
    background-color: #fff;
    box-shadow: none;
    border-bottom: 1px solid #e4eaef !important;
}

.tox .tox-notification {
    display: none !important;
}

.create-ticket-from .theme-btn {
    background: var(--sodainagar-primary-color);
    border-radius: 4px;
    border: none;
    padding: 13px 27px;
    transition: all 0.4s ease;
    margin-top: 20px;
}

.create-ticket-from .theme-btn i {
    position: relative;
    font-size: 18px;
    top: 2px;
    margin-right: 5px;
}

.create-ticket-from .theme-btn:hover {
    background: var(--sodainagar-secondary-color);
    color: #fbfcfc;
}

.create-ticket-from .a-comments-attach-file .inputfile-1 + label {
    border: 1px solid var(--sodainagar-primary-color);
    border-radius: 4px;
    background: transparent;
    color: var(--sodainagar-primary-color);
    padding: 13px 125px;
}

.create-ticket-from .a-comments-attach-file .inputfile-1 + label:hover {
    background: var(--sodainagar-primary-color);
    color: #fbfcfc;
}

.create-ticket-from p {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: #383838;
    margin: 0;
    margin-top: 10px;
}

.create-ticket-from .a-comments-attach-file {
    margin-top: 20px;
}

.tox .tox-tbtn--bespoke .tox-tbtn__select-label {
    margin: 0;
}

.manage-profile-single {
    margin-top: 25px;
}

.manage-profile-title {
    background: #f2f5f7;
    border-radius: 2px;
    font-weight: 600;
    font-size: 19px;
    line-height: 140%;
    color: var(--sodainagar-secondary-color);
    padding: 12px 18px;
    margin-bottom: 20px;
}

.manage-profile-form .form-group {
    margin-bottom: 18px;
}

.manage-profile-form .form-group label {
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    padding: 1px 6px;
    display: inline-block;
    position: relative;
    margin-left: 12px;
    top: 14px;
    z-index: 222;
}

.manage-profile-form .form-group input {
    width: 100%;
    height: 52px;
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    border-radius: 4px;
    padding: 18px 12px 8px 12px;
    color: #383838;
}

.manage-profile-button .theme-btn {
    background: var(--sodainagar-primary-color);
    border-radius: 4px;
    border: none;
    padding: 12px 15px;
    height: 46px;
    transition: all 0.4s ease;
}

.manage-profile-button .theme-btn:hover {
    background: var(--sodainagar-secondary-color);
    color: #f7fafd;
}

.pass-requird-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #383838;
    opacity: 0.5;
    font-style: italic;
    margin: 0;
    margin-top: 8px;
    margin-left: 12px;
}

.m-profile-address-inner {
}

.m-profile-address-single {
    margin-top: 15px;
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    border-radius: 8px;
    padding: 16px;
}

.m-profile-address-single-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4eaef;
    padding-bottom: 8px;
    margin-bottom: 12px;
}

.m-profile-address-single-top p {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: var(--sodainagar-secondary-color);
}

.m-profile-address-list li {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 13px;
    line-height: 200%;
    color: #383838;
}

.m-profile-address-list li:last-child {
    margin: 0;
}

.m-profile-address-list li b {
    font-weight: 600;
}

.add-new-address-btn {
    text-align: center;
    margin-top: 30px;
}

.add-new-address-btn .theme-btn.btn.btn-primary {
    border: none;
}

.add-new-address-btn .theme-btn.btn.btn-primary:focus {
    background: var(--sodainagar-secondary-color);
    box-shadow: none;
}

.add-new-address-btn .theme-btn.btn.btn-primary i {
    font-size: 16px;
    position: relative;
    top: 2px;
    margin-right: 5px;
}

.manage-profile-address {
    margin-top: 40px;
}

.manage-profile-main {
    padding-bottom: 80px;
}

.fade.add-new-address-modal-form.modal.show {
}

.add-new-address-modal-main .modal-dialog {
    margin-top: 200px;
}

.add-new-modal-main .theme-btn {
    width: 100%;
    height: 46px;
    border: none;
    transition: all 0.4s ease;
    margin-top: 24px;
}

.add-new-address-modal-form.modal.show .modal-body {
    padding: 25px;
    padding-bottom: 30px;
    padding-top: 15px;
}
.add-new-address-modal-form .modal-content {
    margin-top: 80px;
    border-radius: 0;
    border: none;
}
.add-new-address-modal-form .modal-header {
    background: #f2f5f7;
    border: none;
    padding: 12px 24px;
    border-radius: 0;
}

.add-new-address-modal-form .modal-title.h4 {
    font-weight: 600;
    font-size: 19px;
    line-height: 140%;
    color: var(--sodainagar-secondary-color);
}

.add-new-address-modal-form .btn-close {
    box-sizing: content-box;
    color: var(--sodainagar-secondary-color);
    border: 0;
    opacity: 1;
    font-size: 16px;
    padding: 0;
    position: relative;
    top: 1px;
    right: 8px;
}

.user-profile-signouy-btn {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 25px;
    padding: 0px 17px;
}

.user-profile-signouy-btn .theme-btn {
    background: var(--sodainagar-secondary-color);
    border-radius: 4px;
    width: 100%;
}

.user-profile-signouy-btn .theme-btn i {
    font-size: 20px;
    color: var(--sodainagar-primary-color);
    margin-right: 8px;
    position: relative;
    top: 4px;
    transition: all 0.4s ease;
}

.user-profile-signouy-btn .theme-btn:hover {
    background: var(--sodainagar-primary-color);
}

.user-profile-signouy-btn .theme-btn:hover i {
    color: #fbfcfc;
}

.imageupload-box {
    padding-bottom: 60px;
    padding-left: 105px;
    position: relative;
    overflow: hidden;
}

.imageupload-box::before {
    position: absolute;
    content: "";
    width: 88px;
    height: 88px;
    background: #d9d9d9;
    border-radius: 100%;
    left: 0;
    top: 0;
}

.imageupload-box img {
    position: absolute;
    left: 0;
    top: 0;
    width: 88px;
    height: 88px;
    border-radius: 100%;
    object-fit: cover;
}

.img-close-btn {
    border-radius: 100%;
    background: #f55353 !important;
    color: #fbfcfc !important;
    padding: 0 !important;
    width: 20px;
    height: 20px;
    font-size: 13px;
    top: 0px;
    position: absolute;
    left: 63px;
}
.create-ticket-img {
    width: 100%;
    height: 250px;
    margin-bottom: 20px;
    position: relative;
}
.create-ticket-img img {
    height: 100% !important;
    /* width: 100%; */
}
.create-ticket-img-close-btn {
    border-radius: 100%;
    background: #f55353 !important;
    color: #fbfcfc !important;
    padding: 0 !important;
    width: 30px;
    height: 30px;
    font-size: 24px;
    top: -5px;
    position: absolute;
    left: -6px;
}
.imageupload-box .theme-btn {
    margin-top: 12px;
    border: none;
    padding: 9px 20px;
}

.user-profile-sedebar-responsive {
    margin-top: 0;
    z-index: 9999;
    background: #f2f5f7;
}
.user-profile-sedebar-responsive-body {
    height: 100%;
}
.user-profile-sedebar-responsive-header {
    position: absolute;
    bottom: 24px;
    right: 24px;
}

.user-profile-sedebar-responsive-header button {
    width: 48px;
    height: 48px;
    line-height: 50px;
    background: var(--sodainagar-primary-color);
    border: none;
    color: #fff;
    border-radius: 100%;
    transition: all 0.4s ease;
    z-index: 22;
    position: relative;
}
.user-profile-sedebar-responsive-header button:hover {
    background: var(--sodainagar-secondary-color);
}
.user-profile-sedebar-responsive-menu {
    background: var(--sodainagar-primary-color);
    border: none;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    font-size: 20px;
    color: #fff;
    line-height: 51px;
    transition: all 0.4s ease;
    position: fixed;
    bottom: 24px;
    right: 5%;
    display: none;
}
.user-profile-sedebar-responsive-menu:hover {
    background: var(--sodainagar-secondary-color);
}

/*========================================================
  End  User Dashboard
===========================================================*/

/*========================================================
  Scroll Top
===========================================================*/
.scrollToTop {
    right: 110px;
    z-index: 33;
    bottom: 40px;
    position: fixed;
    text-align: center;
    opacity: 1;
    transform: translateX(50px);
    visibility: visible;
    transition: all 0.4s ease;
    cursor: pointer;
}

.scrollToTop i {
    height: 55px;
    width: 55px;
    line-height: 62px;
    background: var(--sodainagar-primary-color);
    border-radius: 100%;
    font-size: 25px;
    transition: all 0.6s ease;
    display: block;
    color: #fbfcfc;
    text-align: center;
}

.scrollToTop:hover i {
    background: var(--sodainagar-secondary-color);
    color: #fbfcfc;
}

/*========================================================
  End Scroll Top
===========================================================*/

.CookieConsent {
    max-width: 550px;
    padding: 32px 24px;
    background: #fbfcfc;
    box-shadow: 0px 16px 48px rgba(34, 87, 122, 0.12);
    border-radius: 4px;
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate3d(-50%, -50%, 9999990px);
    width: 95%;
}

.overlayclass {
    position: fixed;
    background: #383838d4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999999;
}

button#rcc-confirm-button {
    font-size: 0;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 12px;
    right: 12px;
}

button#rcc-confirm-button:hover::before {
    background: var(--sodainagar-secondary-color);
    color: #fbfcfc;
}

button#rcc-confirm-button::before {
    position: absolute;
    content: "\f239";
    width: 28px;
    color: var(--sodainagar-secondary-color);
    height: 28px;
    top: 0;
    line-height: 28px;
    font-size: 12px;
    right: 0;
    background: rgba(34, 87, 122, 0.06);
    border-radius: 100%;
    font-family: "uicons-bold-rounded";
    transition: all 0.4s ease;
}

.select-d-location-top {
    text-align: center;
    margin-bottom: 32px;
}

.select-d-location-top img {
    width: 72px;
    height: 72px;
    margin-bottom: 10px;
}

.select-d-location-top-title {
    font-weight: 600;
    font-size: 23px;
    line-height: 140%;
    margin: 0;
    color: #383838;
}

.select-d-location-top-title b {
    font-weight: 700;
}

.CookieConsent .form-input-wrapper .form-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #383838;
    margin: 0;
    background: #fbfcfc;
    margin-left: 12px;
    z-index: 33;
    border: 0;
    padding: 0;
    margin-bottom: 6px;
    position: initial;
}

.select-location-form-inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
}

.select-location-form-inner .form-input-wrapper {
    margin-bottom: 0;
}

.select-location-form-inner .form-input-wrapper .form-select {
    padding: 12px 30px 8px 10px;
}

.select-d-location-btn {
    height: 46px;
    margin-top: 29px;
    border: none;
}

.select-d-location-btn:hover {
    transition: all 0.4s ease;
}

.sodainagar-main-inner.toggle .product-category-top {
    padding-left: 24px;
}

.one .pay-with-options-wrapper {
    border: 2px solid var(--sodainagar-primary-color);
}

.order-c-wrapper-top-btn.btn.btn-primary {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    font-size: 16px;
    position: relative;
    top: 2px;
    color: #202020;
}

.order-c-wrapper-top-btn.btn.btn-primary:focus {
    border: none;
}

.order-c-wrapper-top-btn.btn.btn-primary:focus:focus-visible {
    box-shadow: none;
}

.add-new-address-modal-form.edit-modal-option-form.modal.fade .modal-dialog {
    transform: none !important;
    position: absolute !important;
    right: 0 !important;
    bottom: 0 !important;
}

.add-new-address-modal-form.edit-modal-option-form.modal.show .modal-dialog {
    width: 420px;
    margin: 0;
    float: right;
    box-shadow: -16px 0px 32px rgba(34, 87, 122, 0.16);
    height: 100%;
    z-index: 999;
    background: #fff;
}

.add-new-address-modal-form.edit-modal-option-form .modal-content {
    padding-top: 72px;
    border-radius: 0;
    margin: 0;
    border: none;
}

.place-order-btn .theme-btn {
    color: #fbfcfc;
}

.place-order-btn .btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.5;
}

.sodainagar-main-inner.toggle .login-card.signup-card {
    margin: 0px 250px;
}

.card-info-widget-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-info-main {
    display: flex;
    align-items: center;
}

.card-info-img img {
    width: 50px;
    height: 30px;
}

.card-info-number {
    display: inline-block;
    font-size: 16px;
}

.card-info-expired {
    margin: 0;
    font-size: 15px;
}

.card-info-expired b {
    font-weight: 600;
    margin-left: 5px;
}

.card-info-main-content {
    margin-left: 10px;
}

.card-info-bottom {
    text-align: center;
}

.card-info-bottom .theme-btn {
    padding: 9px 15px;
    font-size: 15px;
}

.add-new-card-btn.theme-btn i {
    position: relative;
    top: 2px;
    margin-right: 5px;
}

.card-info-edit-btn {
    margin-right: 10px;
    position: relative;
    padding-right: 10px;
    color: var(--sodainagar-secondary-color);
    text-decoration: none;
}

.card-info-delete-btn {
    color: #f55353 !important;
    text-decoration: none;
    padding: 0px !important;
}

.card-info-edit-btn::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 14px;
    background: #ddd;
    right: 0;
    top: 12px;
}

.card-info-widget-inner {
    margin-top: 15px;
    background: #fbfcfc;
    border: 1px solid #e4eaef;
    border-radius: 8px;
    padding: 16px;
}

.card-info-bottom {
    margin-top: 30px;
}

.m-profile-address-single-top .dropdown-toggle::after {
    display: none;
}

.m-profile-address-single-top .btn-success {
    background-color: transparent;
    border-color: none;
    color: var(--sodainagar-secondary-color);
}

.m-profile-address-single-top .btn-success:hover {
    color: var(--sodainagar-secondary-color);
    background-color: transparent;
    border-color: none;
}

.m-profile-address-single-top .btn-check:active + .btn-success,
.m-profile-address-single-top .btn-check:checked + .btn-success,
.m-profile-address-single-top .btn-success.active,
.m-profile-address-single-top .btn-success:active,
.m-profile-address-single-top .show > .btn-success.dropdown-toggle {
    color: var(--sodainagar-secondary-color);
    background-color: transparent;
    border-color: none;
}

.m-profile-address-single-top .btn-check:active + .btn-success:focus,
.m-profile-address-single-top .btn-check:checked + .btn-success:focus,
.m-profile-address-single-top .btn-success.active:focus,
.m-profile-address-single-top .btn-success:active:focus,
.m-profile-address-single-top .show > .btn-success.dropdown-toggle:focus {
    box-shadow: none;
    border: none;
}

#dropdown-basic {
    padding: 0;
    margin: 0;
}

#dropdown-basic:hover:focus-within {
    border: none;
}

#dropdown-basic {
    border: none;
}

#dropdown-basic:focus {
    background: transparent;
    color: var(--sodainagar-secondary-color);
}

.m-profile-address-single-top .dropdown-menu.show {
    transform: translate(-100px, 30px) !important;
}

.m-profile-address-single-top .dropdown-menu {
    border: none;
    padding: 0px;
    font-size: 14px;
    box-shadow: 8px 16px 32px #22577a30;
    min-width: 120px;
    position: relative;
    z-index: 2;
}

.m-profile-address-single-top .dropdown-menu::before {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    margin-left: -6px;
    transition: none;
    right: 13px;
    background: #fff;
    top: -7px;
    z-index: -1;
}

.m-profile-address-single-top .dropdown-item:focus,
.m-profile-address-single-top .dropdown-item:hover {
    color: #fff;
    background-color: var(--sodainagar-primary-color);
}

.m-profile-address-single-top .dropdown-item {
    padding: 6px 12px;
    border-bottom: 1px solid #e4eaef;
}

.m-profile-address-single-top .dropdown-item:last-child {
    border: none;
}

.card-info-edit {
    display: flex;
    align-items: center;
}

.card-info-edit-btn.btn.btn-primary {
    background: transparent;
    border: none;
    padding: 0;
    padding-right: 10px;
}

.card-info-edit-btn.btn.btn-primary:focus {
    color: #22577a !important;
}

.card-info-edit-btn {
    color: #22577a !important;
}

.form-check.card-info-cehck .form-check-input:checked {
    background-color: var(--sodainagar-primary-color);
    border: none;
}

.form-check.card-info-cehck {
    margin-top: 12px;
}

#default-checkbox:focus {
    box-shadow: none;
}

.add-new-card-btn.theme-btn.btn.btn-primary {
    border: none;
}

.add-new-card-btn.theme-btn.btn.btn-primary:active {
    background: var(--sodainagar-secondary-color);
}

.add-new-card-btn.theme-btn.btn.btn-primary:focus {
    background: var(--sodainagar-secondary-color);
}

.card-info-number b {
    margin-left: 8px;
    font-weight: 600;
    color: var(--sodainagar-secondary-color);
}

/*========================================================
  Sodainagar Responsive CSS
===========================================================*/

@media only screen and (min-width: 1201px) and (max-width: 1560px) {
    .header-right {
        justify-content: initial;
        float: right;
    }

    .products-inner {
        grid-template-columns: repeat(5, 1fr);
    }

    .testimonial-wrapper {
        padding: 0px;
    }

    .testimonial-area .swiper-button-next,
    .testimonial-area .swiper-button-prev {
        margin-right: 0;
    }

    .blog-card-widget-inner {
        grid-template-columns: repeat(3, 1fr);
    }

    .footer-widget.footer-stay-update {
        margin-left: 0;
    }

    .footer-widget.f-download-app {
        margin-left: 56px;
    }
    .cart-active {
        top: 250px;
    }

    .header-searchbar input {
        width: 485px;
    }

    .payment-method-wrapper {
        grid-template-columns: auto 290px auto auto;
    }

    .support-searchbar input {
        width: 290px;
    }

    .s-ticket-accordian-head-list {
        grid-template-columns: 150px 390px 119px 113px 125px;
    }

    .login-card .form-label-text::after {
        width: 80px;
    }

    .login-card .form-label-text::before {
        width: 80px;
    }

    .s-browse-item-btn .theme-btn {
        height: 40px;
        width: 190px;
        line-height: 19px;
        font-size: 14px;
    }

    .s-browse-item-btn {
        margin-left: -95px;
        overflow: hidden;
    }

    .offer-content {
        margin-top: 40px;
        padding-left: 20px;
    }

    .cart-sidebar-item-main {
        height: 245px;
    }

    .login-card.signup-card {
        margin: 0px 50px;
    }

    .card-info-widget-inner {
        display: block;
    }

    .card-info-edit {
        margin-top: 10px;
    }
    .wishlist-products-inner {
        grid-template-columns: repeat(4, 1fr);
    }
    .add-new-address-modal-form .modal-content {
        margin-top: 0;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .sodainagar-sidemenu .sidebar {
        width: 20%;
        padding-left: 15px;
    }

    .sidebar-title span {
        font-size: 14px;
    }

    .s-hero-cont-title {
        font-size: 28px;
    }

    .sodainagar-hero {
        padding: 100px 50px;
    }

    .sodainagar-hero-content .theme-btn {
        padding: 9px 18px;
    }

    .toggle-btn {
        font-size: 14px;
        right: 20px;
    }

    .section-title.text-center {
        font-size: 28px;
    }

    .section-title {
        font-size: 28px;
    }

    .section-h-btn {
        font-size: 15px;
    }

    .products-inner {
        grid-template-columns: repeat(2, 1fr);
    }

    .cart-active {
        top: 230px;
    }

    .whychoose-cont-title {
        font-size: 24px;
    }

    .testimonial-wrapper {
        padding: 0px;
    }

    .testimonial-content {
        margin-left: 0px;
        margin-top: 0;
        width: initial;
    }

    .testimonial-profile {
        width: initial;
    }

    .testimonial-wrapper {
        display: block;
        text-align: center;
    }

    .testimonial-content-text {
        margin: 0;
        margin-left: 0px;
        padding-bottom: 50px;
    }

    .testimonial-area .swiper-button-next,
    .testimonial-area .swiper-button-prev {
        margin-right: 0;
    }

    .testimonial-img::before {
        left: 180px;
    }

    .sodainagar-main-inner.toggle .testimonial-img::before {
        left: 260px;
    }

    .blog-card-widget-inner {
        grid-template-columns: repeat(2, 1fr);
    }

    .footer-widget.f-download-app {
        margin-left: 24px;
    }

    .footer-widget.footer-stay-update {
        margin-left: 0;
    }

    .footer-left-content-list {
        text-align: center;
    }

    .sodainagar-main-inner.toggle .footer-widget.f-download-app {
        margin-left: 0px;
    }

    .sodainagar-main-inner.toggle .footer-widget.footer-stay-update {
        float: left;
    }

    .h-right-main-accounts .nav-link.dropdown-toggle {
        font-size: 0;
    }

    .header-searchbar input {
        width: 285px;
    }

    .h-delivery-address {
        width: 165px;
    }

    .h-delivery-address {
        margin-left: 15px;
    }

    .h-right-cart {
        display: none;
    }

    .header-right {
        justify-content: initial;
        float: right;
    }

    .theme-btn {
        padding: 8px 10px;
        font-size: 15px;
    }

    .offer-content-title {
        font-size: 25px;
        margin-bottom: 12px;
    }

    .offer-content {
        margin-top: 30px;
        padding-left: 30px;
    }

    .h-delivery-address-map .h-delivery-address-text {
        width: 100%;
    }

    .header-right-main {
        margin-left: 16px;
    }

    .scrollToTop {
        right: 74px;
    }

    .offcanvas-start {
        width: 330px;
    }

    .cart-sidebar-bottom {
        padding: 15px;
    }

    .cart-coupon-form-input .theme-btn {
        font-size: 12px !important;
        padding: 5px !important;
    }

    .h-right-main-accounts .dropdown-menu.show {
        left: -110px !important;
    }

    .user-profile {
        height: 700px;
    }

    .dashboard-main-title {
        font-size: 28px;
    }

    .dashboard-product-title {
        font-size: 24px;
    }

    .user-dashboard-main {
        margin-top: 50px;
    }

    .order-history-table-main td {
        font-size: 14px;
    }

    .wishlist-products-inner {
        grid-template-columns: repeat(2, 1fr);
    }

    .d-payment-option-total {
        font-size: 20px;
    }

    .d-payment-h-option-icon {
        position: initial;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .d-payment-h-option-single {
        padding: 10px;
        text-align: center;
    }

    .payment-m-options-top {
        display: block;
    }

    .payment-m-options-select .product-category-select {
        display: block;
    }

    .payment-m-options-select {
        margin-top: 20px;
    }

    .product-category-select label {
        margin-right: 0;
        margin-bottom: 5px;
    }

    .p-method-single-text {
        font-size: 14px;
    }

    .payment-method-wrapper {
        grid-template-columns: auto 115px auto auto;
    }

    .sodainagar-main-inner.toggle .payment-method-wrapper {
        grid-template-columns: auto 260px auto auto;
    }

    .support-ticket-top {
        display: block;
    }

    .support-ticket-top-left {
        display: block;
    }

    .support-searchbar input {
        width: 100%;
    }

    .support-searchbar {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .support-ticket-top .theme-btn {
        margin-top: 20px;
    }

    .support-ticket-top-left .product-category-select label {
        margin-right: 8px;
        margin-bottom: 0;
    }

    .s-ticket-accordian-head-list {
        grid-template-columns: 89px 231px 151px 159px 104px;
    }

    .sodainagar-main-inner.toggle .s-ticket-accordian-head-list {
        grid-template-columns: 103px 229px 122px 121px 132px;
    }

    .a-comments-attach-file .inputfile-1 + label {
        font-size: 14px;
        padding: 8px 10px;
        text-align: center;
    }

    .accordian-comments-box-btns .theme-btn {
        font-size: 14px;
        width: 100px;
    }

    .accordian-comments-box-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 10px;
    }

    .a-c-user-content-top h6 {
        font-size: 15px;
    }

    .imageupload-box {
        padding-bottom: 40px;
    }

    .imageupload-box .theme-btn {
        display: block;
    }

    .add-new-address-btn {
        margin-top: 80px;
    }

    .order-d-formation-single.order-d-address {
        margin-left: 0;
    }

    .order-d-formation-single.order-d-amount {
        margin-left: 0;
    }

    .order-status-wrapper::before {
        display: none;
    }

    .sodainagar-main-inner.toggle .order-d-formation-single {
        padding: 0px 120px;
    }

    .p-product-details-modal .modal-content {
        width: 100%;
    }

    .p-product-details-modal .modal-dialog {
        left: 0;
        margin-left: 0;
        top: 0px;
        margin-top: 100px;
        margin-bottom: 100px;
        margin-left: 50px;
        margin-right: 50px;
    }

    .p-product-details-modal .carousel .slide img {
        width: 75%;
    }

    .p-details-modal-d-title {
        font-size: 28px;
    }

    .p-details-modal-price span {
        font-size: 24px;
    }

    .d-process-card {
        display: block;
    }

    .h-right-main-accounts .dropdown-toggle::after {
        margin-left: 0;
    }

    .sodainagar-browse-item .swiper-slide {
        width: 54% !important;
    }

    .sodainagar-main-inner.toggle .sodainagar-browse-item .swiper-slide {
        width: 40% !important;
    }

    .checkout-s-tab-wrapper i {
        margin-left: 50px;
    }

    .checkout-s-tab-wrapper {
        margin-right: 50px;
    }

    .shopping-info-accordian-bottom {
        padding-right: 24px;
    }

    .order-confirm-terms-text {
        font-size: 15px;
    }

    .order-confirm-steps-bottom {
        display: block;
    }

    .confirm-steps-wrapper::before {
        display: none;
    }

    .order-t-widget-cont-title {
        font-size: 18px;
    }

    .order-t-box-top-title {
        font-size: 24px;
    }

    .reset-password-card {
        margin: 0;
    }

    .login-product-side {
        padding-left: 0px;
        margin-top: 0px;
    }

    .login-product-side .swiper-slide {
        width: 100% !important;
    }

    .login-card {
        padding-right: 24px;
    }

    .product-category-top {
        display: block;
    }

    .p-item-found-text {
        margin-bottom: 10px;
    }

    .product-category-top .product-category-select label {
        margin: 0;
        margin-right: 8px;
    }

    .sodainagar-main-inner.toggle .product-category-top {
        display: block;
        padding-left: 24px;
    }

    .sodainagar-main-inner.toggle .products-inner {
        padding-left: 24px;
    }

    .map-location-modal-inner .modal-content {
        margin-top: 150px !important;
    }

    .cart-sidebar-item-main {
        height: 215px;
    }

    .login-card.signup-card {
        margin: 0px;
    }

    .sodainagar-main-inner.toggle .login-card.signup-card {
        margin: 0px;
    }

    .m-profile-address-single-top .dropdown-menu.show {
        transform: translate(0px, 30px) !important;
    }
}

@media only screen and (max-width: 767px) {
    .sodainagar-main-inner {
        width: 100%;
    }

    .footer-left-content {
        text-align: center;
    }

    .footer-right-content {
        text-align: center;
        margin-top: 20px;
    }

    .header-searchbar input {
        width: 400px;
    }

    .h-delivery-address {
        width: 250px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sodainagar-main-inner {
        width: 70.5%;
    }

    .sodainagar-sidemenu .sidebar {
        width: 27%;
        padding-left: 15px;
    }

    .sidebar-title span {
        font-size: 14px;
    }

    .s-hero-cont-title {
        font-size: 28px;
    }

    .sodainagar-hero {
        padding: 100px 50px;
    }

    .sodainagar-hero-content .theme-btn {
        padding: 9px 18px;
    }

    .toggle-btn {
        font-size: 14px;
        right: 20px;
    }

    .section-title.text-center {
        font-size: 24px;
    }

    .section-title {
        font-size: 24px;
        font-weight: 600;
    }

    .section-h-btn {
        font-size: 15px;
    }

    .products-inner {
        grid-template-columns: repeat(2, 1fr);
    }

    .sodainagar-main-inner.toggle .products-inner {
        grid-template-columns: repeat(3, 1fr);
    }

    .offer-banner-area {
        grid-template-columns: repeat(1, 1fr);
    }

    .sodainagar-main-inner.toggle .offer-banner-area {
        grid-template-columns: repeat(1, 1fr);
    }

    .sodainagar-main-inner.toggle .offer-content {
        margin-top: 100px;
        padding-left: 50px;
    }

    .cart-active {
        top: 230px;
    }

    .whychoose-cont-title {
        font-size: 24px;
    }

    .testimonial-wrapper {
        padding: 0px;
    }

    .testimonial-content {
        margin-left: 0px;
        margin-top: 0;
        width: initial;
    }

    .testimonial-profile {
        width: initial;
    }

    .testimonial-wrapper {
        display: block;
        text-align: center;
    }

    .testimonial-content-text {
        margin: 0;
        margin-left: 0px;
        padding-bottom: 50px;
    }

    .testimonial-area .swiper-button-next,
    .testimonial-area .swiper-button-prev {
        margin-right: 0;
    }

    .testimonial-img::before {
        left: 56px;
    }

    .sodainagar-main-inner.toggle .testimonial-img::before {
        left: 165px;
    }

    .blog-card-widget-inner {
        grid-template-columns: repeat(2, 1fr);
    }

    .footer-widget.f-download-app {
        margin-left: 0;
    }

    .footer-widget.footer-stay-update {
        margin-left: 0;
    }

    .footer-left-content-list {
        text-align: center;
    }

    .sodainagar-main-inner.toggle .footer-widget.f-download-app {
        margin-left: 0px;
    }

    .sodainagar-main-inner.toggle .footer-widget.footer-stay-update {
        float: left;
    }

    .h-right-main-accounts .nav-link.dropdown-toggle {
        font-size: 0;
    }

    .header-searchbar input {
        width: 285px;
    }

    .h-delivery-address {
        width: 165px;
    }

    .h-delivery-address {
        margin-left: 15px;
    }

    .h-right-cart {
        display: none;
    }

    .header-right {
        justify-content: initial;
        float: right;
    }

    .theme-btn {
        padding: 8px 10px;
        font-size: 15px;
    }

    .offer-content-title {
        font-size: 25px;
        margin-bottom: 12px;
    }

    .offer-content {
        margin-top: 30px;
        padding-left: 30px;
    }

    .h-delivery-address-map .h-delivery-address-text {
        width: 100%;
    }

    .header-right-main {
        margin-left: 16px;
    }

    .scrollToTop {
        right: 74px;
    }

    .offcanvas-start {
        width: 330px;
    }

    .cart-sidebar-bottom {
        padding: 15px;
    }

    .cart-coupon-form-input .theme-btn {
        font-size: 12px !important;
        padding: 5px !important;
    }

    .h-right-main-accounts .dropdown-menu.show {
        left: -110px !important;
    }

    .user-profile {
        height: 600px;
    }

    .dashboard-main-title {
        font-size: 28px;
    }

    .dashboard-product-title {
        font-size: 24px;
    }

    .user-dashboard-main {
        margin-top: 50px;
    }

    .order-history-table-main td {
        font-size: 14px;
    }

    .wishlist-products-inner {
        grid-template-columns: repeat(2, 1fr);
    }

    .d-payment-option-total {
        font-size: 20px;
    }

    .d-payment-h-option-icon {
        position: initial;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .d-payment-h-option-single {
        padding: 10px;
        text-align: center;
    }

    .payment-m-options-top {
        display: block;
    }

    .payment-m-options-select .product-category-select {
        display: block;
    }

    .payment-m-options-select {
        margin-top: 20px;
    }

    .product-category-select label {
        margin-right: 0;
        margin-bottom: 5px;
    }

    .p-method-single-text {
        font-size: 14px;
    }

    .payment-method-wrapper {
        grid-template-columns: auto 115px auto auto;
    }

    .sodainagar-main-inner.toggle .payment-method-wrapper {
        grid-template-columns: auto 260px auto auto;
    }

    .support-ticket-top {
        display: block;
    }

    .support-ticket-top-left {
        display: block;
    }

    .support-searchbar input {
        width: 100%;
    }

    .support-searchbar {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .support-ticket-top .theme-btn {
        margin-top: 20px;
    }

    .support-ticket-top-left .product-category-select label {
        margin-right: 8px;
        margin-bottom: 0;
    }

    .s-ticket-accordian-head-list {
        grid-template-columns: 89px 155px 102px 84px 60px;
    }

    .sodainagar-main-inner.toggle .s-ticket-accordian-head-list {
        grid-template-columns: 103px 229px 122px 121px 132px;
    }

    .a-comments-attach-file .inputfile-1 + label {
        font-size: 14px;
        padding: 8px 10px;
        text-align: center;
    }

    .accordian-comments-box-btns .theme-btn {
        font-size: 14px;
        width: 100px;
    }

    .accordian-comments-box-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 10px;
    }

    .a-c-user-content-top h6 {
        font-size: 15px;
    }

    .imageupload-box {
        padding-bottom: 40px;
    }

    .imageupload-box .theme-btn {
        display: block;
    }

    .add-new-address-btn {
        margin-top: 80px;
    }

    .order-d-formation-single.order-d-address {
        margin-left: 0;
    }

    .order-d-formation-single.order-d-amount {
        margin-left: 0;
    }

    .order-status-wrapper::before {
        display: none;
    }

    .sodainagar-main-inner.toggle .order-d-formation-single {
        padding: 0px 120px;
    }

    .p-product-details-modal .modal-content {
        width: 100%;
    }

    .p-product-details-modal .modal-dialog {
        left: 0;
        margin-left: 0;
        top: 0px;
        margin-top: 100px;
        margin-bottom: 100px;
        margin-left: 50px;
        margin-right: 50px;
    }

    .p-product-details-modal .carousel .slide img {
        width: 75%;
    }

    .p-details-modal-d-title {
        font-size: 28px;
    }

    .p-details-modal-price span {
        font-size: 24px;
    }

    .d-process-card {
        display: block;
    }

    .h-right-main-accounts .dropdown-toggle::after {
        margin-left: 0;
    }

    .sodainagar-browse-item .swiper-slide {
        width: 72% !important;
    }

    .sodainagar-main-inner.toggle .sodainagar-browse-item .swiper-slide {
        width: 50% !important;
    }

    .checkout-s-tab-wrapper i {
        margin-left: 10px;
    }

    .checkout-s-tab-wrapper {
        margin-right: 10px;
    }

    .shopping-info-accordian-bottom {
        padding-right: 24px;
    }

    .order-confirm-terms-text {
        font-size: 15px;
    }

    .order-confirm-steps-bottom {
        display: block;
    }

    .confirm-steps-wrapper::before {
        display: none;
    }

    .order-t-widget-cont-title {
        font-size: 18px;
    }

    .order-t-box-top-title {
        font-size: 24px;
    }

    .reset-password-card {
        margin: 0;
    }

    .login-product-side {
        padding-left: 0px;
        margin-top: 0px;
    }

    .login-product-side .swiper-slide {
        width: 100% !important;
    }

    .login-card {
        padding-right: 24px;
    }

    .product-category-top {
        display: block;
    }

    .p-item-found-text {
        margin-bottom: 10px;
    }

    .product-category-top .product-category-select label {
        margin: 0;
        margin-right: 8px;
    }

    .sodainagar-main-inner.toggle .product-category-top {
        display: block;
        padding-left: 24px;
    }

    .sodainagar-main-inner.toggle .products-inner {
        padding-left: 24px;
    }

    .map-location-modal-inner .modal-content {
        margin-top: 150px !important;
    }

    .cart-sidebar-item-main {
        height: 200px;
    }

    .s-categories-btn .theme-btn {
        width: 140px;
        height: 40px;
        padding: 8px;
        font-size: 15px;
    }

    .s-categories-btn {
        position: absolute;
        left: 50%;
        margin-left: -70px;
        top: 50%;
        margin-top: -21px;
    }

    .login-card.signup-card {
        margin: 0px 50px;
    }

    .sodainagar-main-inner.toggle .login-card.signup-card {
        margin: 0px 100px;
    }

    .login-card.add-margin {
        margin-right: 0;
    }

    .m-profile-address-single-top .dropdown-menu.show {
        transform: translate(0px, 30px) !important;
    }
}

@media only screen and (max-width: 767px) {
    .sodainagar-main-inner {
        width: 100%;
    }

    .sodainagar-sidemenu .sidebar {
        height: 100%;

        width: 100%;
        left: 0;
    }

    body .toggle.sidebar {
        /* left: -100%; */
        display: none;
    }

    .h-delivery-address {
        display: none;
    }

    .h-right-cart {
        display: none;
    }

    .h-right-main-accounts .nav-link.dropdown-toggle {
        font-size: 0;
    }

    .h-right-main-accounts .dropdown-menu.show {
        left: -131px !important;
    }

    .h-right-main-accounts .dropdown-toggle::after {
        margin-left: 0;
    }

    .header-inner {
        padding: 0;
    }

    .s-hero-cont-title {
        font-size: 26px;
    }

    .theme-btn {
        padding: 8px 15px;
        font-size: 15px;
    }

    .h-delivery-address {
        margin: 0;
    }

    .header-searchbar input {
        width: 100%;
    }

    .header-right {
        display: block;
    }

    .header-searchbar {
        position: relative;
        margin-top: 0px;
        width: 100%;
    }

    .header-right-main {
        margin-left: 0;
    }

    .header-searchbar input {
        height: 40px;
        font-size: 14px;
    }

    .header-searchbar .search-btn {
        font-size: 18px;
        top: 11px;
    }

    .select-location-form-inner {
        grid-template-columns: repeat(1, 1fr);
        gap: 15px;
    }

    .select-d-location-btn {
        margin-top: 0;
    }

    .select-d-location-top-title {
        font-size: 18px;
    }

    .select-d-location-top img {
        width: 65px;
        height: 65px;
    }
    .sodainagar-main-inner {
        margin-top: 72px;
    }
    .sodainagar-shop-categories {
        padding-right: 0;
    }

    .s-categories-wrapper {
        margin-right: 0;
    }

    .section-head.style-2 {
        display: block;
    }

    .section-title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 8px;
    }

    .products-inner {
        grid-template-columns: repeat(2, 1fr);
    }

    .popular-products-wrapper {
        margin-right: 0;
    }

    .offer-banner-area {
        grid-template-columns: repeat(1, 1fr);
        padding-right: 0px;
    }

    .cart-active {
        top: 285px;
    }

    .offer-content {
        margin-top: 13px;
        padding-left: 20px;
    }

    .offer-content-title {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .order-steps-wrapper {
        margin-right: 0px;
        text-align: center;
    }

    .order-steps-area {
        padding-left: 0;
    }

    .order-steps-cont-title {
        font-size: 24px;
    }

    .order-steps-icon img {
        width: 90px;
        height: 90px;
    }

    .order-steps-icon {
        margin-bottom: 20px;
    }

    .whychoose-wrapper {
        margin-right: 0px;
        text-align: center;
    }

    .whychoose-cont-title {
        font-size: 24px;
    }

    .whychoose-icon img {
        width: 90px;
        height: 90px;
    }

    .whychoose-icon {
        margin-bottom: 20px;
    }

    .testimonial-wrapper {
        display: block;
        text-align: center;
        padding: 0px;
    }

    .testimonial-profile {
        width: 100%;
    }

    .testimonial-content {
        margin-right: 0;
        width: 100%;
        margin-left: 0;
        margin-top: 0;
    }

    .testimonial-content-text {
        margin: 0;
        margin-left: 0;
        padding-bottom: 55px;
    }

    .testimonial-area .swiper-button-next,
    .testimonial-area .swiper-button-prev {
        margin-right: 0;
    }

    .blog-card-widget-inner {
        grid-template-columns: repeat(1, 1fr);
    }

    .blog-wrapper {
        margin-right: 0;
    }

    .blog-content-title {
        font-size: 20px;
    }

    .blog-btn {
        font-size: 16px;
    }

    .footer-top {
        padding-right: 0;
        padding-left: 0;
    }

    .footer-widget.f-download-app {
        margin-left: 0;
    }

    .f-download-btn {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2, 1fr);
    }

    .f-download-btn img {
        width: 145px;
        height: 45px;
    }

    .google-play-btn {
        margin-right: 15px;
        margin-top: 0;
    }

    .footer-widget-title {
        margin-bottom: 16px;
        font-size: 20px;
    }

    .footer-widget.footer-stay-update {
        margin-left: 0;
    }

    .footer-bottom {
        padding-right: 0;
        padding-left: 0;
    }

    .footer-left-content {
        text-align: center;
    }

    .scrollToTop {
        right: 70px;
    }

    .dashboard-area {
        padding-right: 0;
        padding-bottom: 48px;
    }

    .user-profile {
        height: 600px;
    }

    .dashboard-product-title {
        font-size: 24px;
    }

    .wishlist-products-inner {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
    }

    .order-history-table-scrrol {
        overflow-x: auto;
        max-width: 600px;
    }

    .order-history-table-main.table th {
        font-size: 13px;
        vertical-align: middle;
    }
    .order-history-table-main.table td {
        font-size: 14px;
    }
    .d-payment-option-total {
        font-size: 18px;
    }

    .payment-m-options-top {
        display: block;
    }

    .payment-m-options-select {
        display: block;
    }

    .payment-m-options-select .product-category-select {
        display: block;
        margin-right: 0;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .product-category-select label {
        margin-right: 0;
        margin-bottom: 5px;
    }

    .payment-method-wrapper {
        display: block;
    }

    .payment-method-single {
        margin-top: 15px;
    }

    .payment-method-wrapper {
        padding: 20px;
    }

    .support-searchbar input {
        width: 100%;
    }

    .support-ticket-top {
        display: block;
    }

    .support-ticket-top-left {
        display: block;
    }

    .support-searchbar {
        position: relative;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .support-ticket-top-left .product-category-select label {
        margin-right: 8px;
        margin-bottom: 0;
    }

    .support-ticket-top .theme-btn {
        margin-top: 20px;
    }

    .accordian-comments-profile {
        position: initial;
        margin-bottom: 20px;
    }

    .accordian-comments-box {
        padding-left: 0;
    }

    .s-ticket-accordian-comments {
        text-align: center;
    }

    .s-ticket-accordian-head-list {
        display: block;
    }

    .a-comments-user-content {
        padding-left: 0;
    }

    .a-comments-user-img,
    .a-comments-user-img-customer {
        position: initial;
        margin-bottom: 20px;
        text-align: center;
    }

    .a-comments-user-single {
        text-align: center;
    }

    .a-c-user-content-top {
        display: block;
        margin-bottom: 8px;
    }

    .accordian-comments-box-btm {
        display: block;
    }

    .a-comments-attach-file {
        margin-bottom: 10px;
    }

    .accordian-comments-box-btns {
        margin-top: 0;
    }

    .accordian-comments-box-btns .theme-btn {
        width: 100%;
    }

    .s-ticket-accordian-head-list li {
        margin-bottom: 12px;
    }

    .s-ticket-accordian-head-list li:last-child {
        margin: 0;
    }

    .a-comments-attach-file .inputfile-1 + label {
        width: 100%;
    }

    .c-ticket-inner-title {
        font-size: 20px;
    }

    .create-ticket-from .a-comments-attach-file .inputfile-1 + label {
        padding: 13px 20px;
        text-align: center;
    }

    .add-new-address-btn {
        margin-top: 50px;
    }

    .footer-top {
        margin-top: 0;
    }

    .dashboard-payment-history {
        padding-bottom: 0;
    }

    .support-ticket-main {
        padding-bottom: 0px;
    }

    .user-dashboard-main.pd-bottom {
        padding-bottom: 0;
    }

    .offcanvas-start {
        margin-top: 130px;
        width: 280px;
    }

    .cart-coupon-form-input {
        display: block;
    }

    .cart-coupon-form-input .theme-btn {
        margin: 0;
        margin-top: 10px;
    }

    .cart-sidebar-item-info {
        width: 100%;
        display: block;
    }

    .cart-sidebar-item-content p {
        margin-bottom: 5px;
    }

    .p-product-details-modal .modal-content {
        width: 100%;
    }

    .p-product-details-modal .modal-dialog {
        left: 0;
        margin-left: 0;
    }

    .p-details-modal-buttons {
        grid-template-columns: repeat(1, 1fr);
    }

    .d-process-card {
        display: block;
        align-items: center;
        text-align: center;
    }

    .p-details-modal-buttons {
        display: block;
        text-align: center;
    }

    .p-details-modal-d-title {
        font-size: 24px;
    }
    .p-details-modal-price span {
        font-size: 22px;
    }

    .pages {
        padding-left: 0;
    }

    .reset-password-card {
        padding: 30pxx;
        margin: 0;
    }

    .reset-password-top-title {
        font-size: 25px;
    }

    .login-product-side {
        padding-left: 0;
        margin-top: 0;
    }

    .login-page-banner {
        margin-top: 40px;
        padding: 0;
    }

    .login-product-side h4 {
        font-size: 24px;
    }

    .login-product-side .swiper-slide {
        width: 100% !important;
    }

    .login-product-side .popular-products-wrapper {
        margin-right: 24px;
    }

    .login-card .form-label-text::before {
        width: 60px;
    }

    .login-card .form-label-text::after {
        width: 60px;
    }

    .pay-with-options {
        grid-template-columns: repeat(1, 1fr);
    }

    .shopping-info-accordian-bottom {
        display: block;
        text-align: center;
    }

    .return-shop-btn {
        margin-bottom: 20px;
    }

    .continue-payment-btn {
        display: inline-block;
    }

    .checkout-shopping-tab {
        display: flex;
    }

    .checkout-s-tab-wrapper {
        margin-right: 0;
    }

    .checkout-s-tab-wrapper i {
        display: none;
    }

    .checkout-s-tab-wrapper-title {
        font-size: 15px;
    }

    .shopping-info-accordian .accordion-flush .accordion-item .accordion-button {
        font-size: 20px;
    }

    .ord-confirmation-inner-top-title {
        font-size: 16px;
    }

    .order-confirm-widget-title {
        font-size: 18px;
    }

    .order-confirm-steps-bottom {
        display: block;
    }

    .confirm-steps-wrapper::before {
        display: none;
    }

    .product-category-top {
        display: block;
        padding: 0;
    }

    .p-item-found-text {
        margin-bottom: 15px;
    }

    .product-category-top .product-category-select label {
        margin: 0;
        margin-right: 8px;
    }

    .sodainagar-browse-item .swiper-slide {
        width: 100% !important;
        margin-bottom: 10px;
    }

    .sodainagar-sidemenu .sidebar {
        box-shadow: 22px 18px 45px rgba(34, 87, 122, 0.51);
    }

    .scrollToTop {
        display: none;
    }

    .s-categories-btn .theme-btn {
        font-size: 12px;
        padding: 4px 8px;
        font-weight: 600;
        height: auto;
    }
    .p-details-modal-buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .p-details-modal-buttons .theme-btn {
        width: 145px;
        margin-top: 10px;
        line-height: 30px;
    }

    .p-details-modal-qty-btn {
        background: #eef2f4;
        display: inline-block;
        text-align: center;
        width: 145px;
        height: 46px;
        margin-top: 20px;
    }

    .p-product-details-modal .modal-dialog {
        margin-top: 0;
    }

    .p-details-modal-buttons .theme-btn i {
        font-size: 16px;
    }

    .p-details-modal-buttons {
        gap: 10px;
    }

    .add-cart-btn {
        position: relative;
        top: 10px;
    }

    .add-wishlist .theme-btn {
        overflow: hidden;
    }

    .fade.p-product-details-modal.modal.show {
        z-index: 999999;
    }

    .sodainagar-main {
        overflow: hidden;
    }

    .map-location-modal-inner .modal-content {
        margin-top: 100px !important;
        border-radius: 0;
        width: 100%;
        margin-top: 200px;
        left: 0;
        margin-left: 0;
        border-radius: 5px !important;
    }

    .h-delivery-address {
        display: block;
        padding: 0;
        border: 0;
        background: transparent;
        width: 100%;
        position: absolute;
        right: 40px;
        top: 4px;
    }

    .h-delivery-address-map {
        display: none;
    }

    .h-delivery-address-inner i {
        font-size: 24px;
        top: 0px;
        margin-right: 0;
    }

    .fade.add-new-address-modal-form.modal.show {
        z-index: 9999999;
        padding: 0 !important;
    }

    .modal-backdrop.show {
        z-index: 999999;
    }

    .add-new-address-modal-form .modal-content {
        margin-top: 0;
    }

    .cart-sidebar-item-main {
        height: 150px;
    }

    .coupon-applied-price-title {
        font-size: 24px;
    }

    .sidebar-cart-b-btn button {
        height: 46px !important;
        padding: 11px 20px !important;
    }

    .cart-coupon-form input {
        width: 100%;
    }

    .edit-modal-option-form.modal.show .modal-dialog {
        width: 100% !important;
    }

    .edit-modal-option-form .modal-content {
        padding: 0 !important;
    }

    .add-new-address-btn {
        margin-bottom: 30px;
    }

    .login-card.signup-card {
        margin: 0px;
    }

    .sodainagar-main-inner.toggle .login-card.signup-card {
        margin: 0px;
    }

    .login-card.add-margin {
        margin-right: 0;
    }

    .sodainagar-main-inner.toggle .login-card.add-margin {
        margin-right: 0;
    }

    .m-profile-address-single-top .dropdown-menu.show {
        transform: translate(0px, 30px) !important;
    }

    .card-info-widget-inner {
        display: block;
    }

    .card-info-edit {
        margin-top: 10px;
    }
    .s-categories-wrapper-img img {
        height: 80px;
    }
    .p-products-wrapper-img img {
        height: 120px;
    }
    .p-products-cont-title {
        font-size: 16px;
    }
    .p-products-price span,
    .p-products-price del {
        font-size: 14px;
    }
    .p-products-review-list li {
        font-size: 12px;
    }
    .p-products-cont-top-title {
        padding: 11px 10px 0;
        transition: all 0.4s ease;
    }
    .p-products-cont-btn {
        margin-top: 8px;
        padding: 0px 8px 8px;
        justify-content: space-evenly;
    }
    .p-products-cont-btn .theme-btn i {
        display: none;
    }

    .p-products-cont-btn .theme-btn {
        font-size: 12px;
        padding: 4px 8px;
    }

    .p-products-cont-btn .theme-btn i {
        display: none;
    }
    .product-stock-out {
        margin-top: 7px;
    }
    .product-stock-out-btn {
        height: 35px;
        padding: 4px 8px;
        font-size: 12px;
    }
    .header-logo {
        display: none;
    }
    .header-area {
        padding: 16px 0px;
    }
    .sodainagar-hero .container {
        padding: 0;
    }
    .hero-right-banner {
        margin-top: 12px;
    }
    .hero-single-banner {
        margin-bottom: 0px;
    }
    .hero-right-banner {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    .hero-single-banner-img img,
    .hero-single-banner-img {
        height: 140px;
    }
    .sodainagar-hero {
        padding-bottom: 48px;
    }
    .s-categories-main-inner {
        margin-top: 24px;
    }
    .sodainagar-shop-categories {
        padding-bottom: 48px;
    }
    .products-inner {
        margin-top: 24px;
        gap: 10px;
    }
    .popular-products-wrapper {
        width: 160px;
    }
    .your-ratings .rating-icon {
        font-size: 11px !important;
        padding: 0px 1px !important;
    }
    .p-products-cont-btn {
        display: block;
    }
    .testimonial-area {
        padding: 48px 0px;
    }
    .testimonial-slider-inner {
        margin-top: 24px;
    }
    .testimonial-slider-inner {
        margin-top: 24px;
    }
    .testimonial-content-text {
        padding-bottom: 24px;
    }
    .testimonial-single {
        margin-bottom: 72px;
    }
    .testimonial-slider-inner .swiper-backface-hidden .swiper-slide {
        margin-right: 33px !important;
    }
    .footer-widget {
        margin-top: 24px;
    }
    .f-about-para {
        font-size: 15px;
        margin: 0;
    }
    .f-download-app .footer-widget-title {
        font-size: 22px;
    }
    .footer-address-wrapper {
        margin-top: 28px;
    }
    .f-about-social-list li a {
        width: 40px;
        height: 40px;
        line-height: 38px;
    }
    .f-about-social-list li a img {
        width: 18px;
        height: 18px;
    }
    .footer-left-content-list li a,
    .f-copyright-main-text,
    .payment-currency-img li,
    .footer-bottom-notice li a {
        font-size: 15px;
    }
    .footer-address-wrapper .footer-address-icon i {
        font-size: 18px;
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
    .f-address-cont-list li {
        font-size: 15px;
    }
    .hero-single-slider {
        height: 360px;
    }
    .offcanvas-start {
        margin-top: 0;
        width: 100%;
    }
    .cart-sidebar-item-main {
        height: 60%;
    }
    .h-right-main-accounts .dropdown-toggle::after {
        display: none;
    }
    .h-right-main-accounts {
        display: block;
        position: relative;
        top: 0;
        margin-left: 12px;
    }
    .h-right-main-accounts .nav-link.dropdown-toggle {
        margin: 0px 20px 0px 0px;
    }
    .h-right-main-accounts .dropdown-menu.show::before {
        right: 7px;
    }
    .product-signle-main {
        padding-right: 0;
        padding-top: 56px;
        padding-bottom: 56px;
    }
    .p-details-modal-g-details {
        margin-left: 0;
        margin-top: 16px;
    }
    .p-details-tab-area .container {
        padding: 0;
    }
    .p-details-tab-menu {
        margin-bottom: 32px;
    }
    .p-details-tab-menu .list-group-item {
        font-size: 15px;
    }
    .p-details-modal-des-text {
        font-size: 15px;
    }
    .product-video-thumb-img img {
        height: 280px;
    }
    .product-review-form-head-title {
        font-size: 20px;
    }
    .product-review-form-head-text {
        font-size: 15px;
    }
    .form-group-single.your-opinion {
        display: block;
    }
    .form-group-single label {
        width: 100% !important;
        margin-bottom: 12px;
        font-size: 15px;
    }
    .form-group-single textarea {
        width: 100%;
        height: 132px;
    }
    .product-review-form-button .theme-btn {
        padding: 14px 20px;
    }
    .form-group-single.your-rattings {
        display: block;
    }
    .ueser-review-info-text {
        margin-left: 0;
        margin-top: 12px;
    }
    .user-review-info-img img {
        width: 70px;
        height: 70px;
    }
    .user-review-name,
    .user-review-reply-company {
        font-size: 16px;
        line-height: 18px;
    }
    .ueser-review-text {
        font-size: 15px;
    }
    .your-rattings-list li {
        font-size: 18px;
    }
    .user-review-info,
    .user-review-reply-info {
        align-items: center;
    }
    .user-review-reply-tag {
        font-size: 12px;
    }
    .user-review-reply-tag i {
        font-size: 11px;
    }
    .ueser-review-text-icon i {
        font-size: 32px;
    }
    .pagination-list li a {
        padding: 0;
        width: 34px;
        height: 34px;
        text-align: center;
        line-height: 34px;
        font-size: 14px;
    }
    .carousel .slide img {
        height: 240px;
    }
    .carousel .thumb {
        height: 56px !important;
        width: 56px !important;
    }
    .p-details-modal-d-weight {
        font-size: 15px;
    }
    .p-details-modal-qty-btn .product-qty {
        font-size: 18px;
    }
    .dashboard-area .container {
        padding: 0;
    }
    .dashboard-main-title {
        font-size: 24px;
        font-weight: 600;
    }

    .user-dashboard-main.pd-bottom {
        overflow-y: scroll;
        padding-bottom: 24px;
    }
    .payment-method-bottom-btn .btn {
        font-size: 15px;
    }
    .order-history-table-main {
        margin-top: 12px;
    }
    .d-payment-h-option-single {
        padding: 20px;
        margin-top: 16px;
    }
    .payment-method-options {
        margin-top: 24px;
    }
    .add-new-address-modal-form .modal-content {
        margin-top: 15%;
    }
    .order-details-top {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 18px;
    }
    .order-details-top-title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .order-d-formation-single.order-d-address {
        margin-left: 0;
    }
    .order-d-formation-single.order-d-amount {
        margin-left: 0;
    }
    .order-status-wrapper::before {
        display: none;
    }
    .order-status-icon {
        width: 54px;
        height: 54px;
        line-height: 61px;
        font-size: 20px;
    }
    .order-tracking-area {
        padding-bottom: 0;
    }
    .order-t-box-top-title {
        font-size: 24px;
        font-weight: 600;
    }
    .order-t-widget-cont-title {
        font-size: 18px;
        font-weight: 600;
    }
    .order-tracking-widget {
        margin-top: 24px;
    }
    .reset-password-area {
        padding-bottom: 48px;
    }
    .login-card.add-margin {
        margin: 0;
    }
    .login-page-area {
        padding-bottom: 48px;
    }
    .login-card .form-label-text::before,
    .login-card .form-label-text::after {
        width: 36px;
    }
    .forgot-pass-text a {
        display: block;
    }
    .user-profile-sedebar-responsive-body .user-profile-signouy-btn .theme-btn {
        width: 220px;
    }
    .user-profile-sedebar-responsive-body .user-profile-signouy-btn {
        width: auto;
    }
    .user-profile-sedebar-responsive-menu {
        display: block;
    }
    .user-profile {
        display: none;
    }
    .user-profile-sedebar-responsive .user-profile {
        display: block;
    }
    .user-profile-sedebar-responsive-body .user-profile {
        display: block;
    }
    .offcanvas-body {
        padding: 0 !important;
    }
}

/*========================================================
  End Sodainagar Responsive CSS
===========================================================*/

/*========================================================================
	  Breadcrumbs Area
==========================================================================*/
.sodainagar-breadcrumbs {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-attachment: fixed;
}

.sodainagar-breadcrumbs::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #22577a;
    opacity: 0.7;
}
.sodainagar-breadcrumbs-content {
    text-align: center;
    padding: 112px 0px;
    z-index: 2;
    position: relative;
}
.sodainagar-bread-cont-title {
    font-size: 36px;
    font-weight: 800 !important;
    position: relative;
    display: inline-block;
    padding-bottom: 8px;
    margin-bottom: 20px;
    color: #fff;
}
.sodainagar-bread-cont-title::before {
    position: absolute;
    content: "";
    width: 50%;
    height: 2px;
    background: #57cc99;
    bottom: 0;
    left: 50%;
    margin-left: -25%;
    bottom: -1px;
}
.sodainagar-bread-menu li {
    display: inline-block;
    margin-right: 6px;
}
.sodainagar-bread-menu li:last-child {
    margin: 0;
}
.sodainagar-bread-menu li i {
    margin-left: 6px;
}
.sodainagar-bread-menu li a {
    color: #fff;
    font-weight: 500;
}
.sodainagar-bread-menu li a:hover,
.sodainagar-bread-menu li.active a {
    color: #57cc99;
}

.sodainagar-bread-menu li i {
    font-size: 10px;
    position: relative;
    top: 0px;
    color: #fff;
}

@media only screen and (max-width: 767px) {
    .sodainagar-bread-cont-title {
        font-size: 30px;
    }
    .sodainagar-bread-menu li a {
        font-size: 15px;
    }
}

/*========================================================================
	  End Breadcrumbs Area
==========================================================================*/
/*========================================================================
	  Contact Us Area
==========================================================================*/
.contact-us-info-area {
    position: relative;
    overflow: hidden;
}
.contact-us-info-area #gmap_canvas {
    width: 100%;
    max-height: 100%;
}
.contact-us-info-main {
    padding: 54px 32px;
    border-radius: 8px;
    margin-right: 24px;
    box-shadow: 0px 16px 48px rgba(6, 40, 61, 0.08);
}
.contact-us-info-title {
    font-size: 28px;
    text-transform: capitalize;
    position: relative;
    display: inline-block;
    padding-bottom: 8px;
    margin-bottom: 12px;
    font-weight: 800 !important;
}
.contact-us-info-title::before {
    position: absolute;
    content: "";
    width: 30%;
    height: 3px;
    background: #57cc99;
    bottom: -1.5px;
    left: 0;
}
.contact-us-info-single {
    margin-top: 32px;
    display: flex;
}
.contact-us-info-single-content {
    margin-left: 12px;
}
.contact-us-info-single-title {
    font-size: 18px;
    font-weight: 600 !important;
    margin-bottom: 4px;
}
.contact-us-info-single-icon i {
    width: 70px;
    height: 70px;
    line-height: 80px;
    text-align: center;
    border-radius: 6px;
    background: #f3f7fe;
    display: inline-block;
    color: #57cc99;
    margin-right: 6px;
    font-size: 28px;
}
.contact-us-info-single-text {
    margin: 0;
    font-size: 15px;
    color: #22577a;
}
.contact-us-info-list li a {
    color: #22577a;
    font-size: 15px;
}
.contact-us-info-list li a:hover {
    color: #57cc99;
}

.contact-us-info-map {
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
}
.contact-us-info-area #gmap_canvas {
    width: 100%;
    height: 706px;
    filter: grayscale(100);
}
.contact-us-info-box {
    padding: 80px 0px;
}

.contact-us-info-text {
    margin: 0;
}
.contact-us-form-area {
    background: #f3f7fe;
    padding: 80px 0px;
}
.contact-form-cont-sm-title {
    color: #57cc99;
    font-weight: 600;
    font-size: 18px;
    display: block;
    margin-bottom: 8px;
}

.contact-form-cont-sm-title {
    color: #57cc99;
    font-weight: 500;
    font-size: 18px;
    display: block;
    margin-bottom: 4px;
}
.contact-form-cont-big-title {
    font-size: 38px;
    font-weight: 800 !important;
    position: relative;
    display: inline-block;
    padding-bottom: 8px;
    margin-bottom: 24px;
}
.contact-form-cont-big-title::before {
    position: absolute;
    content: "";
    width: 25%;
    height: 3px;
    background: #57cc99;
    bottom: -1.5px;
    left: 0;
}
.contact-form-cont-text {
    margin: 0;
}
.contact-us-form-cont-top {
    margin-bottom: 32px;
}
.contact-form-main .form-group {
    margin-bottom: 24px;
}
.contact-form-main .form-group input {
    width: 100%;
    height: 54px;
    border: none;
    background: #fff;
    padding: 12px 16px;
    color: #22577a;
    font-size: 15px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(6, 40, 61, 0.12);
    font-weight: 500;
}
.contact-form-main .form-group textarea {
    width: 100%;
    height: 180px;
    border: none;
    background: #fff;
    padding: 12px 16px;
    color: #22577a;
    font-size: 15px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(6, 40, 61, 0.12);
    font-weight: 500;
}
.contact-form-main .form-select {
    width: 100%;
    padding: 12px;
    height: 54px;
    line-height: 19px;
    border: none;
    font-size: 15px;
    background: #fff;
    box-shadow: 0px 2px 4px rgba(6, 40, 61, 0.12);
    color: #22577a;
    font-weight: 500;
}

.contact-form-main .form-select:focus {
    border-color: none;
    outline: 0;
    box-shadow: 0px 2px 4px rgba(6, 40, 61, 0.12);
}
.contact-form-button button {
    transition: all 0.4s ease;
}
.contact-form-button button:hover {
    color: #fff !important;
}
.contact-form-button .sodainagar-btn.secondary-btn::before {
    background: #22577a;
}
.contact-form-img img {
    width: 100%;
    height: 100%;
}
.contact-form-img {
    text-align: right;
}
.contact-form-main .form-group.select-option-arrow {
    position: relative;
}
.contact-form-main .form-group.select-option-arrow i {
    position: absolute;
    right: 12px;
    font-size: 20px;
    top: 18px;
    z-index: 22;
    line-height: 0;
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .contact-form-img {
        margin-top: 40px;
        text-align: left;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-us-info-map {
        position: initial;
        width: 100%;
        height: 100%;
    }
    .contact-us-info-main {
        margin-right: 0;
    }
    .contact-form-img {
        margin-top: 40px;
        text-align: left;
    }
    .contact-us-info-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .contact-us-info-single {
        display: block;
        text-align: center;
        margin-top: 40px;
    }
    .contact-us-info-single-icon {
        margin-bottom: 16px;
    }
}
@media only screen and (max-width: 767px) {
    .product-image-container {
        width: 300px !important;
        height: 235px !important;
    }
    .contact-us-info-main {
        padding: 32px;
        margin-right: 0;
    }
    .contact-us-info-title {
        font-size: 22px;
    }
    .contact-us-info-single-title {
        font-size: 16px;
    }
    .contact-us-info-single-icon i {
        width: 64px;
        height: 64px;
        line-height: 72px;
        font-size: 24px;
    }
    .contact-us-info-list li a {
        font-size: 14px;
    }
    .contact-us-info-single-text {
        font-size: 14px;
    }
    .contact-form-cont-sm-title {
        font-size: 16px;
    }
    .contact-form-cont-big-title {
        font-size: 24px;
    }
    .contact-form-img {
        text-align: left;
        margin-top: 40px;
    }
    .contact-form-main .form-group input {
        height: 48px;
    }
    .contact-form-main .form-select {
        height: 48px;
    }
    .contact-form-main .form-group.select-option-arrow i {
        top: 15px;
    }
    .contact-us-info-map {
        position: initial;
        width: 100%;
        height: 300px;
    }
    .contact-us-info-box {
        padding: 80px 0px 72px;
    }
}

/*========================================================================
	  End Contact Us Area
==========================================================================*/
.about-content.sf-space.offwhite-bg {
    background: transparent !important;
}
.about-content__title {
    margin-bottom: 25px;
    font-weight: 800 !important;
}
.about-content__single p {
    margin-bottom: 20px !important;
}
.about-content__single {
}
.about-content__single:last-child {
}
.about-content__single {
    margin-top: 30px !important;
}
.about-content {
    padding: 60px 0 90px;
    background: #fafbff !important;
}
/*======================================================
  Privacy Policy Us Area start
========================================================*/
.privacy_policy_body {
    margin: 30px auto;
    background-color: #ffffff;
    box-shadow: 0px 8px 24px rgb(22 75 119 / 8%);
    border-radius: 25px;
    padding: 40px;
}
.privacy_policy_text {
    padding: 0px 15px;
}
.privacy_policy_text ul li {
    margin-left: 40px;
    list-style-type: square;
}

/*======================================================
  Privacy Policy Us Area end
========================================================*/
.login-btn {
    background: #57cc99;
    border-radius: 4px;
    display: inline-block;
    padding: 12px 20px;
    color: #fbfcfc;
    font-weight: 500;
    text-decoration: none;
    border-color: #57cc99;
}
.login-btn:hover {
    background: #22577a;
    color: #fbfcfc;
    border-color: #22577a;
}
.login-btn:active:focus {
    box-shadow: none;
    border-color: #57cc99 !important;
    background: #57cc99 !important;
}

.p-details-tab-menu .nav-item .nav-link {
    border: none;
    color: var(--sodainagar-secondary-color);
    background: transparent;
    line-height: 120%;
    margin-right: 12px;
    padding: 16px 12px;
    position: relative;
    transition: all 0.4s ease;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
}
.p-details-tab-menu.nav-tabs .nav-link.active {
    position: relative;
    color: none;
    background-color: transparent;
    border-color: none;
    border-width: 0px;
}
.p-details-tab-menu.nav-tabs .nav-link.active:before {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    background: #22577a !important;
    bottom: 0;
    content: "";
    display: inline-block;
    height: 4px;
    left: 0px;
    position: absolute;
    width: 100%;
}
.your-ratings .rating-icon {
    font-size: 18px;
    padding: 0px 3px;
}
.support-ticket-img {
    width: 300px;
    max-height: 160px;
    padding-bottom: 15px;
}

.load-data-div {
    display: flex;
    justify-content: center;
    gap: 16px;
}
/* Image Zoom  */
.product-image-container {
    position: relative;
    /* overflow: hidden; */
    width: 635px;
    height: 480px;
}
.product-image-container img:hover {
    cursor: zoom-in;
}

.product-image-container img {
    width: 100%;
    height: 100%;
}

.zoomed-image {
    position: absolute;
    top: 0;
    left: 103%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: 999;
}
.modal-gallery-slider {
    margin-top: 24px;
}
.modal-gallery-slider .swiper .swiper-wrapper .swiper-slide {
    width: 72px !important;
    height: 72px !important;
    margin-right: 20px !important;
}
.modal-gallery-slider .swiper .swiper-wrapper .swiper-slide img {
    width: 100%;
    height: 100%;
}
.payment-currency-img img {
    object-fit: contain;
    background: #275b7d;
    border-radius: 4px;
    padding: 5px 10px;
}

/* Additional CSS */
.offcanvas.offcanvas-end {
    z-index: 9999;
    margin-top: 74px;
    border: none;
    box-shadow: -16px 0 32px rgba(34, 87, 122, 0.16);
}

.testimonial-slider-inner .swiper-button-next.swiper-button-disabled,
.testimonial-slider-inner .swiper-button-prev.swiper-button-disabled {
    opacity: 1;
    cursor: pointer;
    pointer-events: initial;
}

@media only screen and (max-width: 767px) {
    .testimonial-slider-inner .swiper-backface-hidden .swiper-slide {
        margin-right: 30px !important;
    }
}


